<template>
  <section class="w-full lg:max-w-[597px]">
    <h1 class="text-[32px] leading-[40px] font-aktiv-grotesk-medium">
      {{ selectorTitle }}
    </h1>
    <div class="items-center mt-6">
      <div
        v-for="button in buttons"
        :key="button.name"
        class="flex mb-2 gap-6 items-center rounded-lg px-[16px] py-[12px] h-[64px] border-1"
        :class="borderCSS(button.value)"
        @click="selectedButton(button.value)"
      >
        <div v-if="button.description" class="flex w-full justify-between">
          <div>
            <div class="text-[#21252C] text-base font-aktiv-grotesk-medium">
              {{ button.name }}
            </div>
            <div class="text-[#637083] text-[10px] font-aktiv-grotesk-regular">
              {{ button.description }}
            </div>
          </div>
          <img
            v-if="button.value === selectedValue"
            class="w-[28px]"
            src="../../../public/assets/icons/blue-tick-language-toggle.svg"
            alt="blue-tick Icon"
          />
        </div>
        <div v-else class="flex w-full justify-between">
          <div>
            <div
              class="text-[#21252C] text-base font-aktiv-grotesk-medium"
              :style="getChineseTextSize(button.name)"
            >
              {{ button.name }}
            </div>
          </div>
          <img
            v-if="button.value === selectedValue"
            class="w-[28px]"
            src="../../../public/assets/icons/blue-tick-language-toggle.svg"
            alt="blue-tick Icon"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  props: {
    selectorTitle: {
      type: String,
      default: "",
    },
    buttons: {
      type: Array,
      required: true,
    },
    selectedValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    selectedButton(value) {
      this.$emit("button-selected", value);
    },
    borderCSS(buttonValue) {
      const isSelected = this.selectedValue === buttonValue;
      return isSelected
        ? "border-[#00A9E0] border-2"
        : "border-[##D3D8DE] border-2";
    },
    getChineseTextSize(text) {
      if (this.$i18n.locale === "th" && text === "简体中文") {
        return { fontSize: "16px" };
      }
      return "";
    },
  },
};
</script>

<style scoped></style>
