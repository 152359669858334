<template>
  <div class="flex justify-between items-center">
    <div class="xs:mx-6 md:mx-8 lg:w-[750px] lg:mx-auto">
      <a :href="link" target="_blank" data-event-tracking="gensapp-home-cvp">
        <img
          :src="`${thumbnailImgMobile}`"
          class="md:hidden w-full mt-[48px]"
          @click="registerAnalytics"
        />
        <img
          :src="`${thumbnailImgTablet}`"
          class="hidden w-full md:block mt-[64px]"
          @click="registerAnalytics"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
export default {
  name: "CVPCard",
  props: {
    link: {
      type: String,
    },
    thumbnailImgMobile: {
      type: String,
    },
    thumbnailImgTablet: {
      type: String,
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    registerAnalytics() {
      FirebaseAnalytics.logEvent({
        name: "click_button", 
        params: {
          "button_name": "gensapp-home-cvp",
          "page": "home"  
        }
      })
    }
  },
};
</script>

<style scoped></style>
