<template>
  <master-layout>
    <div
      class="font-aktiv-grotesk bg-grey-5 w-full md:max-w-[506px] md:mx-auto px-[24px] md:px-0 lg:px-0 pt-[16px] md:pt-[40px] pb-[32px]"
      :class="{ '!pt-[60px]': isIos() }"
    >
      <div class="fixed bottom-0 z-50">
        <CheatConsole v-if="isNonProd"></CheatConsole>
      </div>
      <div class="mb-6">
        <img
          src="../../../public/assets/icons/go-back-chevron.svg"
          alt="Back Icon"
          class="cursor-pointer"
          data-test-id="gensapp-experiencesignup-back"
          @click="back()"
        />
      </div>

      <section class="flex flex-row mb-3">
        <h1
          class="text-[32px] font-aktiv-grotesk-medium mb-[4px] font-aktiv-grotesk-medium"
        >
          Submit Preferences
        </h1>
      </section>

      <div class="flex flex-col items-center w-full">
        <div class="bg-white shadow-md pl-4 py-6 rounded-xl mb-6 w-full">
          <h1 class="text-[20px] font-aktiv-grotesk-medium mb-3 pr-4">
            Custom Experiences Preferences
          </h1>
          <div
            v-for="(exp, index) in pending_signups"
            :key="index"
            class="font-aktiv-grotesk-regular flex mb-2"
          >
            <div
              class="w-[48px] font-aktiv-grotesk-medium bg-[#FF660026] text-orange p-2 text-[14px] leading-[10px] text-center rounded-xl mr-2"
            >
              {{ getRankingNumber(index + 1) }}
            </div>
            {{ exp.name }}
          </div>
        </div>
        <p class="pb-6">
          You'll be allocated to {{ this.mainExpData.allocatedExpsNo }} Custom
          Experiences based on your preferences!
        </p>
        <button
          class="font-aktiv-grotesk-trial block w-full mx-auto max-w-[295px] rounded-[100px] text-white text-[20px] leading-[24px] text-center py-[12px] mb-4 bg-orange hover:bg-[#F27521] cursor-pointer"
          data-event-tracking="gensapp-experiences-submitpreferences-button"
          @click="signed() && getModalShow()"
        >
          Submit
        </button>
      </div>
      <InterestModal
        :getExpAlloc="getExpAlloc()"
        :show="showModal"
        @close-modal="closeModal"
      ></InterestModal>
    </div>
  </master-layout>
</template>
<script>
import moment from "moment";
import momentts from "moment-timezone";
import { mapActions, mapGetters, mapState } from "vuex";
import InterestModal from "../../components/ExperiencePage/InterestModal.vue";
import axios from "axios";
import { isIosNativeOnly } from "@/utils/platform-check";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export default {
  name: "Summary",
  components: { InterestModal },
  data() {
    return {
      showModal: false,
      showCancelModal: false,
    };
  },
  computed: {
    ...mapGetters("signup", ["CG_SIGN_UP_CLOSE_TS"]),
    ...mapGetters("profile", ["type"]),
    ...mapState("signup", [
      "signups",
      "pending_signups",
      "EXP_ALLOC_TS",
      "mainExpData",
    ]),
    ...mapState("user", ["token"]),
  },
  methods: {
    ...mapActions("signup", ["setSignupState", "setBackFromSummary"]),
    getModalShow() {
      this.showModal = !this.showModal;
    },
    closeModal() {
      this.showModal = false;
    },
    isIos() {
      return isIosNativeOnly();
    },
    async signed() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "gensapp-experiences-submitpreferences-button",
          page: "experience",
        },
      });
      const expTypeList = this.pending_signups.map((element) => element.type);

      const { status, data } = await axios.request(
        process.env.VUE_APP_API_URL + "/exps/signup",
        {
          method: "post",
          // change to receive a list of exp types
          // data: { types: ["CG", "MD", "DSN"] },
          data: { types: expTypeList },
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      if (status !== 201) location.reload();
      this.setSignupState(data);

      this.$emit("sign-up");
    },

    back() {
      this.$router.go(-1);
      this.setBackFromSummary(true);
    },
    getRankingNumber(i) {
      let j = i % 10,
        k = i % 100;
      if (j === 1 && k !== 11) {
        return i + "st";
      }
      if (j === 2 && k !== 12) {
        return i + "nd";
      }
      if (j === 3 && k !== 13) {
        return i + "rd";
      }
      return i + "th";
    },
    getExpAlloc() {
      momentts.tz.setDefault("Asia/Singapore");
      const dayStr = momentts(this.EXP_ALLOC_TS).format("dddd, D MMMM");
      const timeStr = momentts(this.EXP_ALLOC_TS).format("ha");
      return dayStr + " at " + timeStr;
    },
  },
};
</script>
