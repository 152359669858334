export const toChineseNumeral = (num) => {
  const units = ["", "十", "百", "千", "万", "亿"];
  const digits = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];

  if (num === 0) return digits[0];

  let result = "";
  let unitIndex = 0;
  let needZero = false;

  while (num > 0) {
    const part = num % 10;

    if (part === 0) {
      needZero = true;
    } else {
      if (needZero) {
        result = digits[0] + result; // Add a '零' when necessary
        needZero = false;
      }
      result = digits[part] + (units[unitIndex] || "") + result;
    }

    unitIndex++;
    num = Math.floor(num / 10);
  }

  // Handle cases like '一十X' to '十X'
  if (result.startsWith("一十")) {
    result = result.substring(1);
  }

  return result;
};
