<template>
    <component :is="tag">
      <slot name="trigger" v-bind="{ toggle, isOpen }" />
      <slot name="collapsible" v-bind="{ isOpen }" />
    </component>
  </template>
  
  <script>
  import HasLocalValue from '@/mixins/HasLocalValue'
  
  export default {
    mixins: [HasLocalValue],
  
    props: {
      tag: {
        type: String,
        default: 'div',
      },
      initialOpen: {
        type: Boolean,
        default: false,
      },
    },
  
    computed: {
      // alias for localValue
      isOpen() {
        return this.localValue
      },
    },
  
    created() {
      if (this.initialOpen) {
        this.localValue = this.initialOpen
      }
    },
  
    methods: {
      toggle() {
        this.localValue = !this.localValue
        this.$emit(this.localValue ? 'opened' : 'closed')
      },
    },
  }
  </script>