<template>
  <div>
    <router-link
      v-if="!isExternal"
      class="flex items-center pt-[20px] pb-[16px] pl-[26px] text-[16px] leading-[20px] rounded-xl button-font font-semibold hover:text-lightblue h-[56px]"
      style="box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1)"
      :class="
        isActive
          ? 'bg-grey-10 text-lightblue'
          : 'bg-white text-grey-100 drop-shadow-tile'
      "
      :to="to"
      :data-test-id="dataTestId"
      @mouseover.native="hover = true"
      @mouseleave.native="hover = false"
      @click.native="$emit('clicked-button')"
    >
      <component :is="icon" :is-active="isActive || hover" />
      <span class="ml-[20px]">{{ label }}</span>
    </router-link>
    <a
      v-else
      class="flex items-center pt-[20px] pb-[16px] pl-[26px] text-[16px] leading-[20px] rounded-lg button-font font-semibold hover:underline h-[56px] bg-white text-grey-100 drop-shadow-tile"
      :href="to"
      :data-test-id="dataTestId"
      target="_blank"
    >
      <component :is="icon" />
      <span class="ml-[20px]">{{ label }}</span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    isExternal: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
      required: true,
    },
    label: {
      type: String,
      default: null,
      required: true,
    },
    icon: {
      type: Object,
      default: null,
      required: true,
    },
    dataEventTracking: {
      type: String,
      default: null,
    },
    dataTestId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    isActive() {
      const isIncludes = this.$route.path.includes(this.to) && this.to !== '/'
      return this.$route.path === this.to || isIncludes
    },
  },
}
</script>

<style scoped>
.button-font {
  font-size: 16px;
}
</style>
