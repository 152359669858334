<template>
  <div
    v-if="!areExpsOver"
    class="relative pt-[48px] pb-[40px] text-center w-full bg-orange text-white"
  >
    <div
      class="relative md:flex md:justify-between lg:justify-center md:flex-row z-10 w-full"
    >
      <div
        id="topbox"
        class="flex flex-col md:items-start md:my-auto mx-auto w-full"
      >
        <div
          v-if="!isSignupClosed() && !hasSignupBefore"
          class="px-6 mx-auto md:m-0 md:flex md:justify-center md:items-center w-full"
        >
          <div class="lg:w-[390px]">
            <p
              class="text-[32px] text-left leading-[40px] text-white font-aktiv-grotesk-medium mx-auto md:ml-0"
            >
              Experiences
            </p>

            <p
              class="text-[16px] text-left mx-auto leading-[24px] text-white my-[16px] font-aktiv-grotesk-regular"
            >
              Go behind the scenes and see GenerationS & Strong Church
              principles come to life.
            </p>
            <img
              class="w-full aspect-ratio mx-auto cursor-pointer rounded-[18px]"
              src="https://cdn.generationsmvmt.com/images/app/exp-homepage-thumbnail-noborder.gif"
              @click="goToExperience()"
            />
            <p
              class="text-[11px] xs:text-[14px] md:text-left heading-[24px] text-white font-aktiv-grotesk italic mx-auto mt-[8px]"
            >
              {{ getSignUpText() }}
            </p>
            <!-- <img
              src="../../../public/assets/images/white-underline.svg"
              class="w-[270px] mx-auto md:ml-0"
            /> -->
          </div>
          <div
            class="hidden md:block md:ml-[65px] w-[295px] h-[197px] md:flex-shrink-0"
          >
            <img
              class="w-[295px] h-[197px] mx-auto cursor-pointer rounded-[18px] hidden md:block"
              src="https://cdn.generationsmvmt.com/images/app/exp-homepage-thumbnail-noborder.gif"
              @click="goToExperience()"
            />
          </div>
        </div>

        <h1
          class="text-[32px] text-left leading-[40px] text-white font-aktiv-grotesk-medium pb-4 mx-auto px-6 md:px-0 w-full md:w-[327px]"
          :class="{
            'md:!w-full md:!px-8': overallStatus === 'Confirmed',
            hidden: !hasSignupBefore && overallStatus !== 'Unavailable',
          }"
        >
          Experiences
        </h1>
        <div
          v-if="
            overallStatus === 'Submitted Interest' ||
            overallStatus === 'Pending'
          "
          class="md:max-w-[327px] md:mx-auto"
        >
          <p
            class="text-[16px] text-left mx-auto leading-[24px] text-white my-4 font-aktiv-grotesk-regular mx-6 md:mx-auto"
          >
            Great! We've received your sign-up.
          </p>
          <p
            class="text-[16px] text-left mx-auto leading-[24px] text-white mt-4 mb-6 font-aktiv-grotesk-regular mx-6 md:mx-auto"
          >
            Custom Experiences will be confirmed by
            <span class="font-aktiv-grotesk-medium"> {{ getExpAlloc }}</span
            >. Come back to see which experiences you'll be attending!
          </p>
          <router-link to="/experience">
            <div class="mx-6 md:mx-auto">
              <button
                class="button-shadow w-full xs:w-[295px] rounded-[100px] px-[20px] py-[12px] bg-white text-[20px] heading-[24px] text-orange font-aktiv-grotesk-medium mx-auto"
              >
                View Experiences
              </button>
            </div>
          </router-link>
          <p
            class="text-[11px] xs:text-[14px] heading-[24px] text-white font-aktiv-grotesk italic mx-auto mt-[8px]"
          >
            {{ getSignUpText() }}
          </p>
          <!-- <img
            v-if="overallStatus === 'Submitted Interest'"
            src="../../../public/assets/images/white-underline.svg"
            class="w-[270px] mx-auto"
          /> -->
        </div>

        <div v-if="overallStatus === 'Confirmed'" class="w-full">
          <p
            class="text-[16px] text-left leading-[24px] text-white font-aktiv-grotesk pb-4 mx-auto px-6 md:px-8 md:w-full"
          >
            Check your confirmed allocation in the Experiences page!
          </p>
          <ExperienceCarousel
            ref="home-page-exp"
            baseSwiperId="home-page-exp"
            :displayTypes="displayTypes"
            :swiperOptions="swiperOptions"
            @show-popup="triggerExpPopup"
          ></ExperienceCarousel>
          <router-link to="/experience">
            <div class="mx-6 md:mx-auto">
              <button
                class="button-shadow w-full xs:w-[295px] rounded-[100px] px-[20px] py-[12px] bg-white text-[20px] heading-[24px] text-orange font-aktiv-grotesk-medium mx-auto"
              >
                View Experiences
              </button>
            </div>
          </router-link>
        </div>
        <div
          v-if="overallStatus === 'Unavailable'"
          class="md:max-w-[327px] md:mx-auto"
        >
          <p
            class="text-[16px] text-left leading-[24px] text-white mb-4 font-aktiv-grotesk-regular mx-6 md:mx-0"
          >
            Join us for Experiences next time! See you!
          </p>
          <router-link to="/experience">
            <div class="mx-6 md:mx-auto">
              <button
                class="button-shadow w-full xs:w-[295px] rounded-[100px] px-[20px] py-[12px] bg-white text-[20px] heading-[24px] text-orange font-aktiv-grotesk-medium mx-auto"
              >
                View Experiences
              </button>
            </div>
          </router-link>
          <p
            class="text-[11px] xs:text-[14px] heading-[24px] text-white font-aktiv-grotesk italic mx-auto mt-[8px]"
          >
            {{ getSignUpText() }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import momentts from "moment-timezone";
import { mapGetters, mapState } from "vuex";
import BaseSwiper from "../common/BaseSwiper.vue";
import ExperienceCarousel from "@/components/ExperiencePage/ExperienceCarousel.vue";
import ExpCard from "../../components/ExperiencePage/ExpCard";

export default {
  name: "ExperienceEntrySection",
  components: {
    BaseSwiper,
    ExperienceCarousel,
    ExpCard,
  },
  data() {
    return {
      readMore: false,
      swiperOptions: {
        centeredSlides: false,
        slidesPerView: "auto",
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true,
        },
        hasNavigation: true,
        watchOverflow: true,
      },
    };
  },
  props: {
    getExpAlloc: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters("profile", ["type"]),
    isSCS() {
      return this.type === "SCS";
    },
    ...mapState("signup", [
      "signups",
      "SIGN_UP_CLOSE_TS",
      "EXP_EVENT_END_TS",
      "EXP_ALLOC_TS",
    ]),
    ...mapState("timer", ["currDate"]),
    areExpsOver() {
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      const expEventEndDate = new Date(this.EXP_EVENT_END_TS);

      return currentDate.getTime() >= expEventEndDate.getTime();
    },
    overallStatus() {
      if (this.hasSignupBefore && !this.isExpAllocated())
        return "Submitted Interest";

      const statusSet = new Set();
      for (const expType in this.signups) {
        const exp = this.signups[expType];
        if (exp && exp.status) statusSet.add(exp.status);
      }

      if (this.isSignupClosed() && statusSet.size == 0) return "Unavailable";

      if (statusSet.size === 1 && statusSet.has("Unavailable"))
        return "Unavailable";

      if (statusSet.has("Confirmed")) return "Confirmed";

      // if (statusSet.has("Pending")) return "Pending";

      return "";
    },
    hasSignupBefore() {
      let result = false;
      for (const expType in this.signups) {
        result = result || Boolean(this.signups[expType]);
      }
      return result;
    },
    signupsByRank() {
      let signupArray = Object.values(this.signups);
      // remove null element
      // remove those is unavaliable status

      signupArray = signupArray.filter((element) => {
        return element && element.status !== "Unavailable";
      });

      signupArray.sort((a, b) => {
        if (a.ranking < b.ranking) return -1;
        if (a.ranking > b.ranking) return 1;
        return 0;
      });

      return signupArray;
    },
    displayTypes() {
      if (!this.signupsByRank.length) {
        return ["CG", "DSN", "MCR", "MD", "LM", "MOH"];
      }

      let registeredTypes = [];
      registeredTypes = this.signupsByRank.map((element) => {
        return element.type;
      });

      return registeredTypes;
    },
  },
  methods: {
    isSignupClosed() {
      // needs refectoring (we are just using CG signup close as of now)
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      const signUpExpiry = new Date(this.SIGN_UP_CLOSE_TS);
      // console.log("signUpExpiry: " + signUpExpiry);
      return currentDate.getTime() >= signUpExpiry.getTime();
    },
    isExpAllocated() {
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      const expAllocDate = new Date(this.EXP_ALLOC_TS);
      return currentDate.getTime() >= expAllocDate.getTime();
    },
    triggerExpPopup(exp) {
      this.$emit("show-popup", exp);
    },
    goToExperience() {
      this.$router.push("/experience");
    },
    getSignUpText() {
      if (this.isSignupClosed()) return "Sign-ups are closed";

      momentts.tz.setDefault("Asia/Singapore");
      const dayStr = momentts(this.SIGN_UP_CLOSE_TS).format(
        // "ddd, DD MMM, hh:mm A"
        "dddd, D MMMM"
      );
      return "⚠️  Sign-ups close on " + dayStr + ", 7PM" + ".";
    },
    updateCarousel() {
      if (this.$refs["home-page-exp"]) this.$refs["home-page-exp"].update();
    },
  },
};
</script>
<style scoped>
.aspect-ratio {
  aspect-ratio: 295/197;
}
.button-shadow {
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
}
::v-deep(.swiper-pagination) {
  display: none;
}

::v-deep(.swiper-pagination-bullet) {
  background-color: #fff3ec;
}

::v-deep(.swiper-pagination-bullet-active) {
  background-color: #ffffff;
}

::v-deep(.swiper-slide:last-child) {
  margin-right: 48px;
}
::v-deep(.swiper-container) {
  padding-bottom: 24px;
}

@media all and (min-width: 768px) {
  ::v-deep(.swiper-wrapper) {
    padding-left: 32px;
    justify-content: center;
  }
  ::v-deep(.swiper-slide:last-child) {
    margin-right: 64px;
  }
}

@media all and (min-width: 1024px) {
  ::v-deep(.swiper-wrapper) {
    padding-left: calc((100% - 750px) / 2);
  }

  ::v-deep(.swiper-slide:last-child) {
    margin-right: calc(100% - 750px);
  }
}
</style>
