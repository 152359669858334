<template>
  <BaseModal :show="show" @close-popup="closeModal()">
    <div
      class="bg-white text-grey-100 w-[327px] left-[50%] right-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-[24px] leading-[32px] rounded-2xl m-auto fixed box-shadow shadow-[0_1px_25px_rgba(0,0,0,0.25) z-50 p-6"
      @wheel.prevent
      @touchmove.prevent
      @scroll.prevent
      @click.stop
    >
      <img
        src="/assets/icons/playback-resources/close--black.svg"
        class="absolute w-[16px] h-[16px] right-6 top-6 cursor-pointer"
        alt=""
        @click="closeModal()"
      />
      <div
        class="font-aktiv-grotesk font-medium text-left text-[24px] leading-[32px] mb-[4px]"
      >
        {{ modalCopy }}
      </div>
      <div
        v-for="resource in resources"
        :key="resource.language"
        class="flex flex-row justify-between mt-[12px]"
      >
        <div class="flex items-center">
          <p class="font-aktiv-grotesk font-medium text-[20px] leading-[24px]">
            {{ setLanguageCopy(resource.language) }}
          </p>
        </div>
        <a
          v-if="modalType === 'download'"
          :href="setUrl(resource)"
          class="cursor-pointer"
          download
          target="_blank"
        >
          <img
            :src="modalIcon"
            class="w-10 h-10"
            alt=""
          />
        </a>
        <a
          v-else
          :href="setUrl(resource)"
          class="cursor-pointer"
          :target="resource.isExternal ? '_blank' : ''"
        >
          <img
            :src="modalIcon"
            class="w-10 h-10"
            alt=""
          />
        </a>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/ResourcesPage/PlaybackResources/BaseModal.vue";

export default {
  components: {
    BaseModal
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    resources: {
      type: Array,
      required: true,
    },
    modalType: {
      type: String,
      default: '',
    },
    slug: {
      type:String,
      required: true,
    },
    code: {
      type: String,
      default:"",
    }
  },
  computed: {
    modalCopy() {
      if(this.modalType === 'video') return 'Watch video'
      else if(this.modalType === 'download') return 'Download file'
      else return 'Read article'
    },
    modalIcon() {
      if(this.modalType === 'video') return require("../../../../public/assets/icons/playback-resources/arrow-link.svg")
      else if(this.modalType === 'download') return require("../../../../public/assets/icons/playback-resources/download-pdf.svg")
      else return require("../../../../public/assets/icons/playback-resources/link-out.svg")
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal')
    },
    setLanguageCopy(language) {
      if(language === 'TradChi') return '繁体中文'
      else if(language === 'Thai') return 'ไทย'
      else return 'English'
    },
    setUrl(resource) {
      if(resource.isExternal || this.modalType === 'download') return resource.resourceUrl
      else return `/resources/video/${this.slug}`
    },
  },
}
</script>
