<template>
  <div
    class="absolute top-0 right-0 text-[14px] leading-[20px] font-aktiv-grotesk text-center rounded-bl-2xl rounded-tr-2xl p-1 px-2 mb-1"
    :class="COLOR_MAP[color]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CardLabel',
  props: {
    color: {
      type: String,
      default: 'grey',
    }
  },
  data() {
    return {
      COLOR_MAP: {
        'grey-20': 'bg-grey-20',
        grey: 'bg-grey-10',
        lightgrey: 'bg-lightgrey',
        green: 'bg-green',
        yellow: 'bg-yellow',
        lightyellow: 'bg-pale-yellow',
        orange: 'bg-orange bg-opacity-25 text-grey-70',
        lightorange: 'bg-light-orange',
        paleorange: 'bg-pale-orange text-orange',
        'pending-yellow': 'bg-pending-yellow',
        paledarkblue: 'bg-paledarkblue',
      },
    }
  },
}
</script>

<style></style>
