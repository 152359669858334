<template>
  <Pill
    link=""
    :text="$t('copy.homePage.mainLayout.usefulLinks.links.gettingAround')"
    data-event-tracking="gensapp-home-view-map"
    @click-trigger="openMap"
  />
</template>

<script>
import Pill from "./Pill.vue";
import { Browser } from "@capacitor/browser";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export default {
  name: "MapSection",
  components: {
    Pill,
  },
  methods: {
    logEvent() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "gensapp-home-view-map",
          page: "home",
        },
      });
    },
    openMap() {
      this.logEvent();
      Browser.open({
        url: this.$t("copy.homePage.mainLayout.usefulLinks.links.mapLink"),
        windowName: "_self",
      });
    },
  },
};
</script>

<style></style>
