<template>
  <div class="relative">
    <img
      v-show="curIndex !== 0 && noOfItems > 3"
      aria-label="Previous"
      class="absolute top-[45%] left-[-48px] w-[32px] h-[32px] cursor-pointer hidden lg:block"
      :class="`${title}-prev`"
      src="/assets/icons/playback-resources/chevron-left--black.svg"
      alt=""
    />

    <img
      v-show="curIndex !== noOfItems - 1 && noOfItems > 3"
      aria-label="Next"
      class="absolute top-[45%] right-[-48px] w-[32px] h-[32px] cursor-pointer hidden lg:block"
      :class="`${title}-next`"
      src="/assets/icons/playback-resources/chevron-right--black.svg"
      alt=""
    />
    <div :id="title" :ref="title" class="scrollbar-hide relative">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Glider from 'glider-js'

import 'glider-js/glider.css'

export default {
  props: {
    itemWidth: {
      type: Number,
      default: 320,
    },
    mobileItemWidth: {
      type: Number,
      default: 280,
    },
    title: {
      type: String,
      default: 'glider',
    },
    noOfItems: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      glider: null,
      curIndex: 0,
    }
  },
  mounted() {
    this.glider = new Glider(this.$refs[`${this.title}`], {
      slidesToShow: 'auto',
      slidesToScroll: 1,
      scrollLock: true,
      draggable: true,
      itemWidth: this.mobileItemWidth,
      exactWidth: true,
      arrows: {
        prev: `.${this.title}-prev`,
        next: `.${this.title}-next`,
      },
      responsive: [
        {
          // screens greater than >= 775px
          breakpoint: 768,
          settings: {
            itemWidth: this.itemWidth,
          },
        },
      ],
    })
    this.initListenOnSlide()
  },
  methods: {
    initListenOnSlide() {
      this.$refs[`${this.title}`].addEventListener(
        'glider-slide-visible',
        ({ detail }) => {
          this.curIndex = detail.slide
        }
      )
    },
    scrollToItem(index) {
      this.glider.scrollItem(index)
    },
  },
}
</script>

<style scoped lang="scss">
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
