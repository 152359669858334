<template>
  <div @click="openBrowser" :data-event-tracking="dataEventTracking">
    <slot></slot>
  </div>
</template>

<script>
import { Browser } from "@capacitor/browser";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
export default {
  name: "InAppBrowser",
  props: {
    href: {
      type: String,
    },
    dataEventTracking: {
      type: String,
      default: "",
    },
    page: {
      type: String,
      default: "",
    },
  },
  methods: {
    getPage() {
      if (this.page) return this.page;
      return this.$route.path.split("/").pop();
    },
    openBrowser() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: this.dataEventTracking,
          page: this.getPage(),
        },
      });
      this.$emit("log-event");
      Browser.open({
        url: this.href,
      });
    },
  },
};
</script>
