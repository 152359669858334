import { createRouter, createWebHistory } from "@ionic/vue-router";
import i18n from "../utils/i18n"
import { Browser } from "@capacitor/browser";
import Team from "@/pages/team.vue";
import Login from "@/pages/login/index.vue";
import Home from "../pages/home/index.vue";
import Experience from "../pages/experience/index.vue";
import Schedule from "../pages/schedule/index.vue";
import SessionCard from "../pages/schedule/session-card/_name.vue";
import Announcement from "../pages/announcement/index.vue";
import Resources from "../pages/resources/index.vue";
import ResourceSearch from "../pages/resources/resource-search.vue";
import ResourcesSessions from "../pages/resources/sessions.vue";
import ResourcesBooks from "../pages/resources/book.vue";
import ResourcesLearningCommunities from "../pages/resources/learning-communities.vue";
import ResourcesGensMvmt from "../pages/resources/gensmvmt.vue";
import PhotoDrop from "../pages/photodrop.vue";
import Survey from "../pages/survey/_name.vue";
import Access2022 from "../pages/login/2022-access.vue";
import AccessDenied from "../pages/login/access-denied.vue";
import TermsOfUse from "../pages/terms-of-use.vue";
import PrivacyPolicy from "../pages/privacy-policy.vue";
import { createAuthGuard, useAuth0 } from "@auth0/auth0-vue";
import Summary from "../pages/experience/summary.vue";
import { isNativeNotWeb } from "@/utils/platform-check";
import Profile from "../pages/profile/index.vue";
import PubProfile from "../pages/profile/pub/_id.vue";
import EditProfile from "../pages/profile/edit.vue";
import QRCode from "../pages/qr-code.vue";
import ProfileCrop from "../pages/profile/crop.vue";
import PreSignIn from "../pages/pre-sign-in.vue";
import ResourceFilter from "../pages/resources/filter-results.vue";
import VideoPage from "../pages/video/_video.vue";
import { auth0 } from "@/utils/auth0";
import store from "@/store";
import {
  getCurrentConferenceType,
  isDelegateGPCOnly,
  isDelegateGPCROnly,
} from "@/utils/conference-type.js";
import GPCR from "@/pages/gpcr/home/index.vue";
import GPCRAnnouncement from "@/pages/gpcr/announcement/index.vue";
import GPCRSchedule from "@/pages/gpcr/schedule/index.vue";
import GPCRConference from "../pages/gpcr/conference.vue";
import GPCRLanguage from "../pages/gpcr/language.vue";
import GPCRExperience from "../pages/gpcr/experience/index.vue";
import GPCRPhotodrop from "../pages/gpcr/photodrop.vue";
import GPCRResources from "../pages/gpcr/resources/index.vue";
import GPCRResourceSearch from "../pages/gpcr/resources/resource-search.vue";
import GPCRResourcesSessions from "../pages/gpcr/resources/sessions.vue";
import GPCRResourcesBooks from "../pages/gpcr/resources/book.vue";
import GPCRResourceFilter from "../pages/gpcr/resources/filter-results.vue";
import GPCRTeam from "@/pages/gpcr/team.vue";
import GPCRSessionCard from "../pages/gpcr/schedule/session-card/_name.vue";
import GPCRVideoPage from "../pages/gpcr/video/_video.vue";

const routes = [
  {
    path: "/",
    redirect: isNativeNotWeb() ? "/pre-sign-in" : "/login",
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/2022-access",
    component: Access2022,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/experience",
    component: Experience,
  },
  {
    path: "/experience/summary",
    component: Summary,
  },
  {
    path: "/schedule",
    component: Schedule,
  },
  {
    path: "/schedule/session-card/:name",
    component: SessionCard,
  },
  {
    path: "/announcement",
    component: Announcement,
  },
  {
    path: "/resources",
    component: Resources,
  },
  {
    path: "/resources/sessions",
    component: ResourcesSessions,
  },
  {
    path: "/resources/sessions/search",
    component: ResourceSearch,
  },
  {
    path: "/resources/sessions/filter",
    component: ResourceFilter,
  },
  // {
  //   path: "/resources/learning-communities",
  //   component: ResourcesLearningCommunities,
  // },
  {
    path: "/resources/book",
    component: ResourcesBooks,
  },
  {
    path: "/resources/more-resources",
    component: ResourcesGensMvmt,
  },
  {
    path: "/team",
    component: Team,
  },
  {
    path: "/photodrop",
    component: PhotoDrop,
  },
  {
    path: "/survey/:name",
    component: Survey,
  },
  {
    path: "/terms-of-use",
    component: TermsOfUse,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/access-denied",
    component: AccessDenied,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/resources/video/:video",
    component: VideoPage,
  },
  {
    path: "/profile/pub/:id",
    component: PubProfile,
  },
  {
    path: "/profile/edit",
    component: EditProfile,
  },
  {
    path: "/qr-code",
    component: QRCode,
  },
  {
    path: "/profile/crop",
    component: ProfileCrop,
  },
  {
    path: "/gpcr/home",
    component: GPCR,
  },
  {
    path: "/gpcr/announcement",
    component: GPCRAnnouncement,
  },
  {
    path: "/gpcr/schedule",
    component: GPCRSchedule,
  },
  {
    path: "/gpcr/conference",
    component: GPCRConference,
  },
  {
    path: "/gpcr/language",
    component: GPCRLanguage,
  },
  {
    path: "/gpcr/experience",
    component: GPCRExperience,
  },
  {
    path: "/gpcr/photodrop",
    component: GPCRPhotodrop,
  },
  {
    path: "/gpcr/resources",
    component: GPCRResources,
  },
  {
    path: "/gpcr/resources/sessions",
    component: GPCRResourcesSessions,
  },
  {
    path: "/gpcr/resources/sessions/search",
    component: GPCRResourceSearch,
  },
  {
    path: "/gpcr/resources/sessions/filter",
    component: GPCRResourceFilter,
  },
  {
    path: "/gpcr/resources/book",
    component: GPCRResourcesBooks,
  },
  {
    path: "/gpcr/team",
    component: GPCRTeam,
  },
  {
    path: "/gpcr/schedule/session-card/:name",
    component: GPCRSessionCard,
  },
  {
    path: "/gpcr/resources/video/:video",
    component: GPCRVideoPage,
  },
];

if (isNativeNotWeb()) {
  routes.push({
    path: "/pre-sign-in",
    component: PreSignIn,
  });
}

routes.forEach((route) => {
  // these are for those page no need logins
  const excludeGuardPaths = [
    "/",
    "/2022-access",
    "/access-denied",
    "/privacy-policy",
    "/terms-of-use",
    "/profile/pub/:id",
    "/pre-sign-in",
  ];
  // these are for web only
  if (
    !excludeGuardPaths.includes(route.path) &&
    process.env.VUE_APP_NODE_ENV !== "local" &&
    !isNativeNotWeb()
  )
    route.beforeEnter = createAuthGuard({
      redirectLoginOptions: {
        appState: {
          returnTo: window.location.pathname, // here
        },
      },
    });
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  const excludeGuardPaths = [
    "/",
    "/2022-access",
    "/access-denied",
    "/privacy-policy",
    "/terms-of-use",
    "/profile/pub/:id",
    "/pre-sign-in",
  ];
  console.log("inside router before Each");
  if (process.env.VUE_APP_NODE_ENV === "local") {
    if (!localStorage.getItem("token") && to.path !== "/login")
      return { path: "/login" };
    return;
  }

  console.log("login - before");
  if (
    !excludeGuardPaths.includes(to.path) &&
    process.env.VUE_APP_NODE_ENV !== "local" &&
    to.path !== "/login" &&
    isNativeNotWeb()
  ) {
    console.log("login - before use auth0");
    console.log("login - ", auth0);
    console.log("login - is Authent", auth0.isAuthenticated.value);
    // Check if the user is authenticated
    if (!auth0.isAuthenticated.value) {
      // If the user is not authenticated, redirect to the login page
      console.log("login - before forward login");

      // Redirect to the login page if the user is not authenticated
      if (!auth0.isAuthenticated.value && to.path !== "/login") {
        console.log("login from path - ", from.path);
        console.log("login from query - ", from.query);
        console.log("login from hash - ", from.hash);
        // Get the path from store.state

        return { path: "/login" };
      }

      return { path: "/login" };
    }
    console.log("after use auth0");
  }

  try {
    // token is needed for all APIs
    const token = await auth0.getAccessTokenSilently({
      cacheMode: "cache-only",
    });
    store.commit("user/setToken", token);
    // ROUTING FOR GPCR & GPC -- start
    await store.dispatch("profile/fetchProfile");
    const delegateType = store.state.profile.confYear;
    console.log("delegateType", store.state.profile.confYear);
    // do conf type check and route to gpcr if regional
    console.log("confType", getCurrentConferenceType(delegateType))
    if ( getCurrentConferenceType(delegateType) === "Global" ) {
      i18n.locale = "en"
    }
    // for global only
    if (
      getCurrentConferenceType(delegateType) === "Global" &&
      to.fullPath.includes("gpcr")
    ) {
      console.log("inside global only");
      const newPath = to.fullPath.replace("/gpcr", "");
      const route = router.resolve(newPath);
      if (route.matched.length > 0) {
        return { path: newPath };
      }
    } 
    // for regional only
    if (
      getCurrentConferenceType(delegateType) === "Regional" &&
      !to.fullPath.includes("gpcr")
    ) {
      console.log("inside regional only");
      const gpcrPath = "/gpcr" + to.fullPath;
      const route = router.resolve(gpcrPath);
      if (route.matched.length > 0) {
        return { path: gpcrPath };
      }
    }

    // TODO to clarify the logic for the routing with preference

    // ROUTING FOR GPCR & GPC -- end
  } catch (err) {
    if (err.error === "login_required") {
      auth0.logout({
        logoutParams: {
          returnTo: createLogoutUri(
            process.env.VUE_APP_PACKAGE_ID,
            process.env.VUE_APP_AUTH0_DOMAIN
          ),
        },
        openUrl: async (url) =>
          await Browser.open({
            url,
            windowName: "_self",
          }),
      });
    }
    console.log("in access token error");
    console.log("check error", err.error)
    console.error(err);
  }

  if (to.query.error === "access_denied") {
    console.log("inside to.query error");
    console.log(to.query.error_description);
    if (to.query.error_description === "access 2022") {
      return {
        path: "/2022-access",
        replace: true,
      };
    } else if (to.query.error_description === "access denied") {
      return {
        path: "/access-denied",
        replace: true,
      };
    }
  }
});

export default router;
