<template>
  <master-layout id="contentReourceBook">
    <ResourcesContainer :show-more-resources="moreResourcesCount > 0">
      <div
        v-if="!loading"
        class="flex flex-wrap gap-12 mb-10 w-full px-6 md:px-0"
      >
        <div v-for="book in resources" :key="book.shortName">
          <a
            :href="book.externalLink"
            target="_blank"
            rel="noreferrer noopener"
            class="block relative w-full sm:w-max mb-3 hover:opacity-90"
            :data-test-id="book.dataTestId"
          >
            <img
              class="rounded-[16px] w-[327px]"
              :src="book.thumbnail.url"
              :alt="book.description"
            />

            <div
              v-if="book.isVideo"
              class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            >
              <div
                class="w-[54px] h-[54px] rounded-full bg-grey-100 opacity-40"
              ></div>
              <svg
                class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                width="23"
                height="27"
                viewBox="0 0 23 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.4316 12.7121C23.0703 13.1019 23.0703 14.0294 22.4316 14.4192L2.46972 26.6034C1.80335 27.0102 0.948727 26.5306 0.948727 25.7499L0.948728 1.38147C0.948728 0.600778 1.80335 0.121172 2.46972 0.527904L22.4316 12.7121Z"
                  fill="white"
                  fill-opacity="0.8"
                />
              </svg>
            </div>
          </a>

          <p class="w-full xs:w-[328px]">{{ book.description }}</p>
        </div>
      </div>
      <div v-else class="w-screen h-screen flex items-center justify-center">
        <loading-state />
      </div>
    </ResourcesContainer>
  </master-layout>
</template>

<script>
// import axios from "axios";
import ResourcesContainer from "../../../components/ResourcesPage/ResourcesContainer";
import LoadingState from "@/components/common/LoadingState.vue";
// import Json from '/public/data/resources/books/en.json'
import { mapState } from "vuex";

export default {
  name: "LifeStoryPage",
  components: {
    ResourcesContainer,
    LoadingState,
  },
  data() {
    return {
      // books: Json
      moreResourcesCount: 0
    };
  },
  computed: {
    ...mapState("cms", ["resources", "loading"]),
  },
  async ionViewWillEnter() {
    let resourceName = "gc-app-view-gensmvmt";
    await this.$store.dispatch("cms/fetchResources", resourceName);
    this.moreResourcesCount = this.getMoreResourcesCount();

    resourceName = "gc-app-books-en";
    await this.$store.dispatch("cms/fetchResources", resourceName);
  },
  methods: {
    getMoreResourcesCount() {
      let materialsCount = 0;
      const gensmvmtMaterials = this.resources[0];
      materialsCount += gensmvmtMaterials.tools.length;
      materialsCount += gensmvmtMaterials.relatedResources.length;
      return materialsCount;
    },
  }
  /* async asyncData() {
    const { data: books } = await axios.get(`/data/resources/books/en.json`);

    return { books };
  }, */
};
</script>
