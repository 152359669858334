<template>
  <section class="px-6 md:px-8 lg:px-[56px]">
    <h2
      v-if="!hasSignupBefore && overallStatus !== 'Unavailable'"
      class="text-[20px] leading-[24px] font-aktiv-grotesk-medium"
    >
      What you can look forward to:
    </h2>
    <div
      class="bg-white border-[1px] border-green border-solid rounded-xl p-4"
      :class="
        !hasSignupBefore && overallStatus !== 'Unavailable' ? 'mt-6' : 'mt-3'
      "
    >
      <h2 class="text-[20px] leading-[24px] font-aktiv-grotesk-medium">
        Conference-Wide Experiences
      </h2>
      <p class="italic text-[14px] leading-[24px]">For all delegates</p>
      <ul class="list-none text-[16px] leading-[24px] mt-2">
        <li>✅ &nbsp; Leaders' Meeting Experience</li>
        <li class="mt-1">✅ &nbsp; Ministry Open House</li>
        <li class="mt-1">✅ &nbsp; Pitstop & Training Experience</li>
      </ul>
      <h2 class="text-[20px] leading-[24px] font-aktiv-grotesk-medium mt-3">
        "Walk-in" Experiences
      </h2>
      <p class="italic text-[14px] leading-[24px]">
        Check these out on Fri/Sat nights!
      </p>
      <ul class="list-none text-[16px] leading-[24px] mt-2">
        <li>✅ &nbsp; Student Hangouts</li>
        <li class="mt-1">✅ &nbsp; Building Maintenance</li>
      </ul>
      <div v-if="!hasSignupBefore && overallStatus !== 'Unavailable'">
        <h2 class="text-[20px] leading-[24px] font-aktiv-grotesk-medium mt-3">
          Custom Experiences
        </h2>
        <p class="italic text-[14px] leading-[24px]">Sign-up required</p>
        <ul class="list-none text-[16px] leading-[24px] mt-2">
          <li>✅ &nbsp; Get to attend 2 out of 4 options</li>
          <li class="mt-1 ml-[10px]">• &nbsp; Connect Group Experience</li>
          <li class="mt-1 ml-[10px]">• &nbsp; Dreams & Stories Night</li>
          <li class="mt-1 ml-[10px]">• &nbsp; Debrief Experience</li>
          <li class="mt-1 ml-[10px]">• &nbsp; Prayer Meeting Experience</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: {
    signups: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("signup", ["SIGN_UP_CLOSE_TS", "EXP_ALLOC_TS"]),
    ...mapState("timer", ["currDate"]),
    overallStatus() {
      if (this.hasSignupBefore && !this.isExpAllocated())
        return "Submitted Interest";

      const statusSet = new Set();
      for (const expType in this.signups) {
        const exp = this.signups[expType];
        if (exp && exp.status) statusSet.add(exp.status);
      }

      if (this.isSignupClosed() && statusSet.size == 0) return "Unavailable";

      if (statusSet.size === 1 && statusSet.has("Unavailable"))
        return "Unavailable";

      if (statusSet.has("Confirmed")) return "Confirmed";

      // if (statusSet.has("Pending")) return "Pending";

      return "";
    },
    hasSignupBefore() {
      let result = false;
      for (const expType in this.signups) {
        result = result || Boolean(this.signups[expType]);
      }
      return result;
    },
  },
  methods: {
    isSignupClosed() {
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      const signUpExpiry = new Date(this.SIGN_UP_CLOSE_TS);
      return currentDate.getTime() >= signUpExpiry.getTime();
    },
    isExpAllocated() {
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      const expAllocDate = new Date(this.EXP_ALLOC_TS);
      return currentDate.getTime() >= expAllocDate.getTime();
    },
  },
};
</script>

<style scoped></style>
