<template>
  <master-layout>
    <ImagePreview v-if="showImagePreview" />
  </master-layout>
</template>
<script>
import ImagePreview from "@/components/Profile/ImagePreview.vue";
export default {
  components: {
    ImagePreview,
  },
  data() {
    return {
      showImagePreview: false,
    };
  },
  ionViewWillEnter() {
    this.showImagePreview=false
    this.$nextTick(() => {
          this.showImagePreview=true
        });
  },
};
</script>
