<template>
  <master-layout id="contentResourceLearningCommunity">
    <ResourcesContainer :show-more-resources="moreResourcesCount > 0">
      <div
        class="tracking-wide font-aktiv-grotesk-medium text-[24px] leading-[32px] text-grey-90 px-6 md:px-0 mb-4"
      >
        Videos
      </div>
      <div
        v-if="!loading"
        class="flex flex-wrap gap-12 mb-10 w-full px-6 md:px-0"
      >
        <div
          v-for="learningCommunity in resources"
          :key="learningCommunity.shortName"
          :data-test-id="learningCommunity.dataTestId"
          @click="goTo(learningCommunity.externalLink)"
          class="w-full lg:w-auto"
        >
        <!-- Todo: We will need to refactor this component as it is a duplicate of share it card. Just without share button-->
          <LearningCommunityCard
            class="cursor-pointer hover:opacity-90"
            :image="learningCommunity"
            :options="{ orientation: 'landscape' }"
            card-type="articles"
            :share-url="learningCommunity.externalLink"
            :data-test-id="learningCommunity.dataTestId"
            :is-video="learningCommunity.isVideo"
          />
        </div>
      </div>
      <div v-else class="w-screen h-screen flex items-center justify-center">
        <loading-state />
      </div>
    </ResourcesContainer>
  </master-layout>
</template>

<script>
// import axios from 'axios'
import LearningCommunityCard from "../../components/common/ShareCard/LearningCommunityCard.vue";
import ResourcesContainer from "../../components/ResourcesPage/ResourcesContainer";
import LoadingState from "@/components/common/LoadingState.vue";
// import Json from '/public/data/resources/life-stories/en.json'
import { mapState } from "vuex";

export default {
  name: "LifeStoryPage",
  components: {
    ResourcesContainer,
    LearningCommunityCard,
    LoadingState,
  },
  /*
  async asyncData() {
    const { data: lifeStories } = await axios.get(
      `/data/resources/life-stories/en.json`
    )

    return { lifeStories }
  },
  */
  data() {
    return {
      swiperOptions: {
        centeredSlides: false,
        slidesPerView: "auto",
      },
      moreResourcesCount: 0
      // lifeStories: Json,
      /*
      learningCommunity: {
        imgSrc: '/images/resources/life-stories/From-A-Day-I-Dreaded.png',
        description:
          "Why many HOGC youths dreaded Father's Day, but now they look forward to it.",
        url: 'https://hogcstories.com/from-a-day-i-dreaded-i-now-look-forward-to-fathers-day-every-year/',
      },
      */
    };
  },
  computed: {
    ...mapState("cms", ["resources", "loading"]),
  },
  async ionViewWillEnter() {
    let resourceName = "gc-app-view-gensmvmt";
    await this.$store.dispatch("cms/fetchResources", resourceName);
    this.moreResourcesCount = this.getMoreResourcesCount()
    resourceName = "gc-app-learning-communities-en";
    await this.$store.dispatch("cms/fetchResources", resourceName);
  },
  methods: {
    goTo(url) {
      window.open(url, "_blank");
    },
    getMoreResourcesCount() {
      let materialsCount = 0
      const gensmvmtMaterials = this.resources[0]
      materialsCount += gensmvmtMaterials.tools.length;
      materialsCount += gensmvmtMaterials.relatedResources.length;
      return materialsCount
    },
  },
};
</script>
