<template>
  <div class="relative">
    <div
      :style="{
        'background-image': `url(${image.thumbnail.url})`,
      }"
      :class="{
        flipped: isFlipped,
        'w-[240px] h-[300px]':
          !options.orientation || options.orientation === 'portrait',
        'max-w-[412px] w-full aspect-video lg:w-[360px]':
          options.orientation === 'landscape',
      }"
      class="desktop-swiper-slide-front bg-grey-200 rounded-2xl font-aktiv-grotesk share-it-card bg-no-repeat bg-cover relative"
    >
      <a
        v-if="cardType === 'quotes'"
        :href="getDownloadUrl"
        target="_blank"
        download
        class="absolute bottom-3 left-3"
      >
        <img
          src="../../../../public/assets/icons/download-blue-bg.svg"
          class="w-10 h-10"
        />
      </a>
      <div
        v-else
        class="absolute bottom-3 left-3"
        :data-test-id="dataTestId + '-toggle-share'"
        :src="getShareColor"
        @click.stop="toggleFlipped"
      >
        <share-it-icon />
      </div>

      <div
        v-if="isVideo"
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        <img
          src="../../../../public/assets/icons/youtube-player/play-button-dark.svg"
          class="w-10 h-10 lg:w-[48px] lg:h-[48px]"
        />
      </div>
    </div>

    <div
      :class="{
        flipped: !isFlipped,
        'w-[240px] h-[300px]':
          !options.orientation || options.orientation === 'portrait',
        'max-w-[412px] w-full aspect-video lg:w-[360px]':
          options.orientation === 'landscape',
      }"
      class="desktop-swiper-slide-back top-0 left-0 absolute rounded-2xl flex flex-col bg-grey-200 font-aktiv-grotesk justify-between px-8 share-it-card"
      :data-test-id="dataTestId + '-toggle-share'"
      @click.stop="toggleFlipped"
    >
      <div class="socials">
        <a
          v-if="cardType === 'quotes'"
          :href="getDownloadUrl"
          target="_blank"
          download
          :data-test-id="dataTestId + '-share-instagram'"
        >
          <img
            class="socialBtn"
            :src="getImgUrl('instagram')"
            alt="Instagram Asset"
          />
        </a>
        <SocialMediaShareCopyButton
          v-if="cardType === 'articles'"
          :url="image.externalLink"
          :data-test-id="dataTestId"
          class="socialBtn"
        ></SocialMediaShareCopyButton>
        <ShareNetwork
          v-for="network in networks"
          :key="network.network"
          :title="getQuoteTitle(network.network)"
          :network="network.network"
          :url="getSharingUrl(network.network)"
          :media="getBackgroundImg()"
          :quote="getQuoteTitle(network.network)"
          :hashtags="getHashTags(network.network)"
          :data-test-id="dataTestId + '-share-' + network.network"
          class="mx-0"
        >
          <img class="socialBtn" :src="getImgUrl(network.network)" />
        </ShareNetwork>
        <a
          v-if="cardType === 'quotes'"
          :href="getDownloadUrl"
          :data-test-id="dataTestId + '-download-quotes'"
          target="_blank"
          download
        >
          <img
            class="socialBtn"
            :src="getImgUrl('download')"
            alt="Download asset"
          />
        </a>
      </div>
    </div>

    <!-- eslint-disable vue/no-v-html -->
    <div v-if="image.title || image.description" class="mt-3">
      <p
        v-if="image.title && cardType != 'quotes'"
        class="font-aktiv-grotesk-medium leading-[24px]"
        :class="{
          'w-[240px]':
            !options.orientation || options.orientation === 'portrait',
          'max-w-[412px] w-full lg:w-[360px]':
            options.orientation === 'landscape',
          'mb-1': image.description,
        }"
      >
        {{ image.title }}
      </p>
      <p
        v-if="image.description && cardType != 'quotes'"
        class="font-aktiv-grotesk-regular leading-[24px]"
        :class="{
          'w-[240px]':
            !options.orientation || options.orientation === 'portrait',
          'max-w-[412px] w-full lg:w-[360px]':
            options.orientation === 'landscape',
        }"
        v-html="image.description"
      ></p>
    </div>
    <!--eslint-enable-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShareItIcon from "@/components/icons/ShareItIcon";
import SocialMediaShareCopyButton from "./SocialMediaShareCopyButton.vue";

export default {
  name: "ShareItCard",
  components: { SocialMediaShareCopyButton, ShareItIcon },
  props: {
    image: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    cardType: {
      type: String,
      default: "quotes",
    },
    shareUrl: {
      type: String,
      default: "",
    },
    dataTestId: {
      type: String,
      default: "",
    },
    isVideo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFlipped: false,
    };
  },
  computed: {
    ...mapGetters("profile", ["type"]),
    getDownloadUrl() {
      return this.image.thumbnail.url;
    },
    networks() {
      if (this.cardType === "quotes") {
        return [
          {
            network: "facebook",
          },
          {
            network: "twitter",
          },
          {
            network: "pinterest",
          },
        ];
      } else if (this.cardType === "articles") {
        return [
          {
            network: "facebook",
          },
          {
            network: "twitter",
          },
          {
            network: "telegram",
          },
          {
            network: "whatsapp",
          },
        ];
      } else {
        return [];
      }
    },
    getShareColor() {
      const color =
        "shareColor" in this.image ? "-" + this.image.shareColor : "";
      return require("../../../../public/assets/icons/share" + color + ".svg");
    },
  },
  methods: {
    toggleFlipped() {
      this.isFlipped = !this.isFlipped;
    },
    getImgUrl(network) {
      return require("../../../../public/assets/icons/" + network + ".svg");
    },
    getSharingUrl(type) {
      if (type === "twitter") return "";
      let url = "";
      if ("url" in this.image) {
        url = this.image.externalLink;
      } else {
        url = this.image.thumbnail.url;
      }

      if (!url.startsWith("https")) {
        url = window.location.origin + url;
      }
      return url;
    },
    getHashTags(network) {
      if (network === "twitter") return "";
      if (network === "facebook")
        return "GenerationSconference,StrongChurchSummit";
      return "";
    },
    getBackgroundImg() {
      return window.location.origin + this.image.thumbnail.url;
    },
    getQuoteTitle(network) {
      let title = "";
      let url = "https://generationsmvmt.com/conference/";
      if (this.cardType === "articles") {
        url = this.shareUrl;
      }
      if (network !== "telegram" && "title" in this.image) {
        title +=
          this.image.description +
          ` #GenerationSconference #StrongChurchSummit `;
      }

      if (network === "twitter") {
        title += `${url}`;
      }
      if (network === "pinterest" && this.type === "SCS") {
        title += `SCS`;
      }
      if (network === "pinterest" && this.type === "GC") {
        title += `GC`;
      }
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
.share-it-card {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.share-btn {
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 50%;
}

.desktop-swiper-slide-front {
  transform: rotateY(0);
}

.desktop-swiper-slide-front,
.desktop-swiper-slide-back {
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.socials {
  align-self: center;
  width: 150px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.desktop-swiper-slide-back {
  background-color: rgba(240, 242, 244, 1);
}

.socialBtn {
  width: 40px;
  height: 40x;
  margin: 0 4px;
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 50%;
  border: 0;
}

.flipped {
  transform: rotateY(180deg);
}

.aspect-video {
  aspect-ratio: 16 / 9;
}
</style>
