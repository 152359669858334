<template>
  <empty-layout>
    <div class="relative bg-grey-5 text-grey-100 font-aktiv-grotesk">
      <div
        class="flex flex-col container mx-auto px-6 md:px-16 lg:px-32 xl:px-48 2xl:px-[310px] py-12"
      >
        <h1 class="text-36-lh-32 font-bold">
          GenerationS Pastors Conference App
        </h1>

        <h2 class="text-28-lh-32 font-bold mt-8 mb-4">Privacy Policy</h2>

        <p class="text-18-lh-38">
          The GenerationS Pastors Conference App (the “App”) manages and governs
          your personal data in accordance with the Personal Data Protection Act
          2012 (PDPA). We may change some parts or all of the contents of this
          policy from time to time to ensure that this policy is in line with
          legal and regulatory requirements. Hence, we advise you to check back
          regularly for updated information on the handling of your personal
          data.
        </p>
        <p class="text-18-lh-38">
          By interacting with or submitting information on the App, you agree
          and consent to the App collecting, using, disclosing and sharing of
          your personal data (if any), for the purposes of the App’s services.
        </p>
        <p class="text-18-lh-38">
          The App respects and is committed to protecting your privacy and
          safeguarding your personal information. We will only share such
          information (if any) with third parties on a required basis.
        </p>
        <p class="text-18-lh-38">
          If you have questions, comments or concerns about this policy or the
          security of personal information that we process (if any), you may
          contact us at:
          <a
            class="text-lightblue"
            target="_blank"
            href="mailto:hello@generationsmvmt.com?subject=Privacy%20Policy"
          >
            hello@generationsmvmt.com</a
          >
        </p>
      </div>
    </div>
  </empty-layout>
</template>

<script>
import EmptyLayout from "@/components/EmptyLayout.vue";
export default {
  layout: "empty",
  auth: false,
  components: {
    EmptyLayout,
  },
};
</script>

<style scoped lang="scss">
p {
  @apply pb-4;
}
</style>
