<template>
  <video
    ref="player"
    class="w-full h-full video-js vjs-big-play-centered"
  ></video>
</template>

<script>
import videojs from 'video.js'
import 'videojs-youtube/dist/Youtube.min.js'
import 'video.js/dist/video-js.min.css'
import 'video.js/dist/video.min.js'
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

// Get the Component base class from Video.js
const Component = videojs.getComponent('Component')

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class TitleBar extends Component` would work
// identically.
const MyButton = videojs.extend(Component, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor(player, options) {
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    Component.apply(this, arguments)

    // If a `text` option was passed in, update the text content of
    // the component.
    if (options.text) {
      this.updateTextContent(options.text)
    }
  },

  // The `createEl` function of a component creates its DOM element.
  createEl() {
    return videojs.createEl('div', {
      // Prefixing classes of elements within a player with "vjs-"
      // is a convention used in Video.js.
      className: 'vjs-button',
    })
  },

  // This function could be called at any time to update the text
  // contents of the component.
  updateTextContent(text) {
    // If no text was provided, default to "Title Unknown"
    if (typeof text !== 'string') {
      text = 'Title Unknown'
    }

    // Use Video.js utility DOM methods to manipulate the content
    // of the component's element.
    videojs.emptyEl(this.el())
    videojs.appendContent(this.el(), text)
  },
})

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('MyButton', MyButton)

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      player: null,
      tenFlag: false,
      thrityFlag: false,
      fiftyFlag: false,
      seventyFlag: false,
      ninetyFlag:false
    }
  },
  mounted() {
    this.player = videojs(this.$refs.player, this.options)
    this.player.removeChild('')
    this.player.bigPlayButton.on('click', function () {
      this.player.isFullscreen = true
    })
    this.player.on('play', function () {
      console.log("video started...")
      FirebaseAnalytics.logEvent({
        name: "video_play", 
        params: {
          "video_title": this.options().sources[0].src,
          "video_status": "start",
          "video_provider": "html"  
        }
      })
    })
    this.player.on('ended', function () {
      console.log("video ended...")
      FirebaseAnalytics.logEvent({
        name: "video_play", 
        params: {
          "video_title": this.options().sources[0].src,
          "video_status": "completed",
          "video_provider": "html"  
        }
      })
    })
    this.player.on('progress', function () {
      const percentage = Math.round((this.player().currentTime() / this.player().duration()) * 100)

      if(percentage>=90 && !this.ninetyFlag){
        FirebaseAnalytics.logEvent({
              name: "video_play", 
              params: {
                "video_title": this.options().sources[0].src,
                "video_percent": "90",
                "video_status": "progress",
                "video_provider": "html"  
              }
            })
        this.ninetyFlag=true
      } 
      else if(percentage>=70 && !this.seventyFlag){
        FirebaseAnalytics.logEvent({
              name: "video_play", 
              params: {
                "video_title": this.options().sources[0].src,
                "video_percent": "70",
                "video_status": "progress",
                "video_provider": "html"  
              }
            })
        this.seventyFlag=true
      } 
      else if(percentage>=50 && !this.fiftyFlag){
        FirebaseAnalytics.logEvent({
              name: "video_play", 
              params: {
                "video_title": this.options().sources[0].src,
                "video_percent": "50",
                "video_status": "progress",
                "video_provider": "html"  
              }
            })
        this.fiftyFlag=true
      } 
      else if(percentage>=30 && !this.thirtyFlag){
        FirebaseAnalytics.logEvent({
              name: "video_play", 
              params: {
                "video_title": this.options().sources[0].src,
                "video_percent": "30",
                "video_status": "progress",
                "video_provider": "html"  
              }
            })
        this.thirtyFlag=true
      } 
      else if(percentage>=10 && !this.tenFlag){
        FirebaseAnalytics.logEvent({
              name: "video_play", 
              params: {
                "video_title": this.options().sources[0].src,
                "video_percent": "10",
                "video_status": "progress",
                "video_provider": "html"  
              }
            })
        this.tenFlag=true
      }
    })
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    onReadyPlay() {},
    onPlay() {
      this.player.poster(this.options.poster[0].src)
      this.player.src(this.options.sources)
      // ios plays videos on full-screen automatically, so not requesting fullscreen
      // solves the issue with regards to videos sourced from youtube not restoring
      // properly from full screen
      if (!this.isIos()) {
        this.player.requestFullscreen()
      }
      setTimeout(() => {
        this.player.play()
      }, 100)
    },
    isIos() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      // (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    },
    onPause() {
      this.player.pause()
    },
  },
}
</script>
