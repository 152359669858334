<template>
  <ion-page :lang="$i18n.locale">
    <ion-content
      :lang="$i18n.locale"
      fullscreen="true"
      class="master"
      :scroll-events="enableScrollEvent"
      @ionScroll="onScroll"
      ><main class="lg:flex">
        <slot class="flex-1 pb-16 lg:pb-0"> </slot>
      </main>
    </ion-content>
  </ion-page>
</template>
<script>
import { IonPage, IonContent, isPlatform } from "@ionic/vue";
import { StatusBar } from "@capacitor/status-bar";
import store from "@/store";
export default {
  components: {
    IonPage,
    IonContent,
  },
  props: {
    enableScrollEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLogoutPopup: false,
    };
  },
  async IonViewWillEnter() {
    if (isPlatform("android") && !isPlatform("mobileweb")) {
      // Display content under transparent status bar (Android only)
      await StatusBar.setOverlaysWebView({ overlay: true });
    }
  },
  methods: {
    onScroll(event) {
      this.$emit("on-scroll", event);
    },
  },
};
</script>
<style scoped></style>
