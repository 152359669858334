<template>
  <Pill
    link=""
    :text="$t('copy.homePage.mainLayout.usefulLinks.links.appGuidedTour')"
    data-event-tracking="gensapp-home-restart-tour"
    @click-trigger="resetTour"
  />
</template>

<script>
import Pill from "./Pill.vue";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export default {
  name: "GuidedTourSection",
  components: { Pill },
  props: {
    conferenceType: {
      type: String,
      default: "Global",
    },
  },
  methods: {
    async resetTour(e) {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "gensapp-home-restart-tour",
          page: "home",
        },
      });
      console.log("check", this.conferenceType)
      const homeTourStatus = { hasCompletedHomepageTour: false, conferenceType: this.conferenceType };
      await this.$store.dispatch(
        "profile/setHomeTourStatus",
        homeTourStatus
      );
      const scheduleTourStatus = { hasCompletedScheduleTour: false, conferenceType: this.conferenceType };
      await this.$store.dispatch(
        "profile/setScheduleTourStatus",
        scheduleTourStatus
      );
      const experienceTourStatus = { hasCompletedExperienceTour: false, conferenceType: this.conferenceType };
      await this.$store.dispatch(
        "profile/setExperienceTourStatus",
        experienceTourStatus
      );
      this.$emit("reset-tour");
    },
  },
};
</script>

<style></style>
