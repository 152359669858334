<template>
  <div
    class="w-[288px] md:w-[288px] md:h-[162px] h-[162px] pr-[16px] md:pr-[24px]"
    @click="openLanguageModal"
  >
    <YoutubeInAppBrowser
      v-if="isYoutubeLink(url)"
      customClass="w-[288px] md:w-[288px] h-[162px] md:h-[162px] rounded-[16px]"
      :video-id="getYoutubeId(url)"
      :video-url="url"
      :poster-url="imageUrl"
      :is-dark-button="true"
      :preferred-caption="null"
    />
    <router-link
      v-else-if="isNativeVideoLink(url) && resourceTranslations.length < 2"
      :to="`/resources/video/${slug}`"
    >
      <ResourceCardThumbnail
        :image-url="imageUrl"
        :type="type"
        :duration="duration"
        :slug="slug"
        :tag-slug="tagSlug"
      />
    </router-link>
    <in-app-browser
      v-else-if="
        (type === 'PDF' || type === 'Excel') && resourceTranslations.length < 2
      "
      download
      :href="url"
    >
      <ResourceCardThumbnail
        :image-url="imageUrl"
        :type="type"
        :duration="duration"
        :slug="slug"
        :tag-slug="tagSlug"
      />
    </in-app-browser>
    <in-app-browser
      v-else-if="type === 'sm_quotes' && resourceTranslations.length < 2"
      :href="imageUrl"
      target="_blank"
    >
      <ResourceCardThumbnail
        :image-url="imageUrl"
        :type="type"
        :duration="duration"
        :slug="slug"
        :tag-slug="tagSlug"
      />
    </in-app-browser>
    <in-app-browser
      v-else-if="resourceTranslations.length < 2"
      :href="url"
      target="_blank"
    >
      <ResourceCardThumbnail
        :image-url="imageUrl"
        :type="type"
        :duration="duration"
        :slug="slug"
        :tag-slug="tagSlug"
      />
    </in-app-browser>
    <ResourceCardThumbnail
      v-else
      :image-url="imageUrl"
      :type="type"
      :duration="duration"
      :slug="slug"
      :tag-slug="tagSlug"
    />
    <p
      v-if="type != 'sm_quotes'"
      class="text-[16px] leading-[20px] font-medium font-aktiv-grotesk mt-[8px]"
    >
      {{ title }}
    </p>
    <VideoTag
      class="mt-[8px]"
      :is-video="type === 'video'"
      :is-exclusive="isExclusive"
      :is-audio="isAudio"
      :has-chinese-overdub="availTranslations?.hasChineseOverdub"
      :has-thai-overdub="availTranslations?.hasThaiOverdub"
      :has-chinese-sub="availTranslations?.hasTradChiSubtitles"
      :translations="resourceTranslations"
      :type="type"
    />
  </div>
</template>

<script>
import VideoTag from "@/components/ResourcesPage/PlaybackResources/VideoTag.vue";
import ResourceCardThumbnail from "@/components/ResourcesPage/PlaybackResources/ResourceCardThumbnail.vue";
import YoutubeInAppBrowser from "@/components/ResourcesPage/PlaybackResources/YoutubeInAppBrowser.vue";

export default {
  components: { VideoTag, ResourceCardThumbnail, YoutubeInAppBrowser },
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    isExclusive: {
      type: Boolean,
      default: false,
    },
    isAudio: {
      type: Boolean,
      default: false,
    },
    availTranslations: {
      type: Object,
      required: false,
      default: () => {},
    },
    resourceTranslations: {
      type: Array,
      default: () => [],
    },
    duration: {
      type: String,
      required: false,
      default: "0:00",
    },
    code: {
      type: String,
      required: false,
      default: "",
    },
    tagSlug: {
      type: String,
      default: "",
    },
  },
  computed: {
    url() {
      if (this.resourceTranslations) {
        return this.resourceTranslations[0].resourceUrl;
      }
      return "";
    },
  },
  methods: {
    openLanguageModal() {
      this.$emit("openLanguageModal");
    },
    isYoutubeLink(url) {
      return url?.includes("youtube") || url?.includes("youtu.be");
    },
    isNativeVideoLink(url) {
      return this.type === "video" && !this.isYoutubeLink(url);
    },
    getYoutubeId(url) {
      return url?.match(
        /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^\/&]{10,12})/
      )[1];
    },
  },
};
</script>
