<template>
    <div ref="notification-popup" class="notification-popup hidden" @click="closeModal()">
    <transition name="fade" appear>
      <div
        class="fixed top-0 left-0 right-0 bottom-0 z-40 bg-grey-100 opacity-60"
        @wheel.prevent
        @touchmove.prevent
        @scroll.prevent
      ></div>
    </transition>
 
      <div
        class="fit-content bg-white w-[327px] lg:w-[480px] text-center rounded-2xl m-auto fixed inset-0 box-shadow shadow-[0_1px_25px_rgba(0,0,0,0.25) z-50 mx-auto pt-8"
        @wheel.prevent
        @touchmove.prevent
        @scroll.prevent
        @click.stop
      >
        <div
          class="pb-4 px-6 text-grey-100 font-aktiv-grotesk-medium text-[24px] leading-[32px]"
        >
          {{ title }}
        </div>
        <div
          class="pb-8 px-6 text-grey-100 font-aktiv-grotesk-regular text-[16px] leading-[24px]"
        >
          {{ body }}
        </div>
        <div
          class="flex flex-col w-[284px] mx-auto text-[20px] leading-[24px] mb-[32px]"
        >
          <button
            class="px-5 py-3 rounded-full  w-full border-2 bg-orange border-grey-70 font-aktiv-grotesk-medium text-[#FFFFFF] text-center text-[20px] leading-[24px]"
            data-event-tracking="gensapp-notificationpopup-close"
            data-test-id="gensapp-notificationpopup-close"
            @click="closeModal()"
          >
            Got it!
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  import { mapState } from "vuex";
  import {clearNotifications} from "@/utils/push-notification"
  
  export default {
    computed:{  
      ...mapState('announcements',['title','body']) 
    },
    methods: {
      closeModal() {
        const notificationBox = document.getElementsByClassName('notification-popup')
        console.log(notificationBox)
        for(const key in notificationBox){
          console.log(notificationBox[key].classList)
          if(notificationBox[key].classList && !(notificationBox[key].classList.contains("hidden"))){
            notificationBox[key].classList.add("hidden")
          }
        }
        clearNotifications()
        this.$router.push('/announcement')
      },
    },
  };
  </script>
  
  <style scoped>
  .box-shadow {
    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.25);
  }

  .fit-content {
    height: fit-content;
  }
  </style>
  