<template>
  <div class="mx-6 md:mx-[32px] lg:mx-[56px]">
    <div
      class="mt-3 md:mt-0 md:ml-0 bg-white border border-1 border-grey-10 rounded-xl p-3 pr-7 mb-3 relative overflow-hidden text-[16px] leading-[24px]"
    >
      <a
        :href="annc.link"
        data-test-id="gensapp-announcements-annc-card"
        :target="getTarget(annc)"
      >
        <div>
          <CardLabel
            v-if="annc.isNew"
            color="yellow"
            class="font-aktiv-grotesk-medium text-[14px] leading-[20px] text-grey-80"
            >{{
              $t("copy.announcementPage.announcementCard.newLabel")
            }}</CardLabel
          >
          <div class="mb-3 font-aktiv-grotest-regular">
            <p
              class="tracking-wide text-[16px] leading-[24px] pr-3 text-grey-100 font-aktiv-grotesk"
            >
              <html-to-vue :html="annc.descriptionFull" />
            </p>
          </div>
          <div
            v-if="annc.postedTime != ''"
            class="flex justify-end font-aktiv-grotesk"
          >
            <p class="tracking-wide text-xs text-grey-100">
              {{ formatPostedTime(annc.postedTime) }}
            </p>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import momentts from "moment-timezone";
import CardLabel from "../common/CardLabel.vue";
export default {
  name: "AnncCard",
  components: { CardLabel },
  props: {
    annc: {
      type: Object,
      default: () => {},
    },
    hash: {
      // type: String,
      type: Boolean,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      // descriptionLength: 70,
      months: [
        ["Jan", "January"],
        ["Feb", "February"],
        ["Mar", "March"],
        ["Apr", "April"],
        ["May", "May"],
        ["Jun", "June"],
        ["Jul", "July"],
        ["Aug", "August"],
        ["Sep", "September"],
        ["Oct", "October"],
        ["Nov", "November"],
        ["Dec", "December"],
      ],
    };
  },
  computed: {
    todayText() {
      return this.$t("copy.announcementPage.announcementCard.today");
    },
  },
  methods: {
    formatPostedTime(time) {
      momentts.tz.setDefault("Asia/Singapore");
      const currentDate = momentts();
      const postedDate = momentts(time);
      const currentLocale = this.$i18n.locale;
      let formattedDate = "";
      let formattedTime = "";

      // Date Portion
      if (currentDate.isSame(postedDate, "day")) {
        formattedDate = `${this.todayText}`;
      } else {
        if (currentLocale == "zh") {
          formattedDate = new Intl.DateTimeFormat("zh-CN", {
            month: "long",
            day: "numeric",
          }).format(postedDate);
        } else if (currentLocale == "th") {
          formattedDate = new Intl.DateTimeFormat("th-TH", {
            month: "long",
            day: "numeric",
          }).format(postedDate);
        } else {
          formattedDate = postedDate.format("DD MMM,");
          formattedDate = formattedDate.toUpperCase();
        }
      }

      // Time Portion
      if (currentLocale == "th") {
        formattedTime = new Intl.DateTimeFormat("th-TH", {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        }).format(postedDate);
        formattedTime = formattedTime + " น";
      } else {
        formattedTime = new Intl.DateTimeFormat("en", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(postedDate);
      }

      return formattedDate + " " + formattedTime;
    },
    getTarget(item) {
      return item.isExternal === true ? "_blank" : "_self";
    },
  },
};
</script>
<style scoped>
p >>> a {
  color: #00aae4;
  font-weight: 600;
  text-decoration: underline;
}
</style>
