<template>
  <component :is="convertedComponent" class="inline" />
</template>

<script>
import { RouterLink } from "vue-router";

export default {
  name: "HtmlToVue",
  props: ["html"],
  components: { RouterLink },
  computed: {
    convertedComponent() {
      return convertHtmlToVueComponent(this.html);
    },
  },
};

function convertHtmlToVueComponent(html) {
  const regx = html.match(/<a[^>]*>(.*?)<\/a>/g);

  if (regx) {
    const match = regx[0]
      .match(/href="(.*?)"/g)[0]
      .replace(/href="/g, "")
      .replace(/"/g, "");

    if (match[0] === "/") {
      const replacedHtml = regx[0]
        .replace(/<a/g, "<router-link")
        .replace(/<\/a/g, "</router-link")
        .replace(/href="(.*?)"/g, 'to="$1"')
        .replace(/target="(.*?)"/g, "");

      return {
        template: html.replace(regx, replacedHtml),
      };
    }
  }

  return {
    template: html,
  };
}
</script>

<style></style>
