import axios from "axios";

const INITIAL_STATE = {
  livevoiceSession: false,
};

const state = () => INITIAL_STATE;

const mutations = {
  setlivevoiceSession(state, session) {
    state.livevoiceSession = session;
  },
};

const actions = {
  async fetchLivevoiceSession({ commit, dispatch, rootState }, sessionId) {
    try {
      const accessToken = rootState.user.token;
      const { data: data } = await axios.get(
        process.env.VUE_APP_API_URL + "/livevoice/session/" + sessionId,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      commit("setlivevoiceSession", data);
    } catch (e) {}
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
