<template>
  <empty-layout>
    <div
        class="close-btn absolute cursor-pointer z-50 mx-[24px] md:mx-[32px] lg:ml-[56px] pt-4"
        :class="{ '!top-[36px]': isIos() }"
        @click="back"
      >
        <img src="../../../public/assets/icons/go-back-chevron.svg"
        />
      </div>
  <iframe
    :src="forms.scs.getSrc()"
    title="Strong Church Summit Survey"
    @load="load"
    style="height: 100vh; width: 100vw;"
  ></iframe>
  </empty-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import EmptyLayout from "@/components/EmptyLayout.vue";
import { isIosNativeOnly } from "@/utils/platform-check";

export default {
  layout: 'form-empty',
  components: {
    EmptyLayout,
  },
  data() {
    return {
      forms: {
        scs: {
          getSrc: () => {
            return `https://gens.app/${this.surveyId}?login=true&delegate_id=${this.delegateId}`
          },
        },
      },
      surveyId:''
    }
  },
  computed: {
    ...mapGetters('profile', ['delegateId']),
    encodedEmail() {
      return encodeURIComponent(this.email)
    },
  },
  ionViewWillEnter() {
     this.surveyId = this.$route.params.name;
  },
  methods:{
    isIos() {
      return isIosNativeOnly();
    },
    back() {
      this.$router.push('/announcement')
    },
  }
}
</script>
