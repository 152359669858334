import axios from "axios";

const state = () => ({
  isSelectingPhotos: false,
  photos: [],
  selectedPhotos: [],
  isShowQr: false,
  loading: false,
  everyonePhotos: [],
  everyonePhotoSchedule: {
    day1: false,
    day2: false,
    day3: false,
    day4: false,
    day5: false,
  },
  selectedDayTab: "Featured",
  selectedMyPhotoTypeTab: "personal",
  viewMore: false,
});

const getters = {
  isPhotoSelected: (state) => (photo) => {
    return state.selectedPhotos.includes(photo);
  },
  isPhotosEmpty: (state) => {
    return state.photos.length === 0;
  },
};

const mutations = {
  setIsSelectingPhotos(state, value) {
    state.isSelectingPhotos = value;
  },
  setIsShowQr(state, value) {
    state.isShowQr = value;
  },
  setPhotos(state, photos) {
    state.photos = photos;
  },
  setEveryonePhotos(state, photos) {
    state.everyonePhotos = photos;
  },
  addEveryonePhotos(state, photos) {
    if (!photos.length) return;
    state.everyonePhotos = state.everyonePhotos.concat(photos);
  },
  setEveryonePhotoSchedule(state, schedule) {
    state.everyonePhotoSchedule = schedule;
  },
  setSelectedDayTab(state, selectedDayTab) {
    state.selectedDayTab = selectedDayTab;
  },
  setSelectedMyPhotoTypeTab(state, selectedMyPhotoTypeTab) {
    state.selectedMyPhotoTypeTab = selectedMyPhotoTypeTab;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  addToSelectedPhotos(state, photo) {
    state.selectedPhotos.push(photo);
  },
  removeFromSelectedPhotos(state, photo) {
    state.selectedPhotos = state.selectedPhotos.filter(
      (item) => item !== photo
    );
  },
  resetSelectedPhotos(state) {
    state.selectedPhotos = [];
  },
  setViewMore(state, viewMore) {
    state.viewMore = viewMore;
  },
};

const actions = {
  updateIsSelectingPhotos({ commit }, value) {
    commit("setIsSelectingPhotos", value);
  },
  updateIsShowQr({ commit }, value) {
    commit("setIsShowQr", value);
  },
  async fetchPhotos({ commit, dispatch, rootState }, tabName) {
    if (!rootState.photos.photos) commit("setLoading", true);

    try {
      const accessToken = rootState.user.token;
      const params = {
        tabName: tabName,
      };
      const { data: photos } = await axios.get(
        process.env.VUE_APP_API_URL + "/photo/all/v2",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: params,
        }
      );
      commit("setPhotos", photos);
    } catch (e) {
      dispatch("toast/error", "Failed to fetch everyone photos.", {
        root: true,
      });
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchPhotosEveryone(
    { commit, dispatch, rootState },
    { tabName, startWith, conferenceType = "Global" }
  ) {
    const params = {
      tabName: tabName,
      conferenceType: conferenceType,
    };

    if (startWith) params.startWith = startWith;
    if (!rootState.photos.photos) commit("setLoading", true);
    try {
      const accessToken = rootState.user.token;
      const { data: photos } = await axios.get(
        process.env.VUE_APP_API_URL + "/photo/everyone",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: params,
        }
      );
      commit("setViewMore", photos.viewMore);
      if (startWith) {
        commit("addEveryonePhotos", photos.photos);
      } else {
        commit("setEveryonePhotos", photos.photos);
      }
      commit("setEveryonePhotoSchedule", photos.schedules);
    } catch (e) {
      dispatch("toast/error", "Failed to fetch photos.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },
  toggleSelectedPhoto({ getters, commit }, photo) {
    if (getters.isPhotoSelected(photo))
      commit("removeFromSelectedPhotos", photo);
    else commit("addToSelectedPhotos", photo);
  },
  addSelectedPhoto({ getters, commit }, photo) {
    if (!getters.isPhotoSelected(photo)) commit("addToSelectedPhotos", photo);
  },
  resetSelectedPhotos({ commit }) {
    commit("resetSelectedPhotos");
  },
  setSelectedDayTab({ commit }, selectedDayTab) {
    commit("setSelectedDayTab", selectedDayTab);
  },
  setSelectedMyPhotoTypeTab({ commit }, selectedMyPhotoTypeTab) {
    commit("setSelectedMyPhotoTypeTab", selectedMyPhotoTypeTab);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
