<template>
  <div>
    <div
      :id="id"
      class="flex flex-col mx-[24px] text-[16px] leading-[24px] font-aktiv-grotesk header-scroll-margin"
    >
      <div
        class="relative rounded-2xl overflow-hidden border-[1px] border-grey-10 border-solid p-[16px]"
        :class="cardClass"
      >
        <div
          class="inline-block uppercase text-[12px] leading-[20px] font-aktiv-grotesk-medium text-center rounded-xl py-[2px] px-[8px] mb-[8px] bg-black text-white"
        >
          EXPERIENCE
        </div>
        <div class="font-aktiv-grotesk-trial mb-3 text-[20px] leading-[24px]">
          <h1>Media Control Room Tour</h1>
        </div>
        <div class="flex mb-2 justify-start items-start">
          <img
            src="../../../public/assets/icons/event-duration-icon-orange.svg"
            class="w-[20px] h-[20px] mt-1 ml-[4px]"
          />
          <p class="ml-[6px] text-grey-80 text-[16px] leading-[24px]">
            {{ timing }}
          </p>
        </div>

        <div
          class="flex mb-1 justify-start items-start rounded-[8px] location cursor-pointer bg-none text-grey-80"
        >
          <img
            src="../../../public/assets/icons/event-location-icon-orange.svg"
            alt="icon"
            class="mt-1 ml-2"
          />
          <div v-if="location.length > 0">
            <p v-for="place in location" :key="place" class="ml-2">
              {{ place }}
            </p>
          </div>
        </div>

        <CardLabel color="orange">By invite</CardLabel>
      </div>
    </div>
  </div>
</template>

<script>
import CardLabel from "../common/CardLabel.vue";

export default {
  name: "MediaControlRoomCard",
  components: {
    CardLabel,
  },
  data() {
    return {};
  },
  props: {
    timing: {
      type: String,
      default: "",
    },
    location: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    cardClass() {
      return "border-orange border-opacity-25";
    },
  },
  methods: {},
};
</script>
<style scoped>
.location {
  width: fit-content;
}

.header-scroll-margin {
  scroll-margin: 100px;
}
</style>
