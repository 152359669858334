<template>
  <div>
    <BaseSwiper
      :id="baseSwiperId"
      :ref="baseSwiperId"
      v-slot="slotProps"
      :items="carouselItems"
      :options="swiperOptions"
      @active-index-change="indexIsEnd"
    >
      <div v-html="slotProps.item.html" class="w-screen"></div>
    </BaseSwiper>
  </div>
</template>

<script>
import BaseSwiper from "../common/BaseSwiper";
// import { expData } from "../../utils/exp.js";

export default {
  name: "PreSignInCarousel",
  components: {
    BaseSwiper,
  },
  props: {
    baseSwiperId: {
      type: String,
    },
    displayTypes: {
      type: Array,
    },
    swiperOptions: {
      type: Object,
      default: () => {},
    },
    expData: {
      type: Object,
    },
  },
  computed: {
    carouselItems() {
      return [
        {
          html: this.$t("copy.preSignInPage.firstScreen")
        },
        {
          html: this.$t("copy.preSignInPage.secondScreen")
        },
        {
          html: this.$t("copy.preSignInPage.thirdScreen")
        },
      ]
    },
  },
  methods: {
    update() {
      console.log("update in presignin");
      const swiper = this.$refs[this.baseSwiperId];
      swiper.update();
    },
    indexIsEnd(isEnd) {
      this.$emit("index-is-end", isEnd);
    },
    slideNext() {
      console.log("slide next");
      this.$refs[this.baseSwiperId].next();
    },
  },
};
</script>

<style scoped></style>
