<template>
  <master-layout>
  <div></div>
</master-layout>
</template>

<script>
import { isIosNativeOnly } from '@/utils/platform-check'
import { StatusBar, Style } from '@capacitor/status-bar';
export default {
  name: "ResourcesPage",
  async ionViewWillEnter() {
    this.$router.push("/resources/sessions");
    if(isIosNativeOnly()){
      await StatusBar.setStyle({ style: Style.Light });
    }
  }
};
</script>
