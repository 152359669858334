import { createI18n } from "vue-i18n";
import { getCurrentLocale } from "./deviceLocale";
import translation from "../i18n/translation";
import { DEFAULT_CONFERENCE_TYPE } from "./conference-type";

const initialLocale = await getCurrentLocale(DEFAULT_CONFERENCE_TYPE);
console.log("initial locale", initialLocale);

document.querySelector("html").setAttribute("lang", initialLocale);
const i18n = createI18n(translation(initialLocale));

export default i18n;
