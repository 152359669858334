<template>
  <div v-if="sharing">
    <div class="mb-[8px] mb-[8px] mt-2 border-[0.5px] border-grey-10"></div>
    <div v-if="eventId === 5">
      <p class="text-[14px] font-aktiv-grotesk-medium">Table Time</p>
      <ul class="text-[14px] text-grey-60">
        <li>
          •&nbsp; What do you need to reprioritize to be able to build a Deep
          Bench and GenerationS?
        </li>

        <li>
          •&nbsp; How can you Invite, Include, Involve youths in your sphere of
          influence?
        </li>
      </ul>
    </div>
    <div v-else-if="eventId === 8">
      <p class="text-[14px] font-aktiv-grotesk-medium">Table Time</p>
      <ul class="text-[14px] text-grey-60">
        <li>
          •&nbsp; What structures do you need to make it easier for youths to be
          connected/engaged?
        </li>

        <li>
          •&nbsp; What structures do you need to make it easier for youths to
          rise up to serve?
        </li>
      </ul>
    </div>
    <div v-else-if="eventId === 13">
      <p class="text-[14px] font-aktiv-grotesk-medium">Table Time</p>
      <ul class="text-[14px] text-grey-60">
        <li>
          •&nbsp; Individually, how can you display a Father’s/Mother’s heart
          for young people?
        </li>

        <li>
          <ul>
            •&nbsp; Organizationally,
            <li>
              &nbsp;&nbsp;◦&nbsp; How can you cultivate a desire for youths to
              want to hang out in church?
            </li>
            <li>
              &nbsp;&nbsp;◦&nbsp; Do you have a facility for youths to hang out
              beyond the services?
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div v-else-if="eventId === 17">
      <p class="text-[14px] font-aktiv-grotesk-medium">Table Time</p>
      <ul class="text-[14px] text-grey-60">
        <li>•&nbsp; Do you have a system to get people from Lost to Leader?</li>

        <li>
          <ul>
            •&nbsp; Which aspect of the Transformational Journey do you need to
            work on?
            <li>&nbsp;&nbsp;◦&nbsp; Getting people saved (I3)</li>
            <li>&nbsp;&nbsp;◦&nbsp; Getting people planted (Follow-Up)</li>
            <li>&nbsp;&nbsp;◦&nbsp; Getting people to serve (BMT)</li>
          </ul>
        </li>
      </ul>
    </div>
    <div v-else-if="eventId === 20">
      <p class="text-[14px] font-aktiv-grotesk-medium">Table Time</p>
      <ul class="text-[14px] text-grey-60">
        <li>
          •&nbsp; What schools do you need to start to be able to mass train
          your youths?
        </li>

        <li>
          •&nbsp; How can you make building your training syllabus a part of
          your training process?
        </li>
      </ul>
    </div>
    <div v-else-if="eventId === 35">
      <p class="text-[14px] font-aktiv-grotesk-medium">Table Time</p>
      <ul class="text-[14px] text-grey-60">
        <li>
          •&nbsp; In the next month, which of the 5 Steps to Implement
          GenerationS can you put into practice?
        </li>
      </ul>
    </div>
    <div v-else>
      <p class="text-[14px] font-aktiv-grotesk-medium">{{ header }}</p>
      <span class="text-[14px] text-grey-60">{{ body }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sharing: {
      type: String,
      default: 'table_time_and_alumni',
    },
    eventId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    header() {
      if (this.sharing === 'table_time_and_alumni')
        return '*Includes Table time & Alumni Sharing'
      return '*Includes Table Time'
    },
    body() {
      if (this.sharing === 'table_time_and_alumni')
        return 'Set actionable steps together with your team and hear from others who have done it'
      return 'Lock in what is relevant and applicable to your team/context.'
    },
  },
}
</script>

<style></style>
