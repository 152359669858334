<template>
  <master-layout id="contentResourceSessions">
    <div
      class="mt-4 md:mt-8 md:mx-[72px] px-6 lg:w-3/4"
      :class="{ 'pt-12': isIos || isAndroid }"
    >
      <div class="block mb-6 md:-mx-12">
        <img
          src="../../../public/assets/icons/go-back-chevron.svg"
          alt="Back Icon"
          class="cursor-pointer"
          @click="back()"
        />
      </div>
      <SessionSearch
        class="max-w-[768px]"
        :searchTerm="searchTerm"
        :assets="flattenedMaterials"
        @submit-search="onSearch"
      />
      <div v-if="isSearching">
        <p v-if="searchTerm" class="my-6 text-[16px] leading-[28px]">
          {{ searchResultsCopy }}
        </p>
        <div class="font-aktiv-grotesk">
          <!-- PDF -->
          <div v-if="getAssets.pdf.length > 0">
            <ResourceCard
              v-for="(material, idx) in getAssets.pdf"
              :key="idx"
              :resource="material"
              class="my-6"
            />
          </div>

          <!-- Videos & articles -->
          <div v-if="getAssets.links.length > 0">
            <ResourceCard
              v-for="(material, idx) in getAssets.links"
              :key="idx"
              :resource="material"
              class="my-6"
            />
          </div>

          <div v-if="searchedMaterials.books.length > 0">
            <div class="flex justify-between items-center md:px-0 mb-4">
              <div
                class="tracking-wide font-aktiv-grotesk-medium text-[20px] md:text-[24px] mt-10 leading-[32px] text-grey-90 font-bold"
              >
                Book
                <in-app-browser :href="bookUrl" target="_blank">
                  <img
                    class="w-full rounded-[16px] cursor-pointer"
                    :src="searchedMaterials.books[0].thumbnail"
                  />
                </in-app-browser>
              </div>
            </div>
          </div>

          <div
            v-if="searchedMaterials.quotes.length > 0 && loadedQuotes"
            class="mt-[60px] pb-[87px] w-[calc(100vw-24px)] lg:w-[calc(100vw-500px)] bg-[#F8F9F9]"
          >
            <h1
              class="mb-[16px] font-aktiv-grotesk text-[24px] leading-[32px] font-bold"
            >
              Quotes

              <ResourcesSlider
                :category-index="i"
                :items="searchedMaterials.quotes"
              />
            </h1>
          </div>

          <div v-if="getResultCount === 0">
            <p
              class="font-aktiv-grotesk font-bold text-xl leading-[32px] mb-[16px]"
            >
              You may be interested in...
            </p>
            <div class="flex items-start flex-wrap gap-2">
              <div v-for="suggestion in searchSuggestions" :key="suggestion.id">
                <div
                  class="rounded-full border-black border-2 text-black px-4 py-1 cursor-pointer"
                  @click="useSuggestion(suggestion.keyword)"
                >
                  {{ suggestion.keyword }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isSearching">
        <p
          class="font-aktiv-grotesk font-bold text-[20px] md:text-[24px] leading-[24px] md:leading-[32px] mt-6"
        >
          You may be interested in...
        </p>
        <div class="flex items-start flex-wrap gap-3 mt-6">
          <div v-for="suggestion in searchSuggestions" :key="suggestion.id">
            <div
              class="rounded-full border-black border-2 text-black px-4 py-1 cursor-pointer"
              @click="useSuggestion(suggestion.keyword)"
            >
              {{ suggestion.keyword }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </master-layout>
</template>

<script>
import LanguageDropdown from "../../components/ResourcesPage/LanguageDropdown.vue";
import SessionSearch from "@/components/ResourcesPage/SessionSearch.vue";
import { mapGetters, mapState } from "vuex";
import ResourceCard from "@/components/ResourcesPage/Results/ResourceCard.vue";
import ShareItCard from "@/components/common/ShareCard/ShareItCard.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import ResourcesSlider from "@/components/ResourcesPage/PlaybackResources/ResourcesSlider.vue";
import { isIosNativeOnly, isAndroidNativeOnly } from "@/utils/platform-check";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export default {
  name: "ResourceSearch",
  components: {
    LanguageDropdown,
    SessionSearch,
    ResourceCard,
    ShareItCard,
    Swiper,
    SwiperSlide,
    ResourcesSlider,
  },
  data() {
    return {
      nonDuplicateResources: [],
      flattenedMaterials: {
        pdf: [],
        links: [],
        books: [],
        quotes: [],
      },
      searchedMaterials: {
        pdf: [],
        links: [],
        books: [],
        quotes: [],
      },
      bookMaterials: [],
      quotesMaterials: [],
      currentLanguageSelect: {
        displayText: "English",
        code: "en",
      },
      swiperOptions: {
        centeredSlides: false,
        slidesPerView: "auto",
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
        },
      },
      swipers: {},
      materialsCount: 0,
      resultsCount: 0,
      sessions: [],
      images: [],
      searchTerm: "",
      isSearching: false,
      loadedQuotes: false,
    };
  },
  computed: {
    ...mapState("user", ["token"]),
    ...mapState("cms", ["sessionMaterials", "loading"]),
    ...mapState("filter", [
      "searchSuggestions",
      "allResources",
      "flattenedBooks",
      "flattenedQuotes",
    ]),
    ...mapState("player", ["playingPlayer"]),
    ...mapGetters("profile", ["type"]),
    ...mapGetters("schedule", ["scheduleDates"]),
    isIos() {
      return isIosNativeOnly();
    },
    isAndroid() {
      return isAndroidNativeOnly();
    },
    getAssets() {
      if (this.searchTerm) {
        // Fetch assets to render based on the search bar
        return this.searchedMaterials;
      }
      return this.flattenedMaterials;
    },
    getResultCount() {
      if (this.searchTerm) {
        return this.resultsCount;
      }
      return this.materialsCount;
    },
    bookUrl() {
      if (this.searchedMaterials.books) {
        return this.searchedMaterials.books[0].resourceTranslations[0]
          .resourceUrl;
      }
      return "";
    },
    searchResultsCopy() {
      if (this.getResultCount > 0) {
        return "Search results for " + '"' + this.searchTerm + '"';
      } else {
        return (
          "Your search for " +
          '"' +
          this.searchTerm +
          '"' +
          " didn't return any results."
        );
      }
    },
  },
  async ionViewDidEnter() {
    FirebaseAnalytics.setScreenName({
      screenName: "resources - search",
      nameOverride: "ResourcesPage - Search",
    });
    const combinedResources = this.allResources.flatMap(
      (resource) => resource.resources
    );
    if (combinedResources.length === 0) {
      this.$router.push("/resources/sessions");
    }
    const noDuplicateResources =
      this.RemoveDuplicatedResources(combinedResources);

    this.flattenedMaterials.pdf = noDuplicateResources.filter(
      (resource) => resource.type == "sm_tools"
    );
    this.materialsCount += this.flattenedMaterials.pdf.length;

    this.flattenedMaterials.quotes = this.flattenedQuotes;
    this.materialsCount += this.flattenedMaterials.quotes.length;
    this.loadedQuotes = true;

    this.flattenedMaterials.books = this.flattenedBooks;
    this.materialsCount += this.flattenedMaterials.books.length;

    this.flattenedMaterials.links = noDuplicateResources.filter(
      (resource) =>
        resource.type !== "sm_quotes" && resource.type !== "sm_tools"
    );
    this.materialsCount += this.flattenedMaterials.links.length;
  },
  async ionViewWillLeave() {
    if (this.playingPlayer) {
      await this.playingPlayer.pauseVideo();
    }
  },
  methods: {
    RemoveDuplicatedResources(resources) {
      return resources.filter((resource, index, self) => {
        const firstIndex = self.findIndex(
          (r) => r.title === resource.title && r.type === resource.type
        );
        return firstIndex === index;
      });
    },

    onSearch(searchResult, searchTerm, resultsCount, isSearching) {
      this.isSearching = true;
      this.searchedMaterials.pdf = searchResult.pdf;
      this.searchedMaterials.links = searchResult.links;
      this.searchedMaterials.books = searchResult.books;
      this.searchedMaterials.quotes = searchResult.quotes;
      this.searchTerm = searchTerm;
      this.resultsCount = resultsCount;
      this.isSearching = isSearching;
      this.loadedQuotes = false;
      setTimeout(() => {
        this.loadedQuotes = true;
      }, 100);
      console.log("Search Results", this.searchedMaterials);
    },
    useSuggestion(suggestionName) {
      this.searchTerm = suggestionName;
    },
    isYoutubeLink(url) {
      return url?.includes("youtube") || url?.includes("youtu.be");
    },

    back() {
      this.$router.back();
      this.searchTerm = "";
      this.isSearching = false;
    },
    setSwiperInstance(instance, header) {
      this.swipers[header] = instance;
    },
  },
};
</script>
<style scoped>
.tool-shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.shadow {
  box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.25);
}

.swiper-slide {
  max-width: 328px;
  align-items: flex-start;
  vertical-align: middle;
  border-radius: 10px;
  display: flex;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.swiper-container {
  list-style: none;
  z-index: 1;
  padding-bottom: 0px;
}

.download-button svg {
  fill: #00aae4;
}

.download-button:active svg {
  fill: #ffffff;
}

@media all and (min-width: 768px) {
  ::v-deep(.swiper-wrapper) {
    padding-left: 0;
  }
}
</style>
