<template>
  <Pill
    link=""
    :text="$t('copy.homePage.mainLayout.usefulLinks.links.giving')"
    @click-trigger="givingWebView"
  />
</template>

<script>
import Pill from "./Pill.vue";
import { Browser } from "@capacitor/browser";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { mapState } from "vuex";
export default {
  name: "GivingSection",
  components: {
    Pill,
  },
  methods: {
    givingWebView() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "useful-links-giving",
          page: "home",
        },
      });
      Browser.open({
        url: "https://gens.app/give?prefilled_email=" + this.email,
        // windowName: "_self",
      });
    },
  },
  computed: {
    ...mapState("profile", ["email"]),
  },
};
</script>

<style></style>
