<template>
  <div
    v-if="announcementStr !== ''"
    class="bg-grey-10 rounded-2xl p-3 text-[14px] leading-[24px] mb-[24px] mx-[24px] font-aktiv-grotesk"
    v-html="announcementStr"
  ></div>
</template>

<script>
export default {
  name: 'AnnouncementPanel',
  props: {
    announcementStr: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      anchors: [],
    }
  },
  updated() {
    if (this.announcementStr === '') return
    this.anchors = this.$el.getElementsByTagName('a') // Not iterable

    Array.from(this.anchors).forEach((anchor) => {
      anchor.addEventListener('click', this.useVueRouter)
    })
  },
  beforeUnmount() {
    if (!this.anchors) return

    Array.from(this.anchors).forEach((anchor) => {
      anchor.removeEventListener('click', this.useVueRouter)
    })
  },
  methods: {
    useVueRouter(e) {
      if (e.currentTarget instanceof Element) {
        const href = e.currentTarget.getAttribute('href') || ''
        if (href.startsWith('http')) return

        e.preventDefault()

        // Use vue router
        this.$router.push(href)
      }
    },
  },
}
</script>

<style scoped></style>
