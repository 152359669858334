<template>
  <master-layout :enable-scroll-event="true">
    <div class="flex flex-col min-h-screen bg-grey-5">
      <div
        class="mx-[24px] md:mx-[32px] lg:ml-[56px] pt-4 mb-[20px] md:mt-[40px]"
        :class="{ 'pt-[60px]': isIos() }"
      >
        <router-link to="/home" data-test-id="gensapp-announcements-page-back"
          ><img src="../../../public/assets/icons/go-back-chevron.svg"
        /></router-link>
      </div>

      <section
        class="mx-[24px] md:mx-[32px] lg:ml-[56px] font-aktiv-grotesk font-bold"
      >
        <h4 class="text-[24px] leading-[32px] mb-[4px] text-black md:mb-[32px]">
          Latest updates
        </h4>
      </section>

      <section>
        <AnncCard
          v-for="annc in announcements"
          :id="`card${annc.id}`"
          :key="annc.id"
          :annc="annc"
          :status="annc.isNew"
          :data-test-id="'gensapp-announcements-page-annc-id-' + annc.id"
          class="scroll-margin"
        ></AnncCard>
      </section>
      <div class="my-8 md:my-10 lg:my-12"></div>
    </div>
  </master-layout>
</template>

<script>
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import AnncCard from "../../components/AnncPage/AnncCard";
import axios from "axios";
import { isIosNativeOnly } from "@/utils/platform-check";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export default {
  name: "AnnouncementPage",
  components: { AnncCard },
  data() {
    return {
      announcements: [],
      hash: null,
    };
  },
  computed: {
    ...mapGetters("profile", ["type"]),
    ...mapState("user", ["token"]),
  },
  ionViewWillEnter() {
    axios
      .get(process.env.VUE_APP_API_URL + "/cms/announcements/get", {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((res) => {
        res.data
          .sort((a, b) => moment(b.postedTime).diff(a.postedTime))
          .forEach((item) => {
            this.announcements.push(item);
          });
        this.$nextTick(() => {
          if (this.$route.hash) {
            const el = document.querySelector(this.$route.hash);
            el && el.scrollIntoView({ behavior: "smooth" });
            this.hash = this.$route.hash.slice(-1);
          }
        });
      });
    FirebaseAnalytics.setScreenName({
      screenName: "announcements",
      nameOverride: "AnnouncementsPage",
    });
  },
  async ionViewDidLeave() {
    await axios.request(
      process.env.VUE_APP_API_URL + "/announcement/mark-read-announcements",
      {
        method: "post",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }
    );
    this.announcements.length = 0;
  },
  updated() {
    this.$nextTick(() => {
      if (this.$route.hash) {
        "#card" + announcement.id + " a"
      }
    });
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
  },
};
</script>

<style>
.scroll-margin {
  scroll-margin-top: 48px;
}
</style>
