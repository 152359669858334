<template>
  <div v-if="show" @click="close()">
    <transition name="fade" appear>
      <div
        class="fixed top-0 left-0 right-0 bottom-0 z-40 bg-grey-100 opacity-60"
        @wheel.prevent
        @touchmove.prevent
        @scroll.prevent
      ></div>
    </transition>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PopupModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    enableClickAway: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    close() {
      if (!this.enableClickAway) return

      this.$emit('close-popup')
    },
  },
}
</script>

<style></style>
