import { PushNotifications } from '@capacitor/push-notifications';
import { Preferences } from '@capacitor/preferences'
import { isPlatform } from "@ionic/vue";
import store from "../store/index";
import { FCM } from "@capacitor-community/fcm";

export async function pushInit(){
      try {

        await PushNotifications.addListener(
          'registration',
          async (token) => {
            console.log('My token: ' + JSON.stringify(token));
            console.log('My token value: ' + token.value);
              Preferences.set({
                  key: 'token',
                  value: token.value
              });
              const payload = {
                'fcmToken' : token.value
              }
            await store.dispatch('profile/setFcmToken', payload)
          }
        );

        await PushNotifications.addListener('registrationError', err =>          {
          console.log(`error on register ${JSON.stringify(err.error)}`);
        });

        await PushNotifications.register();

        if(store.state.profile.channels.length>0){
          console.log("store state")
          console.log(store.state.profile.channels)
          // now you can subscribe to a specific topic
          for (const key in store.state.profile.channels){
            await FCM.subscribeTo({ topic: store.state.profile.channels[key]})
          }
        }
        

        /* const fcmToken = await FCM.getToken();
        // this.token = JSON.stringify(fcmToken);
        console.log("token:" + JSON.stringify(fcmToken)); */
        
        await PushNotifications.addListener('pushNotificationReceived',
          async (notification) => {
            console.log('Push notification received: ', JSON.stringify(notification));

            // this handles foreground android notifications
            if (isPlatform("android")) {
              console.log("foreground notification notice")
                store.dispatch('announcements/setNotification', notification)
                const notificationBox = document.getElementsByClassName('notification-popup')
                console.log(notificationBox)

                for(const key in notificationBox){
                  if(notificationBox[key].classList.contains("hidden")){
                    notificationBox[key].classList.remove("hidden")
                  }
                }
            }
        });
        
        await PushNotifications.addListener('pushNotificationActionPerformed',
        async (notification) => {
            // Do something
            // this handles background android notifications
            if (isPlatform("android")){
              const androidBackgroundNotification = {
                title:'',
                body:''
              }    
              androidBackgroundNotification.title = notification.notification.data.title
              androidBackgroundNotification.body = notification.notification.data.body

              store.dispatch('announcements/setNotification', androidBackgroundNotification)
              const notificationBox = document.getElementsByClassName('notification-popup')
                console.log(notificationBox)

                for(const key in notificationBox){
                  if(notificationBox[key].classList.contains("hidden")){
                    notificationBox[key].classList.remove("hidden")
                  }
                }
            } 
            else {
              const iosNotification = {
                title:'',
                body:''
              }    
              iosNotification.title = notification.notification.title
              iosNotification.body = notification.notification.body
  
              store.dispatch('announcements/setNotification', iosNotification)
              const notificationBox = document.getElementsByClassName('notification-popup')
                console.log(notificationBox)

                for(const key in notificationBox){
                  if(notificationBox[key].classList.contains("hidden")){
                    notificationBox[key].classList.remove("hidden")
                  }
                }
            }
        });
      } catch (e) {
        console.log(e);
      }
}

export function clearNotifications(){
  PushNotifications.removeAllDeliveredNotifications()
}

export function unsubscribePush(){
  if(store.state.profile.type){
    console.log("store state unsub")
    console.log(store.state.profile.type)
  // now you can subscribe to a specific topic
   FCM.unsubscribeFrom({ topic: store.state.profile.type.toString()})
  }
}
