<template>
  <Vue3DraggableResizable
    v-if="!close"
    class="rounded-3xl bg-black text-white p-4 opacity-50 touch-none"
    :class="getOpacity()"
    :w="'auto'"
    :h="'auto'"
    :parent="false"
    :resizable="false"
    @dragging="onDrag"
  >
    <h2 class="font-bold" @click="toggleShowMore()">
      Date
      <i @click="closeModal()">X</i>
    </h2>
    <div v-if="showMore">
      <div class="flex">
        <label>Language</label>
        <input v-model="language" type="text" class="text-black" />
        <button
          class="bg-blue p-2 rounded-xl hover:bg-[#303494]"
          @click="setLanguage()"
        >
          Update
        </button>
      </div>
      <p>Input Date: {{ getInputDate() }}</p>
      <p>Current Date: {{ getCurrDate() }}</p>
      <div>
        Speed: {{ speed }} s / tick
        <button
          class="bg-blue p-1 rounded-3xl hover:bg-[#303494]"
          @click="addSpeed(10)"
        >
          +10
        </button>
        <button
          class="bg-blue p-1 px-2 rounded-3xl hover:bg-[#303494]"
          @click="addSpeed(1)"
        >
          +1
        </button>
        <button
          class="bg-blue p-1 px-2 rounded-3xl hover:bg-[#303494]"
          @click="addSpeed(-1)"
        >
          -1
        </button>
        <button
          class="bg-blue p-1 rounded-3xl hover:bg-[#303494]"
          @click="addSpeed(-10)"
        >
          -10
        </button>
      </div>
      <input v-model="date" type="datetime-local" />
      <div>
        <button
          class="bg-blue p-2 rounded-xl hover:bg-[#303494]"
          @click="update()"
        >
          Update
        </button>
        <button
          class="bg-blue p-2 rounded-xl hover:bg-[#303494]"
          @click="closeModal()"
        >
          Close
        </button>
      </div>
    </div>
  </Vue3DraggableResizable>
</template>

<script>
import moment from "moment";
import momentts from "moment-timezone";
import Vue3DraggableResizable from "vue3-draggable-resizable";
import { mapActions, mapGetters, mapState } from "vuex";
import { setLocale } from "../../utils/deviceLocale";
export default {
  name: "CheatConsole",
  components: {
    Vue3DraggableResizable,
  },
  data() {
    return {
      date: new Date().toISOString(),
      showMore: false,
      x: 0,
      y: 0,
      milliSecElapsed: 0,
      iniDate: null,
      language: "en",
    };
  },
  computed: {
    ...mapState("timer", ["currDate"]),
    ...mapState("timer", ["speed", "close"]),
  },
  watch: {
    currDate(newCurrDate, oldCurrDate) {
      this.setCurrDate(newCurrDate);
    },
  },
  mounted() {
    if (this.currDate === "") this.iniDate = new Date();
    this.timer();
  },
  beforeUnmount() {
    clearInterval(this.intervalid);
  },
  methods: {
    ...mapActions("timer", ["setCurrDate", "setSpeed", "setClose"]),
    closeModal() {
      this.setClose(true);
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    update() {
      this.setCurrDate(this.date);
      this.milliSecElapsed = 0;
    },
    getInputDate() {
      momentts.tz.setDefault("Asia/Singapore");
      if (!this.date) return momentts().format();
      return momentts(this.date).format();
    },
    getCurrDate() {
      momentts.tz.setDefault("Asia/Singapore");
      if (this.currDate === "")
        return momentts(this.iniDate).add(this.milliSecElapsed).format();
      return momentts(this.currDate).add(this.milliSecElapsed).format();
    },
    getOpacity() {
      return this.showMore ? "opacity-100" : "opacity-50";
    },
    onDrag(x, y) {
      this.x = x;
      this.y = y;
    },
    timer() {
      this.intervalid = setInterval(() => {
        this.milliSecElapsed += 1000 * this.speed;
      }, 1000);
    },
    addSpeed(speed) {
      this.setSpeed(this.speed + speed);
    },
    setLanguage() {
      setLocale(this.language, this.$i18n, "Regional");
    },
  },
};
</script>

<style scoped></style>
