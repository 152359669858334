<template>
  <header>
    <div
      v-for="i in [0, 1]"
      :key="i"
      class="bg-cover md:flex items-start lg:items-center justify-between fixed top-0 left-0 right-0 z-[30] h-[88px] hidden"
      :class="{
        fixed: i,
        'relative invisible': !i,
        'bg-gens-conf-regional-blue-bar': isGPCRPage(),
        'bg-gens-conf-yellow-bar': !isGPCRPage(),
      }"
      @wheel.prevent
      @touchmove.prevent
      @scroll.prevent
    >
      <router-link to="/">
        <div class="ml-[40px] my-[20px] inline-flex items-center">
          <div v-if="isGPCRPage()">
            <img
              src="../../../public/assets/images/gpcr/gpcr-2024-logo.png"
              class="w-[189px] h-[64px]"
              alt="gens conf regional logo"
            />
          </div>
          <div v-else>
            <img
              src="../../../public/assets/images/gpc-2024-logo.png"
              class="w-[138px] h-[49px]"
              alt="gens conf logo"
            />
          </div>
        </div>
      </router-link>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import { isGPCRPage } from "@/utils/conference-type";

export default {
  name: "CommonHeader",
  components: {},
  computed: {
    ...mapGetters("profile", ["type"]),
  },
  methods: {
    isGPCRPage() {
      return isGPCRPage();
    },
  },
};
</script>

<style scoped></style>
