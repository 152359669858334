<template>
  <svg
    v-if="!isActive"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66667 16H4L16 4L28 16H25.3333"
      stroke="#00AAE4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.6665 16V25.3333C6.6665 26.0406 6.94746 26.7189 7.44755 27.219C7.94765 27.719 8.62593 28 9.33317 28H22.6665C23.3737 28 24.052 27.719 24.5521 27.219C25.0522 26.7189 25.3332 26.0406 25.3332 25.3333V16"
      stroke="#00AAE4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <mask id="path-3-inside-1_874_10339" fill="white">
      <path
        d="M12 27.334V19.834C12 19.1709 12.281 18.5351 12.781 18.0662C13.2811 17.5974 13.9594 17.334 14.6667 17.334H17.3333C18.0406 17.334 18.7189 17.5974 19.2189 18.0662C19.719 18.5351 20 19.1709 20 19.834V27.334"
      />
    </mask>
    <path
      d="M10.5 27.334C10.5 28.1624 11.1716 28.834 12 28.834C12.8284 28.834 13.5 28.1624 13.5 27.334H10.5ZM14.6667 17.334V18.834V17.334ZM18.5 27.334C18.5 28.1624 19.1716 28.834 20 28.834C20.8284 28.834 21.5 28.1624 21.5 27.334H18.5ZM13.5 27.334V19.834H10.5V27.334H13.5ZM13.5 19.834C13.5 19.6012 13.5978 19.3566 13.807 19.1605L11.7551 16.9719C10.9641 17.7135 10.5 18.7407 10.5 19.834H13.5ZM13.807 19.1605C14.0194 18.9613 14.3271 18.834 14.6667 18.834V15.834C13.5917 15.834 12.5429 16.2334 11.7551 16.9719L13.807 19.1605ZM14.6667 18.834H17.3333V15.834H14.6667V18.834ZM17.3333 18.834C17.6729 18.834 17.9806 18.9613 18.193 19.1605L20.2449 16.9719C19.4571 16.2334 18.4083 15.834 17.3333 15.834V18.834ZM18.193 19.1605C18.4022 19.3566 18.5 19.6012 18.5 19.834H21.5C21.5 18.7407 21.0359 17.7135 20.2449 16.9719L18.193 19.1605ZM18.5 19.834V27.334H21.5V19.834H18.5Z"
      fill="#00AAE4"
      mask="url(#path-3-inside-1_874_10339)"
    />
  </svg>

  <svg
    v-else
    class="block"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.66667 16H4L16 4L28 16H25.3333" fill="#00AAE4" />
    <path
      d="M6.66667 16H4L16 4L28 16H25.3333"
      stroke="#00AAE4"
      stroke-width="2.20267"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.6665 16V25.3333C6.6665 26.0406 6.94746 26.7189 7.44755 27.219C7.94765 27.719 8.62593 28 9.33317 28H22.6665C23.3737 28 24.052 27.719 24.5521 27.219C25.0522 26.7189 25.3332 26.0406 25.3332 25.3333V16"
      fill="#00AAE4"
    />
    <path
      d="M6.6665 16V25.3333C6.6665 26.0406 6.94746 26.7189 7.44755 27.219C7.94765 27.719 8.62593 28 9.33317 28H22.6665C23.3737 28 24.052 27.719 24.5521 27.219C25.0522 26.7189 25.3332 26.0406 25.3332 25.3333V16"
      stroke="#00AAE4"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 28.0007V20.0007C12 19.2934 12.281 18.6151 12.781 18.115C13.2811 17.6149 13.9594 17.334 14.6667 17.334H17.3333C18.0406 17.334 18.7189 17.6149 19.2189 18.115C19.719 18.6151 20 19.2934 20 20.0007V28.0007"
      fill="#F0F2F4"
    />
    <path
      d="M12 28.0007V20.0007C12 19.2934 12.281 18.6151 12.781 18.115C13.2811 17.6149 13.9594 17.334 14.6667 17.334H17.3333C18.0406 17.334 18.7189 17.6149 19.2189 18.115C19.719 18.6151 20 19.2934 20 20.0007V28.0007"
      stroke="#00AAE4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
