<template>
  <svg
    v-if="!isActive"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.37867 15.0408C9.42738 11.1269 13.4624 8.79732 17.3762 9.84603C21.29 10.8947 23.6196 14.9297 22.5709 18.8436L21.1498 24.1474L22.7819 28.6881L3.27367 23.4609L6.95752 20.3446L8.37867 15.0408Z"
      stroke="#00AAE4"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M21.2472 8.17943L21.3387 7.83804C21.8124 6.0701 20.7681 4.26132 19.0001 3.7876C17.2322 3.31388 15.4234 4.35818 14.9497 6.12612L14.8582 6.46751C17.5651 6.461 19.0502 6.81973 21.2472 8.17943Z"
      stroke="#00AAE4"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M12.4874 29.9944L11.5364 29.7396C9.43924 29.1777 8.19857 27.0288 8.7605 24.9316L17.2954 27.2186C16.7334 29.3157 14.5845 30.5564 12.4874 29.9944Z"
      stroke="#00AAE4"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else 
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3363 25.6335L22.0141 21.9986L23.1596 17.7236C24.1661 13.9675 21.9034 10.0805 18.1205 9.06684C14.3375 8.05319 10.4345 10.2881 9.42803 14.0442L8.28256 18.3192L5.32009 20.8061C5.08894 21 4.98463 21.3057 5.05098 21.6015C5.11732 21.8973 5.33687 22.1229 5.62948 22.2013L22.3498 26.6815C22.6424 26.7599 22.9586 26.6667 23.1612 26.4541C23.3666 26.2311 23.4291 25.9142 23.3258 25.6307L23.3363 25.6335Z"
      fill="#00AAE4"
    />
    <path
      d="M15.4216 7.72127C15.4942 7.74073 15.5565 7.75741 15.6375 7.74574C17.8289 7.74352 18.9627 8.0362 20.6491 9.08861C20.8683 9.22519 21.1312 9.24004 21.3673 9.1476C21.6034 9.05516 21.7787 8.85747 21.8454 8.60844L21.9232 8.31791C22.4404 6.38796 21.2934 4.40134 19.3634 3.88422C17.4335 3.36709 15.4469 4.51406 14.9297 6.44401L14.8519 6.73454C14.788 6.97319 14.8409 7.23205 14.986 7.43773C15.0931 7.57764 15.2452 7.674 15.4216 7.72127Z"
      fill="#00AAE4"
    />
    <path
      d="M17.2888 26.1528L10.0255 24.2066C9.6001 24.0926 9.15279 24.3508 9.03879 24.7763C8.4466 26.9864 9.76068 29.2624 11.9708 29.8546L12.7801 30.0715C14.9902 30.6637 17.2663 29.3496 17.8585 27.1395C17.9725 26.7141 17.7142 26.2667 17.2888 26.1528Z"
      fill="#00AAE4"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
