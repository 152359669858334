<template>
  <div class="inline-flex items-center">
    <span v-if="$slots.left" class="text-[16px] leading-[20px]">
      <slot name="left" />
    </span>
    <div
      class="relative inline-flex flex-shrink-0 h-[32px] transition-colors duration-200 ease-in-out border-[4px] border-transparent rounded-full w-[64px] focus:outline-none"
      :class="[disabledClasses, valueClasses]"
      @click="toggle"
    >
      <span
        aria-hidden="true"
        class="inline-block w-[24px] h-[24px] transition duration-200 ease-in-out transform rounded-full shadow pointer-events-none ring-0"
        :class="[localValue ? 'translate-x-[32px]' : 'translate-x-0', disabledCenterClasses]"
      />
    </div>
    <span v-if="$slots.right" class="text-[16px] leading-[20px]">
      <slot name="right" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
      require: true,
    },
  },

  data () {
    return {
      localValue: this.value,
    }
  },

  computed: {
    disabledClasses () {
      if (this.disabled) {
        return this.localValue ? 'bg-[#D3D8DE] cursor-default' : 'bg-[#787880] opacity-[0.16]'
      } else {
        return 'cursor-pointer'
      }
    },
    valueClasses () {
      return this.localValue ? 'bg-[#34C759]' : 'bg-[#787880] opacity-[0.16]'
    },
    disabledCenterClasses () {
      if (this.disabled && !this.localValue) {
        return 'bg-[#FFFFFF]'
      }
      return 'bg-[#FFFFFF]'
    },
  },

  watch: {
    localValue (newValue) {
      this.$emit('input', newValue)
    },
    value (newValue) {
      this.localValue = newValue
    },
  },
  methods: {
    toggle () {
      if (!this.disabled) {
        this.localValue = !this.localValue
      }
    },
  },
}
</script>
