import { isGPCRPage } from "@/utils/conference-type";
import axios from "axios";
// import { getApiUrl } from '@/utils/platform-check'

const state = () => ({
  registrationLink: [],
  announcements: [],
  quotes: [],
  sessionMaterials: [],
  loading: false,
  audioFlag: false,
  video: {},
});

const getters = {};

const mutations = {
  setAnnouncements(state, announcements) {
    state.announcements = announcements;
  },
  setRegistrationLink(state, registrationLink) {
    state.registrationLink = registrationLink;
  },
  setQuotes(state, quotes) {
    state.quotes = quotes;
  },
  setSessionMaterials(state, sessionMaterials) {
    state.sessionMaterials = sessionMaterials;
  },
  setGensmvmtMaterials(state, GensmvmtMaterials) {
    state.gensmvmtMaterials = gensmvmtMaterials;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setAudioFlag(state, audioFlag) {
    state.audioFlag = audioFlag;
  },
  updateVideo(state, video) {
    state.video = video;
  },
};

const actions = {
  setLoading({ commit }, loading) {
    commit("setLoading", loading);
  },
  async fetchAnnouncements({ commit, dispatch, rootState }) {
    commit("setLoading", true);
    try {
      const accessToken = rootState.user.token;
      const { data: announcements } = await axios.get(
        // 'https://api.dev.generations.app/cms/announcements'
        // getApiUrl() + '/cms/announcements'
        process.env.VUE_APP_API_URL + "/cms/announcements/get",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      commit("setAnnouncements", announcements);
    } catch (e) {
      console.log(e);
      dispatch("toast/error", "Failed to fetch announcements.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchRegistrationLink(
    { commit, dispatch, rootState },
    { locale = "en", conferenceType = "Global" }
  ) {
    try {
      const accessToken = rootState.user.token;
      const { data: registrationLink } = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/registration-links",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            locale: locale,
            conferenceType: conferenceType,
          },
        }
      );
      commit("setRegistrationLink", registrationLink[0]);
    } catch (e) {
      dispatch("toast/error", "Failed to fetch registration link.", {
        root: true,
      });
    }
  },
  async fetchQuotes({ commit, dispatch, rootState }, resourceName) {
    commit("setLoading", true);
    try {
      const accessToken = rootState.user.token;
      const { data: quotes } = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/" + resourceName,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      commit("setQuotes", quotes);
    } catch (e) {
      dispatch("toast/error", "Failed to fetch quotes.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchSessionMaterials(
    { commit, dispatch, rootState },
    { sessionId, conferenceType }
  ) {
    commit("setLoading", true);
    try {
      const accessToken = rootState.user.token;
      const { data: sessionMaterials } = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/sessionMaterials/" + sessionId,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            conferenceType: conferenceType,
          },
        }
      );
      commit("setSessionMaterials", sessionMaterials[0]);
    } catch (e) {
      dispatch("toast/error", "Failed to fetch session materials.", {
        root: true,
      });
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchVideoBySlug({ commit, rootState }, { slug, conferenceType }) {
    commit("setLoading", true);
    try {
      const accessToken = rootState.user.token;
      // TODO to remove after we merge the collection
      let response = null;
      if (isGPCRPage(conferenceType)) {
        response = await axios.get(
          process.env.VUE_APP_API_URL + `/cms/videos/${slug}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      } else {
        response = await axios.get(
          process.env.VUE_APP_API_URL + `/cms/gpc-videos/videos/${slug}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      }
      commit("updateVideo", response.data);
      commit("setLoading", false);
    } catch (error) {
      commit("setLoading", false);
    }
  },
  async fetchAllSessionMaterials({ commit, dispatch, rootState }) {
    commit("setLoading", true);
    try {
      const accessToken = rootState.user.token;
      const { data: sessionMaterials } = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/sessionMaterials/all",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      commit("setSessionMaterials", sessionMaterials);
    } catch (e) {
      dispatch("toast/error", "Failed to fetch session materials.", {
        root: true,
      });
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchAudioFlag({ commit, dispatch, rootState }) {
    try {
      const accessToken = rootState.user.token;
      const paramName = "AUDIO_FLAG";
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/sysparams/params/" + paramName,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200) throw new Error("Failed in getting audio flag");
      commit("setAudioFlag", data);
    } catch (e) {
      dispatch("toast/error", "Failed to load profile.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },
  setResources(state, resources) {
    if (!Array.isArray(resources)) state.resources = [resources];
    else state.resources = resources;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
