// tour.js

const state = {
    tour: null,
  };

  const getters = {
    getTour: (state) => state.tour,
  };

  const mutations = {
    setTour: (state, tour) => {
      state.tour = tour;
    },
  };

  const actions = {
    setTour: ({ commit }, tour) => {
      commit('setTour', tour);
    },
  };

  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
  };
