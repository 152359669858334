<template>
  <div>
    <div
      v-for="(resource, i) in getSortedResources"
      :key="i"
      class="mt-[30px] md:mt-[60px] w-[calc(100vw-24px)] lg:w-[calc(100vw-500px)] bg-[#F8F9F9]"
    >
      <h1
        class="mb-[16px] font-aktiv-grotesk text-[24px] leading-[32px] font-bold"
      >
        {{ resource[0].name }}
      </h1>
      <ResourcesSlider :category-index="i" :items="resource" />
    </div>
    <div
      v-if="filteredBooks.length > 0"
      class="mt-[60px] w-[calc(100vw-24px)] mr-[24px] lg:ml-0 lg:w-full bg-[#F8F9F9]"
    >
      <h1
        class="mb-[16px] font-aktiv-grotesk text-[24px] leading-[32px] font-bold"
      >
        Book
      </h1>
      <div class="rounded-[16px] w-[228px] md:w-[262px] md:pr-6 lg:w-[287px]">
        <in-app-browser :href="bookUrl" target="_blank">
          <img
            class="w-full rounded-[16px] cursor-pointer"
            :src="filteredBooks[0].thumbnail"
          />
        </in-app-browser>
      </div>
    </div>
    <div
      v-if="filteredQuotes.length > 0"
      class="mt-[60px] w-[calc(100vw-24px)] lg:w-[calc(100vw-500px)] bg-[#F8F9F9]"
    >
      <h1
        class="mb-[16px] font-aktiv-grotesk text-[24px] leading-[32px] font-bold"
      >
        Quotes
      </h1>
      <ResourcesSlider :category-index="i" :items="filteredQuotes" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ResourcesSlider from "@/components/ResourcesPage/PlaybackResources/ResourcesSlider.vue";

export default {
  components: {
    ResourcesSlider,
  },
  props: {
    filteredResources: {
      type: Array,
    },
    filteredQuotes: {
      type: Array,
    },
    filteredBooks: {
      type: Array,
    },
  },
  computed: {
    ...mapState("filter", ["allResources", "isLoading"]),

    getSortedResources() {
      let contentType = [];
      for (const key in this.filteredResources) {
        if (!contentType.includes(this.filteredResources[key].name)) {
          contentType.push(this.filteredResources[key].name);
        }
      }
      let result = [];
      for (const key in contentType) {
        result.push(
          this.filteredResources.filter(
            (resource) => resource.name === contentType[key]
          )
        );
      }
      return result;
    },
    bookUrl() {
      if (this.filteredBooks) {
        return this.filteredBooks[0].resourceTranslations[0].resourceUrl;
      }
      return "";
    },
  },
};
</script>
