export const startSurvicate = (email, name, organization, key, tracking) => {
  console.log("Check if survicate is initialize");
  if (organization !== "Heart of God Church") {
    const traits = {
      name: name,
    };
    if (tracking) {
      traits.email = email;
      traits.organization = organization;
    }
    console.log("Non HOGC Member Detected: Initialize Survicate");
    // identify session
    (function (opts) {
      opts.traits = traits;
      console.log(opts.traits);
    })((window._sva = window._sva || {}));

    (function (w) {
      var s = document.createElement("script");
      s.src =
        "https://survey.survicate.com/workspaces/" + key + "/web_surveys.js";
      s.async = true;
      var e = document.getElementsByTagName("script")[0];
      e.parentNode.insertBefore(s, e);
      console.log(s);
    })(window);
  }
};
