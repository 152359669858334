<template>
  <button
    class="bg-lightblue rounded-full flex flex-row grow-0 text-white text-center px-[16px] py-[8px]"
    @click="toggleShowSharing"
  >
    <img
      class="mr-[8px]"
      src="../../../../public/assets/icons/playback-resources/share-icon.svg"
      alt=""
    />
    {{ $t("copy.resourcesPage.videoPage.shareButton") }}
  </button>
</template>

<script>
export default {
  methods: {
    toggleShowSharing() {
      this.$emit("toggleSharing");
    },
  },
};
</script>

<style scoped></style>
