import { isPlatform } from "@ionic/vue";

export const createRedirectUri = (packageId, domain) => {
  const isNative = isPlatform("ios") || isPlatform("android");
  const isNativeBrowser = isPlatform("mobileweb");

  return isNative && !isNativeBrowser
    ? `${packageId}://${domain}/capacitor/${packageId}/callback`
    : window.location.origin;
};

export const createLogoutUri = (packageId, domain) => {
  const isNative = isPlatform("ios") || isPlatform("android");
  const isNativeBrowser = isPlatform("mobileweb");

  return isNative && !isNativeBrowser
    ? `${packageId}://${domain}/capacitor/${packageId}/logout`
    : window.location.origin;
};

export const redirectNoAccess = (router) => {
  router.push("/");
};

export const redirectDeepLinkPath = (router, url) => {
  console.log("url:", url);
  const urlObj = new URL(url);
  console.log("urlObj.origin", urlObj.origin);
  if (urlObj.origin === process.env.VUE_APP_APP_DOMAIN) {
    const params = new URLSearchParams(urlObj.search);
    const paramsObject = {};
    for (let [key, value] of params.entries()) {
      paramsObject[key] = value;
    }
    // this one for redirect after deep linking login
    const deepLinkPathObj = {
      path: urlObj.pathname,
      query: paramsObject,
      hash: urlObj.hash,
    };
    console.log("deepLinkPathObj", deepLinkPathObj);
    sessionStorage.setItem("deepLinkPathObj", JSON.stringify(deepLinkPathObj));
    router.push(deepLinkPathObj);
    return true;
  }
  console.log("redirectDeepLinkPath: false");
  return false;
};
