<template>
  <PopupModal :show="show" @close-popup="closeModal()">
    <div
      class="exp-modal-wrapper fixed overflow-y-auto pb-8 bg-white md:rounded-2xl w-full h-full top-[50%] left-1/2 transform -translate-y-1/2 -translate-x-1/2 flex flex-col z-50 font-aktiv-grotesk-medium w-full h-max md:max-w-[375px] md:min-h-0 md:max-h-[calc(100vh-140px)]"
      @click.stop
    >
      <div
        class="close-btn absolute cursor-pointer z-50 mx-[24px] md:mx-[32px] lg:ml-[56px] pt-4"
        :class="{ '!top-[44px]': isIos() }"
        @click="closeModal()"
      >
        <img src="../../../public/assets/icons/go-back-chevron-orange.svg" />
      </div>
      <BaseSwiper
        :id="'exp-modal-swiper'"
        :ref="'exp-modal-swiper'"
        v-slot="slotProps"
        :items="exp.images"
        :options="swiperOptions"
      >
        <div class="image-container w-screen md:w-[375px]">
          <img
            class="md:rounded-t-2xl md:w-[375px] aspect-ratio w-full"
            :src="slotProps.item.url"
          />
        </div>
      </BaseSwiper>

      <div class="mx-6 mb-[100px] md:mb-[32px]">
        <div
          v-if="isSignUpRequired(exp.title)"
          class="inline-block text-[12px] leading-[20px] font-aktiv-grotesk-medium text-center rounded-xl py-[4px] px-[8px] mb-[0px] bg-pale-orange text-orange"
        >
          Sign-up required
        </div>
        <div class="summary-container my-3 border-b border-[#D3D8DE]">
          <div
            class="exp-title flex mb-8 justify-start items-start text-h5-bold"
          >
            {{ exp.title }}
          </div>

          <div
            v-for="dateTime in exp.info"
            :key="dateTime"
            :class="exp.info.length == 1 ? 'mb-1' : 'mb-5'"
          >
            <div
              class="exp-date flex mb-1 justify-start items-start font-aktiv-grotesk-regular"
            >
              <img
                src="../../../public/assets/icons/event-date-icon-orange.svg"
                class="mt-1"
              />
              <span class="text-[16px] leading-[24px] text-grey-80 ml-2">
                {{ dateTime.date }}
              </span>
            </div>

            <!-- <div
              class="exp-timing flex justify-start items-start font-aktiv-grotesk-regular"
              :class="exp.location.length == 0 ? 'mb-5' : ''"
            > -->
            <div
              class="exp-timing flex justify-start items-start font-aktiv-grotesk-regular"
              :class="dateTime.venue == null ? 'mb-5' : ''"
            >
              <img
                src="../../../public/assets/icons/event-duration-icon-orange.svg"
                class="mt-1"
              />
              <span class="text-[16px] leading-[24px] text-grey-80 ml-2">
                {{ dateTime.timing }}
              </span>
            </div>
          </div>
          <div
            v-if="exp.location"
            class="exp-location flex mb-5 justify-start items-start font-aktiv-grotesk-regular"
          >
            <img
              class="ml-[4px] mt-1"
              src="../../../public/assets/icons/event-location-icon-orange.svg"
            />
            <div class="flex flex-col">
              <span class="text-[16px] leading-[24px] text-grey-80 ml-[10px]">
                {{ exp.location }}
              </span>
            </div>
          </div>
          <p
            v-if="exp.note"
            class="font-aktiv-grotesk text-[14px] leading-[20px] text-grey-80 italic mb-5"
          >
            {{ exp.note }}
          </p>
        </div>

        <div
          class="text-[16px] leading-[24px] font-aktiv-grotesk-regular text-grey-100"
        >
          {{ exp.fullDescription }}
        </div>

        <div
          v-if="exp.video"
          class="video-aspect-ratio min-w-[272px] relative mt-4 mb-12"
        >
          <video-player
            ref="experienceVideo"
            class="video-aspect-ratio rounded-2xl w-full"
            data-event-tracking="experience-video-player"
            :options="getVideoOptions(exp)"
          />
          <div
            ref="preview"
            class="absolute top-0 w-full video-aspect-ratio rounded-2xl cursor-pointer flex flex-col items-center"
          >
            <img
              :src="exp.videoPreviewImage.url"
              class="w-full video-aspect-ratio rounded-2xl"
              data-test-id="experience-video-preview"
              @click="playVideo"
            />
            <div
              class="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 rounded-2xl"
            >
              <img
                src="../../../public/assets/icons/youtube-player/play-button-dark.svg"
                data-test-id="experience-video-play"
                class="w-[54px] h-[54px]"
                @click="playVideo"
              />
            </div>
          </div>
        </div>
        <div v-if="showButton" class="flex justify-center mt-[32px]">
          <div
            class="rounded-full cursor-pointer py-3 px-4 bg-orange text-white font-aktiv-grotesk-medium text-[20px] leading-[24px]"
            :data-event-tracking="exp.title in detMap ? detMap[exp.title] : ''"
            @click="goExperiencePage()"
          >
            Sign up now
          </div>
        </div>
      </div>
    </div>
  </PopupModal>
</template>

<script>
import BaseSwiper from "../common/BaseSwiper";
import PopupModal from "../common/PopupModal.vue";
import VideoPlayer from "../common/VideoPlayer";
import { isIosNativeOnly } from "@/utils/platform-check";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { mapState } from "vuex";
import { isGPCRPage } from "@/utils/conference-type";

export default {
  name: "ExperienceModal",
  components: {
    BaseSwiper,
    PopupModal,
    VideoPlayer,
  },
  data() {
    return {
      swiperOptions: {
        centeredSlides: false,
        slidesPerView: "auto",
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
          type: "custom",
          renderCustom: (_swiper, current, total) => {
            return `<span class='z-10 absolute bottom-5 right-4 bg-orange py-0.5 px-2 rounded-lg text-white font-aktiv-grotesk-regular'>${current}/${total}</span>`;
          },
        },
        hasNavigation: true,
      },
      detMap: {
        "CG Meeting": "gensapp-schedule-cgexperience-signupnow",
        "Prayer Meeting": "gensapp-schedule-mediaexperience-signupnow",
        "Ministry Debriefs":
          "gensapp-schedule-ministrydebriefexperience-signupnow",
        "Dreams & Stories Night":
          "gensapp-schedule-dreamsstoriesexperience-signupnow",
      },
      mainExp: {},
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    exp: {
      type: Object,
      default: () => {},
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState("signup", ["mainExpData"]),
    labelColor() {
      // Handle scenario: where no label
      if (!this.label) return "orange";
      // Below code: all has label

      // Handle scenario: where weekday
      if (this.isWeekday) return "black";
      // weekday & experience
      if (this.isWeekday) return "black";
      if (this.isWeekday) return "yellow";

      // Below all is weekend
      if (this.eventType === "experience") return "dark-blue";
      return "yellow";
    },
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
    closeModal() {
      this.$emit("close-modal");
    },
    goExperiencePage() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "gensapp-home-sign-up-experiences",
          page: "home",
        },
      });

      this.closeModal();
      this.$router.push("/experience");
    },
    getVideoOptions(exp) {
      return {
        autoplay: false,
        controls: true,
        playsinline: true,
        sources: [
          {
            src: exp.video,
            type: "video/mp4",
          },
        ],
      };
    },
    playVideo() {
      this.$refs.experienceVideo.player.play();
      this.$refs.preview.classList.add("z-[-100]");
    },
    update() {
      const swiper = this.$refs["exp-modal-swiper"];
      swiper.update();
    },
    getLabelColor() {
      if (this.cgExp && this.subExp) return "orange";
      if (this.isSignupClosed() || this.subExp) return "grey";
      // if (!exp) return !this.isWeekday ? "grey" : "paledarkblue";
      return this.isWeekday ? "orange" : "paledarkblue";
    },
    isSignUpRequired(title) {
      let signUpRequired = false;
      for (const key in this.mainExpData.registerableExps) {
        if (this.mainExpData.registerableExps[key].title === title) {
          signUpRequired = true;
        }
      }
      return signUpRequired;
    },
  },
};
</script>

<style scoped>
.aspect-ratio {
  aspect-ratio: 375/250;
}

.video-aspect-ratio {
  aspect-ratio: 800/450;
}

:deep(.swiper-container) {
  padding-left: 0;
  padding-bottom: 0;
}

::v-deep(.swiper-pagination) {
  margin-top: 8px;
}

:deep(.swiper-wrapper) {
  padding-left: 0 !important;
  z-index: -1;
}

:deep(.swiper-slide) {
  height: 100%;
}

:deep(.swiper-slide:last-child) {
  margin-right: 0 !important;
}

:deep(.experience-video) {
  @media screen and (max-width: 375px) {
    height: 153px;
  }

  @media screen and (min-width: 375px) {
    height: 184px;
  }
}
</style>
