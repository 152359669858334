<template>
  <div>
    <BaseSwiper
      :id="baseSwiperId"
      :ref="baseSwiperId"
      v-slot="slotProps"
      :items="finalExps"
      :options="swiperOptions"
    >
      <div class="card w-[264px] rounded-[12px] bg-white relative">
        <img
          class="rounded-t-2xl w-[264px]"
          :src="slotProps.item.images[0].url"
        />
        <div v-if="isSignUpRequired(slotProps.item.title)">
          <CardLabel color="paleorange">Sign-up required</CardLabel>
        </div>
        <!-- <img
          class="rounded-t-2xl w-[264px]"
          :src="carouselItems.item.images[0]"
        /> -->
        <div class="px-4 mt-[12px] mx-auto text-left">
          <h2
            class="font-aktiv-grotesk-medium text-[16px] leading-[24px] text-[#0B0C0F] mb-[15px]"
          >
            {{ slotProps.item.title }}
            <!-- {{ carouselItems.item.title }} -->
          </h2>
          <p
            class="text-content font-aktiv-grotesk-regular text-[16px] leading-[24px] text-[#0B0C0F] overflow-hidden h-[48px]"
          >
            {{ slotProps.item.carouselDescription }}
            <!-- {{ slotProps.item.description }} -->
          </p>
          <div class="h-[20px] relative my-[20px]">
            <!-- v-if="slotProps.item.readMore" -->
            <button
              id="experience-carousel"
              class="font-aktiv-grotesk font-bold text-[14px] leading-[20px] text-[#FF6600] absolute right-0"
              :data-event-tracking="slotProps.item.dataEventTracking"
              @click="displayExpModal(true, slotProps.item)"
            >
              {{ $t("copy.experiencePage.carousel.viewDetailsButton") }}
            </button>
          </div>
        </div>
      </div>
    </BaseSwiper>
  </div>
</template>

<script>
import BaseSwiper from "../common/BaseSwiper";
import ExperienceModal from "./ExperienceModal.vue";
import { expData } from "../../utils/exp.js";
import CardLabel from "../common/CardLabel.vue";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { mapState } from "vuex";

export default {
  name: "ExperienceCarousel",
  components: {
    BaseSwiper,
    ExperienceModal,
    CardLabel,
  },
  props: {
    baseSwiperId: {
      type: String,
    },
    displayTypes: {
      type: Array,
    },
    swiperOptions: {
      type: Object,
      default: () => {},
    },
    cardLabel: {
      type: String,
      default: "",
    },
    expData: {
      type: Object,
    },
  },
  data() {
    return {
      // experiences: expData,
      // experiences: this.carouselItems,
    };
  },
  computed: {
    ...mapState("signup", ["mainExpData"]),
    finalExps() {
      const expList = [];
      // this.displayTypes.forEach((type) => {
      //   if (this.experiences[type]) expList.push(this.experiences[type]);
      // });
      if (!this.expData) return [];

      Object.keys(this.expData).forEach((key) => {
        if (this.expData[key].type) expList.push(this.expData[key]);
      });
      console.log("EXPLIST:", expList);
      return expList;
    },
  },
  methods: {
    displayExpModal(show, exp) {
      this.$emit("show-popup", exp);
      console.log("EXP:", exp.info);
      exp.info.forEach((dateTime) => {
        console.log("DATE:", dateTime.date);
        console.log("TIMING:", dateTime.timing);
        console.log("VENUE:", dateTime.venue);
      });
      this.$router.push({
        path: "/experience",
        hash: `#${exp.slug}`,
      });
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: exp.dataEventTracking,
          page: "experience",
        },
      });
    },
    update() {
      const swiper = this.$refs[this.baseSwiperId];
      swiper.update();
    },
    isSignUpRequired(title) {
      let signUpRequired = false;

      if (this.mainExpData === null) {
        return false;
      } else {
        for (const key in this.mainExpData.registerableExps) {
          if (this.mainExpData.registerableExps[key].title === title) {
            signUpRequired = true;
          }
        }
        return signUpRequired;
      }
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
}
</style>
