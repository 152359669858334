<template>
  <ion-app>
    <ion-router-outlet animated="false" />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, useBackButton, isPlatform } from "@ionic/vue";
import { defineComponent } from "vue";
import { App as CapApp } from "@capacitor/app";
import { useAuth0 } from "@auth0/auth0-vue";
import { Browser } from "@capacitor/browser";
import { pushInit, unsubscribePush } from "./utils/push-notification";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { FirebaseCrashlytics } from "@awesome-cordova-plugins/firebase-crashlytics";
import { isIosNativeOnly } from "@/utils/platform-check";
import { redirectDeepLinkPath } from "@/utils/redirect-uri";
import router from "./router";

// import { useStore } from 'vuex';

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },

  setup() {
    useBackButton(10, () => {
      // leave empty to not trigger any back button event
    });

    const { handleRedirectCallback, getAccessTokenSilently, isAuthenticated } =
      useAuth0();
    const isNative = isPlatform("android") || isPlatform("ios");
    const isNativeBrowser = isPlatform("mobileweb");
    // const store = useStore()

    CapApp.addListener("appUrlOpen", async ({ url }) => {
      console.log("cap app url:", url);
      console.log("before capapp");

      const needRedirect = redirectDeepLinkPath(router, url);
      if (needRedirect) {
        return;
      }

      let appState = null;
      if (
        url.includes("state") &&
        (url.includes("code") || url.includes("error"))
      ) {
        try {
          const redirect = await handleRedirectCallback(url);
          appState = redirect.appState;
          console.log("appState", appState);
        } catch (e) {
          if (e.error === "access_denied") {
            if (e.error_description === "access 2022") {
              console.log("forward to 2022-access");
              window.location.href = "/2022-access";
            } else if (e.error_description === "access denied") {
              console.log("forward to access-denied");
              window.location.href = "/access-denied";
            }
          } else {
            throw e;
          }
        }
        console.log("after redirect capapp");
      }
      try {
        await Browser.close();
      } catch (error) {
        console.log(error);
      }

      if (url.includes("/callback")) {
        console.log("login done...");
        console.log("callback url: " + url);
        //init push notifications here
        if (isNative && !isNativeBrowser) {
          // pushInit();

          /**
           * Platform: Web/Android/iOS
           * Sets whether analytics collection is enabled for this app on this device.
           * @param name - enabled - boolean true/false
           * @returns void
           */
          FirebaseAnalytics.setCollectionEnabled({
            enabled: true,
          });

          // FirebaseCrashlytics.initialise();

          //init firebase analytics here
          // if (isIosNativeOnly){
          // /**
          //  * Platform: iOS
          //  * Configure and initialize the firebase app.
          //  * @param options - firebase web app configuration options
          //  * */
          // FirebaseAnalytics.initializeFirebase({
          //   apiKey: "AIzaSyBergRxyzcQN6navwYVq2cyDwKUMm7Y7jw",
          //   // authDomain: "...",
          //   // databaseURL: "...",
          //   projectId: "gens-conf-mobile-2023",
          //   storageBucket: "gens-conf-mobile-2023.appspot.com",
          //   messagingSenderId: "641254926110",
          //   appId: "1:641254926110:ios:c0cdd848109e8797c88b0f",
          //   // measurementId: "...",
          // });
          // }
          // else {
          //   /**
          //    * Platform: Android
          //    * Configure and initialize the firebase app.
          //    * @param options - firebase web app configuration options
          //    * */
          //    FirebaseAnalytics.initializeFirebase({
          //     apiKey: "AIzaSyDs8LMEg2pLeWiLdlz8PVC5gULqsQDFVcg",
          //     // authDomain: "...",
          //     // databaseURL: "...",
          //     projectId: "gens-conf-mobile-2023",
          //     storageBucket: "gens-conf-mobile-2023.appspot.com",
          //     messagingSenderId: "641254926110",
          //     appId: "1:641254926110:android:f2bdb3c1658b6f6bc88b0f",
          //     // measurementId: "...",
          //   });
          // }
          if (appState && appState.fullPathObj) {
            router.push({
              path: appState.fullPathObj.path,
              query: appState.fullPathObj.query,
              hash: appState.fullPathObj.hash,
            });
          }
        }
      } else if (url.includes("/logout")) {
        console.log("logout done...");
        window.location.href = "/";
        unsubscribePush();
        /**
         * Platform: Web/Android/iOS
         * Sets whether analytics collection is enabled for this app on this device.
         * @param name - enabled - boolean true/false
         * @returns void
         */
        FirebaseAnalytics.setCollectionEnabled({
          enabled: false,
        });
      }
    });
    console.log("Capapp: " + isAuthenticated.value);
    // const token = getAccessTokenSilently();
    // return{
    //   setToken: async() => await store.commit('user/setToken', token)
    // }
  },
});
</script>
