<!-- eslint-disable vue/no-v-html -->
<template>
  <master-layout v-slot="slotProps">
    <div
      class="mb-[40px] lg:mb-[104px] lg:mx-[56px] w-full max-w-[1040px] pb-12"
      :class="{ 'pt-12': isIos || isAndroid }"
    >
      <LoadingRing v-if="isLoading" />
      <div v-else id="content-video" class="lg:mt-[32px] w-full mx-auto">
        <button class="hidden lg:block max-lg:hidden mb-[32px]" @click="back">
          <img src="../../../public/assets/icons/go-back-chevron.svg" alt="" />
        </button>
        <div
          id="video-spotlight"
          class="w-full flex flex-col lg:flex-row lg:max-h-[384px]"
        >
          <div
            class="w-full lg:max-w-[680px] lg:mr-[40px]"
            :class="{
              'mx-auto': video.videoChapters && video.videoChapters.length > 0,
            }"
          >
            <!--  TODO: select the right video translation. For now I hard coded it to be the first translation in the array-->
            <VideoPlayer
              :id="slug"
              :ref="`video-component-${slug}`"
              :metadata="{
                url: video.resourceTranslations[0]?.resourceUrl,
                mime: 'application/x-mpegURL',
                thumbnail: video.videoThumbnail,
                title: video.title,
              }"
            />
          </div>
          <div
            v-if="video.videoChapters && video.videoChapters.length > 0"
            class="bg-[#F0F2F4] w-full mx-auto lg:max-w-[312px] pt-[20px] rounded-[16px] hidden lg:block"
          >
            <VideoChapters
              :video-chapters="video.videoChapters"
              @jumpToChapter="jumpToChapter"
            />
          </div>
        </div>
        <div class="mx-[24px] lg:mx-0 max-lg:mx-[24px] mt-[32px] relative">
          <ShareButton
            id="share-button"
            class="absolute right-0"
            @toggleSharing="toggleSharing(slotProps)"
          />
          <button class="lg:hidden mb-[32px]" @click="back">
            <img
              src="../../../public/assets/icons/go-back-chevron.svg"
              alt=""
            />
          </button>
          <VideoTag
            :is-video="true"
            :is-audio="video.isAudio"
            :is-exclusive="video.isCVPExclusive"
            :has-chinese-overdub="video.availTranslations?.hasChineseOverdub"
            :has-thai-overdub="video.availTranslations?.hasThaiOverdub"
            :has-chinese-sub="video.availTranslations?.hasTradChiSubtitles"
            :translations="video.resourceTranslations"
            :type="type"
          />
          <div class="w-full lg:w-[812px] mt-[32px]">
            <h1
              class="font-aktiv-grotesk font-bold mt-[8px] text-[#21252C] text-[24px] leading-[32px]"
            >
              {{ video.title }}
            </h1>
            <p
              class="font-aktiv-grotesk text-[16px] lg:text-[20px] leading-[28px] lg:leading-[32px] mt-[8px] text-[#21252C]"
            >
              {{ video.preacher }}
            </p>
          </div>
          <p
            v-link-in-new-tab
            v-link-styling
            class="font-aktiv-grotesk text-[16px] lg:text-[20px] leading-[28px] lg:leading-[32px] mt-[24px] text-[#21252C]"
            v-html="video.description"
          ></p>
          <div
            v-if="video.videoChapters && video.videoChapters.length > 0"
            class="bg-[#F0F2F4] w-full mx-auto pt-[20px] px-[20px] rounded-[16px] lg:hidden mt-[36px]"
          >
            <VideoChapters
              :video-chapters="video.videoChapters"
              @jumpToChapter="jumpToChapter"
            />
          </div>
        </div>
      </div>
    </div>
  </master-layout>
</template>

<script>
import { mapState } from "vuex";
import LoadingRing from "@/components/common/LoadingRing";
import VideoPlayer from "@/components/Video/VideoPlayer";
import VideoChapters from "@/components/Video/VideoChapters";
import VideoTag from "@/components/ResourcesPage/PlaybackResources/VideoTag.vue";
import ShareButton from "@/components/ResourcesPage/PlaybackResources/ShareButton.vue";
import { isAndroidNativeOnly, isIosNativeOnly } from "@/utils/platform-check";

export default {
  components: {
    VideoTag,
    LoadingRing,
    VideoPlayer,
    VideoChapters,
    ShareButton,
  },
  directives: {
    "link-in-new-tab": {
      inserted: function (el) {
        const anchors = el.querySelectorAll("a");
        anchors.forEach((anchor) => {
          anchor.target = "_blank";
        });
      },
    },
    "link-styling": {
      inserted: function (el) {
        const anchors = el.querySelectorAll("a");
        anchors.forEach((anchor) => {
          anchor.classList.add("underline");
          anchor.classList.add("text-[#00A9E0]");
        });
      },
    },
  },
  layout: "default",
  computed: {
    ...mapState("cms", ["isLoading", "video"]),
    slug() {
      return this.$route.params.video;
    },
    isIos() {
      return isIosNativeOnly();
    },
    isAndroid() {
      return isAndroidNativeOnly();
    },
  },
  async ionViewWillEnter() {
    await this.$store.dispatch("cms/fetchVideoBySlug", {
      slug: this.$route.params.video,
      conferenceType: "Global",
    });
  },
  ionViewDidLeave() {
    this.$store.commit("cms/updateVideo", {});
  },
  methods: {
    jumpToChapter(title, timestamp) {
      const splitTimestamp = timestamp.split(":");
      const seconds = +splitTimestamp[0] * 60 + +splitTimestamp[1];
      this.$refs[`video-component-${this.slug}`].seek(seconds);
      this.$refs[`video-component-${this.slug}`].play();
      this.backToTop();
    },
    backToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    back() {
      this.$router.go(-1);
    },
    toggleSharing(slotProps) {
      slotProps.toggleNewsletter();
    },
  },
};
</script>
