const state = () => ({
  message: '',
  icon: '',
  color: '',
})

const mutations = {
  set(state, data) {
    state.message = data.message
    state.icon = data.icon || require('../../../public/assets/icons/toast-check.svg')
    state.color = data.color || 'bg-[#056DFF]'
  },
}

const actions = {
  show({ commit }, data) {
    if (typeof data === 'string') {
      commit('set', { message: data })
    } else {
      commit('set', data)
    }

    setTimeout(() => commit('set', { message: '' }), 3000)
  },

  error({ dispatch }, message) {
    dispatch('show', {
      message,
      color: 'bg-[#BE3A34]',
      icon: require('../../../public/assets/icons/alert-triangle.svg'),
    })
  },
}

export default{
  namespaced: true,
  state,
  actions,
  mutations
}
