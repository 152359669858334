import { Smartlook as SmartlookMobile } from "@awesome-cordova-plugins/smartlook";
import Smartlook from "smartlook-client";
import { isNativeNotWeb } from "@/utils/platform-check";

// This is called only upon refetching profile, which happens only once till refresh/ login
export const startSmartlook = (
  email,
  name,
  organization,
  confYear,
  groupId,
  tracking
) => {
  const delegateType =
    organization !== "Heart of God Church" ? "Delegate" : "Host";

  if (!isNativeNotWeb()) {
    console.log("Not Native");

    Smartlook.init(process.env.VUE_APP_SMARTLOOK_API_KEY);
    console.log("after init");
    // To restart the session, use anonymize
    Smartlook.anonymize();

    const trackingObj = {
      name,
      delegateType,
    };

    let identifier = "";
    if (tracking) {
      trackingObj.email = email;
      identifier = `${email}-`;
    }
    identifier += `${Math.ceil(Math.random() * 10000)}`;

    // Identify session
    Smartlook.identify(identifier, trackingObj);

    console.log("after set event");
  } else {
    console.log("Native");

    SmartlookMobile.setProjectKey({
      key: process.env.VUE_APP_SMARTLOOK_API_KEY,
    });
    SmartlookMobile.start();

    console.log("after init");
    SmartlookMobile.eventTrackingEnableAll();
    SmartlookMobile.setEventTrackingInteractionUserStatus({
      isEnabled: true,
    });
    SmartlookMobile.setEventTrackingInteractionRageClickStatus({
      isEnabled: true,
    });
    // To restart the session, use anonymize
    // Smartlook.anonymize();

    console.log("after set event");
    // Identify session
    SmartlookMobile.setUserIdentifier({
      identifier: `${email}-${Math.ceil(Math.random() * 10000)}`,
    });
    SmartlookMobile.setUserName({ name: name });
    if (tracking) {
      SmartlookMobile.setUserEmail({ email: email });
      console.log("after set email");
      SmartlookMobile.setUserProperty({
        propertyName: "church",
        value: organization,
      });
      console.log("after set church");
    }
    SmartlookMobile.setUserProperty({
      propertyName: "team ID",
      value: "property-value",
    });
    console.log("after set team ID");
    SmartlookMobile.setUserProperty({
      propertyName: "year",
      value: confYear.split("-")[0],
    });
    console.log("after set year");
    SmartlookMobile.setUserProperty({
      propertyName: "delegateType",
      value: delegateType,
    });
    console.log("after set user");
  }
};
