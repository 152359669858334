<template>
  <div>
    <h3
      class="font-aktiv-grotesk font-bold text-[20px] text-[#21252C] leading-[28px] mb-4 lg:px-[20px]"
    >
      {{ $t('copy.resourcesPage.videoPage.videoChapters') }}
    </h3>
    <div class="lg:overflow-y-auto lg:max-h-[310px] lg:pb-[8px] lg:px-[20px]">
      <div
        v-for="(chapter, index) in videoChapters"
        :key="chapter.title"
        :data-testid="`chapter-container-${index}`"
        class="cursor-pointer"
        @click="click(chapter.title, chapter.timestamp)"
      >
        <div class="flex flex-row justify-between py-[12px]">
          <p class="text-[14px] leading-[20px] font-medium mr-6">
            {{ chapter.title }}
          </p>
          <p
            class="font-aktiv-grotesk font-medium text-[14px] leading-[24px] text-white inline-block px-[8px] bg-[#00A9E0] rounded-[4px] h-[fit-content] self-end"
          >
            {{ chapter.timestamp }}
          </p>
        </div>
        <hr v-if="index !== videoChapters.length - 1" class="bg-black" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },
    videoChapters: {
      type: Array,
      default() {
        return [
          {
            title: 'Chapter 1',
            timestamp: '00:00',
          },
        ]
      },
      validator(chapters) {
        return chapters.every((chapter) => chapter.title && chapter.timestamp)
      },
    },
  },
  methods: {
    click(title, timestamp) {
      if (this.isPreview) {
        this.$emit('openRegistrationModal')
      } else {
        this.$emit('jumpToChapter', title, timestamp)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
