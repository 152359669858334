<template>
  <empty-layout>
    <div
      class="w-full h-full flex justify-center text-black font-proxima-nova"
      :class="isIos() ? 'pt-[60px]': ''"
    >
      <div
        class="mt-[20px] mb-[42px] md:mt-[48px] md:mb-[88px] lg:w-[854px] lg:max-w-[854px] flex flex-col items-center"
      >
        <!-- <img
          class="w-[282px] md:w-[307px] lg:w-[335px]"
          src="/assets/images/hogc-sg-logo.svg"
          alt="HOGC SG"
        /> -->
        <div class="absolute left-1 px-[24px] md:px-8 lg:px-[56px]">
          <img
            src="../../../public/assets/icons/go-back-chevron.svg"
            alt="Back Icon"
            class="cursor-pointer"
            data-test-id="gensapp-experiencesignup-back"
            @click="back()"
          />
        </div>
        <div
          class="font-caveat font-bold text-center text-[36px] md:text-[44px] leading-[40px] md:leading-[44px] tracking-[-1%] mt-[24px] md:mt-[44px]"
        >
          Hello There! <br class="md:hidden"> Tried signing in?
        </div>
        <div class="relative">
          <img
            src="/assets/images/access-denied-highlight-long.svg"
            class="lg:block hidden absolute bottom-[5px] right-[220px]"
          />
          <img
            src="/assets/images/access-denied-highlight-short.svg"
            class="md:hidden absolute bottom-[25px] right-[1px]"
          />
          <img
            src="/assets/images/access-denied-highlight-short2.svg"
            class="hidden md:block lg:hidden absolute bottom-[38px] right-1"
          />
          <img
            src="/assets/images/access-denied-highlight-medium.svg"
            class="lg:hidden absolute bottom-[2px] right-[93px] md:right-[190px]"
          />
          <p
            class="relative mt-[24px] md:mt-[32px] w-[327px] md:w-[522px] lg:w-[702px] text-center text-[20px] leading-[24px] md:text-[24px] md:leading-[32px]"
          >
            We've noticed you attempted to log in with an account from a previous year. Please note that session resources are only valid for the <span class="font-bold">year of registration.</span>
          </p>
        </div>
        <div
          class="w-[327px] h-[370px] mt-[24px] rounded-[12px] py-[24px] md:w-[522px] lg:w-[702px] md:h-[440px] md:mt-[28px] md:py-[32px] lg:w-[780px] lg:h-[464px] lg:py-[40px] bg-[#080A6E] flex items-center justify-center"
        >
          <div class="flex flex-col items-center w-[303px] md:w-[478px]">
            <p
              class="font-bold text-grey-5 text-center text-[20px] md:text-[24px] leading-[24px] md:leading-[32px]"
            >
              Feel free to check out <br class="hidden md:block">our Conference Video Playback!
            </p>
            <div class="flex flex-row mt-[20px] md:mt-[24px] lg:mt-[28px]">
              <img
                class="hidden md:block w-[420px]"
                src="/assets/icons/cvp-logo-desktop.svg"
                alt="Playback Desktop"
              />
              <img
                class="block md:hidden w-[240px]"
                src="/assets/icons/cvp-logo-mobile.svg"
                alt="Playback Mobile"
              />
            </div>
            <!-- <a href="https://generationsmvmt.com/resources/" target="_blank"> -->
            <a href="https://playback.generationsmvmt.com/" target="_blank">
              <button
                class="bg-lightblue text-grey-5 text-[18px] leading-[24px] font-aktiv-grotesk-medium mt-[20px] rounded-[48px] md:mt-[24px] md:w-[440px] lg:mt-[28px] p-2 md:py-3 px-6"
              >
                Head over to Conference Video Playback
              </button>
            </a>
          </div>
        </div>
        <p
          class="mt-[20px] text-center text-[16px] leading-[24px] w-[327px] md:mt-[28px] md:text-[20px] md:w-[600px] lg:mt-[32px] lg:w-[854px]"
        >
          If you need further assistance, feel free to contact us at
          <span>
            <a
              class="text-[#0007B9] no-underline"
              href="mailto:hello@generationsmvmt.com"
              >hello@generationsmvmt.com</a
            >
          </span>
        </p>
      </div>
    </div>
  </empty-layout>
</template>

<script>
import EmptyLayout from "@/components/EmptyLayout.vue";
import { isIosNativeOnly } from "@/utils/platform-check";
export default {
  components: { EmptyLayout },
  name: "Access2022",
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
    back() {
      if (this.isEditEnable) {
        this.toggleEdit();
        return;
      }
      this.$router.back();
    }
  },
};
</script>

<style scope></style>
