<template>
  <div v-click-away="collapseDropdown" class="group">
    <div class="relative min-w-[120px]">
      <div
        class="h-[48px] flex items-center justify-center py-1 px-4 cursor-pointer hover:opacity-80"
        :class="{
          'border-grey-40 rounded-[8px]': !isDropdownExpanded,
        }"
        data-event-tracking="gensapp-schedule-float-select-day"
        data-test-id="gensapp-schedule-float-select-day"
        @click="toggleDropdown()"
      >
        <p class="text-grey-100 font-aktiv-grotesk-regular mr-4">
          {{ selectedDay.displayText }}
        </p>

        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :class="{
            'rotate-180': isDropdownExpanded,
          }"
        >
          <path
            d="M0.878418 1.08301L6.79406 7.1662L12.8772 1.25055"
            stroke="#373E49"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div
        v-if="isDropdownCanShow && isDropdownExpanded"
        class="w-full absolute z-10 top-[47px] left-0 border rounded-[8px] bg-white overflow-hidden box-shadow"
        :class="{
          'border-grey-40': !isDropdownExpanded,
        }"
      >
        <div
          v-for="days in validDays"
          :key="days.code"
          class="font-aktiv-grotesk-regular py-2 px-4 cursor-pointer text-[#363d49] hover:bg-[#ededed] border-b border-grey-20"
          :class="{
            'bg-[#E5F7FE] pointer-events-none': selectedDay.code === days.code,
          }"
          :data-test-id="
            'gensapp-resourcespage-language-dropdown-selected-' + days.code
          "
          @click="onDaySelect(days)"
        >
          {{ days.displayText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue3-click-away";
import { mapState } from "vuex";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { isGPCRPage } from "@/utils/conference-type";

export default {
  name: "DayDropdown",
  mixins: [clickaway],
  props: {
    resourcesType: {
      type: String,
      validator(value) {
        return ["articles", "life-stories", "quotes"].includes(value);
      },
      default: "articles",
    },
    highlightDay: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isDropdownCanShow: true,
      isDropdownExpanded: false,
    };
  },
  mounted() {
    // const { language } = this.$route.query
    // if (!language) return
    // this.selectedDay = this.languages.find(
    //   (lang) => lang.code === language
    // )
    // this.isGPCR();
  },
  computed: {
    ...mapState("profile", ["type"]),
    validDays() {
      return this.type === "GC" ? this.days.slice(2, 4) : this.days;
    },
    selectedDay() {
      let dayArray = Object.values(this.days);
      // remove null element
      // remove those is unavaliable status

      dayArray = dayArray.filter((element) => {
        return element && element.date == this.highlightDay;
      });
      return dayArray[0];
    },
    days() {
      const gpcrDayOne = this.$t('copy.comma.gpcr.dayOne');
      const gpcrDayTwo = this.$t('copy.comma.gpcr.dayTwo');
      const gpcrDayThree = this.$t('copy.comma.gpcr.dayThree');

      // const gpcrDayOne = this.$t('copy.schedulePage.dayOne.day') + ", " + this.$t('copy.schedulePage.dayOne.dayNum') + " " + this.$t('copy.schedulePage.dayOne.month');
      // const gpcrDayTwo = this.$t('copy.schedulePage.dayTwo.day') + ", " + this.$t('copy.schedulePage.dayTwo.dayNum') + " " + this.$t('copy.schedulePage.dayTwo.month');
      // const gpcrDayThree = this.$t('copy.schedulePage.dayThree.day') + ", " + this.$t('copy.schedulePage.dayThree.dayNum') + " " + this.$t('copy.schedulePage.dayThree.month');

      const gpcSchedule = [
        {
          displayText: "Thu, 11 Jul",
          date: "2024-07-11",
          code: "0",
        },
        {
          displayText: "Fri, 12 Jul",
          date: "2024-07-12",
          code: "1",
        },
        {
          displayText: "Sat, 13 Jul",
          date: "2024-07-13",
          code: "2",
        },
        {
          displayText: "Sun, 14 Jul",
          date: "2024-07-14",
          code: "3",
        },
        {
          displayText: "Mon, 15 Jul",
          date: "2024-07-15",
          code: "4",
        },
      ];
      const gpcrSchedule = [
        {
          displayText: gpcrDayOne,
          date: "2024-11-15",
          code: "0",
        },
        {
          displayText: gpcrDayTwo,
          date: "2024-11-16",
          code: "1",
        },
        {
          displayText: gpcrDayThree,
          date: "2024-11-17",
          code: "2",
        },
      ];

      if (isGPCRPage()) {
        return gpcrSchedule;
      }
      return gpcSchedule;
    },
  },
  methods: {
    collapseDropdown() {
      this.isDropdownExpanded = false;
    },
    toggleDropdown() {
      this.isDropdownExpanded = !this.isDropdownExpanded;
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "gensapp-schedule-float-select-day",
          page: "schedule",
        },
      });
    },
    onDaySelect(days) {
      this.isDropdownCanShow = false;
      this.isDropdownExpanded = false;

      setTimeout(() => {
        this.isDropdownCanShow = true;
      }, 100);

      this.selectedDay = days;
      this.$emit("onChange", days);

      // this.$router.push({
      //   path: this.$route.path,
      //   query: {
      //     ...this.$route.query,
      //     language: language.code,
      //   },
      // })
    },
    isGPCRPage() {
      return isGPCRPage();
    },
  },
};
</script>

<style scoped>
.box-shadow {
  box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.25);
}
</style>
