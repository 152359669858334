<template>
  <a v-if="link" :href="link" target="blank">
    <div
      class="w-full cursor-pointer text-lightblue bg-grey-10 rounded-2xl px-5 py-4 font-aktiv-grotesk-medium text-[20px] leading-[24px] flex justify-between"
    >
      <p>
        {{ text }}
      </p>
      <img
        src="../../../public/assets/icons/chevrons-blue.svg"
        class="w-6 h-6"
      />
    </div>
  </a>
  <div
    v-else
    class="w-full cursor-pointer text-lightblue bg-grey-10 rounded-2xl px-5 py-4 font-aktiv-grotesk-medium text-[20px] leading-[24px] flex justify-between"
    @click="clickTrigger"
  >
    <p>
      {{ text }}
    </p>
    <img
      src="../../../public/assets/icons/chevrons-blue.svg"
      class="w-6 h-6"
    />
  </div>
</template>

<script>
export default {
  name: "Pill",
  props: {
    link: {
      type: String,
    },
    text: {
      type: String,
    },
  },
  methods: {
    clickTrigger(event) {
      this.$emit("click-trigger");
    },
  },
};
</script>

<style scoped>
</style>
