import markerSDK from "@marker.io/browser";
import axios from "axios";
import { startSmartlook } from "../../utils/smartlook";
import { startSurvicate } from "@/utils/survicate";
import {
  CURRENT_ACTIVE_CONFYEAR,
  CURRENT_LIVECHAT_CONFYEAR,
  getCurrentConferenceType,
} from "@/utils/conference-type";
import { getCurrentLocale } from "@/utils/deviceLocale";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { FirebaseCrashlytics } from "@awesome-cordova-plugins/firebase-crashlytics";
import { isPlatform } from "@ionic/vue";
import { App } from "@capacitor/app";
import { Device } from "@capacitor/device";
import OneSignal from "onesignal-cordova-plugin";

// import { useAuth0 } from '@auth0/auth0-vue';

const INITIAL_STATE = {
  id: undefined,
  name: "",
  hashId: "",
  preferredName: "",
  type: "",
  userType: "",
  auth0Id: "",
  auth0IdPrefixed: "",
  email: { email: undefined },
  organization: "",
  loading: false,
  hasCompletedHomepageTour: false,
  hasCompletedScheduleTour: false,
  hasCompletedExperienceTour: false,
  hasCompletedGPCRHomepageTour: false,
  hasCompletedGPCRScheduleTour: false,
  hasCompletedGPCRExperienceTour: false,
  signupCheck: true,
  needLiveVoice: false,
  fcmToken: "",
  channels: [],
  confYear: "",
};

const state = () => INITIAL_STATE;

const mutations = {
  setProfileState(state, newProfile) {
    console.log("newProfile", newProfile);
    for (const key in newProfile) state[key] = newProfile[key];
  },
  setLoading(state, isLoading) {
    state.loading = isLoading;
  },
};

const actions = {
  async fetchProfile({ dispatch, state }) {
    // Will not call backend api if profile already set
    if (state.name) {
      return;
    }
    await dispatch("forceFetchProfile");
  },

  async forceFetchProfile({ commit, dispatch, rootState }) {
    // for tracking false - hide email, organization
    commit("setLoading", true);
    try {
      const isNative = isPlatform("ios") || isPlatform("android");
      const isNativeBrowser = isPlatform("mobileweb");
      const accessToken = rootState.user.token;
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/profile",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200) throw new Error("Failed in setting profile");
      commit("setProfileState", data);

      // if (!isNative && isNativeBrowser) {
      //   window.$datadogRum.setUser({
      //     name: data.name,
      //     email: data.email,
      //   });
      //   window.$datadogRum.setUserProperty("organization", data.organization);
      //   window.$datadogRum.setUserProperty("type", data.type);
      // }
      const userProps = {
        delegateName: data.name,
        teamID: data.groupName,
        year: data.confYear.split("-")[0],
        userType: data.userType,
        delegateType:
          data.organization === "Heart of God Church" ? "Host" : "Delegate",
      };

      if (data.tracking) {
        userProps.delegateEmail = data.email;
        userProps.churchName = data.organization;
      }

      const windowDataLayer = {
        event: "login",
        userId: data.id,
        userType: data.type,
        ...userProps,
      };

      if (data.tracking) {
        windowDataLayer.userOrganization = data.organization;
        windowDataLayer.userEmail = data.email;
      }
      window.dataLayer.push(windowDataLayer);

      /**
       * Platform: Web/Android/iOS
       * Sets a user property to a given value.
       * @param options - property name and value to set
       * @returns void
       * https://firebase.google.com/docs/analytics/user-properties
       */
      FirebaseAnalytics.setUserId({
        userId: data.id,
      });
      FirebaseAnalytics.setUserProperty({
        name: "user_identifier",
        value: data.id.toString(),
      });
      if (data.tracking) {
        FirebaseAnalytics.setUserProperty({
          name: "email",
          value: data.email,
        });
        FirebaseAnalytics.setUserProperty({
          name: "user_organization",
          value: data.organization,
        });
      }
      FirebaseAnalytics.setUserProperty({
        name: "team_id",
        value: data.groupName,
      });
      FirebaseAnalytics.setUserProperty({
        name: "language",
        value: await getCurrentLocale(getCurrentConferenceType(data.confYear)),
      });
      FirebaseAnalytics.setUserProperty({
        name: "conference_year",
        value: data.confYear.split("-")[0],
      });
      FirebaseAnalytics.setUserProperty({
        name: "latest_conference_type",
        value: data.confYear.split("-")[1].includes("R")
          ? "Regional"
          : "Global",
      });
      FirebaseAnalytics.setUserProperty({
        name: "selected_conference_type",
        value: getCurrentConferenceType(data.confYear),
      });
      FirebaseAnalytics.setUserProperty({
        name: "user_type",
        value:
          data.organization === "Heart of God Church" ? "Host" : "Delegate",
      });

      if (isNative && !isNativeBrowser) {
        FirebaseAnalytics.logEvent({
          name: "native_login",
          params: {
            user_id: data.id,
            ...userProps,
          },
        });
        OneSignal.login("GPC-" + data.id);
        // OneSignal.setEmail(data.email);
        OneSignal.User.addTags({ confYear: data.confYear, ...userProps });
      }
      // FirebaseCrashlytics.setUserId(data.email)

      const { email, name, organization, groupName, confYear, tracking } = data;
      console.log("Smart Look - Start");
      startSmartlook(email, name, organization, groupName, confYear, tracking);
      console.log("Smart Look - End");
      console.log("Survicate - start");
      startSurvicate(
        email,
        name,
        organization,
        process.env.VUE_APP_SURVICATE_KEY,
        tracking
      );
      console.log("Survicate - end");
    } catch (e) {
      dispatch("toast/error", "Failed to load profile.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },

  async setHomeTourStatus({ commit, dispatch, rootState }, payload) {
    commit("setLoading", true);
    try {
      const accessToken = rootState.user.token;
      const { status, data } = await axios.request(
        process.env.VUE_APP_API_URL +
          `/profile/set-homepage-tour?conferenceType=${payload.conferenceType}`,
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: payload,
        }
      );
      if (status !== 201) throw new Error("Failed in setting homepage tour");
      commit("setProfileState", data);
    } catch (e) {
      dispatch("toast/error", "Failed to load profile.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },

  async setScheduleTourStatus({ commit, dispatch, rootState }, payload) {
    commit("setLoading", true);
    try {
      const accessToken = rootState.user.token;
      const { status, data } = await axios.request(
        process.env.VUE_APP_API_URL +
          `/profile/set-schedule-tour?conferenceType=${payload.conferenceType}`,
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: payload,
        }
      );
      if (status !== 201) throw new Error("Failed in setting schedule tour");
      commit("setProfileState", data);
    } catch (e) {
      dispatch("toast/error", "Failed to load profile.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },

  async setExperienceTourStatus({ commit, dispatch, rootState }, payload) {
    commit("setLoading", true);
    try {
      const accessToken = rootState.user.token;
      const { status, data } = await axios.request(
        process.env.VUE_APP_API_URL +
          `/profile/set-experience-tour?conferenceType=${payload.conferenceType}`,
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: payload,
        }
      );
      if (status !== 201) throw new Error("Failed in setting experience tour");
      commit("setProfileState", data);
    } catch (e) {
      dispatch("toast/error", "Failed to load profile.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },

  async setFcmToken({ commit, dispatch, rootState }, payload) {
    console.log("setFcmToken here...");
    try {
      const accessToken = rootState.user.token;
      const { status, data } = await axios.request(
        process.env.VUE_APP_API_URL + "/profile/set-fcm-token",
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: payload,
        }
      );
      if (status !== 201) throw new Error("Failed in setting fcm token");
      commit("setProfileState", data);
    } catch (e) {
      dispatch("toast/error", "Failed to load profile.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },
};

const getters = {
  name(state) {
    return state.preferredName && state.preferredName !== ""
      ? state.preferredName
      : state.name;
  },
  delegateId(state) {
    return state.id;
  },
  email(state) {
    return state.email;
  },
  type(state) {
    return state.type;
  },
  auth0Id(state) {
    return state.auth0Id;
  },
  payload(state) {
    if (!state.name) {
      return {
        name: undefined,
        organization: undefined,
        type: undefined,
        auth0Id: undefined,
      };
    }
    return {
      name:
        (state.preferredName && state.preferredName) !== ""
          ? state.preferredName
          : state.name,
      organization: state.organization,
      type: state.type,
      email: state.email,
      auth0Id: state.auth0Id,
    };
  },
  SIGNUP_CLOSE_DATE(state) {
    return state.SIGNUP_CLOSE_DATE;
  },
  isAbsentAlumni(state) {
    return !CURRENT_ACTIVE_CONFYEAR.includes(state.confYear);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
