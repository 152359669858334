<template>
  <div
    class="fixed top-0 left-0 right-0 bottom-0 bg-white flex flex-col z-50 font-aktiv-grotesk-medium w-full h-[calc(100%-69px)]"
  >
    <Cropper
      ref="cropper"
      class="upload-example-cropper"
      :src="image"
      :stencil-component="$options.components.CircleStencil"
    />
    <div
      class="fixed bottom-0 left-0 right-0 mb-[70px] flex flew-row bg-black justify-between items-center"
      :class="{ '!mb-[87px]': isIos() }"
    >
      <img
        class="w-[32px] h-[32px] cursor-pointer"
        src="../../../public/assets/icons/cross-white.svg"
        @click="closeImagePreview()"
      />
      <img
        class="w-[32px] h-[32px] cursor-pointer"
        src="../../../public/assets/icons/blue-tick.svg"
        @click="cropImage()"
      />
    </div>
  </div>
</template>
<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import CircleStencil from "./CircleStencil";
import { mapState } from "vuex";
import { isIosNativeOnly } from "@/utils/platform-check";

export default {
  components: { Cropper, CircleStencil },
  data() {
    return {
      image: "",
    };
  },
  computed: {
    ...mapState("social", ["selectedPicture"]),
  },
  mounted() {
    this.image = this.selectedPicture;
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
    closeImagePreview() {
      this.$router.push("/profile/edit");
    },
    async cropImage() {
      const result = this.$refs.cropper.getResult();
      const imgObj = result.canvas.toDataURL("image/jpeg");
      this.$store.commit("social/setForm64Picture", imgObj);

      // base64 to blob conversion
      const base64Response = await fetch(imgObj);
      const blob = await base64Response.blob();
      this.$store.commit("social/setFormPicture", blob);
      this.$router.push("/profile/edit");
    },
  },
};
</script>
<style>
.upload-example-cropper {
  border: solid 1px #000;
  height: 100%;
  width: 100%;
}
</style>
