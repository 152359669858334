<template>
  <div
    class="relative pt-[48px] pb-[40px] text-center w-full bg-orange text-white"
  >
    <div
      class="relative md:flex md:justify-between lg:justify-center md:flex-row z-10 w-full"
    >
      <div
        id="topbox"
        class="flex flex-col md:items-start md:my-auto mx-auto w-full"
      >
        <div

          class="px-6 mx-auto md:m-0 md:flex md:justify-center md:items-center w-full"
        >
          <div class="lg:w-[390px]">
            <p
              class="text-[32px] text-left leading-[40px] text-white font-aktiv-grotesk-medium mx-auto md:ml-0"
            >
              {{ $t('copy.experiencePage.title') }}
            </p>

            <p
              class="text-[16px] text-left mx-auto leading-[24px] text-white my-[16px] font-aktiv-grotesk-regular"
            >
              {{ $t('copy.experiencePage.description') }}
            </p>
            <img
              v-if='this.$i18n.locale === "en"'
              class="w-full aspect-ratio mx-auto cursor-pointer rounded-[18px] md:hidden"
              src="https://cdn.generationsmvmt.com/images/app/experiences-banner-en.gif"
              @click="goToExperience()"
            />
            <img
              v-else-if='this.$i18n.locale === "th"'
              class="w-full aspect-ratio mx-auto cursor-pointer rounded-[18px] md:hidden"
              src="https://cdn.generationsmvmt.com/images/app/experiences-banner-th.gif"
              @click="goToExperience()"
            />
            <img
              v-else 
              class="w-full aspect-ratio mx-auto cursor-pointer rounded-[18px] md:hidden"
              src="https://cdn.generationsmvmt.com/images/app/experiences-banner-chi.gif"
              @click="goToExperience()"
            />
            <div class="">
              <p
                class="italic text-[11px] xs:text-[14px] md:text-left heading-[24px] text-white font-aktiv-grotesk mx-auto mt-[8px]"
              >
                {{ $t('copy.experiencePage.note') }}
              </p>
              <img 
                class="mx-auto md:m-0"
                :class="underlineWidth"
                src="../../../public/assets/icons/vector-line.svg"
              />
            </div>
          </div>
          <div
            class="hidden md:block md:ml-[65px] w-[295px] h-[197px] md:flex-shrink-0"
          >
            <img
              v-if='this.$i18n.locale === "en"'
              class="w-full aspect-ratio mx-auto cursor-pointer rounded-[18px]"
              src="https://cdn.generationsmvmt.com/images/app/experiences-banner-en.gif"
              @click="goToExperience()"
            />
            <img
              v-else-if='this.$i18n.locale === "th"'
              class="w-full aspect-ratio mx-auto cursor-pointer rounded-[18px]"
              src="https://cdn.generationsmvmt.com/images/app/experiences-banner-th.gif"
              @click="goToExperience()"
            />
            <img
              v-else 
              class="w-full aspect-ratio mx-auto cursor-pointer rounded-[18px]"
              src="https://cdn.generationsmvmt.com/images/app/experiences-banner-chi.gif"
              @click="goToExperience()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import momentts from "moment-timezone";
import { mapGetters, mapState } from "vuex";
import BaseSwiper from "../common/BaseSwiper.vue";
import ExperienceCarousel from "@/components/ExperiencePage/ExperienceCarousel.vue";
import ExpCard from "../../components/ExperiencePage/ExpCard";

export default {
  name: "ExperienceEntrySection",
  components: {
    BaseSwiper,
    ExperienceCarousel,
    ExpCard,
  },
  data() {
    return {
      readMore: false,
      swiperOptions: {
        centeredSlides: false,
        slidesPerView: "auto",
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true,
        },
        hasNavigation: true,
        watchOverflow: true,
      },
    };
  },
  props: {
    getExpAlloc: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters("profile", ["type"]),
    isSCS() {
      return this.type === "SCS";
    },
    ...mapState("signup", [
      "signups",
      "SIGN_UP_CLOSE_TS",
      "EXP_EVENT_END_TS",
      "EXP_ALLOC_TS",
    ]),
    ...mapState("timer", ["currDate"]),
    overallStatus() {
      if (this.hasSignupBefore && !this.isExpAllocated())
        return "Submitted Interest";

      const statusSet = new Set();
      for (const expType in this.signups) {
        const exp = this.signups[expType];
        if (exp && exp.status) statusSet.add(exp.status);
      }

      if (this.isSignupClosed() && statusSet.size == 0) return "Unavailable";

      if (statusSet.size === 1 && statusSet.has("Unavailable"))
        return "Unavailable";

      if (statusSet.has("Confirmed")) return "Confirmed";

      // if (statusSet.has("Pending")) return "Pending";

      return "";
    },
    signupsByRank() {
      let signupArray = Object.values(this.signups);
      // remove null element
      // remove those is unavaliable status

      signupArray = signupArray.filter((element) => {
        return element && element.status !== "Unavailable";
      });

      signupArray.sort((a, b) => {
        if (a.ranking < b.ranking) return -1;
        if (a.ranking > b.ranking) return 1;
        return 0;
      });

      return signupArray;
    },
    displayTypes() {
      if (!this.signupsByRank.length) {
        return ["CG", "DSN", "MCR", "MD", "LM", "MOH"];
      }

      let registeredTypes = [];
      registeredTypes = this.signupsByRank.map((element) => {
        return element.type;
      });

      return registeredTypes;
    },
    underlineWidth() {
      if (this.$i18n.locale === "en") {
        return "w-[311px]"
      } else if (this.$i18n.locale === "zh") {
        return "w-[150px]"
      } 
      return "w-[235px]"
    }
  },
  methods: {
    isSignupClosed() {
      // needs refectoring (we are just using CG signup close as of now)
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      const signUpExpiry = new Date(this.SIGN_UP_CLOSE_TS);
      // console.log("signUpExpiry: " + signUpExpiry);
      return currentDate.getTime() >= signUpExpiry.getTime();
    },
    isExpAllocated() {
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      const expAllocDate = new Date(this.EXP_ALLOC_TS);
      return currentDate.getTime() >= expAllocDate.getTime();
    },
    triggerExpPopup(exp) {
      this.$emit("show-popup", exp);
    },
    goToExperience() {
      this.$router.push("/experience");
    },
  },
};
</script>
<style scoped>
.aspect-ratio {
  aspect-ratio: 295/197;
}
.button-shadow {
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
}
::v-deep(.swiper-pagination) {
  display: none;
}

::v-deep(.swiper-pagination-bullet) {
  background-color: #fff3ec;
}

::v-deep(.swiper-pagination-bullet-active) {
  background-color: #ffffff;
}

::v-deep(.swiper-slide:last-child) {
  margin-right: 48px;
}
::v-deep(.swiper-container) {
  padding-bottom: 24px;
}

@media all and (min-width: 768px) {
  ::v-deep(.swiper-wrapper) {
    padding-left: 32px;
    justify-content: center;
  }
  ::v-deep(.swiper-slide:last-child) {
    margin-right: 64px;
  }
}

@media all and (min-width: 1024px) {
  ::v-deep(.swiper-wrapper) {
    padding-left: calc((100% - 750px) / 2);
  }

  ::v-deep(.swiper-slide:last-child) {
    margin-right: calc(100% - 750px);
  }
}
</style>
