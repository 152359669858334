<template>
  <button
    class="relative border-0"
    @click.stop="copy"
  >
    <div>
      <img
        src="../../../../public/assets/icons/copy-link.svg"
        class="w-10 h-10 sm:w-12 sm:h-12"
      />
    </div>
    <div
      v-if="isClicked"
      class="icon-position font-aktiv-grotesk bg-orange text-size text-[#E5E5E5] w-10 h-10 mb-2 mt-1 flex justify-center items-center"
    >
      Copied
    </div>
  </button>
</template>
<script>
export default {
  name: 'SocialMediaShareCopyButton',
  props: {
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isClicked: false,
    }
  },
  methods: {
    copy(e) {
      if (window.isSecureContext) {
        navigator.clipboard.writeText(this.url)
      }
      this.isClicked = !this.isClicked
      setTimeout(this.timeOut, 3000)
    },
    timeOut() {
      this.isClicked = false
    },
  },
}
</script>

<style scoped lang="scss">
.text-size {
  font-size: 12px;
  text-align: center;
}
.icon-position {
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 9999px;
}
</style>
