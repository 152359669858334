const INITIAL_STATE = {
  token: "",
};

const state = () => INITIAL_STATE;

const actions = {
  setToken(context, token) {
    context.commit("setToken", token);
    console.log("in setToken actions");
  },
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
