<template>
  <master-layout>
    <div
      v-if="!loading"
      class="mb-6 px-[24px] md:px-8 lg:px-[56px] w-full pt-[16px] md:pt-[40px]"
      :class="{ '!pt-[60px]': isIos() }"
    >
      <img
        src="../../../public/assets/icons/go-back-chevron.svg"
        alt="Back Icon"
        class="cursor-pointer mb-[24px] lg:mb-[28px]"
        @click="backButton()"
      />
      <Selector
        :selectorTitle="conferenceLanguage.title"
        :buttons="conferenceLanguage.conferenceList"
        :selectedValue="selectedConference"
        @button-selected="handleButtonSelected"
      />
    </div>
  </master-layout>
</template>

<script>
import Selector from "@/components/common/Selector.vue";
import { isIosNativeOnly } from "@/utils/platform-check";
import store from "@/store";
import {
  getSelectedConferenceView,
  getCurrentConferenceType,
  setConferenceView,
} from "@/utils/conference-type.js";
import { getCurrentLocale } from "@/utils/deviceLocale";

export default {
  components: {
    Selector,
  },
  data() {
    return {
      loading: false,
      selectedConference: "Global",
    };
  },
  async ionViewDidEnter() {
    this.selectedConference = await getCurrentConferenceType(
      store.state.profile.confYear
    );
    this.loading = false;
  },
  computed: {
    conferenceLanguage() {
      return {
        title: this.$t("copy.homePage.conferenceType.title"),
        conferenceList: [
          {
            name: this.$t("copy.homePage.conferenceType.gpcr.name"),
            value: "Regional",
            description: "",
          },
          {
            name: this.$t("copy.homePage.conferenceType.gpc.name"),
            value: "Global",
            description: "",
          },
        ],
      };
    },
  },
  methods: {
    backButton() {
      // route check based on selected conf
      if (getSelectedConferenceView() == "Regional") {
        this.$router.push("/gpcr/home");
      } else {
        this.$router.push("/home");
      }
    },
    async handleButtonSelected(selectedConference) {
      this.selectedConference = selectedConference;
      // store selected conf in local storage
      setConferenceView(this.selectedConference);
      const locale = await getCurrentLocale(selectedConference);
      this.loading = true;

      if (getSelectedConferenceView() == "Regional") {
        this.$router.push("/gpcr/home");
      } else {
        this.$router.push("/home");
      }
      this.$i18n.locale = locale;
      document.querySelector("html").setAttribute("lang", locale);
    },
    isIos() {
      return isIosNativeOnly();
    },
  },
};
</script>

<style></style>
