<template>
  <div
    class="rounded-xl border-[1px] border-grey-10 border-solid p-[16px] relative overflow-visible text-[14px] leading-[20px] font-aktiv-grotesk"
  >
    <h1 class="text-[16px] font-aktiv-grotesk-trial leading-[24px] mb-3">
      Collab <br class="xs:hidden" />
      Conversations
    </h1>
    <div class="flex mb-1 justify-start items-start">
      <img
        src="../../../public/assets/icons/event-location-icon-blue.svg"
        class="mt-1"
      />
      <a
        href="https://cdn.generationsmvmt.com/docs/HOGC-Map-2024.pdf"
        target="_blank"
        class="cursor-pointer"
        data-test-id="gensapp-schedule-collab-convo-map"
      >
        <p class="text-[16px] leading-[24px] text-grey-80 ml-2 hover:underline">
          The Hub, Level 4
        </p>
      </a>
    </div>

    <CardLabel color="lightgrey">For senior leaders</CardLabel>

    <div class="mt-4 flex justify-between">
      <div class="flex justify-start">
        <p class="italic text-grey-80 ml-auto">Sign up is closed.</p>
      </div>

      <!-- <a href="https://collab-conversations.paperform.co" target="_blank"> -->

      <button
        class="rounded-3xl bg-grey-40 text-white text-[16px] leading-[24px] font-aktiv-grotesk-trial text-center py-2 px-4 mb-2"
        data-event-tracking="gensapp-schedule-collabconversations-interested-signupnow"
        data-test-id="gensapp-schedule-collabconversations-interested-signupnow"
      >
        I’m interested
      </button>

      <!-- </a> -->
    </div>
  </div>
</template>

<script>
import CardLabel from "../common/CardLabel.vue";
export default {
  components: { CardLabel },
};
</script>
