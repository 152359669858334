<template>
  <div
    v-if="showTime"
    class="tracking-wide text-[20px] leading-[24px] font-aktiv-grotesk-trial flex justify-between"
  >
    <div class="whitespace-nowrap">
      {{ labelText }}
    </div>
    <div class="border-t-[0.25px] border-grey-40 my-auto ml-[8px] flex-1"></div>
  </div>
</template>

<script>
import moment from "moment";
import momentts from "moment-timezone";

export default {
  props: {
    showTime: {
      type: Boolean,
      default: true,
    },
    now: {
      type: Boolean,
      default: false,
    },
    startTime: {
      type: String,
      default: "",
    },
    timerTxt: {
      type: String,
      default: "",
    },
    timerTxtReplace: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    labelText() {
      let labelText = "";
      let timer = "";
      if (this.now)
        labelText += this.$t(
          "copy.homePage.mainLayout.scheduleSection.schedulePanel.happeningNow"
        );
      else if (this.timerTxt) labelText = this.timerTxt;

      if (!this.timerTxtReplace) {
        momentts.tz.setDefault("Asia/Singapore");
        const time = momentts(this.startTime);
        if (this.$i18n.locale === "th") {
          timer = time.format("H:mm") + " น";
        } else {
          timer = time.format("h:mm A");
        }
      }

      return labelText + timer;
    },
  },
};
</script>
