<template>
<svg v-if="!isActive" width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="7.87612" cy="9.08901" rx="3.84878" ry="3.84878" stroke="#99A4B2" stroke-width="2"/>
<circle cx="16.8916" cy="12.7436" r="3.84714" stroke="#99A4B2" stroke-width="2"/>
<path d="M24.7026 24.9794L24.5564 23.8678C24.0576 20.0754 20.8251 17.2402 17 17.2402C13.1749 17.2402 9.9424 20.0754 9.44358 23.8678L9.29737 24.9794C9.13982 26.1773 10.0721 27.2402 11.2803 27.2402H22.7197C23.9279 27.2402 24.8602 26.1773 24.7026 24.9794Z" stroke="#99A4B2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.04228 22.9801L10.0343 23.1058V23.1058L9.04228 22.9801ZM9.00231 23.2955V24.2955C9.50599 24.2955 9.93106 23.9209 9.99437 23.4212L9.00231 23.2955ZM13.7213 17.3707L14.0227 18.3242C14.3129 18.2325 14.5455 18.0136 14.6547 17.7295C14.7639 17.4454 14.7378 17.127 14.5837 16.8645L13.7213 17.3707ZM1.15521 19.9467L2.14728 20.0724L1.15521 19.9467ZM1.01617 21.0441L2.00823 21.1698L1.01617 21.0441ZM8.05021 22.8544L8.01024 23.1698L9.99437 23.4212L10.0343 23.1058L8.05021 22.8544ZM13.42 16.4172C10.5956 17.3098 8.44024 19.7761 8.05021 22.8544L10.0343 23.1058C10.3238 20.821 11.9234 18.9877 14.0227 18.3242L13.42 16.4172ZM14.5837 16.8645C13.2252 14.5501 10.7115 13.0234 7.87635 13.0234V15.0234C9.98055 15.0234 11.8484 16.1555 12.8589 17.877L14.5837 16.8645ZM7.87635 13.0234C3.96027 13.0234 0.655398 15.936 0.163146 19.821L2.14728 20.0724C2.51291 17.1868 4.96764 15.0234 7.87635 15.0234V13.0234ZM0.163146 19.821L0.0240973 20.9184L2.00823 21.1698L2.14728 20.0724L0.163146 19.821ZM0.0240973 20.9184C-0.202982 22.7106 1.19379 24.2955 3.0003 24.2955V22.2955C2.39813 22.2955 1.93254 21.7672 2.00823 21.1698L0.0240973 20.9184ZM3.0003 24.2955H9.00231V22.2955H3.0003V24.2955Z" fill="#99A4B2"/>
<path d="M24.9592 22.9801L23.9671 23.1058V23.1058L24.9592 22.9801ZM24.9992 23.2955V24.2955C24.4955 24.2955 24.0704 23.9209 24.0071 23.4212L24.9992 23.2955ZM20.2802 17.3707L19.9788 18.3242C19.6886 18.2325 19.4559 18.0136 19.3467 17.7295C19.2375 17.4454 19.2637 17.127 19.4178 16.8645L20.2802 17.3707ZM32.8463 19.9467L31.8542 20.0724L32.8463 19.9467ZM32.9853 21.0441L33.9774 20.9184L32.9853 21.0441ZM25.9513 22.8544L25.9912 23.1698L24.0071 23.4212L23.9671 23.1058L25.9513 22.8544ZM20.5815 16.4172C23.4059 17.3098 25.5612 19.7761 25.9513 22.8544L23.9671 23.1058C23.6776 20.821 22.0781 18.9877 19.9788 18.3242L20.5815 16.4172ZM19.4178 16.8645C20.7763 14.5501 23.2899 13.0234 26.1251 13.0234V15.0234C24.0209 15.0234 22.1531 16.1555 21.1426 17.877L19.4178 16.8645ZM26.1251 13.0234C30.0412 13.0234 33.3461 15.936 33.8383 19.821L31.8542 20.0724C31.4886 17.1868 29.0338 15.0234 26.1251 15.0234V13.0234ZM33.8383 19.821L33.9774 20.9184L31.9932 21.1698L31.8542 20.0724L33.8383 19.821ZM33.9774 20.9184C34.2044 22.7106 32.8077 24.2955 31.0012 24.2955V22.2955C31.6033 22.2955 32.0689 21.7672 31.9932 21.1698L33.9774 20.9184ZM31.0012 24.2955H24.9992V22.2955H31.0012V24.2955Z" fill="#99A4B2"/>
<ellipse cx="3.84878" cy="3.84878" rx="3.84878" ry="3.84878" transform="matrix(-1 0 0 1 29.9741 5.24023)" stroke="#99A4B2" stroke-width="2"/>
</svg>
<svg v-else width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="6.87631" cy="9.32925" rx="3.84878" ry="3.84878" fill="#00AAE4"/>
<circle cx="15.8917" cy="12.9834" r="3.84714" fill="#00AAE4"/>
<path d="M23.7026 25.2196L23.5564 24.1081C23.0576 20.3157 19.8251 17.4805 16 17.4805C12.1749 17.4805 8.9424 20.3157 8.44358 24.1081L8.29737 25.2197C8.13982 26.4175 9.07214 27.4805 10.2803 27.4805H21.7197C22.9279 27.4805 23.8602 26.4175 23.7026 25.2196Z" fill="#00AAE4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.00231 23.5353L8.04228 23.2198C8.38204 20.5383 10.2595 18.3885 12.7213 17.6105C11.5368 15.5926 9.34605 14.2632 6.87635 14.2632C3.46396 14.2632 0.584153 16.8011 0.155214 20.1865L0.0161655 21.2839C-0.135221 22.4787 0.795959 23.5353 2.0003 23.5353H8.00231Z" fill="#00AAE4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.9992 23.5353L23.9592 23.2198C23.6195 20.5383 21.742 18.3885 19.2802 17.6105C20.4648 15.5926 22.6555 14.2632 25.1252 14.2632C28.5376 14.2632 31.4174 16.8011 31.8463 20.1865L31.9854 21.2839C32.1367 22.4787 31.2056 23.5353 30.0012 23.5353H23.9992Z" fill="#00AAE4"/>
<ellipse cx="3.84878" cy="3.84878" rx="3.84878" ry="3.84878" transform="matrix(-1 0 0 1 28.974 5.48047)" fill="#00AAE4"/>
</svg>
</template>

<script>
export default {
  name: 'TeamIcon',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
