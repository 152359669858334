<template>
  <master-layout id="contentResourceMoreResources">
    <ResourcesContainer ref="resourceContainer" :show-more-resources="true">
      <div v-if="!loading" class="font-aktiv-grotesk">
        <p
          class="font-aktiv-grotesk text-[16px] leading-[24px] pb-4 px-6 md:px-0"
        >
          These resources are made publicly available on
          <a
            href="https://generationsmvmt.com/Resources"
            target="_blank"
            class="cursor-pointer text-lightblue hover:underline"
            >GenerationSmvmt.com</a
          >
          to be shared with pastors and leaders.
        </p>
        <p class="font-aktiv-grotesk text-[16px] leading-[24px] px-6 md:px-0">
          Also Available in 🇹🇭 Thai:
        </p>
        <p
          class="font-aktiv-grotesk text-[16px] leading-[24px] pb-8 px-6 md:px-0"
        >
          <span class="whitespace-nowrap">ถ้าคุณต้องการภาษาไทย</span>
          <br class="md:hidden" />
          <span class="whitespace-nowrap">
            ลองดูที<a
              href="https://generationsmvmt.com/th/resources/"
              class="whitespace-nowrap cursor-pointer text-lightblue hover:underline"
              target="_blank"
              >หน้าแหล่งข้อมูลภาษาไทย</a
            >!
          </span>
        </p>
        <div v-for="(asset, type) in assets" :key="type" class="px-6 md:px-0">
          <div v-if="asset.materials.length > 0" class="mb-10">
            <div
              class="tracking-wide font-aktiv-grotesk-medium text-[24px] leading-[32px] text-grey-90 mb-4"
            >
              {{ asset.header }}
            </div>
            <div class="flex flex-col mr-2">
              <div
                v-for="(material, index) in asset.materials"
                :key="index"
                class="md:hidden w-full mb-10 md:mb-0"
              >
                <a
                  v-if="!asset.header.includes('Questions')"
                  :href="material.externalLink"
                  target="_blank"
                  rel="noreferrer noopener"
                  :data-test-id="material.dataTestId"
                  class="block relative w-full hover:opacity-90"
                >
                  <img
                    class="rounded-[16px] w-full aspect-video"
                    :class="
                      asset.header.includes('Tools') ? 'tool-shadow' : 'shadow'
                    "
                    :src="material.thumbnail.url"
                    :alt="material.description"
                  />

                  <div
                    v-if="material.isVideo"
                    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  >
                    <img
                      src="../../../public/assets/icons/youtube-player/play-button-dark.svg"
                      class="w-10 h-10 lg:w-[48px] lg:h-[48px]"
                    />
                  </div>
                </a>
                <div>
                  <p
                    v-if="material.description"
                    class="w-full mt-3 break-words"
                  >
                    {{ material.description }}
                  </p>
                </div>
                <div class="flex justify-center gap-3 select-none mt-3">
                  <a
                    v-if="material.externalLinkTc"
                    :href="material.externalLinkTc"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div
                      class="flex flex-row justify-center items-center h-[40px] w-[120px] gap-2.5 box-border border-2 border-lightblue rounded-[100px] text-lightblue active:bg-lightblue download-button text-base active:text-[#FFFFFF]"
                    >
                      <span class="">繁体中文</span>
                      <!-- <span class="text-base">{{ itemName }}</span> -->
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.5 16C1.95 16 1.479 15.804 1.087 15.412C0.695002 15.02 0.499335 14.5493 0.500002 14V11H2.5V14H14.5V11H16.5V14C16.5 14.55 16.304 15.021 15.912 15.413C15.52 15.805 15.0493 16.0007 14.5 16H2.5ZM8.5 12L3.5 7L4.9 5.55L7.5 8.15V0L9.5 0V8.15L12.1 5.55L13.5 7L8.5 12Z"
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    v-if="material.externalLinkTh"
                    :href="material.externalLinkTh"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div
                      class="flex flex-row justify-center items-center h-[40px] w-[120px] gap-2.5 box-border border-2 border-lightblue rounded-[100px] text-lightblue active:bg-lightblue download-button text-base active:text-[#FFFFFF]"
                    >
                      <span class="">ไทย</span>
                      <!-- <span class="text-base">{{ itemName }}</span> -->
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.5 16C1.95 16 1.479 15.804 1.087 15.412C0.695002 15.02 0.499335 14.5493 0.500002 14V11H2.5V14H14.5V11H16.5V14C16.5 14.55 16.304 15.021 15.912 15.413C15.52 15.805 15.0493 16.0007 14.5 16H2.5ZM8.5 12L3.5 7L4.9 5.55L7.5 8.15V0L9.5 0V8.15L12.1 5.55L13.5 7L8.5 12Z"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
              <div class="relative hidden md:block">
                <!-- TODO refactor to use Base Swiper -->
                <swiper
                  ref="myResSwiper"
                  class="mx-auto relative overflow-hidden"
                  :options="swiperOptions"
                  :slides-per-view="swiperOptions.slidesPerView"
                  @swiper="setSwiperInstance($event, asset.header)"
                >
                  <swiper-slide
                    v-for="(material, index) in asset.materials"
                    :key="index"
                    class="rounded-2xl mr-3 lg:mr-6"
                  >
                    <div class="w-[327px] md:mr-6">
                      <a
                        :href="material.externalLink"
                        target="_blank"
                        rel="noreferrer noopener"
                        :data-test-id="material.dataTestId"
                        class="block relative w-full sm:w-max mb-3 hover:opacity-90"
                      >
                        <img
                          class="rounded-[16px] w-full aspect-video sm:w-[328px]"
                          :class="
                            asset.header.includes('Tools')
                              ? 'tool-shadow'
                              : 'shadow'
                          "
                          :src="material.thumbnail.url"
                          :alt="material.description"
                        />

                        <div
                          v-if="material.isVideo"
                          class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        >
                          <img
                            src="../../../public/assets/icons/youtube-player/play-button-dark.svg"
                            class="w-10 h-10 lg:w-[48px] lg:h-[48px]"
                          />
                        </div>
                      </a>

                      <div>
                        <p
                          v-if="material.description"
                          class="w-full mt-3 break-words"
                        >
                          {{ material.description }}
                        </p>
                      </div>
                      <div class="flex justify-center gap-3 select-none mt-3">
                        <a
                          v-if="material.externalLinkTc"
                          :href="material.externalLinkTc"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <div
                            class="flex flex-row justify-center items-center h-[40px] w-[120px] gap-2.5 box-border border-2 border-lightblue rounded-[100px] text-lightblue active:bg-lightblue download-button text-base active:text-[#FFFFFF]"
                          >
                            <span class="">繁体中文</span>
                            <!-- <span class="text-base">{{ itemName }}</span> -->
                            <svg
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.5 16C1.95 16 1.479 15.804 1.087 15.412C0.695002 15.02 0.499335 14.5493 0.500002 14V11H2.5V14H14.5V11H16.5V14C16.5 14.55 16.304 15.021 15.912 15.413C15.52 15.805 15.0493 16.0007 14.5 16H2.5ZM8.5 12L3.5 7L4.9 5.55L7.5 8.15V0L9.5 0V8.15L12.1 5.55L13.5 7L8.5 12Z"
                              />
                            </svg>
                          </div>
                        </a>
                        <a
                          v-if="material.externalLinkTh"
                          :href="material.externalLinkTh"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <div
                            class="flex flex-row justify-center items-center h-[40px] w-[120px] gap-2.5 box-border border-2 border-lightblue rounded-[100px] text-lightblue active:bg-lightblue download-button text-base active:text-[#FFFFFF]"
                          >
                            <span class="">ไทย</span>
                            <!-- <span class="text-base">{{ itemName }}</span> -->
                            <svg
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.5 16C1.95 16 1.479 15.804 1.087 15.412C0.695002 15.02 0.499335 14.5493 0.500002 14V11H2.5V14H14.5V11H16.5V14C16.5 14.55 16.304 15.021 15.912 15.413C15.52 15.805 15.0493 16.0007 14.5 16H2.5ZM8.5 12L3.5 7L4.9 5.55L7.5 8.15V0L9.5 0V8.15L12.1 5.55L13.5 7L8.5 12Z"
                              />
                            </svg>
                          </div>
                        </a>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="w-screen h-screen flex items-center justify-center">
        <loading-state />
      </div>
    </ResourcesContainer>
  </master-layout>
</template>

<script>
import {
  Swiper as SwiperClass,
  Pagination,
  EffectCoverflow,
} from "swiper/core";
// import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import ShareItSwiper from "../../components/common/ShareCard/ShareItSwiper";
import ShareItCard from "../../components/common/ShareCard/ShareItCard.vue";
import LanguageDropdown from "../../components/ResourcesPage/LanguageDropdown.vue";
import SessionDropdown from "../../components/ResourcesPage/SessionDropdown.vue";
import ResourcesContainer from "../../components/ResourcesPage/ResourcesContainer.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import LoadingState from "@/components/common/LoadingState.vue";
// import Json from '/public/data/resources/articles/en.json'
import { mapGetters, mapState } from "vuex";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

SwiperClass.use([Pagination, EffectCoverflow]);

export default {
  name: "SessionsPage",
  components: {
    ResourcesContainer,
    LanguageDropdown,
    SessionDropdown,
    ShareItCard,
    LoadingState,
    ShareItSwiper,
    Swiper,
    SwiperSlide,
  },
  /*
  async asyncData({ query }) {
    const language = query.language || "en";
    const { data: articles } = await axios.get(
      `/data/resources/articles/${language}.json`
    );
    return { articles, language };
  }, */
  data() {
    return {
      materialsCount: 0,
      sessions: [],
      images: [],
      swiperOptions: {
        centeredSlides: false,
        slidesPerView: "auto",
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
        },
      },
      assets: {
        links: {
          header: "Videos & Articles",
          materials: [],
        },
        pdf: {
          header: "Tools",
          materials: [],
        },
      },
      // articles: Json,
      swipers: {},
    };
  },
  computed: {
    ...mapState("user", ["token"]),
    ...mapState("cms", ["resources", "loading"]),
    ...mapGetters("profile", ["type"]),
    ...mapGetters("schedule", ["scheduleDates"]),
  },
  async ionViewDidEnter() {
    const resourceName = "gc-app-view-gensmvmt";
    await this.$store.dispatch("cms/fetchResources", resourceName);
    this.init();

    if (this.$refs["quotes-carousel"]) this.$refs["quotes-carousel"].update();

    for (const header in this.swipers) {
      const swiper = this.swipers[header];
      swiper.update();
    }
    FirebaseAnalytics.setScreenName({
      screenName: "resources-sessions",
      nameOverride: "ResourcesPage - Sessions",
    });
  },
  methods: {
    goTo(url) {
      window.open(url, "_blank");
    },
    async handleScroll($event) {
      const scrollElement = await $event.target.getScrollElement();
      showNavbar.value = scrollElement.scrollTop >= scrollThreshold;
    },
    setSwiperInstance(instance, header) {
      this.swipers[header] = instance;
    },
    async init() {
      const gensmvmtMaterials = this.resources[0];
      this.assets.pdf.materials = gensmvmtMaterials.tools;
      this.materialsCount += gensmvmtMaterials.tools.length;

      this.assets.links.materials = gensmvmtMaterials.relatedResources;
      this.materialsCount += gensmvmtMaterials.relatedResources.length;
    },
  },
};
</script>
<style scoped>
.tool-shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.shadow {
  box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.25);
}

.swiper-slide {
  max-width: 328px;
  align-items: flex-start;
  vertical-align: middle;
  border-radius: 10px;
  display: flex;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.swiper-container {
  list-style: none;
  z-index: 1;
  padding-bottom: 0px;
}

.download-button svg {
  fill: #00aae4;
}

.download-button:active svg {
  fill: #ffffff;
}

@media all and (min-width: 768px) {
  ::v-deep(.swiper-wrapper) {
    padding-left: 0;
  }
}
</style>
