<template>
  <section class="px-6 md:px-8 lg:px-[56px]">
    <div class="bg-lightgrey rounded-xl p-4 mt-3 max-w-[600px]">
      <h2 class="text-[20px] leading-[24px] font-aktiv-grotesk-medium mt-3">
        {{ $t("copy.experiencePage.experienceList.title") }}
      </h2>
      <ul class="list-none text-[16px] leading-[24px] mt-2">
        <li class="mt-1 ml-[10px]">
          • &nbsp; {{ $t("copy.experiencePage.experienceList.exp1") }}
        </li>
        <li class="mt-1 ml-[10px]">
          • &nbsp; {{ $t("copy.experiencePage.experienceList.exp2") }}
        </li>
        <li class="mt-1 ml-[10px]">
          • &nbsp; {{ $t("copy.experiencePage.experienceList.exp3") }}
        </li>
        <li class="mt-1 ml-[10px]">
          • &nbsp; {{ $t("copy.experiencePage.experienceList.exp4") }}
        </li>
      </ul>
      <p class="italic text-[14px] leading-[24px] mt-2">
        {{ $t("copy.experiencePage.experienceList.note") }}
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: {
    signups: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("signup", ["SIGN_UP_CLOSE_TS", "EXP_ALLOC_TS"]),
    ...mapState("timer", ["currDate"]),
    overallStatus() {
      if (this.hasSignupBefore && !this.isExpAllocated())
        return "Submitted Interest";

      const statusSet = new Set();
      for (const expType in this.signups) {
        const exp = this.signups[expType];
        if (exp && exp.status) statusSet.add(exp.status);
      }

      if (this.isSignupClosed() && statusSet.size == 0) return "Unavailable";

      if (statusSet.size === 1 && statusSet.has("Unavailable"))
        return "Unavailable";

      if (statusSet.has("Confirmed")) return "Confirmed";

      // if (statusSet.has("Pending")) return "Pending";

      return "";
    },
    hasSignupBefore() {
      let result = false;
      for (const expType in this.signups) {
        result = result || Boolean(this.signups[expType]);
      }
      return result;
    },
  },
  methods: {
    isSignupClosed() {
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      const signUpExpiry = new Date(this.SIGN_UP_CLOSE_TS);
      return currentDate.getTime() >= signUpExpiry.getTime();
    },
    isExpAllocated() {
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      const expAllocDate = new Date(this.EXP_ALLOC_TS);
      return currentDate.getTime() >= expAllocDate.getTime();
    },
  },
};
</script>

<style scoped></style>
