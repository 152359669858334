<template>
  <div v-if="show" @click="close()">
    <transition name="fade" appear>
      <div
        class="fixed top-0 left-0 right-0 bottom-0 z-40 bg-[#0B0C0F] opacity-60"
        @wheel.prevent
        @touchmove.prevent
        @scroll.prevent
      ></div>
    </transition>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PopupModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.lockBackgroundScroll()
  },
  destroyed() {
    this.unlockBackgroundScroll()
  },
  methods: {
    close() {
      this.$emit('close-popup')
    },
    lockBackgroundScroll() {
      document.body.classList.add('overflow-hidden')
      const MOBILE_WIDTH = 1023
      if (process.client && window && window.innerWidth < MOBILE_WIDTH) {
        document.body.style.top = `-${window.scrollY}px`
        window.scrollTo(window.scrollX, 0)
        // workarounds for modal bottom space issue on iOS
        if (this.isIos) {
          document.body.classList.add('absolute')
          document.body.classList.add('w-full')
          document.body.classList.add('h-full')
          document.body.addEventListener('touchmove', this.preventDefault)
        } else {
          // const leftPos = (document.body.clientWidth - window.innerWidth) / 2
          // document.body.style.left = `-${leftPos}px`
          document.body.classList.add('fixed')
        }
        document.body.classList.add('scrollbar-hide')
      }
    },
    unlockBackgroundScroll() {
      document.body.classList.remove('overflow-hidden')
      const MOBILE_WIDTH = 1023
      if (process.client && window && window.innerWidth < MOBILE_WIDTH) {
        // workarounds for modal bottom space issue on iOS
        if (this.isIos) {
          document.body.classList.remove('absolute')
          document.body.classList.remove('w-full')
          document.body.classList.remove('h-full')
          document.body.removeEventListener('touchmove', this.preventDefault)
        } else {
          document.body.classList.remove('fixed')
        }
        const scrollY = document.body.style.top
        document.body.style.top = ''
        document.body.style.paddingRight = '0'
        document.body.style.background = ''
        window.scrollTo(window.scrollX, parseInt(scrollY || '0') * -1)
        document.body.classList.remove('scrollbar-hide')
      }
    },
    preventDefault(event) {
      event.preventDefault()
    },
  },
}
</script>

<style></style>
