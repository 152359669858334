<template>
  <div v-click-away="collapseDropdown" class="group">
    <div class="relative min-w-[120px]">
      <div
        class="border-[2px] border-lightblue rounded-[8px] h-[48px] flex items-center justify-between py-[10px] px-3 cursor-pointer hover:opacity-80"
        data-test-id="gensapp-resourcespage-session-dropdown-expand"
        @click="isDropdownExpanded = !isDropdownExpanded"
      >
        <p class="text-lightblue font-bold font-aktiv-grotesk mr-4 text-[14px]">
          {{ getTitle(selectedSession) }}
        </p>

        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :class="{
            'rotate-180': isDropdownExpanded,
          }"
        >
          <path
            d="M0.878418 1.08301L6.79406 7.1662L12.8772 1.25055"
            stroke="#00AAE4"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div
        v-if="isDropdownCanShow && isDropdownExpanded"
        class="w-full absolute z-10 top-[48px] left-0 rounded-[8px] bg-white overflow-hidden box-shadow"
      >
        <div
          v-for="(session, index) in getGCResources"
          :key="session.sessionId.sessionId"
          class="py-2 px-4 cursor-pointer hover:bg-[#ededed] font-aktiv-grotesk text-[14px] text-lightblue bg-white"
          :class="{
            'bg-lightblue text-white font-bold pointer-events-none':
              selectedSession.sessionId.sessionId ===
              session.sessionId.sessionId,
            'text-lightblue':
              selectedSession.sessionId.sessionId !==
              session.sessionId.sessionId,
            'border-t border-grey-10': index != 0,
          }"
          :data-test-id="
            'gensapp-resourcespage-session-dropdown-selected-' +
            session.sessionId.sessionId
          "
          @click="onSessionSelect(session)"
        >
          {{ getTitle(session) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";
import { mixin as clickaway } from "vue3-click-away";
import { mapGetters, mapState } from "vuex";

export default {
  name: "SessionDropdown",
  mixins: [clickaway],
  props: {
    sessionId: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isDropdownCanShow: true,
      isDropdownExpanded: false,
      selectedSession: {
        title: "Deep Bench",
        sessionId: {
          sessionId: 1,
        },
      },
      learningCommunitySession: {
        title: "Learning Community",
        sessionId: {
          sessionId: 9999,
        },
      },
      sessions: [],
    };
  },
  async mounted() {
    const { status, data } = await axios.get(
      process.env.VUE_APP_API_URL + "/cms/schedules/session/all",
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }
    );

    if (status === 200) {
      this.sessions = data;
      this.selectedSession = data[0];
    }

    if (this.type === "GC") {
      let fullList = this.sessions.filter(this.checkGCSessions);
      this.sessions = fullList;
      this.selectedSession = this.sessions[0];
    }
    this.sessions.push(this.learningCommunitySession)
  },
  computed: {
    ...mapState("user", ["token"]),
    ...mapGetters("schedule", ["scheduleDates"]),
    ...mapGetters("profile", ["type"]),

    getGCResources() {
      if (this.type === "GC") {
        let fullList = this.sessions.filter(this.checkGCSessions);
        this.sessions = fullList;
      }
      this.selectedSession = this.sessions[0];
      return this.sessions;
    },
  },
  methods: {
    collapseDropdown() {
      this.isDropdownExpanded = false;
    },
    getDay(date) {
      moment.tz.setDefault("Asia/Singapore");
      const dateObj = moment(new Date(date));
      const dateString = dateObj.format("YYYY-MM-DD");
      const day = this.scheduleDates.indexOf(dateString) + 1;
      return this.type === "SCS" ? day : day - 2;
    },
    checkGCSessions(session) {
      const GCDates = this.scheduleDates.slice(2, 4);
      for (let date in GCDates) {
        moment.tz.setDefault("Asia/Singapore");
        const dateObj = moment(new Date(session.startTime));
        const dateString = dateObj.format("YYYY-MM-DD");
        if (dateString === GCDates[date]) {
          return true;
        }
      }
      return false;
    },
    onSessionSelect(session) {
      this.isDropdownCanShow = false;
      this.isDropdownExpanded = false;

      setTimeout(() => {
        this.isDropdownCanShow = true;
      }, 100);

      this.selectedSession = session;
      this.$emit("onChange", session.sessionId.sessionId);

      if (session.code === "en") {
        this.$router.push({
          path: this.$route.path,
        });

        return;
      }

      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          session: session.code,
        },
      });
    },
    getTitle(schedule) {
      if (!schedule.startTime) return schedule.title
      return "Day " + this.getDay(schedule.startTime) + ": " + schedule.title;
    },
  },
};
</script>
<style scoped>
.box-shadow {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}
</style>
