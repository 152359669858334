<template>
  <div
    class="day-tabs w-full lg:w-[calc(100vw-292px)] flex py-6 text-grey-60 font-aktiv-grotesk-medium overflow-x-auto pl-4 md:justify-center bg-white"
  >
    <div
      class="rounded-[12px] custom-shadow"
      style="
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1),
          0px 4px 6px -4px rgba(0, 0, 0, 0.1);
      "
    >
      <button
        :class="[
          'py-2 px-4 mx-0 rounded-[8px] border-2 border-solid flex-shrink-0 ',
          {
            'bg-lightblue text-white': selectedMyPhotoTypeTab !== 'exp',
            'border-transparent': selectedMyPhotoTypeTab === 'exp',
          },
        ]"
        @click="changeDayTab('personal')"
      >
        {{ $t("copy.photoDropPage.personalTab.tabName") }}
      </button>
      <button
        :class="[
          'py-2 px-4 mx-0 rounded-[8px] border-2 border-solid flex-shrink-0 ',
          {
            'bg-lightblue text-white': selectedMyPhotoTypeTab === 'exp',
            'border-transparent': selectedMyPhotoTypeTab !== 'exp',
          },
        ]"
        @click="changeDayTab('exp')"
      >
        {{ $t("copy.photoDropPage.customExperiencesTab.tabName") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "PhotoMyPhotoTypeTab",
  data() {
    return {};
  },
  computed: {
    ...mapState("photos", ["selectedMyPhotoTypeTab"]),
  },
  methods: {
    ...mapActions("photos", ["setSelectedMyPhotoTypeTab"]),
    async changeDayTab(selected) {
      const currentCount = this.changeDayTabFuncCount;
      this.setSelectedMyPhotoTypeTab(selected);
      this.$emit("refresh-photo");
    },
  },
};
</script>

<style>
.custom-shadow {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1),
    0px 4px 6px -4px rgba(0, 0, 0, 0.1);
}
</style>
