<template>
  <div class="bg-cover h-[60px] overflow-hidden" :class="getBreakColor"></div>
</template>

<script>
export default {
  name: "BreakCard",
  props: {
    isWeekday: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getBreakColor() {
      if (this.$i18n.locale == "zh") {
        return "bg-break-wide-blue-zh";
      }
      if (this.$i18n.locale == "th") {
        return "bg-break-wide-blue-th";
      }
      return "bg-break-wide-blue";
    },
  },
};
</script>

<style scoped></style>
