<template>
  <div class="flex flex-col w-[327px] lg:w-[680px] items-center">
    <div>
      <div
        v-if="!(this.profilePicture || this.form64Picture)"
        class="flex items-center w-[96px] h-[96px] text-[#FFF] text-[40px] font-family-['aktiv-grotesk-medium'] justify-center leading-loose rounded-full bg-lightblue border-white border-[3px]"
      >
        {{ name.charAt(0) }}
      </div>
      <img
        v-if="(this.profilePicture || this.form64Picture)"
        :src="imageURL"
        class="w-[96px] h-[96px] rounded-full border-white border-[3px]"
      />
    </div>
    <div class="w-[100px] lg:w-[680px] h-[24px]">
      <button
        class="font-aktiv-grotesk-trial block w-full mx-auto max-w-[295px] text-lightblue text-[16px] leading-[24px] text-center"
        @click="$refs.fileInput.click()"
      >
        {{ $t("copy.profilePage.addImage") }}
      </button>
    </div>
    <input
      id="file-upload"
      ref="fileInput"
      type="file"
      accept="image/png, image/jpeg, image/gif"
      class="hidden"
      @change="openImagePreview"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      filePreview: null,
      currentPicture: null,
    };
  },
  computed: {
    ...mapState("social", ["name", "profilePicture", "form64Picture"]),
    imageURL() {
      return this.form64Picture ? this.form64Picture : this.profilePicture;
    },
  },
  methods: {
    openImagePreview() {
      this.filePreview = URL.createObjectURL(this.$refs.fileInput.files[0]);
      this.$store.commit("social/setSelectedPicture", this.filePreview);
      this.$router.push("/profile/crop");
    },
  },
};
</script>
