<template>
  <div class="relative">
    <swiper
      :slides-per-view="options.slidesPerView"
      :grab-cursor="options.grabCursor"
      :ref="`mySwiper${id}`"
      class="mx-auto relative overflow-hidden"
      :options="options"
      :pagination="options.pagination"
      @swiper="setSwiperInstance"
      @slideChange="updateIndex"
    >
      <swiper-slide v-for="(item, index) in items" :key="index">
        <slot v-if="!loading" :item="item" :index="index"></slot>
      </swiper-slide>
      <div class="swiper-pagination"></div>
    </swiper>
    <!-- TODO: Refactor for customize prev & next-->
    <div v-if="options.hasNavigation" class="swiper-controls">
      <div
        v-if="!atFirstPage()"
        class="cursor-pointe swiper-button-prev"
        @click="prev()"
      >
        <slot name="prevArrow"></slot>
      </div>
      <div
        v-if="!atLastPage()"
        class="cursor-pointer swiper-button-next"
        @click="next()"
      >
        <slot name="nextArrow"></slot>
      </div>
    </div>
  </div>
</template>
<script>
// styles required for Pagination component
import "swiper/components/pagination/pagination.min.css";
import { Swiper as Pagination, Navigation, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";
// import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

// SwiperClass.use([Pagination, EffectCoverflow, Navigation])
// const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const swiper = ref(null);
    const setSwiperInstance = (swiperInstance) => {
      swiper.value = swiperInstance;
    };
    return {
      modules: [EffectCoverflow, Pagination, Navigation],
      setSwiperInstance,
      swiper,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      default: () => {
        return {
          grabCursor: true,
          centeredSlides: false,
          slidesPerView: "auto",
          hasNavigation: false,
          pagination: {},
          onActiveIndexChange: (swiper) => this.updateIndex(swiper),
        };
      },
      type: Object,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isBeginning: true,
      isEnd: false,
      loading: false,
    };
  },
  methods: {
    next() {
      this.swiper.slideNext();
    },
    prev() {
      this.swiper.slidePrev();
    },
    scrollToIndex(index) {
      this.swiper.slideTo(index);
    },
    updateIndex() {
      this.isBeginning = this.swiper.isBeginning;
      this.isEnd = this.swiper.isEnd;
      this.$emit("active-index-change", this.isEnd);
    },
    atFirstPage() {
      if (!this.swiper) return true;
      return this.isBeginning;
    },
    atLastPage() {
      if (!this.swiper) return false;
      return this.isEnd;
    },
    update() {
      this.swiper.update();
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  align-items: flex-start;
  vertical-align: middle;
  border-radius: 10px;
  display: flex;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-right: 16px;
}
.swiper-slide:last-child {
  margin-right: 24px;
}
.swiper-container {
  list-style: none;
  z-index: 1;
  // padding-left: 24px;
}

@media screen and (min-width: 768px) {
  .swiper-slide {
    margin-right: 24px;
  }

  .swiper-slide:last-child {
    margin-right: 84px;
  }
}

@media screen and (min-width: 1024px) {
  .swiper-slide:last-child {
    margin-right: 100px;
  }
}

@media screen and (min-width: 1280px) {
  .swiper-slide:last-child {
    margin-right: 292px;
  }
}
</style>
