<template>
  <div
    class="relative rounded-2xl overflow-hidden p-[16px] pt-8 text-[16px]"
    :class="cardStyle"
  >
    <div class="font-aktiv-grotesk-trial mb-3">
      {{ title }}
    </div>
    <div v-if="date" class="flex mb-1 justify-start items-start">
      <img
        src="../../../public/assets/icons/event-date-icon-orange.svg"
        alt="icon"
        class="mt-[2px]"
      />
      <div>
        <p class="ml-2">
          {{ date }}
        </p>
      </div>
    </div>
    <div v-if="expTime" class="flex mb-1 justify-start items-start">
      <img
        src="../../../public/assets/icons/event-duration-icon-orange.svg"
        alt="icon"
        class="mt-[2px]"
      />
      <div>
        <p class="ml-2">
          {{ expTime }}
        </p>
      </div>
    </div>
    <div
      v-if="location && location.length > 0"
      class="flex mb-1 justify-start items-start"
    >
      <img
        src="../../../public/assets/icons/event-location-icon-orange.svg"
        alt="icon"
        class="mt-1 ml-1"
      />
      <div class="flex flex-col">
        <p v-for="locate in location" :key="locate" class="ml-2">
          {{ locate }}
        </p>
      </div>
    </div>

    <CardLabel v-if="conferenceType === 'Global'" color="green"
      >Confirmed</CardLabel
    >
    <div
      v-if="type === 'DSN'"
      class="flex justify-end"
      :class="{ hidden: hideDSN }"
    >
      <in-app-browser :href="viewFloorplanButtonLink" target="_blank">
        <div
          class="font-bold ml-auto text-[14px] leading-[20px] mt-2 hover:underline text-orange"
        >
          {{ $t("copy.experiencePage.allocationPanel.viewFloorplanButton") }}
        </div>
      </in-app-browser>
    </div>
    <div
      v-else-if="location && location.length > 0 && location[0].includes('Hub')"
      class="flex justify-end"
    >
      <in-app-browser
        :href="$t('copy.experiencePage.allocationPanel.viewMapButtonLink')"
        target="_blank"
      >
        <div
          class="font-bold ml-auto text-[14px] leading-[20px] mt-2 hover:underline text-orange"
        >
          {{ $t("copy.experiencePage.allocationPanel.viewMapButton") }}
        </div>
      </in-app-browser>
    </div>
  </div>
</template>

<script>
import CardLabel from "../common/CardLabel.vue";
import InAppBrowser from "../InAppBrowser.vue";
import { isGPCRPage } from "@/utils/conference-type";
import { mapGetters } from "vuex";
export default {
  components: { CardLabel, InAppBrowser },
  props: {
    title: {
      type: String,
    },
    date: {
      type: String,
    },
    expTime: {
      type: String,
    },
    location: {
      type: String,
    },
    type: {
      type: String,
    },
    conferenceType: {
      type: String,
      default: "Global",
    },
  },
  computed: {
    ...mapGetters("signup", ["DSN_MAP_FLAG"]),
    cardStyle() {
      if (this.conferenceType === "Global")
        return "border-[1px] border-green border-solid";

      return "bg-orange bg-opacity-5";
    },
    viewFloorplanButtonLink() {
      return isGPCRPage()
        ? this.$t("copy.experiencePage.allocationPanel.viewFloorplanButtonLink")
        : "https://cdn.generationsmvmt.com/docs/Dream-and-Stories-Night-Floorplan.pdf";
    },
    hideDSN() {
      return isGPCRPage() && this.DSN_MAP_FLAG === 0;
    },
  },
};
</script>

<style></style>
