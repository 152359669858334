const INITIAL_STATE = {
  playingPlayer: null,
}

const state = () => INITIAL_STATE;

const mutations = {
  setPlayingPlayer(state, playingPlayer) {
    state.playingPlayer = playingPlayer;
  },
};

const actions = {
  setPlayingPlayer(context, playingPlayer) {
    context.commit("setPlayingPlayer", playingPlayer);
  },
};

const getters = {
  playingPlayer(state) {
    return state.playingPlayer
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
