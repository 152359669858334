import axios from "axios";
const INITIAL_STATE = {
  unreadCount: 0,
  title: "",
  body: "",
};

const state = () => INITIAL_STATE;

const actions = {
  async refreshAnnouncements(
    { dispatch, rootState },
    { locale = "en", conferenceType = "Global" }
  ) {
    try {
      const accessToken = rootState.user.token;
      const { status } = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/announcements/get",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            locale: locale,
            conferenceType: conferenceType,
          },
        }
      );
      if (status !== 200) throw new Error("Failed in refreshing announcements");
    } catch (e) {
      dispatch("toast/error", "Failed to refresh announcements.", {
        root: true,
      });
    }
  },

  async fetchUnreadCount(
    { commit, dispatch, rootState },
    conferenceType = "Global"
  ) {
    try {
      const accessToken = rootState.user.token;
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL +
          "/announcement/get-unread-announcements-count",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            conferenceType: conferenceType,
          },
        }
      );
      if (status !== 200) throw new Error("Failed in setting unread count");
      commit("setUnreadCount", data);
    } catch (e) {
      dispatch("toast/error", "Failed to set unread count.", { root: true });
    }
  },

  setNotification({ commit }, notification) {
    commit("setTitle", notification.title);
    commit("setBody", notification.body);
  },
};

const mutations = {
  setUnreadCount(state, count) {
    state.unreadCount = count;
  },
  setTitle(state, title) {
    state.title = title;
  },
  setBody(state, body) {
    state.body = body;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
