<template>
  <div class="mt-[16px]">
    <!-- <ResourcesLanguageModal
      v-if="showLanguageModal"
      :show="showLanguageModal"
      :type="items[itemIndex].type"
      :resources="items[itemIndex].resourceTranslations"
      :modal-type="modalType"
      :slug="items[itemIndex].slug"
      @close-modal="closeLanguageModal"
    /> -->
    <BaseCarousel
      :title="`video-spotlight-${categoryIndex}`"
      :item-width="304"
      :mobile-item-width="304"
      :no-of-items="items.length"
    >
      <ResourceCard
        v-for="(item, index) in items"
        :key="`${item.slug}-${item.type}`"
        :image-url="item.thumbnail"
        :title="item.title"
        :slug="item.slug"
        :type="item.type"
        :tag-slug="tagSlug"
        :duration="item.duration"
        :is-exclusive="item.isCVPExclusive"
        :is-audio="item.isAudio"
        :avail-translations="item.availTranslations"
        :resource-translations="item.resourceTranslations"
        @openLanguageModal="openLanguageModal(index)"
      />
      <!-- <div class="hidden xsm:w-[10px] xsm:h-full xsm:block sm:hidden"></div> -->
    </BaseCarousel>
  </div>
</template>

<script>
import BaseCarousel from "@/components/ResourcesPage/PlaybackResources/BaseCarousel.vue";
import ResourceCard from "@/components/ResourcesPage/PlaybackResources/ResourceCard.vue";
import ResourcesLanguageModal from "@/components/ResourcesPage/PlaybackResources/ResourcesLanguageModal.vue";

export default {
  components: {
    ResourcesLanguageModal,
    ResourceCard,
    BaseCarousel,
  },
  props: {
    categoryIndex: {
      type: Number,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    tagSlug: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showLanguageModal: false,
      itemIndex: null,
      downloadTypes: ["PDF", "Excel"],
      articleTypes: ["Link", "Article"],
    };
  },
  computed: {
    hasOneItem() {
      return this.items.length === 1;
    },
    breakpoints() {
      const breakpoint = this.hasOneItem ? 640 : 1024;

      return {
        [breakpoint]: {
          slidesPerView: 2.3,
          spaceBetween: 20,
          centeredSlides: false,
        },
      };
    },
    modalType() {
      if (this.items[this.itemIndex].type === "video") return "video";
      else if (this.downloadTypes.includes(this.items[this.itemIndex].type))
        return "download";
      else return "link";
    },
  },
  methods: {
    openLanguageModal(index) {
      this.itemIndex = index;
      const item = this.items[index];
      if (item.resourceTranslations && item.resourceTranslations.length > 1) {
        this.showLanguageModal = true;
      }
    },
    closeLanguageModal() {
      this.showLanguageModal = false;
    },
  },
};
</script>
