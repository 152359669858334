<template>
  <div class="relative">
    <DownloadModal :show="showModal" @close-modal="closeModal"></DownloadModal>
    <div
      class="relative md:flex md:justify-between lg:justify-center md:flex-row px-6 md:px-8 lg:px-0 mt-12 pb-[88px] z-10"
    >
      <div class="flex flex-col md:items-start md:my-auto">
        <p v-html="$t('copy.homePage.mainLayout.kickOffSection.title')"></p>
        <p
          v-html="$t('copy.homePage.mainLayout.kickOffSection.description')"
        ></p>
      </div>

      <div
        class="lg:flex lg:justify-between lg:items-center md:ml-[28px] lg:ml-[32px]"
      >
        <div
          class="w-full md:max-w-[332px] lg:max-w-[392px] md:w-[332px] lg:w-[392px] mx-auto"
        >
          <div id="home-page-kick-off-video-container" class="flex flex-col">
            <div
              class="md:w-[332px] lg:w-[392px] md:h-[188px] lg:h-[220px] mb-[10px] rounded-2xl"
            >
              <div
                id="pre-watch"
                class="absolute w-[calc(100%-48px)] aspect-video md:max-w-[332px] lg:max-w-[392px] md:w-[332px] lg:w-[392px] md:h-[188px] lg:h-[220px] rounded-2xl"
                data-test-id="gensapp-home-kickoff-video"
              >
                <!-- <div id="home-page-video-player" class="rounded-2xl"></div> -->

                <video-player
                  ref="aboutvideoPlayer"
                  class="rounded-2xl md:h-[183px] lg:h-[220px]"
                  data-event-tracking="video-weirdest-habit-autoplay"
                  :options="videoOptions"
                />
              </div>
              <div
                ref="preview"
                class="relative absolute md:w-[332px] lg:w-[392px] md:h-[188px] lg:h-[220px] rounded-2xl cursor-pointer"
              >
                <img
                  src="../../../public/assets/images/home/preview-video.jpg"
                  class="md:w-[332px] lg:w-[392px] rounded-2xl"
                  data-test-id="gensapp-home-kickoff-play-video"
                  @click="play"
                />
                <div
                  class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-2xl"
                >
                  <img
                    src="../../../public/assets//icons/youtube-player/play-button-dark.svg"
                    data-test-id="gensapp-home-kickoff-play-video"
                    class="w-10 h-10 lg:w-[48px] lg:h-[48px]"
                    @click="play"
                  />
                </div>
              </div>
            </div>
            <div class="flex justify-center">
              <div
                class="flex flex-row justify-center mb-1 lg:mb-0 cursor-pointer border-[2px] border-lightblue py-2 px-4 rounded-full"
                data-event-tracking="gensapp-home-kickoff-download-video"
                data-test-id="gensapp-home-kickoff-download-video"
                @click="openModal()"
              >
                <div
                  class="font-bold text-lightblue text-[14px] leading-[20px] mr-[6px] hover:underline"
                >
                  {{
                    $t("copy.homePage.mainLayout.kickOffSection.downloadVideo")
                  }}
                </div>
                <img
                  src="../../../public/assets/icons/download-outline-blue.svg"
                  class="w-[14px] h-[15px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VideoPlayer from "@/components/common/VideoPlayer";
// import Player from '@vimeo/player'
import DownloadModal from "@/components/HomePage/DownloadModal";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { isGPCRPage } from "@/utils/conference-type";

export default {
  name: "KickOffSection",
  components: {
    DownloadModal,
    VideoPlayer,
  },
  data() {
    return {
      readMore: false,
      player: null,
      showModal: false,
      gpcVideoOptions: {
        autoplay: false,
        controls: true,
        playsinline: true,
        sources: [
          {
            src: "https://cdn.generationsmvmt.com/videos/conference-app/strong_church_summit_pre-watch.mp4",
            // type: 'application/x-mpegURL',
            type: "video/mp4",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("profile", ["type"]),
    isSCS() {
      return this.type === "SCS";
    },
    videoOptions() {
      return isGPCRPage() ? this.gpcrVideoOptions : this.gpcVideoOptions;
    },
    gpcrVideoOptions() {
      return {
        autoplay: false,
        controls: true,
        playsinline: true,
        sources: [
          {
            src: this.$t(
              "copy.homePage.mainLayout.kickOffSection.downloadLink"
            ),
            type: "video/mp4",
          },
        ],
      };
    },
  },
  /*
  mounted() {
    const options = {
      url: 'https://vimeo.com/staff/iphone',
      responsive: true,
      title: false,
    }
    this.player = new Player('home-page-video-player', options)
  },
  */
  methods: {
    toggleReadMore() {
      this.readMore = !this.readMore;
    },
    play(e) {
      this.$refs.aboutvideoPlayer.player.play();
      this.$refs.preview.classList.add("z-[-100]");
    },
    pause(e) {
      this.$refs.aboutvideoPlayer.player.pause();
    },
    closeModal() {
      this.showModal = false;
    },
    openModal() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "gensapp-home-kickoff-download-video",
          page: "home",
        },
      });
      this.showModal = true;
    },
  },
};
</script>
<style scoped>
.aspect-video {
  aspect-ratio: 332/186;
}

@media all and (min-width: 768px) {
  .aspect-video {
    aspect-ratio: 332/183;
  }
}

@media all and (min-width: 1024px) {
  .aspect-video {
    aspect-ratio: 16/9;
  }
}
</style>
