<template>
  <empty-layout>
    <div class="w-full">
      <div id="profileBanner" class="h-[200px] w-full">
        <div class="z-1 pt-[66px]">
          <img
            :src="desktopProfileBanner.url"
            :class="desktopProfileBanner.width"
            class="hidden lg:block mx-auto"
          />
          <img
            :src="mobileProfileBanner.url"
            :class="mobileProfileBanner.width"
            class="lg:hidden mx-auto"
          />
        </div>
      </div>
      <div
        class="z-10 w-full mt-[-48px] flex flex-col justify-center items-center gap-[24px]"
      >
        <div>
          <div
            v-if="!showProfilePic"
            class="flex items-center w-[96px] h-[96px] text-[#FFF] text-[40px] font-family-['aktiv-grotesk-medium'] justify-center leading-loose rounded-full bg-lightblue border-white border-[3px]"
          >
            {{ userDetails.name.charAt(0) }}
          </div>
          <img
            v-if="showProfilePic"
            :src="userDetails.profilePicture"
            class="w-[96px] h-[96px] rounded-full border-white border-[3px]"
          />
        </div>
        <div
          class="flex flex-col w-[327px] lg:w-full gap-3 text-center justify-center"
        >
          <div
            class="w-full font-semibold text-[20px] leading-[24px] font-['Proxima Nova']"
          >
            {{ userDetails.name }}
          </div>
          <div class="w-full">
            <div class="font-[16px] font-semibold text-[16px] leading-[24px]">
              {{ userDetails.organization }}
            </div>
            <div class="font-[16px] font-normal text-[14px] leading-[20px]">
              {{ userDetails.title }}
            </div>
          </div>
        </div>
        <div
          v-if="
            showEmail() ||
            userDetails.instagram ||
            userDetails.facebook ||
            userDetails.x ||
            userDetails.linkedin
          "
          class="flex flex-col w-[327px] lg:w-[504px] py-[12px] px-[12px] rounded-[8px] bg-white border-solid border-[1px] border-grey-20"
        >
          <div v-if="showEmail()" class="mb-3">
            <div class="w-full font-semibold text-[16px] leading-[24px]">
              Email
            </div>
            <div
              class="w-full font-normal text-lightblue text-[16px] leading-[28px]"
            >
              {{ userDetails.email }}
            </div>
          </div>
          <div
            v-if="
              userDetails.instagram ||
              userDetails.facebook ||
              userDetails.x ||
              userDetails.linkedin
            "
            class="w-full font-semibold text-[16px] leading-[24px] mb-2"
          >
            Socials
          </div>
          <div class="flex flex-row w-full">
            <a
              v-if="userDetails.instagram"
              class="w-[32px] h-[32px] mr-6"
              :href="instagramURL()"
              target="_blank"
            >
              <img
                src="../../../../public/assets/icons/logo-instagram.svg"
                alt="Instagram Social"
              />
            </a>
            <a
              v-if="userDetails.facebook"
              class="w-[32px] h-[32px] mr-6"
              :href="facebookURL()"
              target="_blank"
            >
              <img
                src="../../../../public/assets/icons/logo-facebook.svg"
                alt="Facebook Social"
              />
            </a>
            <a
              v-if="userDetails.x"
              class="w-[32px] h-[32px] mr-6"
              :href="xURL()"
              target="_blank"
            >
              <img
                src="../../../../public/assets/icons/logo-twitterx.svg"
                alt="X Social"
              />
            </a>
            <a
              v-if="userDetails.linkedin"
              class="w-[32px] h-[32px]"
              :href="linkedInURL()"
              target="_blank"
            >
              <img
                src="../../../../public/assets/icons/logo-linkedin.svg"
                alt="LinkedIn Social"
              />
            </a>
          </div>
        </div>
        <button
          class="rounded-full grow-0 text-white text-center px-5 py-3 bg-lightblue font-aktiv-grotesk font-bold"
          @click="generateVcf()"
        >
          Download contact
        </button>
      </div>
    </div>
  </empty-layout>
</template>

<script>
import EmptyLayout from "@/components/EmptyLayout.vue";
import axios from "axios";
import VCard from "vcard-creator";

export default {
  name: "PubProfile",
  components: {
    EmptyLayout,
  },
  data() {
    return {
      userDetails: {
        name: "",
        organization: "",
        title: "",
        email: "",
        emailFlag: false,
        instagram: "",
        facebook: "",
        x: "",
        linkedin: "",
        profilePicture: "",
      },
      showProfilePic: false,
      profileUrl: "",
    };
  },
  async created() {
    const hashId = this.$route.params.id;
    const { status, data } = await axios.get(
      process.env.VUE_APP_API_URL + "/socialprofile?id=" + hashId
    );
    if (status !== 200) {
      return;
    }

    this.userDetails = data.socialprofile;
    this.showProfilePic = this.userDetails?.profilePicture;
  },
  computed: {
    desktopProfileBanner() {
      const banners = {
        en: {
          url: require("../../../../public/assets/images/profile-logo-desktop.svg"),
          width: "w-[930px]",
        },
        zh: {
          url: require("../../../../public/assets/images/profile-logo-desktop-zh.svg"),
          width: "w-[626px]",
        },
        th: {
          url: require("../../../../public/assets/images/profile-logo-desktop-th.svg"),
          width: "w-[841px]",
        },
      };
      return banners[this.$i18n.locale] || banners.en;
    },
    mobileProfileBanner() {
      const banners = {
        en: {
          url: require("../../../../public/assets/images/profile-logo-mobile.svg"),
          width: "w-[351px]",
        },
        zh: {
          url: require("../../../../public/assets/images/profile-logo-mobile-zh.svg"),
          width: "w-[160px]",
        },
        th: {
          url: require("../../../../public/assets/images/profile-logo-mobile-th.svg"),
          width: "w-[334px]",
        },
      };
      return banners[this.$i18n.locale] || banners.en;
    },
  },
  mounted() {
    if (navigator.userAgent.match(/samsung/i)) {
      this.isSamsung = true;
      console.log("You're samsung");
    } else {
      console.log("You're not samsung");
    }
  },
  methods: {
    instagramURL() {
      return "https://www.instagram.com/" + this.userDetails.instagram; // Remove as string when store has been re-adjusted
    },
    facebookURL() {
      return "https://www.facebook.com/" + this.userDetails.facebook;
    },
    xURL() {
      return "https://www.x.com/" + this.userDetails.x;
    },
    linkedInURL() {
      return "https://www.linkedin.com/in/" + this.userDetails.linkedin;
    },
    showEmail() {
      if (this.userDetails.email) {
        if (this.userDetails.emailFlag) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    async generateVcf() {
      // Define a new vCard
      const myContact = new VCard();
      // Add personal data
      myContact.addName("", this.userDetails.name, "", "", "");
      // Add work data
      if (this.userDetails.organization) {
        myContact.addCompany(this.userDetails.organization);
      }
      if (this.userDetails.title) {
        myContact.addJobtitle(this.userDetails.title);
      }
      if (this.showEmail() && this.userDetails.email) {
        myContact.addEmail(this.userDetails.email);
      }

      if (this.userDetails.x) {
        myContact.addSocial(this.xURL(), "Twitter");
      }

      if (this.userDetails.instagram) {
        myContact.addSocial(this.instagramURL(), "Instagram");
      }

      if (this.userDetails.facebook) {
        myContact.addSocial(this.facebookURL(), "Facebook");
      }

      if (this.userDetails.linkedin) {
        myContact.addSocial(this.linkedInURL(), "linkedin");
      }
      if (this.userDetails.profilePicture) {
        const base64 = await this.getBase64Image(
          this.userDetails.profilePicture
        );
        console.log("base64", base64);
        myContact.addPhoto(base64);
      }

      // Create a Blob from the vCard data
      const blob = new Blob([myContact], { type: "text/vcard" });

      // Create a URL for the Blob
      const myContactUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      console.log(myContactUrl);
      link.href = myContactUrl;
      link.download = "profile.vcf";

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the body
      document.body.removeChild(link);

      URL.revokeObjectURL(this.profileUrl); // Clean up the URL
    },
    async getBase64Image(imgUrl) {
      const response = await fetch(imgUrl, { cache: "no-cache" });
      console.log(response);
      const blob = await response.blob();
      console.log(blob);
      const url = URL.createObjectURL(blob);
      console.log(url);
      this.profileUrl = url;
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      await new Promise((resolve) => (img.onload = resolve));
      const canvas = document.createElement("canvas");
      canvas.width = 640;
      canvas.height = 640;
      canvas.getContext("2d").drawImage(img, 0, 0, 640, 640);
      const dataUrl = canvas.toDataURL("image/png");
      const base64 = dataUrl.split(",")[1];
      return base64;
    },
  },
};
</script>
<style scoped>
@media (min-width: 769px) {
  #profileBanner {
    background-image: url("../../../../public/assets/images/bg-profileDesktop.png");
    background-size: 100% 100%; /* Stretch image to cover entire area */
    background-repeat: no-repeat; /* Prevent image from repeating */
    background-position: center; /* Center the background image */
  }
}

@media (max-width: 768px) {
  #profileBanner {
    background-image: url("../../../../public/assets/images/bg-profileMobile.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
