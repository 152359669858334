<template>
  <section
    class="bg-gens-conf-yellow-bar rounded-2xl mx-6 mt-12 lg:mt-16 md:mx-auto md:w-[530px] flex flex-col md:flex-row justify-center items-center px-10 py-7 mb-16"
  >
    <img :src="titleImgUrl.url" class="md:hidden" :class="titleImgUrl.width" />
    <img :src="albumLogoText" class="w-[220px] md:w-[184px] mt-3 md:mr-6" />
    <div class="md:flex md:flex-col md:justify-center md:items-center">
      <img
        :src="titleImgUrl.url"
        class="hidden md:block"
        :class="titleImgUrl.width"
      />
      <in-app-browser :href="playlistUrl" target="_blank">
        <button
          class="rounded-full bg-black py-3 px-5 text-white mt-7 md:mt-4 font-aktiv-grotesk-medium text-[18px] xs:text-[20px] leading-[24px]"
          data-event-tracking="gensapp-home-playlist"
          @click="logEvent()"
        >
          {{ playlistText }}
        </button>
      </in-app-browser>
    </div>
  </section>
</template>

<script>
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import InAppBrowser from "../InAppBrowser.vue";
import { isGPCRPage } from "@/utils/conference-type";
export default {
  components: { InAppBrowser },
  computed: {
    albumLogoText() {
      return isGPCRPage()
        ? require("../../../public/assets/images/gpcr/album-logo.png")
        : require("../../../public/assets/images/home/album-logo.png");
    },
    playlistUrl() {
      return isGPCRPage()
        ? "https://gens.app/music-gpcr"
        : "https://gens.app/music";
    },
    playlistText() {
      return isGPCRPage()
        ? this.$t("copy.homePage.mainLayout.worshipSongs.availableOnYoutube")
        : "Available on Spotify";
    },
    // titleImgUrl() {
    //   return !isGPCRPage()
    //     ? require(this.$t("copy.homePage.mainLayout.worshipSongs.titleImgUrl"))
    //     : require("../../../public/assets/images/home/playlist-th.svg");
    // },
    titleImgUrl() {
      const banners = {
        en: {
          url: require("../../../public/assets/images/home/playlist.png"),
          width: "w-[247px]",
        },
        zh: {
          url: require("../../../public/assets/images/home/playlist-zh.svg"),
          width: "w-[204px]",
        },
        th: {
          url: require("../../../public/assets/images/home/playlist-th.svg"),
          width: "w-[253px]",
        },
      };
      return banners[this.$i18n.locale] || banners.en;
    },
  },
  methods: {
    logEvent() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "gensapp-home-playlist",
          page: "home",
        },
      });
    },
  },
};
</script>

<style scoped></style>
