<template>
  <div class="flex flex-col h-full bg-[#F0FFFF] w-[100vw] justify-center items-center">
    <h1 class="font-bold mb-[24px] text-xl">Mock Login (Only in Local Mode)</h1>
    <div class="custom-select" @blur="open = false">
      <div class="selected" @click="open = !open">
        {{ selected ? selected : 'Select a username to login' }}
      </div>
      <div class="items" :class="{ selectHide: !open }">
        <div
          v-for="(option, i) of usernames"
          :key="i"
          @click="selectUser(option)"
        >
          {{ option }}
        </div>
      </div>
    </div>
    <button
      :disabled="!selected"
      class="mt-[24px] bg-[blue] rounded-lg px-[16px] py-[12px] text-[white] w-[400px]"
      :class="{ 'cursor-not-allowed': !selected, 'bg-[grey]': !selected }"
      @click="localLogin"
    >
      Login
    </button>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'LocalLogin',
  props: {
    usernames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: null,
      open: false,
    }
  },
  methods: {
    selectUser(username) {
      this.selected = username
      this.open = false
    },
    async localLogin() {
      try {
        const { status, data } = await axios.request(
          process.env.VUE_APP_API_URL + "/local/login",
          {
            method: "post",
            data: { name: this.selected },
          }
        );
        localStorage.setItem("token", data.token);
        this.$store.commit("user/setToken", data.token);
        await this.$store.dispatch('profile/fetchProfile')
        this.$router.push({ path: '/home' })
      } catch (error) {
        console.log(error.message)
      }
    },
  },
}
</script>

<style scoped>
.custom-select {
  position: relative;
  width: 400px;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;
}

.custom-select .selected {
  background-color: #0a0a0a;
  border-radius: 6px;
  border: 1px solid #666666;
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .selected.open {
  border: 1px solid #ad8225;
  border-radius: 6px 6px 0px 0px;
}

.custom-select .selected:after {
  position: absolute;
  content: '';
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.custom-select .items {
  color: #fff;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  border-right: 1px solid #ad8225;
  border-left: 1px solid #ad8225;
  border-bottom: 1px solid #ad8225;
  position: absolute;
  background-color: #0a0a0a;
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select .items div {
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #ad8225;
}

.selectHide {
  display: none;
}
</style>
