<template>
  <div
    class="day-tabs w-full lg:w-[calc(100vw-292px)] flex py-6 text-grey-80 font-aktiv-grotesk-medium overflow-x-auto pl-4 md:justify-center bg-white"
  >
    <button
      role="tab"
      :class="[
        'py-2 px-4 mx-2 rounded-[100px] border-2 border-solid flex-shrink-0 ',
        {
          'border-lightblue text-lightblue font-bold':
            selectedDayTab === 'Featured',
          'border-transparent': selectedDayTab !== 'Featured',
        },
      ]"
      @click="changeDayTab('Featured')"
    >
      {{ $t("copy.photoDropPage.forEveryoneTab.videosTab") }}
    </button>
    <button
      role="tab"
      v-for="(date, i) in scheduleDatesComputed"
      :key="date"
      :class="[
        'py-2 px-4 mx-2 rounded-[100px] border-2 border-solid flex-shrink-0 ',
        {
          'border-lightblue text-lightblue font-bold': selectedDayTab == date,
          'border-transparent': selectedDayTab !== date,
          'font-aktiv-grotesk-regular text-grey-40': showDisabled(i),
        },
      ]"
      :disabled="showDisabled(i)"
      @click="changeDayTab(date)"
    >
      {{ $t("copy.photoDropPage.forEveryoneTab.dayTab", { i: translatedDay(i+1) }) }}
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { forEveryoneDaySlugs } from "@/utils/photodropPageSlugs";
import { isGPCRPage } from "@/utils/conference-type";
import { toChineseNumeral } from "@/utils/translation.js";
export default {
  name: "PhotoEveryoneDayTab",
  data() {
    return {
      forEveryoneDaySlugs: forEveryoneDaySlugs,
      forEveryoneDaySlugMappings: {},
    };
  },
  computed: {
    ...mapGetters("schedule", ["scheduleDates", "GPCRScheduleDates"]),
    ...mapState("profile", ["type"]),
    ...mapState("photos", {
      days: "everyonePhotoSchedule",
      selectedDayTab: "selectedDayTab",
      photos: "everyonePhotos",
    }),
    scheduleDatesComputed() {
      return isGPCRPage() ? this.GPCRScheduleDates : this.scheduleDates;
    },
  },
  mounted() {
    this.populateForEveryoneDaySlugMappings();
  },
  methods: {
    ...mapActions("photos", ["setSelectedDayTab"]),
    async changeDayTab(selected) {
      if (selected == this.selectedDayTab) return;

      this.setSelectedDayTab(selected);
      this.$store.commit("setEveryonePhotos", []);
      await this.$store.dispatch("photos/fetchPhotosEveryone", {
        tabName: selected,
        conferenceType: isGPCRPage() ? "Regional" : "Global",
      });

      const hash = this.forEveryoneDaySlugMappings[selected];
      if (this.$router.path !== "/photodrop") return;
      this.$router.push({
        path: "/photodrop",
        hash: `#${hash}`,
      });
    },
    populateForEveryoneDaySlugMappings() {
      this.forEveryoneDaySlugs.forEach((slug, index) => {
        if (this.scheduleDates[index]) {
          this.forEveryoneDaySlugMappings[this.scheduleDates[index]] = slug;
        } else {
          this.forEveryoneDaySlugMappings["Featured"] = slug;
        }
      });
    },
    showDisabled(index) {
      const dayKey = `day${index + 1}`;
      return !this.days[dayKey];
    },
    translatedDay(i) {
      const savedLocale = this.$i18n.locale;
      return savedLocale == "zh" ? toChineseNumeral(i) : i;
    },
  },
};
</script>

<style></style>
