<template>
  <Collapsible
    v-model="localValue"
    class="rounded-[16px]"
    @opened="$emit('opened')"
    @closed="$emit('closed')"
  >
    <template #trigger="{ isOpen, toggle }">
      <div
        class="w-full px-[12px] md:px-[24px] py-[12px] flex cursor-pointer rounded-t-[16px] bg-grey-10"
        :class="isOpen ? 'rounded-b-0' : 'rounded-b-[16px]'"
        @click="toggle"
      >
        <h3 class="text-[24px] leading-[32px] font-bold mr-[12px]">
          {{ title }}
        </h3>
        <img
          src="/assets/icons/chevron-down-purple.svg"
          alt=""
          class="ml-auto"
          :class="{ 'rotate-180': isOpen }"
        />
      </div>
    </template>
    <template #collapsible="{ isOpen }">
      <div
        v-show="isOpen"
        class="w-full bg-grey-10 px-[12px] pb-[21px] md:px-[24px] md:pb-[12px] rounded-b-[16px] body md:pb-0"
      >
        <slot />
      </div>
    </template>
  </Collapsible>
  <!-- <Collapsible :title="title" v-model="localValue" @opened="$emit('opened')" @closed="$emit('closed')">
    <template #trigger="{ isOpen, toggle }">
      <div class="flex w-full justify-between items-center px-[8px] py-[20px] cursor-pointer" @click="toggle">
        <h3 class="text-[20px] leading-[25px] font-bold font-[#373E49]">
          {{ title }}
        </h3>
        <img src="/assets/icons/chevron-down.svg" alt="" class="rotate-0 theme-accordian-icon"
          :class="{ 'rotate-[-180deg] theme-accordian-icon': isOpen }" />
      </div>
    </template>
    <template #collapsible="{ isOpen }">
      <div v-show="isOpen" class="w-full body bg-[#21252C] md:pb-0">
        <slot />
      </div>
    </template>
  </Collapsible> -->
</template>

<script>
// import Collapsible from '../../components/common/Collapsible.vue'
import Collapsible from "../../components/common/CollapsibleWrapper.vue";
import HasLocalValue from "@/mixins/HasLocalValue";
// import { Collapsible, HasLocalValue } from '@team-hogc/design-internal-components-vue'

export default {
  components: {
    Collapsible,
  },

  mixins: [HasLocalValue],

  props: {
    title: {
      type: String,
      required: true,
    },
    initialOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      localValue: false,
    };
  },
};
</script>
