import { Device } from "@capacitor/device";
import { isNativeNotWeb } from "@/utils/platform-check";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export const CURRENT_ACTIVE_CONFYEAR = ["2024-G", "2024-R", "2024-GR"];
export const CURRENT_LIVECHAT_CONFYEAR = ["2024-R", "2024-GR"];

export const isGPCRPage = () => {
  return window.location.pathname.includes("gpcr");
};

export const isDelegateGPCROnly = (confYear) => {
  return confYear === "2024-R";
};

export const isDelegateGPCOnly = (confYear) => {
  console.log(confYear);
  return ["2024-G", "2023-G"].includes(confYear);
};

// return either "Global" or "Regional"
export const getSelectedConferenceView = () => {
  // check localStorage conf view
  const savedConfView = localStorage.getItem("gpcr-app-conftype");
  if (!savedConfView) {
    return "Regional";
  }

  return savedConfView;
};

// return either "Global" or "Regional"
export const getCurrentConferenceType = (confYear) => {
  if (isDelegateGPCROnly(confYear)) {
    return "Regional";
  } else if (isDelegateGPCOnly(confYear)) {
    return "Global";
  }
  // if delegate is returning delegate from GPC, check most recent selected view
  return getSelectedConferenceView();
};

export const setConferenceView = (confType) => {
  localStorage.setItem("gpcr-app-conftype", confType);
  if (isNativeNotWeb()) {
    FirebaseAnalytics.setUserProperty({
      name: "selected_conference_type",
      value: confType,
    });
  }
};

export const DEFAULT_CONFERENCE_TYPE = "Regional";
