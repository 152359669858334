<template>
  <svg v-if="!isActive" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.6 5.9L24.55 6.95M2.5 15.5H4H2.5ZM16 2V3.5V2ZM28 15.5H29.5H28ZM6.4 5.9L7.45 6.95L6.4 5.9Z" stroke="#99A4B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.5 21.5C10.2407 20.5555 9.3105 19.2388 8.84115 17.7363C8.3718 16.2337 8.3871 14.6216 8.88488 13.1283C9.38266 11.635 10.3377 10.3361 11.6147 9.41568C12.8917 8.49528 14.4259 8 16 8C17.5741 8 19.1083 8.49528 20.3853 9.41568C21.6623 10.3361 22.6173 11.635 23.1151 13.1283C23.6129 14.6216 23.6282 16.2337 23.1589 17.7363C22.6895 19.2388 21.7593 20.5555 20.5 21.5C19.9144 22.0797 19.4734 22.7892 19.2128 23.5709C18.9522 24.3527 18.8793 25.1848 19 26C19 26.7956 18.6839 27.5587 18.1213 28.1213C17.5587 28.6839 16.7956 29 16 29C15.2044 29 14.4413 28.6839 13.8787 28.1213C13.3161 27.5587 13 26.7956 13 26C13.1207 25.1848 13.0478 24.3527 12.7872 23.5709C12.5266 22.7892 12.0856 22.0797 11.5 21.5Z" stroke="#99A4B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.5508 23H19.4508" stroke="#99A4B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.84115 17.7363C9.3105 19.2388 10.2407 20.5555 11.5 21.5C12.0856 22.0797 12.3138 22.2182 12.5744 23H19C19.2606 22.2182 19.9144 22.0797 20.5 21.5C21.7593 20.5555 22.6895 19.2388 23.1589 17.7363C23.6282 16.2337 23.6129 14.6216 23.1151 13.1283C22.6173 11.635 21.6623 10.3361 20.3853 9.41568C19.1083 8.49528 17.5741 8 16 8C14.4259 8 12.8917 8.49528 11.6147 9.41568C10.3377 10.3361 9.38266 11.635 8.88488 13.1283C8.3871 14.6216 8.3718 16.2337 8.84115 17.7363Z" stroke="#99A4B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  <svg v-else width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.6 5.9L24.55 6.95M2.5 15.5H4H2.5ZM16 2V3.5V2ZM28 15.5H29.5H28ZM6.4 5.9L7.45 6.95L6.4 5.9Z" stroke="#00AAE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.5 21.5C10.2407 20.5555 9.3105 19.2388 8.84115 17.7363C8.3718 16.2337 8.3871 14.6216 8.88488 13.1283C9.38266 11.635 10.3377 10.3361 11.6147 9.41568C12.8917 8.49528 14.4259 8 16 8C17.5741 8 19.1083 8.49528 20.3853 9.41568C21.6623 10.3361 22.6173 11.635 23.1151 13.1283C23.6129 14.6216 23.6282 16.2337 23.1589 17.7363C22.6895 19.2388 21.7593 20.5555 20.5 21.5C19.9144 22.0797 19.4734 22.7892 19.2128 23.5709C18.9522 24.3527 18.8793 25.1848 19 26C19 26.7956 18.6839 27.5587 18.1213 28.1213C17.5587 28.6839 16.7956 29 16 29C15.2044 29 14.4413 28.6839 13.8787 28.1213C13.3161 27.5587 13 26.7956 13 26C13.1207 25.1848 13.0478 24.3527 12.7872 23.5709C12.5266 22.7892 12.0856 22.0797 11.5 21.5Z" stroke="#00AAE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.5508 23H19.4508" stroke="#00AAE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.84115 17.7363C9.3105 19.2388 10.2407 20.5555 11.5 21.5C12.0856 22.0797 12.3138 22.2182 12.5744 23H19C19.2606 22.2182 19.9144 22.0797 20.5 21.5C21.7593 20.5555 22.6895 19.2388 23.1589 17.7363C23.6282 16.2337 23.6129 14.6216 23.1151 13.1283C22.6173 11.635 21.6623 10.3361 20.3853 9.41568C19.1083 8.49528 17.5741 8 16 8C14.4259 8 12.8917 8.49528 11.6147 9.41568C10.3377 10.3361 9.38266 11.635 8.88488 13.1283C8.3871 14.6216 8.3718 16.2337 8.84115 17.7363Z" fill="#00AAE4" stroke="#00AAE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: 'ResourcesIcon',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
