<template>
  <div
    v-if="isShow"
    :id="exp.type"
    class="experience-card mb-6 bg-white rounded-xl p-4 pt-6 relative overflow-visible text-grey-100"
    :class="cardStyle"
  >
    <div class="flex justify-between">
      <CardLabel
        v-if="exp.status && isSignupClosed() && !isEdit"
        :color="getLabelColor(exp)"
        >{{ getLabelText(exp) }}</CardLabel
      >
      <h1
        class="text-[20px] leading-[24px] font-aktiv-grotesk-trial text-grey-80 mb-4"
        :class="exp.status && isSignupClosed() && !isEdit ? 'mt-4' : ''"
      >
        {{ exp.title }}
      </h1>
      <div v-if="isEdit || (!isSignupClosed() && exp.status)" class="min-w-max">
        <div
          class="rounded-xl p-3 font-aktiv-grotesk-medium flex h-[48px] ml-auto"
          :class="selectedStyle"
          @click="updateChoice()"
        >
          <img
            v-if="isEdit && needDisable && !isSelectedState"
            class=""
            src="../../../public/assets/icons/disabled-checkbox.svg"
          />
          <img
            v-else-if="isEdit && isSelectedState"
            class=""
            src="../../../public/assets/icons/checked-checkbox.svg"
          />
          <img
            v-else-if="isEdit"
            class=""
            src="../../../public/assets/icons/unchecked-checkbox.svg"
          />
          <img
            v-else
            class=""
            src="../../../public/assets/icons/non-selected-checkbox.svg"
          />
          <p class="ml-[9px]">{{ selectedText }}</p>
        </div>
      </div>
    </div>
    <p
      v-if="isEdit && needDisable && !isSelectedState"
      class="rounded-lg bg-grey-10 mt-[18px] mb-4 text-grey-80 font-aktiv-grotesk-regular text-[16px] leading-[24px] px-4 py-3"
    >
      You have selected 3 Experiences. Please remove one of your option before
      select a new one.
    </p>
    <!-- <div v-if="showLocation(exp)" class="flex mb-1 justify-start items-start">
      <img
        class="ml-[4px] mt-1"
        src="../../../public/assets/icons/event-location-icon-orange.svg"
      />
      <p class="text-[16px] leading-[24px] text-grey-80 ml-[10px]">
        {{ exp.location }}
      </p>
    </div>
    <div class="flex mb-1 justify-start items-start">
      <img
        src="../../../public/assets/icons/event-date-icon-orange.svg"
        class="mt-1"
      />
      <p class="text-[16px] leading-[24px] text-grey-80 ml-2">
        {{ exp.date }}
      </p>
    </div>
    <div class="flex mb-4 justify-start items-start">
      <img
        src="../../../public/assets/icons/event-duration-icon-orange.svg"
        class="mt-1"
      />
      <p class="text-[16px] leading-[24px] text-grey-80 ml-2">
        {{ exp.timing }}
      </p>
    </div> -->
    <p class="text-[16px] leading-[24px] mb-3 mt-[18px]">
      {{ exp.description1 }}
    </p>
    <!-- <p class="tracking-wide italic text-[13px] mb-6 text-grey-80">
			{{ getSignUpText() }}
		</p> -->
    <div class="flex justify-end">
      <span
        class="text-sm font-bold text-orange cursor-pointer"
        @click="displayExpModal(true)"
        >Read more</span
      >
        <p
        class="font-aktiv-grotesk-trial text-[16px] leading-[24px]"
        :data-test-id="'gensapp-experiencesignup-experiencestatus-' + exp.type">
          Status: Slot {{ exp.status }}
        </p>
      </div>

      <h1
        v-if="exp.status === 'Pending'"
        class="text-[16px] leading-[24px] pt-2 mb-2 mt-6"
        :data-test-id="'gensapp-experiencesignup-experiencetext-' + exp.type + '-' + exp.status">

        Thank you for indicating your interest 😊
      </h1>
      <h1
        v-else-if="exp.status === 'Confirmed'"
        class="text-[16px] leading-[24px] pt-2 mb-2 mt-6"
        :data-test-id="'gensapp-experiencesignup-experiencetext-' + exp.type + '-' + exp.status"
      >
        You’re good to go!
      </h1>
      <h1
        v-else-if="exp.status === 'Unavailable'"
        class="text-[16px] leading-[24px] pt-2 mb-2 mt-6"
        :data-test-id="'gensapp-experiencesignup-experiencetext-' + exp.type + '-' + exp.status"
      >
        Thank you for your interest in our Connect Group Experience. We are so encouraged
        by the overwhelming response we got!
      </h1>

      <div v-if="exp.status === 'Pending'">
        Check the status of your sign up in your
        <span
          class="text-orange font-aktiv-grotesk-trial hover:underline inline-block cursor-pointer"
          data-test-id="gensapp-experiencesignup-cg-pending-gotoschedule"
          @click="goToSchedule()"
        >
          Schedule</span
        >
        on {{ confirmedDate }}.
      </div>
      <div v-else-if="exp.status === 'Confirmed'">
        Time and venue is confirmed in your
        <span
          class="text-orange font-aktiv-grotesk-trial hover:underline inline-block cursor-pointer"
          data-test-id="gensapp-experiencesignup-cg-confirmed-gotoschedule"
          @click="goToSchedule()"
        >
          Schedule</span
        >. See you there!
      </div>
      <div v-else-if="exp.status === 'Unavailable'">
        <h1 class="text-[16px] leading-[24px] mb-2">
          We have allocated slots in a way that ensures every
          church/organisation has a representative attending the experience.
        </h1>
        <h1 class="text-[16px] leading-[24px]">
          We apologise to those who did not manage to get a slot. Your interest
          and questions are important to us! Do speak to your hosts, they might
          be able to answer you! If not, they will let the global team know and
          we will do our best to connect you with the right people. 👍🏻😃
        </h1>
      </div>
    </div>
    <div
      v-if="exp.status !== '' && exp.status && exp.type === 'MD'"
      class="relative bg-grey-5 rounded-xl px-4 py-11 pb-3 mb-5"
    >
      <div
        class="w-full absolute top-0 left-0 rounded-tl-xl rounded-tr-xl p-1 text-center"
        :class="getPanelColor(exp.status)"
      >
        <p
        class="font-aktiv-grotesk-trial text-[16px] leading-[24px]"
        :data-test-id="'gensapp-experiencesignup-experiencestatus-' + exp.type">
          Status: Slot {{ exp.status }}
        </p>
      </div>
      <div v-if="exp.status === 'Pending'">
        <h1
        class="text-[16px] leading-[24px] pt-1 mb-2"
        :data-test-id="'gensapp-experiencesignup-experiencetext-' + exp.type + '-' + exp.status">
          Thank you for indicating your interest 😊
        </h1>
        Check the status of your sign up in your
        <span
          class="text-orange font-aktiv-grotesk-trial hover:underline inline-block cursor-pointer"
          data-test-id="gensapp-experiencesignup-md-gotoschedule"
          @click="goToSchedule()"
        >
          Schedule</span
        >
        on {{ confirmedDate }}.
      </div>
      <div v-else-if="exp.status === 'Confirmed'">
        <h1
        class="text-[16px] leading-[24px] mb-2"
        :data-test-id="'gensapp-experiencesignup-experiencetext-' + exp.type + '-' + exp.status">
          Your spot is confirmed! See you there!
        </h1>
      </div>
      <div v-else-if="exp.status === 'Unavailable'">
        <h1
        class="text-[16px] leading-[24px] mb-2"
        :data-test-id="'gensapp-experiencesignup-experiencetext-' + exp.type + '-' + exp.status">
          Thank you for your interest in our Ministry Debrief experience. We are
          so encouraged by the overwhelming response we got!
        </h1>
        <h1 class="text-[16px] leading-[24px] mb-2">
          We have allocated slots in a way that ensures every
          church/organisation has a representative attending the experience.
        </h1>
        <h1 class="text-[16px] leading-[24px]">
          We apologise to those who did not manage to get a slot. Your interest
          and questions are important to us! Do speak to your hosts, they might
          be able to answer you! If not, they will let the global team know and
          we will do our best to connect you with the right people. 👍🏻😃
        </h1>
      </div>
    </div>
    <div
      v-if="exp.status !== '' && exp.status && exp.type === 'SPE'"
      class="relative bg-grey-5 rounded-xl px-4 py-11 pb-3 mb-5"
    >
      <div
        class="w-full absolute top-0 left-0 rounded-tl-xl rounded-tr-xl p-1 text-center"
        :class="getPanelColor(exp.status)"
      >
        <p
        class="font-aktiv-grotesk-trial text-[16px] leading-[24px]"
        :data-test-id="'gensapp-experiencesignup-experiencestatus-' + exp.type">
          Status: Slot {{ exp.status }}
        </p>
      </div>
      <div v-if="exp.status === 'Pending'">
        <h1
        class="text-[16px] leading-[24px] pt-1 mb-2"
        :data-test-id="'gensapp-experiencesignup-experiencetext-' + exp.type + '-' + exp.status">
          Thank you for indicating your interest 😊
        </h1>
        Check the status of your sign up in your
        <span
          class="text-orange font-aktiv-grotesk-trial hover:underline inline-block cursor-pointer"
          data-test-id="gensapp-experiencesignup-spe-gotoschedule"
          @click="goToSchedule()"
        >
          Schedule</span
        >
        on {{ confirmedDate }}.
      </div>
      <div v-else-if="exp.status === 'Confirmed'">
        <h1
        class="text-[16px] leading-[24px]"
        :data-test-id="'gensapp-experiencesignup-experiencetext-' + exp.type + '-' + exp.status">
          Your spot is confirmed! See you there!
        </h1>
      </div>
      <div v-else-if="exp.status === 'Unavailable'">
        <h1
        class="text-[16px] leading-[24px] mb-2"
        :data-test-id="'gensapp-experiencesignup-experiencetext-' + exp.type + '-' + exp.status">
          Thank you for your interest in our Service Prep Experience. We are so
          encouraged by the overwhelming response we got!
        </h1>
        <h1 class="text-[16px] leading-[24px] mb-2">
          We have allocated slots in a way that ensures every
          church/organisation has a representative attending the experience.
        </h1>
        <h1 class="text-[16px] leading-[24px]">
          We apologise to those who did not manage to get a slot. Your interest
          and questions are important to us! Do speak to your hosts, they might
          be able to answer you! If not, they will let the global team know and
          we will do our best to connect you with the right people. 👍🏻😃
        </h1>
      </div>
    </div>
    <div v-if="show">
      <p v-if="exp.status" class="text-[16px] leading-[24px] mb-3">
        {{ exp.description1 }}
      </p>
      <div v-if="exp.type === 'CG'">
        <h1 class="text-[16px] leading-[24px] font-aktiv-grotesk-trial mb-2">
          {{ exp.subtitle2 }}
        </h1>
        <div
          class="preview-img-container flex flex-row flex-nowrap overflow-x-auto mb-5"
        >
          <img
            class="preview-img w-[128px] h-[128px] mr-[12px]"
            src="../../../public/assets/images/experience/cg-experience-1.jpg"
          />
          <img
            class="preview-img w-[128px] h-[128px] mr-[12px]"
            src="../../../public/assets/images/experience/cg-experience-2.jpg"
          />
          <img
            class="preview-img w-[128px] h-[128px]"
            src="../../../public/assets/images/experience/cg-experience-3.jpg"
          />
        </div>
      </div>
      <div v-else>
        <p class="text-[16px] leading-[24px]">Ministries:</p>
        <ul class="text-[16px] leading-[24px] mb-4 list-disc relative left-6">
          <li>Worship Team</li>
          <li>Media Team</li>
          <li>Ushers</li>
          <li>Guest Experience Host</li>
          <li>HeartKidz</li>
          <li>Photography</li>
        </ul>
      </div>
    </div>
    <p class="tracking-wide italic text-[13px] mb-6 text-grey-80">
      {{ getSignUpText() }}
    </p>
    <div v-if="exp.status === '' || !exp.status" class="relative">
      <!-- TODO: REMOVE Test checkbox -->
      <input type="checkbox" id="checkbox" v-model="checked"/>
      <label for="checkbox"> Select (CONNECT GROUP EXPERIENCE)</label>
      <button
        :disabled="isSignupClosed(exp.type)"
        class="font-aktiv-grotesk-trial block w-full mx-auto max-w-[295px] rounded-[100px] bg-orange text-white text-[20px] leading-[24px] text-center py-[12px] mb-4"
        :data-event-tracking="signUpButtonDataEventTracking"
        :data-test-id="signUpButtonDataTestId"
        :class="
          isSignupClosed(exp.type)
            ? 'bg-grey-30'
            : 'bg-orange hover:bg-[#F27521]'
        "
        @click="
          getModal(true);
          signed();
        "
      >
        I'm interested
      </button>
      <div
				v-if="exp.type !== 'MD'"
				class="cursor-pointer w-full max-w-[32px] h-[32px] rounded-full bg-[#21252C] flex items-center justify-center mb-[-34px] mx-auto"
				:data-event-tracking="expandButtonDataEventTracking"
				:data-test-id="expandButtonDataTestId"
				:class="rotationClass"
				@click="expandCard()"
			>
				<ChevronDownIcon class="text-white" />
			</div>
    </div>
    <div v-else class="relative">
      <div class="flex justify-center mb-6">
        <button
          v-if="!isEventEnded()"
          class="text-orange text-[16px] leading-[24px] font-aktiv-grotesk-trial"
          :data-event-tracking="cancelDataEventTracking"
          :data-test-id="cancelDataTestId"
          @click="getCancelModal(true, exp.type)"
        >
          Withdraw my interest
        </button>
      </div>
    </div>
  
  <ExperienceModal
    :show="showExpModal"
    @close-modal="closeModal"
    :exp="exp"
  ></ExperienceModal>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import ExperienceModal from "./ExperienceModal.vue";
import CardLabel from "../common/CardLabel.vue";
import moment from "moment";
import ChevronDownIcon from "@/components/icons/ChevronDownIcon.vue";
import axios from "axios";

export default {
  name: "ExpCard",
  components: { ChevronDownIcon, ExperienceModal, CardLabel },
  props: {
    exp: {
      type: Object,
      default: () => {},
    },
    getModal: Function,
    getCancelModal: Function,
    getModal2: Function,
    updateChoice: Function,
    isEdit: Boolean,
    dataTestId: String,
    status: String,
  },
  data() {
    return {
      show: false,
      showExpModal: false,
      signup: false,
    };
  },
  computed: {
    ...mapGetters("signup", [
      "SIGN_UP_CLOSE_TS",
      "EXP_EVENT_END_TS",
    ]),
    ...mapGetters("profile", ["type"]),
    ...mapState("timer", ["currDate"]),
    ...mapState("user", ["token"]),
    ...mapState("signup", ["pending_signups"]),

    isShow() {
      return (
        this.type === "SCS" || (this.type === "GC" && this.exp.type !== "CG")
      );
    },
    rotationClass() {
      return this.show ? "rotate-180" : "";
    },
    selectedStyle() {
      if (!this.isEdit && !this.isSignupClosed() && this.exp.status)
        return "bg-light-orange text-orange";

      if (this.isEdit && this.isSelectedState)
        return "border border-orange bg-light-orange text-orange cursor-pointer";
      return this.isEdit && this.needDisable
        ? "bg-grey-10 text-grey-40"
        : "bg-light-orange text-orange cursor-pointer";
    },
    cardStyle() {
      if (this.isEdit && this.isSelectedState) return "bg-light-orange";
    },
    selectedText() {
      const rankText = {
        1: "1st",
        2: "2nd",
        3: "3rd",
      };
      if (this.isEdit)
        return this.pending_signups[this.exp.type]
          ? rankText[this.pending_signups[this.exp.type]]
          : "Select";
      else 
        return rankText[this.exp.ranking] ? rankText[this.exp.ranking]: '';
    },
    isSelectedState() {
      return this.pending_signups[this.exp.type];
    },
    needDisable() {
      let counter = 0;
      for (const selected in this.pending_signups) {
        if (this.pending_signups[selected]) counter++;
      }
      return counter >= 3;
    },
  },
  created() {
    this.$store.dispatch("signup/fetchDates");
  },
  mounted() {
    const hash = this.$route.hash;

    if (hash === "#" + this.exp.type && hash !== "#MD") this.expandCard();
  },
  methods: {
    ...mapActions("schedule", ["setLinkOpenDate", "setScrollPosition"]),
    ...mapActions("signup", ["setSignupState", "setPendingSignupState"]),
    closeModal() {
      this.showExpModal = false;
    },
    displayExpModal(show) {
      this.showExpModal = show;
    },
    expandCard() {
      this.show = !this.show;
    },
    // async signed() {
    //   const { status, data } = await axios.request(
    //     process.env.VUE_APP_API_URL + "/exps/signup",
    //     {
    //       method: "post",
    //       data: { types: [this.exp.type] },
    //       headers: {
    //         Authorization: `Bearer ${this.token}`,
    //       },
    //     }
    //   );
    //   if (status !== 201) location.reload();
    //   this.setSignupState(data);

    //   this.$emit("sign-up");
    // },
    updateChoice() {
      if (!this.isEdit || (this.needDisable && !this.isSelectedState)) return;
      this.setPendingSignupState(this.exp.type);
    },
    isSignupClosed() {
      const signUpDate = new Date(this.SIGN_UP_CLOSE_TS)
      const currentDate =
        this.currDate === '' ? new Date() : new Date(this.currDate)
      return currentDate.getTime() >= signUpDate.getTime()
    },
    isEventEnded() {
      const currentDate =
        this.currDate === '' ? new Date() : new Date(this.currDate)
      const eventExpiry = new Date(this.EXP_EVENT_END_TS);

      return currentDate.getTime() >= eventExpiry.getTime();
    },
    goToSchedule() {
      const DATE_MAP = {
        CG: "2022-10-21",
        SPE: "2022-10-23",
        MD: "2022-10-22",
      };
      const date = DATE_MAP[this.exp.type];
      const scrollPosition = this.exp.type === "CG" ? 9999 : 0;
      this.setLinkOpenDate(date);
      this.setScrollPosition(scrollPosition);
      this.$router.push({
        path: "/schedule",
      });
    },
    showLocation(exp) {
      if (this.isSignupClosed() && exp.type === "CG") {
        return exp.location !== "To be assigned";
      }
      return true;
    },
    getLabelColor(exp) {
      if (!this.isSignupClosed()) {
        return "lightorange";
      }

      const STATUS_MAP = {
        Unavailable: "grey-20",
        Pending: "pending-yellow",
        Confirmed: "green",
      };
      return STATUS_MAP[exp.status];
    },
    getLabelText(exp) {
      if (!this.isSignupClosed()) {
        return "Submitted Interest";
      }

      return exp.status;
    },
  },
};
</script>

<style scoped lang="scss">
.experience-card {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.preview-img-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.preview-img-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.preview-img {
  flex: 0 0 auto;
}

.accend-orange {
  accent-color: #ff6600;
}
</style>
