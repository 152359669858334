<template>
  <div
    class="flex flex-col items-center justify-center text-grey-100 h-[calc(100vh-350px)] overflow-hidden"
    @wheel.prevent
    @touchmove.prevent
    @scroll.prevent
  >
    <img
      src="../../../public/assets/images/photodrop/experience-photo-icon.png"
    />
    <p v-if="this.tabType === 'personal'" class="mt-[12px] text-[16px] leading-[24px] text-center mt-[24px]">
      {{ $t("copy.photoDropPage.personalTab.subtitle") }}
    </p>
    <p v-else class="mt-[12px] text-[16px] leading-[24px] text-center mt-[24px]">
      {{ $t("copy.photoDropPage.customExperiencesTab.subtitle") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "PhotosExpPhotosEmpty",
  props: {
    tabType: String,
  }
};
</script>
