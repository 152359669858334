<template>
  <master-layout id="gpcr-content-home" v-slot="slotProps">
    <div
      v-show="!loading"
      class="lg:flex-1 lg:overflow-hidden relative font-aktiv-grotesk pb-[118px] md:pb-[68px] lg:pb-[142px] bg-white"
    >
      <div class="w-full">
        <LogoutPopup :show="showModal" @close-modal="closeModal"></LogoutPopup>
        <div
          class="bg-gens-conf-regional-blue-bar bg-cover bg-center pb-[72px] font-aktiv-grotesk-trial text-left text-2xl font-semibold not-italic px-[24px] pt-[32px] lg:px-[56px] align-top flex justify-between"
          :class="{ 'pt-[76px]': isIos() }"
        >
          <div class="lg:hidden inline-flex items-center gap-[12px]">
            <div
              class="profile-icon mt-auto rounded-full w-[56px] h-[56px] bg-lightblue border-white border-[2px] relative"
            >
              <div
                v-if="isDropdownVisible"
                class="bg-black absolute rounded-full w-[51px] h-[51px] opacity-30"
              ></div>
              <button v-if="showProfilePic" @click="toggleDropdown">
                <img :src="profilePicture" class="rounded-full" />
              </button>
              <!-- TODO refactor the dropdown to be 1 single component -->
              <button
                v-if="!showProfilePic"
                class="text-[#FFF] text-[28px] w-[52px] h-[52px] font-family-['aktiv-grotesk-medium'] text-center leading-loose"
                @click="toggleDropdown"
              >
                {{ myName.charAt(0) }}
              </button>
              <div
                v-if="isDropdownVisible"
                v-click-away="removeDropdown"
                class="w-max min-w-[144px] mt-[8px] relative py-[8px] bg-white rounded-[12px] flex-col items-start shadow-lg z-50"
              >
                <router-link to="/profile">
                  <div
                    class="flex pr-[20px] pl-[16px] py-[2px] items-center gap-[8px] self-stretch text-grey-100 text-[16px] z-50"
                    data-event-tracking="gensapp-profile-viewprofile"
                    @click="logEvent('gensapp-profile-viewprofile')"
                  >
                    <img
                      src="../../../../public/assets/icons/profile-circle.svg"
                    />{{ $t("copy.homePage.profileDropdown.profile") }}
                  </div>
                </router-link>
                <router-link to="/gpcr/language">
                  <div
                    class="flex pr-[20px] pl-[16px] py-[2px] items-center gap-[8px] self-stretch text-grey-100 text-[16px] z-50"
                  >
                    <img
                      src="../../../../public/assets/icons/language-icon.svg"
                    />{{ $t("copy.homePage.profileDropdown.language") }}
                  </div>
                </router-link>

                <router-link
                  v-if="confYear === '2024-GR'"
                  to="/gpcr/conference"
                >
                  <div
                    class="flex pr-[20px] pl-[16px] py-[2px] items-center gap-[8px] self-stretch text-grey-100 text-[16px] z-50"
                  >
                    <img
                      src="../../../../public/assets/icons/conference-icon.svg"
                    />{{ $t("copy.homePage.profileDropdown.conference") }}
                  </div>
                </router-link>

                <div
                  class="flex pr-[20px] pl-[16px] py-[2px] items-center gap-[8px] self-stretch text-[#B80A0A] text-[16px]"
                  data-event-tracking="gensapp-home-logout-icon"
                  data-test-id="gensapp-home-logout-icon"
                  @click="openModal()"
                >
                  <img
                    src="../../../../public/assets/icons/log-out-black.svg"
                  />{{ $t("copy.homePage.profileDropdown.logout") }}
                </div>
              </div>
            </div>
            <div class="text-white lg:hidden">{{ myName }}</div>
          </div>
        </div>

        <div class="fixed bottom-[128px] z-50">
          <CheatConsole v-if="isNonProd"></CheatConsole>
        </div>

        <div
          class="grid gap-[29px] xs:gap-[45px] md:gap-[82px] px-[24px] lg:px-[54px] flex-none grow-0 order-none mt-[-60px] justify-center z-10"
          :class="{
            'grid-cols': isSCS && !isAbsentAlumni,
            'grid-cols-GC': !(isSCS && !isAbsentAlumni),
          }"
        >
          <div
            v-if="isSCS && !isAbsentAlumni"
            class="flex flex-col items-center"
          >
            <button
              id="experienceIcon"
              class="cursor-pointer h-[79px] w-[75px] md:h-[110px] md:w-[96px] hover:opacity-90 my-3 md:my-0"
              data-event-tracking="gensapp-home-buttons-experience"
              data-test-id="gensapp-home-buttons-experience"
              @click="goToExperience()"
            >
              <img
                src="../../../../public/assets/images/gpcr/experience-logo.svg"
              />
            </button>
            <div
              class="font-aktiv-grotesk-trial font-semibold not-italic text-base flex items-end text-center text-grey-100"
            >
              {{ $t("copy.homePage.mainLayout.topNav.experiences") }}
            </div>
          </div>
          <div class="flex flex-col items-center">
            <!-- bg-photodrop bg-cover -->
            <button
              id="photodropIcon"
              class="cursor-pointer h-[79px] w-[75px] md:h-[110px] md:w-[96px] hover:opacity-90 my-3 md:my-0"
              data-event-tracking="gensapp-home-buttons-photodrop"
              data-test-id="gensapp-home-buttons-photodrop"
              @click="goToPhotoDrop()"
            >
              <img
                src="../../../../public/assets/images/gpcr/photodrop-logo.svg"
              />
            </button>
            <div
              class="font-aktiv-grotesk-trial font-semibold not-italic text-base flex items-end text-center text-grey-100"
            >
              {{ $t("copy.homePage.mainLayout.topNav.photoDrop") }}
            </div>
          </div>
          <div class="flex flex-col items-center relative">
            <button
              class="cursor-pointer h-[75px] w-[75px] md:h-[110px] md:w-[96px] hover:opacity-90 my-[14px] md:my-0"
              data-event-tracking="gensapp-home-buttons-update"
              data-test-id="gensapp-home-buttons-update"
              @click="goToAnnouncements()"
            >
              <img
                src="../../../../public/assets/images/gpcr/announcements-logo.svg"
              />
            </button>
            <div v-if="hasUnreadAnnouncements()" class="unread-count">
              <p>{{ unreadCount }}</p>
            </div>
            <div
              class="font-aktiv-grotesk-trial font-semibold not-italic text-base flex items-end text-center text-grey-100"
            >
              {{ $t("copy.homePage.mainLayout.topNav.updates") }}
            </div>
          </div>
        </div>
      </div>
      <section class="flex flex-col">
        <div v-for="component in homePageSection" :key="component.name">
          <div
            v-if="
              showCustomComponent(component.name) &&
              showForDelegates(component.isPublic)
            "
            v-html="component.html"
          ></div>
          <CVPCard
            class="w-full"
            v-if="
              cvpObj.show &&
              component.name === 'cvp' &&
              showForDelegates(component.isPublic)
            "
            :link="cvpObj.url"
            :thumbnailImgMobile="cvpObj.thumbnailMobile.url"
            :thumbnailImgTablet="cvpObj.thumbnailTablet.url"
          ></CVPCard>
          <h2
            class="mx-6 md:mx-8 lg:w-[750px] lg:mx-auto mt-12"
            v-if="
              component.name === 'schedule' &&
              showForDelegates(component.isPublic)
            "
          >
            {{ $t("copy.homePage.mainLayout.scheduleSection.title") }}
          </h2>
          <ScheduleSection
            ref="gpcrScheduleSection"
            v-if="
              component.name === 'schedule' &&
              showForDelegates(component.isPublic)
            "
            :events="events"
            :seatings="seatings"
            @init="init"
          ></ScheduleSection>
          <GPCRExperiences
            v-if="
              isSCS &&
              component.name === 'experience' &&
              showForDelegates(component.isPublic)
            "
            ref="experienceSection"
            :getExpAlloc="getExpAlloc()"
            @show-popup="triggerExpPopup($event, slotProps)"
          ></GPCRExperiences>
          <section
            v-if="
              showRegistrationLink &&
              component.name === 'registration' &&
              showForDelegates(component.isPublic)
            "
            class="bg-white"
          >
            <div
              class="relative md:mt-[64px] md:rounded-3xl lg:w-[750px] md:mx-auto"
            >
              <img
                :src="registrationBannerPath"
                class="w-screen md:rounded-3xl lg:w-[750px]"
              />
              <div
                class="absolute bottom-[2rem] left-0 right-0 mx-auto md:mb-16 h-[48px] flex justify-center font-aktiv-grotesk-trial text-[20px] leading-[24px]"
              >
                <in-app-browser
                  :href="`${registrationLink.url}`"
                  target="_blank"
                >
                  <button
                    class="px-5 py-3 bg-lightblue text-white rounded-full text-center"
                    data-event-tracking="gensapp-home-sign-up-gens-2025"
                    data-test-id="gensapp-home-sign-up-gens-2025"
                    @click="logEvent('gensapp-home-sign-up-gens-2025')"
                  >
                    {{ registrationLink.text }}
                  </button>
                </in-app-browser>
              </div>
            </div>
          </section>
          <KickOffSection
            v-if="
              component.name === 'kickoff' &&
              showForDelegates(component.isPublic)
            "
            ref="kickOffSection"
          ></KickOffSection>
          <PlaylistSection
            v-if="
              component.name === 'playlist' &&
              showForDelegates(component.isPublic)
            "
          ></PlaylistSection>
          <section
            v-if="
              component.name === 'link' && showForDelegates(component.isPublic)
            "
            class="flex flex-col gap-5 mx-6 md:mx-8 lg:w-[750px] lg:mx-auto"
          >
            <h2>{{ $t("copy.homePage.mainLayout.usefulLinks.title") }}</h2>
            <MapSection v-if="!isAbsentAlumni"></MapSection>
            <GuidedTourSection
              conferenceType="Regional"
              @reset-tour="resetGuidedTour"
            ></GuidedTourSection>
            <GivingSection></GivingSection>
            <BusScheduleSection></BusScheduleSection>
            <!-- <NonAlumniSurveySection></NonAlumniSurveySection>
        <AlumniSurveySection></AlumniSurveySection> -->
          </section>
        </div>
      </section>
    </div>
    <div
      v-show="loading"
      class="w-screen h-screen flex items-center justify-center"
    >
      <loading-state />
    </div>
  </master-layout>
</template>

<script>
import momentts from "moment-timezone";
import axios from "axios";
import { mapGetters, mapState, mapActions } from "vuex";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar, Style } from "@capacitor/status-bar";
import LoadingState from "@/components/common/LoadingState.vue";
import CVPCard from "@/components/HomePage/CVPCard.vue";
import ScheduleSection from "@/components/HomePage/ScheduleSection.vue";
import MapSection from "@/components/HomePage/MapSection.vue";
import KickOffSection from "@/components/HomePage/KickOffSection.vue";
import PlaylistSection from "@/components/HomePage/PlaylistSection.vue";
import GivingSection from "@/components/HomePage/GivingSection.vue";
// import NonAlumniSurveySection from "@/components/HomePage/NonAlumniSurveySection.vue";
// import AlumniSurveySection from "@/components/HomePage/AlumniSurveySection.vue";
import GuidedTourSection from "@/components/HomePage/GuidedTourSection.vue";
import BusScheduleSection from "@/components/HomePage/BusScheduleSection.vue";
import CheatConsole from "@/components/common/CheatConsole.vue";
import GPCRExperiences from "@/components/HomePage/GPCRExperiences.vue";
import LogoutPopup from "@/components/HomePage/LogoutPopup.vue";
import { isIosNativeOnly, isNativeNotWeb } from "@/utils/platform-check";
import { getCurrentLocale } from "@/utils/deviceLocale";
import store from "@/store/index";
import { nextTick } from "vue";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { pushInit } from "@/utils/push-notification";
import { FCM } from "@capacitor-community/fcm";
import { mixin as clickaway } from "vue3-click-away";

export default {
  name: "IndexPage",
  mixins: [clickaway],
  components: {
    // Announcements,
    ScheduleSection,
    MapSection,
    CVPCard,
    KickOffSection,
    PlaylistSection,
    GivingSection,
    GuidedTourSection,
    CheatConsole,
    LoadingState,
    LogoutPopup,
    GPCRExperiences,
    BusScheduleSection,
  },
  layout: "default",
  data() {
    return {
      events: [],
      cvpObj: {},
      homePageSection: [],
      isSCS: true,
      seatings: [],
      showModal: false,
      tour: null,
      isDropdownVisible: false,
    };
  },
  computed: {
    ...mapGetters("timer", ["currDate"]),
    ...mapGetters("profile", ["name", "type", "isAbsentAlumni"]),
    ...mapState("cms", ["registrationLink", "loading"]),
    ...mapState("user", ["token"]),
    ...mapState("announcements", ["unreadCount"]),
    ...mapState("tour", ["tour"]),
    ...mapState("profile", [
      "hasCompletedGPCRHomepageTour",
      "channels",
      "confYear",
      "currentYear",
    ]),
    ...mapState("signup", ["signups", "pending_signups", "backFromSummary"]),
    ...mapGetters("signup", ["SIGN_UP_CLOSE_TS", "EXP_ALLOC_TS"]),
    ...mapState("social", ["profilePicture"]),
    ...mapState("social", {
      displayName: "name",
    }),

    isNonProd() {
      return process.env.VUE_APP_NODE_ENV !== "production";
    },
    isSCS() {
      if (this.type === "GC") {
        this.isSCS = false;
      }
      return this.isSCS;
    },
    registrationBannerPath() {
      return require("../../../../public/assets/images/home/" +
        this.$t("copy.homePage.mainLayout.registration.bannerName"));
    },
    showRegistrationLink() {
      return this.registrationLink.show;
    },
    myName() {
      return this.displayName ? this.displayName : this.name;
    },
    showProfilePic() {
      return this.profilePicture;
    },
    closestDateStr() {
      momentts.tz.setDefault("Asia/Singapore");
      let displayStr =
        this.currDate === ""
          ? momentts().format("YYYY-MM-DD")
          : momentts(this.currDate).format("YYYY-MM-DD");
      const dateFirstStr = "2024-11-15";
      const dateLastStr = "2024-11-17";
      const dateFirstStrGC = "2024-11-16";
      const dateLastStrGC = "2024-11-17";

      const display = new Date(displayStr);
      const dateFirst = new Date(dateFirstStr);
      const dateLast = new Date(dateLastStr);
      const dateFirstGC = new Date(dateFirstStrGC);
      const dateLastGC = new Date(dateLastStrGC);

      if (display.getTime() < dateFirst.getTime()) displayStr = dateFirstStr;
      if (display.getTime() > dateLast.getTime()) displayStr = dateLastStr;
      if (display.getTime() < dateFirstGC.getTime() && this.type === "GC")
        displayStr = dateFirstStrGC;
      if (display.getTime() > dateLastGC.getTime() && this.type === "GC")
        displayStr = dateLastStrGC;
      return displayStr;
    },
    appGuidedTour() {
      return {
        homeTourTextStep0SCS: this.$t(
          "copy.homePage.mainLayout.appGuidedTour.homeTourTextStep0SCS"
        ),
        homeTourTextStep0GC: this.$t(
          "copy.homePage.mainLayout.appGuidedTour.homeTourTextStep0GC"
        ),
        homeTourTextStep1: this.$t(
          "copy.homePage.mainLayout.appGuidedTour.homeTourTextStep1"
        ),
        homeTourTextStep2: this.$t(
          "copy.homePage.mainLayout.appGuidedTour.homeTourTextStep2"
        ),
        homeTourTextStep3: this.$t(
          "copy.homePage.mainLayout.appGuidedTour.homeTourTextStep3"
        ),
        homeTourTextStep4: this.$t(
          "copy.homePage.mainLayout.appGuidedTour.homeTourTextStep4"
        ),
        homeTourTextStep5: this.$t(
          "copy.homePage.mainLayout.appGuidedTour.homeTourTextStep5"
        ),
      };
    },
  },
  watch: {
    currDate(newCurrDate, oldCurrDate) {
      this.init();
    },
  },

  async created() {
    await this.$store.dispatch("signup/fetchSignup");
    await this.$store.dispatch("signup/fetchDates");
    await this.$store.dispatch("cms/fetchAnnouncements");
    await SplashScreen.hide();
  },
  async ionViewDidEnter() {
    const locale = await getCurrentLocale("Regional");
    if (isIosNativeOnly()) {
      await StatusBar.setStyle({ style: Style.Dark });
    }
    setTimeout(() => {
      if (!this.hasCompletedGPCRHomepageTour) {
        if (this.type === "SCS") {
          if (isNativeNotWeb()) {
            this.loadSCSNativeTour();
          } else {
            this.loadSCSTour();
          }
        }
      }
      if (isNativeNotWeb()) {
        // pushInit();
        // if (this.channels) {
        //   for (const key in this.channels) {
        //     console.log("subscribeTo FCM with: " + this.channels[key]);
        //     FCM.subscribeTo({ topic: this.channels[key] });
        //   }
        // }
      }
    }, 100);

    // if (this.$refs[`announcementSection`])
    //   this.$refs[`announcementSection`].update();
    // this.updateExperienceCarousel();

    // this.setLoading(true);
    this.setLoading(true);
    await this.init();
    await this.$store.dispatch("social/fetchSocialProfile");
    await this.$store.dispatch("signup/fetchSignup");
    await this.$store.dispatch("signup/fetchDates");
    await this.$store.dispatch("announcements/refreshAnnouncements", {
      locale: locale,
      conferenceType: "Regional",
    });
    await this.$store.dispatch("announcements/fetchUnreadCount", "Regional");
    await this.$store.dispatch("cms/fetchRegistrationLink", {
      locale: locale,
      conferenceType: "Regional",
    });
    this.fetchHomePage();
    this.fetchCVP();

    if (this.$refs.gpcrScheduleSection) {
      this.$refs.gpcrScheduleSection[0].setClosestEvent();
    }    

    // this.seatings = await this.getSeatings();
    this.$nextTick(() => {
      this.setLoading(false);
    });
    FirebaseAnalytics.setScreenName({
      screenName: "home",
      nameOverride: "HomePage",
    });
  },
  // ionViewDidLeave() {
  //   if (this.$refs.kickOffSection) {
  //     this.$refs.kickOffSection[0]?.pause();
  //   }
  // },
  methods: {
    ...mapActions("tour", ["setTour"]),
    ...mapActions("cms", ["setLoading"]),
    async getSeatings() {
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/attendance/get-user-attendance/",
        {
          params: { serviceIds: "0,1,2,3,4,999" },
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      if (status === 200) return data.delegateSeatings;
      return [];
    },
    logEvent(event) {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: event,
          page: "home",
        },
      });
    },
    closeModal() {
      this.showModal = false;
    },
    openModal() {
      this.isDropdownVisible = false;
      this.showModal = true;
    },
    goToExperience() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "gensapp-home-buttons-experience",
          page: "home",
        },
      });
      this.$router.push("/gpcr/experience");
    },
    goToPhotoDrop() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "gensapp-home-buttons-photodrop",
          page: "home",
        },
      });
      this.$router.push("/gpcr/photodrop");
    },
    goToExperienceSignup() {
      this.$router.push("/experience");
    },
    goToAnnouncements() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "gensapp-home-buttons-update",
          page: "home",
        },
      });
      this.$router.push("/gpcr/announcement");
    },
    hasUnreadAnnouncements() {
      return this.unreadCount > 0;
    },
    async init() {
      await this.fetchEvent(this.closestDateStr);
    },
    async fetchEvent(dateStr) {
      const { data } = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/schedules/" + dateStr,
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
          params: {
            locale: await getCurrentLocale("Regional"),
            conferenceType: "Regional",
          },
        }
      );
      this.events = data;
    },
    async fetchCVP() {
      const { data } = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/gc-app-cvp",
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      this.cvpObj = data;
    },
    async fetchHomePage() {
      const { data } = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/gc-app-homepage-sections",
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
          params: {
            locale: await getCurrentLocale("Regional"),
            conferenceType: "Regional",
          },
        }
      );
      this.homePageSection = data;
    },
    addToBookmark() {
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
      const isChrome = /Chrome/i.test(navigator.userAgent);
      const isSafari = /Safari/i.test(navigator.userAgent);
      const isFirefox = /Firefox/i.test(navigator.userAgent);
      const isEdge = /Edge/i.test(navigator.userAgent);

      if (isMobile) {
        if (isChrome) {
          return `
                    <span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Add to Home Screen</span>
                    <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Add this app to your home screen for quick and easy access throughout the conference.</span>
                    <br>
                    <b>Step 1:</b> <i>Tap on the share button on the bottom navigation bar</i>
                    <img src="../../../assets/images/guided-tour/mobile/chrome/navbar.png" alt="Add to Home Screen Step 1" />
                    <br>
                    <b>Step 2:</b> <i>Click on "More tools"</i>
                    <img src="../../../assets/images/guided-tour/mobile/chrome/tools.png" alt="Click on More tools">
                    <br>
                    <b>Step 3:</b> <i>Click on "Create shortcut..."</i>
                    <img src="../../../assets/images/guided-tour/mobile/chrome/shortcut.png" alt="Create shortcut">
                  `;
        } else if (isSafari) {
          return `
                    <div>
                      <span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Add to Home Screen</span>
                      <br>
                      <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Add this app to your home screen for quick and easy access throughout the conference.</span>
                      <br>
                      <b>Step 1:</b> <i>Tap on the share button on the bottom navigation bar</i>
                      <img src="../../../assets/images/guided-tour/mobile/safari/navbar.png" alt="Step 1" />
                      <br>
                      <b>Step 2:</b> <i>Click on “Add to Home Screen”</i>
                      <img src="../../../assets/images/guided-tour/mobile/safari/bookmark.png" alt="Step 2" />
                    </div>
                  `;
        }
      } else {
        if (isFirefox) {
          return `
                    <div>
                      <span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Bookmark this app</span>
                      <br>
                      <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Bookmark this app for quick and easy access throughout the conference.</span>
                      <br>
                      <b>Step 1:</b> <i>Click on the star at the right side of the search bar</i>
                      <img src="../../../assets/images/guided-tour/desktop/firefox/navbar.png" alt="Step 1" />
                      <br>
                      <b>Step 2:</b> <i>Click on Save to add bookmark into your browser</i>
                      <img src="../../../assets/images/guided-tour/desktop/firefox/bookmark.png" alt="Step 2" />
                    </div>
                  `;
        } else if (isEdge) {
          return `
                    <div>
                      <span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Bookmark this app</span>
                      <br>
                      <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Bookmark this app for quick and easy access throughout the conference.</span>
                      <br>
                      <b>Step 1:</b> <i>Click on the star at the right side of the search bar</i>
                      <img src="../../../assets/images/guided-tour/desktop/edge/navbar.png" alt="Step 1" />
                      <br>
                      <b>Step 2:</b> <i>Click on Done to add bookmark into your browser</i>
                      <img src="../../../assets/images/guided-tour/desktop/edge/bookmark.png" alt="Step 2" />
                    </div>
                  `;
        } else if (isSafari) {
          return `
                    <div>
                      <span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Bookmark this app</span>
                      <br>
                      <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Bookmark this app for quick and easy access throughout the conference.</span>
                      <br>
                      <b>Step 1:</b> <i>Click on the icon on the top right corner of the browser</i>
                      <img src="../../../assets/images/guided-tour/desktop/safari/navbar.png" alt="Step 1" />
                      <br>
                      <b>Step 2:</b> <i>Click on Add Bookmark</i>
                      <img src="../../../assets/images/guided-tour/desktop/safari/bookmark.png" alt="Step 2" />
                      <br>
                      <b>Step 3:</b> <i>Click on Add to add bookmark into your browser"</i>
                      <img src="../../../assets/images/guided-tour/desktop/safari/AddBookmark.png" alt="Step 3" />
                    </div>
                  `;
        } else if (isChrome) {
          return `
                    <div>
                      <span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Bookmark this app</span>
                      <br>
                      <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Bookmark this app for quick and easy access throughout the conference.</span>
                      <br>
                      <b>Step 1:</b> <i>Click on the star at the right side of the search bar</i>
                      <img src="../../../assets/images/guided-tour/desktop/chrome/navbar.png" alt="Step 1" />
                      <br>
                      <b>Step 2:</b> <i>Click on Done to add bookmark into your browser</i>
                      <img src="../../../assets/images/guided-tour/desktop/chrome/bookmark.png" alt="Step 2" />
                      <br>
                      <b>Step 3:</b> <i>Drag and drop the highlighted URL into your desktop screen"</i>
                    </div>
                  `;
        }
      }
      // If no condition matches, return the default image URL
      return "../../assets/images/guided-tour/mobile/chrome/";
    },

    step3back() {
      console.log("in step3back");
      this.tour.back();
      this.$router.push("/gpcr/home");
      console.log(this.tour.getCurrentStep().id);
      const payload = {
        hasCompletedHomepageTour: true,
        conferenceType: "Regional",
      };
      this.$store.dispatch("profile/setHomeTourStatus", payload);
    },
    isSignupClosed() {
      // needs refectoring (we are just using CG signup close as of now)
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      const signUpExpiry = new Date(this.SIGN_UP_CLOSE_TS);
      // console.log("signUpExpiry: " + signUpExpiry.getTime());
      return currentDate.getTime() >= signUpExpiry.getTime();
    },
    getExpAlloc() {
      momentts.tz.setDefault("Asia/Singapore");
      const dayStr = momentts(this.EXP_ALLOC_TS).format("dddd, D MMMM");
      const timeStr = momentts(this.EXP_ALLOC_TS).format("ha");
      return dayStr + " at " + timeStr;
    },
    hasSignupBefore() {
      let result = false;
      for (const expType in this.signups) {
        result = result || Boolean(this.signups[expType]);
      }
      return result;
    },
    backToTop() {
      const elem = document.getElementById("gpcr-content-home");
      elem.scrollToTop(1000);
    },
    showCustomComponent(name) {
      // added bus button because is converting from cms to hardcoded for analytics
      return !["cvp", "schedule", "experience", "links", "bus-button"].includes(
        name
      );
    },
    showForDelegates(isPublic) {
      return isPublic || !this.isAbsentAlumni;
    },
    getProfileElement() {
      return window.innerWidth > 1024 ? ".side-profile-icon" : ".profile-icon";
    },
    resetGuidedTour() {
      this.backToTop();

      setTimeout(() => {
        if (!this.hasCompletedHomepageTour) {
          if (this.type === "SCS") {
            if (isNativeNotWeb()) {
              this.loadSCSNoSignUpNativeTour();
            } else {
              this.loadSCSNoSignUpTour();
            }
          }
        }
      }, 100);
    },
    startHomeTour() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "guided-tour-start-home",
          page: "home",
        },
      });
      this.tour.next();
    },
    async skipHomeTour() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "guided-tour-skip-home",
          page: "home",
        },
      });
      const payload = {
        hasCompletedHomepageTour: true,
        conferenceType: "Regional",
      };
      await this.$store.dispatch("profile/setHomeTourStatus", payload);
      await this.$store.dispatch("tour/setTour", null);
      this.backToTop();
      this.tour.cancel();
    },
    async endHomeTour() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "guided-tour-end-home",
          page: "home",
        },
      });
      const payload = {
        hasCompletedHomepageTour: true,
        conferenceType: "Regional",
      };
      await this.$store.dispatch("profile/setHomeTourStatus", payload);
      await this.$store.dispatch("tour/setTour", null);
      this.backToTop();
      this.tour.cancel();
    },
    loadSCSTour() {
      const translatedStepText = this.$t(
        "copy.homePage.mainLayout.appGuidedTour.stepPill.stepText"
      );
      const translatedOfText = this.$t(
        "copy.homePage.mainLayout.appGuidedTour.stepPill.ofText"
      );
      const skipTourText = this.$t(
        "copy.homePage.mainLayout.appGuidedTour.skipTour"
      );
      document.documentElement.style.setProperty(
        "--skip-tour-text",
        `'${skipTourText}'`
      );
      const checkLocale = this.$i18n.locale;
      this.tour = this.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
          scrollTo: true,
          when: {
            show() {
              const currentStep = this.tour.getCurrentStep();
              const currentStepElement = currentStep?.getElement();
              const header =
                currentStepElement?.querySelector(".shepherd-header");
              const progress = document.createElement("span");
              progress.style["border-radius"] = "100px";
              progress.style["background"] = "#FFD900";
              progress.style["font-size"] = "12px";
              progress.style["font-weight"] = "500";
              progress.style["line-height"] = "20px";
              progress.style["color"] = "#2B2E79";
              progress.style["font-family"] = "Arial";
              progress.style["width"] = checkLocale == "th" ? "104px" : "77px";
              progress.style["text-align"] = "center";
              progress.className = "shepherd-progress";
              progress.innerText = `${translatedStepText} ${this.tour.steps.indexOf(
                currentStep
              )} ${translatedOfText} ${this.tour.steps.length - 1}`;
              header?.insertBefore(
                progress,
                currentStepElement?.querySelector(".shepherd-cancel-icon")
              );
            },
            cancel() {
              const payload = {
                hasCompletedHomepageTour: true,
                conferenceType: "Regional",
              };
              store.dispatch("profile/setHomeTourStatus", payload);
              // store.dispatch("tour/setTour", null);
            },
          },
          keyboardNavigation: false,
        },
      });
      const steps = [
        {
          id: "welcome",
          attachTo: {
            on: "top",
          },
          scrollTo: { behavior: "smooth", block: "start" },
          canClickTarget: false,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.skipButton"
              ),
              action: this.skipHomeTour,
              secondary: true,
            },
            {
              text: this.$t("copy.homePage.mainLayout.appGuidedTour.startTour"),
              action: this.startHomeTour,
            },
          ],
          text: this.appGuidedTour.homeTourTextStep0SCS,
          when: {
            show() {
              const currentStep = this.tour.getCurrentStep();
              const currentStepElement = currentStep?.getElement();
              const header =
                currentStepElement?.querySelector(".shepherd-content");
              header.classList.add("welcome-bg");
            },
          },
        },
        {
          id: "schedule",
          attachTo: {
            element: ".schedule-section",
            on: "bottom",
          },
          scrollTo: { behavior: "smooth", block: "start" },
          canClickTarget: false,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.nextButton"
              ),
              action: this.tour.next,
            },
          ],
          highlightClass: "highlight",
          text: this.appGuidedTour.homeTourTextStep1,
          cancelIcon: {
            enabled: true,
          },
        },
        {
          id: "experiences",
          attachTo: {
            element: "button#experienceIcon",
            on: "bottom",
          },
          scrollTo: { behavior: "smooth", block: "center" },
          canClickTarget: false,
          text: this.appGuidedTour.homeTourTextStep2,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.nextButton"
              ),
              action: this.tour.next,
            },
          ],
          cancelIcon: {
            enabled: true,
          },
        },
        {
          id: "photodrop",
          attachTo: {
            element: "button#photodropIcon",
            on: "bottom",
          },
          scrollTo: { behavior: "smooth", block: "center" },
          canClickTarget: false,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.nextButton"
              ),
              action: this.tour.next,
            },
          ],
          highlightClass: "highlight",
          text: this.appGuidedTour.homeTourTextStep3,
          cancelIcon: {
            enabled: true,
          },
        },
        // {
        //   id: "prewatch",
        //   attachTo: {
        //     element: "div#pre-watch",
        //     on: "bottom",
        //   },
        //   scrollTo: { behavior: "smooth", block: "center" },
        //   canClickTarget: false,
        //   buttons: [
        //     {
        //       text: this.$t(
        //         "copy.homePage.mainLayout.appGuidedTour.backButton"
        //       ),
        //       action: this.tour.back,
        //       secondary: true,
        //     },
        //     {
        //       text: this.$t(
        //         "copy.homePage.mainLayout.appGuidedTour.nextButton"
        //       ),
        //       action: this.tour.next,
        //     },
        //   ],
        //   highlightClass: "highlight",
        //   text: this.appGuidedTour.homeTourTextStep4,
        //   cancelIcon: {
        //     enabled: true,
        //   },
        // },
        {
          id: "profile",
          attachTo: {
            element: this.getProfileElement(),
            on: "bottom",
          },
          scrollTo: { behavior: "smooth", block: "start" },
          canClickTarget: false,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.nextButton"
              ),
              action: this.tour.next,
            },
          ],
          highlightClass: "highlight",
          text: this.appGuidedTour.homeTourTextStep5,
          cancelIcon: {
            enabled: true,
          },
        },
        {
          id: "postRegister",
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t("copy.homePage.mainLayout.appGuidedTour.endButton"),
              action: this.endHomeTour,
            },
          ],
          highlightClass: "highlight",
          canClickTarget: false,
          text: this.addToBookmark(),
          cancelIcon: {
            enabled: true,
            // label: skipTourText,
          },
        },
      ];
      this.tour.addSteps(steps);
      this.tour.start();
      this.setTour(this.tour);
    },
    loadSCSNativeTour() {
      const translatedStepText = this.$t(
        "copy.homePage.mainLayout.appGuidedTour.stepPill.stepText"
      );
      const translatedOfText = this.$t(
        "copy.homePage.mainLayout.appGuidedTour.stepPill.ofText"
      );
      const skipTourText = this.$t(
        "copy.homePage.mainLayout.appGuidedTour.skipTour"
      );
      const checkLocale = this.$i18n.locale;
      document.documentElement.style.setProperty(
        "--skip-tour-text",
        `'${skipTourText}'`
      );
      this.tour = this.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
          scrollTo: true,
          when: {
            show() {
              const currentStep = this.tour.getCurrentStep();
              const currentStepElement = currentStep?.getElement();
              const header =
                currentStepElement?.querySelector(".shepherd-header");
              const progress = document.createElement("span");
              progress.style["border-radius"] = "100px";
              progress.style["background"] = "#FFD900";
              progress.style["font-size"] = "12px";
              progress.style["font-weight"] = "500";
              progress.style["line-height"] = "20px";
              progress.style["color"] = "#2B2E79";
              progress.style["font-family"] = "Arial";
              progress.style["width"] = checkLocale == "th" ? "104px" : "77px";
              progress.style["text-align"] = "center";
              progress.className = "shepherd-progress";
              progress.innerText = `${translatedStepText} ${this.tour.steps.indexOf(
                currentStep
              )} ${translatedOfText} ${this.tour.steps.length - 1}`;
              header?.insertBefore(
                progress,
                currentStepElement?.querySelector(".shepherd-cancel-icon")
              );
            },
            cancel() {
              const payload = {
                hasCompletedHomepageTour: true,
                conferenceType: "Regional",
              };
              store.dispatch("profile/setHomeTourStatus", payload);
              // store.dispatch("tour/setTour", null);
            },
          },
          keyboardNavigation: false,
        },
      });
      const steps = [
        {
          id: "welcome",
          attachTo: {
            on: "top",
          },
          scrollTo: { behavior: "smooth", block: "start" },
          canClickTarget: false,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.skipButton"
              ),
              action: this.skipHomeTour,
              secondary: true,
            },
            {
              text: this.$t("copy.homePage.mainLayout.appGuidedTour.startTour"),
              action: this.startHomeTour,
            },
          ],
          text: this.appGuidedTour.homeTourTextStep0SCS,
          when: {
            show() {
              const currentStep = this.tour.getCurrentStep();
              const currentStepElement = currentStep?.getElement();
              const header =
                currentStepElement?.querySelector(".shepherd-content");
              header.classList.add("welcome-bg");
            },
          },
        },
        {
          id: "schedule",
          attachTo: {
            element: ".schedule-section",
            on: "bottom",
          },
          scrollTo: { behavior: "smooth", block: "center" },
          canClickTarget: false,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.nextButton"
              ),
              action: this.tour.next,
            },
          ],
          highlightClass: "highlight",
          text: this.appGuidedTour.homeTourTextStep1,
          cancelIcon: {
            enabled: true,
            label: skipTourText,
          },
        },
        {
          id: "experiences",
          attachTo: {
            element: "button#experienceIcon",
            on: "bottom",
          },
          scrollTo: { behavior: "smooth", block: "center" },
          canClickTarget: false,
          text: this.appGuidedTour.homeTourTextStep2,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.nextButton"
              ),
              action: this.tour.next,
            },
          ],
          cancelIcon: {
            enabled: true,
            label: skipTourText,
          },
        },
        {
          id: "photodrop",
          attachTo: {
            element: "button#photodropIcon",
            on: "bottom",
          },
          scrollTo: { behavior: "smooth", block: "center" },
          canClickTarget: false,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.nextButton"
              ),
              action: this.tour.next,
            },
          ],
          highlightClass: "highlight",
          text: this.appGuidedTour.homeTourTextStep3,
          cancelIcon: {
            enabled: true,
            label: skipTourText,
          },
        },
        // {
        //   id: "prewatch",
        //   attachTo: {
        //     element: "div#pre-watch",
        //     on: "bottom",
        //   },
        //   scrollTo: { behavior: "smooth", block: "center" },
        //   canClickTarget: false,
        //   buttons: [
        //     {
        //       text: this.$t(
        //         "copy.homePage.mainLayout.appGuidedTour.backButton"
        //       ),
        //       action: this.tour.back,
        //       secondary: true,
        //     },
        //     {
        //       text: this.$t(
        //         "copy.homePage.mainLayout.appGuidedTour.nextButton"
        //       ),
        //       action: this.tour.next,
        //     },
        //   ],
        //   highlightClass: "highlight",
        //   text: this.appGuidedTour.homeTourTextStep4,
        //   cancelIcon: {
        //     enabled: true,
        //     label: skipTourText,
        //   },
        // },
        {
          id: "profile",
          attachTo: {
            element: this.getProfileElement(),
            on: "bottom",
          },
          scrollTo: { behavior: "smooth", block: "start" },
          canClickTarget: false,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t("copy.homePage.mainLayout.appGuidedTour.endButton"),
              action: this.endHomeTour,
            },
          ],
          highlightClass: "highlight",
          text: this.appGuidedTour.homeTourTextStep5,
          cancelIcon: {
            enabled: true,
            label: skipTourText,
          },
        },
      ];
      this.tour.addSteps(steps);
      this.tour.start();
      this.setTour(this.tour);
    },

    loadSCSNoSignUpTour() {
      const translatedStepText = this.$t(
        "copy.homePage.mainLayout.appGuidedTour.stepPill.stepText"
      );
      const translatedOfText = this.$t(
        "copy.homePage.mainLayout.appGuidedTour.stepPill.ofText"
      );
      const skipTourText = this.$t(
        "copy.homePage.mainLayout.appGuidedTour.skipTour"
      );
      document.documentElement.style.setProperty(
        "--skip-tour-text",
        `'${skipTourText}'`
      );
      const checkLocale = this.$i18n.locale;
      this.tour = this.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
          scrollTo: true,
          when: {
            show() {
              const currentStep = this.tour.getCurrentStep();
              const currentStepElement = currentStep?.getElement();
              const header =
                currentStepElement?.querySelector(".shepherd-header");
              const progress = document.createElement("span");
              progress.style["border-radius"] = "100px";
              progress.style["background"] = "#FFD900";
              progress.style["font-size"] = "12px";
              progress.style["font-weight"] = "500";
              progress.style["line-height"] = "20px";
              progress.style["color"] = "#2B2E79";
              progress.style["font-family"] = "Arial";
              progress.style["width"] = checkLocale == "th" ? "104px" : "77px";
              progress.style["text-align"] = "center";
              progress.className = "shepherd-progress";
              progress.innerText = `${translatedStepText} ${this.tour.steps.indexOf(
                currentStep
              )} ${translatedOfText} ${this.tour.steps.length - 1}`;
              header?.insertBefore(
                progress,
                currentStepElement?.querySelector(".shepherd-cancel-icon")
              );
            },
            cancel() {
              const payload = {
                hasCompletedHomepageTour: true,
                conferenceType: "Regional",
              };
              store.dispatch("profile/setHomeTourStatus", payload);
              store.dispatch("tour/setTour", null);
            },
          },
          keyboardNavigation: false,
        },
      });
      const steps = [
        {
          id: "welcome",
          attachTo: {
            on: "top",
          },
          scrollTo: { behavior: "smooth", block: "start" },
          canClickTarget: false,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.skipButton"
              ),
              action: this.skipHomeTour,
              secondary: true,
            },
            {
              text: this.$t("copy.homePage.mainLayout.appGuidedTour.startTour"),
              action: this.startHomeTour,
            },
          ],
          text: this.appGuidedTour.homeTourTextStep0SCS,
          when: {
            show() {
              const currentStep = this.tour.getCurrentStep();
              const currentStepElement = currentStep?.getElement();
              const header =
                currentStepElement?.querySelector(".shepherd-content");
              header.classList.add("welcome-bg");
            },
          },
        },
        {
          id: "schedule",
          attachTo: {
            element: ".schedule-section",
            on: "bottom",
          },
          scrollTo: { behavior: "smooth", block: "start" },
          canClickTarget: false,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.nextButton"
              ),
              action: this.tour.next,
            },
          ],
          highlightClass: "highlight",
          text: this.appGuidedTour.homeTourTextStep1,
          cancelIcon: {
            enabled: true,
            label: skipTourText,
          },
        },
        {
          id: "experiences",
          attachTo: {
            element: "button#experienceIcon",
            on: "bottom",
          },
          scrollTo: { behavior: "smooth", block: "center" },
          canClickTarget: false,
          text: this.appGuidedTour.homeTourTextStep2,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.nextButton"
              ),
              action: this.tour.next,
            },
          ],
          cancelIcon: {
            enabled: true,
            label: skipTourText,
          },
        },
        {
          id: "photodrop",
          attachTo: {
            element: "button#photodropIcon",
            on: "bottom",
          },
          scrollTo: { behavior: "smooth", block: "center" },
          canClickTarget: false,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.nextButton"
              ),
              action: this.tour.next,
            },
          ],
          highlightClass: "highlight",
          text: this.appGuidedTour.homeTourTextStep3,
          cancelIcon: {
            enabled: true,
            label: skipTourText,
          },
        },
        // {
        //   id: "prewatch",
        //   attachTo: {
        //     element: "div#pre-watch",
        //     on: "bottom",
        //   },
        //   scrollTo: { behavior: "smooth", block: "center" },
        //   canClickTarget: false,
        //   buttons: [
        //     {
        //       text: this.$t(
        //         "copy.homePage.mainLayout.appGuidedTour.backButton"
        //       ),
        //       action: this.tour.back,
        //       secondary: true,
        //     },
        //     {
        //       text: this.$t(
        //         "copy.homePage.mainLayout.appGuidedTour.nextButton"
        //       ),
        //       action: this.tour.next,
        //     },
        //   ],
        //   highlightClass: "highlight",
        //   text: this.appGuidedTour.homeTourTextStep4,
        //   cancelIcon: {
        //     enabled: true,
        //     label: skipTourText,
        //   },
        // },
        {
          id: "profile",
          attachTo: {
            element: this.getProfileElement(),
            on: "bottom",
          },
          scrollTo: { behavior: "smooth", block: "start" },
          canClickTarget: false,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.nextButton"
              ),
              action: this.tour.next,
            },
          ],
          highlightClass: "highlight",
          text: this.appGuidedTour.homeTourTextStep5,
          cancelIcon: {
            enabled: true,
            label: skipTourText,
          },
        },
        {
          id: "postRegister",
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t("copy.homePage.mainLayout.appGuidedTour.endButton"),
              action: this.endHomeTour,
            },
          ],
          highlightClass: "highlight",
          canClickTarget: false,
          text: this.addToBookmark(),
          cancelIcon: {
            enabled: true,
            label: skipTourText,
          },
        },
      ];
      this.tour.addSteps(steps);
      this.tour.start();
      this.setTour(this.tour);
    },

    loadSCSNoSignUpNativeTour() {
      const translatedStepText = this.$t(
        "copy.homePage.mainLayout.appGuidedTour.stepPill.stepText"
      );
      const translatedOfText = this.$t(
        "copy.homePage.mainLayout.appGuidedTour.stepPill.ofText"
      );
      const skipTourText = this.$t(
        "copy.homePage.mainLayout.appGuidedTour.skipTour"
      );
      document.documentElement.style.setProperty(
        "--skip-tour-text",
        `'${skipTourText}'`
      );
      const checkLocale = this.$i18n.locale;
      this.tour = this.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
          scrollTo: true,
          when: {
            show() {
              const currentStep = this.tour.getCurrentStep();
              const currentStepElement = currentStep?.getElement();
              const header =
                currentStepElement?.querySelector(".shepherd-header");
              const progress = document.createElement("span");
              progress.style["border-radius"] = "100px";
              progress.style["background"] = "#FFD900";
              progress.style["font-size"] = "12px";
              progress.style["font-weight"] = "500";
              progress.style["line-height"] = "20px";
              progress.style["color"] = "#2B2E79";
              progress.style["font-family"] = "Arial";
              progress.style["width"] = checkLocale == "th" ? "104px" : "77px";
              progress.style["text-align"] = "center";
              progress.className = "shepherd-progress";
              progress.innerText = `${translatedStepText} ${this.tour.steps.indexOf(
                currentStep
              )} ${translatedOfText} ${this.tour.steps.length - 1}`;
              header?.insertBefore(
                progress,
                currentStepElement?.querySelector(".shepherd-cancel-icon")
              );
            },
            cancel() {
              const payload = {
                hasCompletedHomepageTour: true,
                conferenceType: "Regional",
              };
              store.dispatch("profile/setHomeTourStatus", payload);
              store.dispatch("tour/setTour", null);
            },
          },
          keyboardNavigation: false,
        },
      });
      const steps = [
        {
          id: "welcome",
          attachTo: {
            on: "top",
          },
          scrollTo: { behavior: "smooth", block: "start" },
          canClickTarget: false,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.skipButton"
              ),
              action: this.skipHomeTour,
              secondary: true,
            },
            {
              text: this.$t("copy.homePage.mainLayout.appGuidedTour.startTour"),
              action: this.startHomeTour,
            },
          ],
          text: this.appGuidedTour.homeTourTextStep0SCS,
          when: {
            show() {
              const currentStep = this.tour.getCurrentStep();
              const currentStepElement = currentStep?.getElement();
              const header =
                currentStepElement?.querySelector(".shepherd-content");
              header.classList.add("welcome-bg");
            },
          },
        },
        {
          id: "schedule",
          attachTo: {
            element: ".schedule-section",
            on: "bottom",
          },
          scrollTo: { behavior: "smooth", block: "center" },
          canClickTarget: false,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.nextButton"
              ),
              action: this.tour.next,
            },
          ],
          highlightClass: "highlight",
          text: this.appGuidedTour.homeTourTextStep1,
          cancelIcon: {
            enabled: true,
            label: skipTourText,
          },
        },
        {
          id: "experiences",
          attachTo: {
            element: "button#experienceIcon",
            on: "bottom",
          },
          scrollTo: { behavior: "smooth", block: "center" },
          canClickTarget: false,
          text: this.appGuidedTour.homeTourTextStep2,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.nextButton"
              ),
              action: this.tour.next,
            },
          ],
          cancelIcon: {
            enabled: true,
            label: skipTourText,
          },
        },
        {
          id: "photodrop",
          attachTo: {
            element: "button#photodropIcon",
            on: "bottom",
          },
          scrollTo: { behavior: "smooth", block: "center" },
          canClickTarget: false,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.nextButton"
              ),
              action: this.tour.next,
            },
          ],
          highlightClass: "highlight",
          text: this.appGuidedTour.homeTourTextStep3,
          cancelIcon: {
            enabled: true,
            label: skipTourText,
          },
        },
        // {
        //   id: "prewatch",
        //   attachTo: {
        //     element: "div#pre-watch",
        //     on: "bottom",
        //   },
        //   scrollTo: { behavior: "smooth", block: "center" },
        //   canClickTarget: false,
        //   buttons: [
        //     {
        //       text: this.$t(
        //         "copy.homePage.mainLayout.appGuidedTour.backButton"
        //       ),
        //       action: this.tour.back,
        //       secondary: true,
        //     },
        //     {
        //       text: this.$t(
        //         "copy.homePage.mainLayout.appGuidedTour.nextButton"
        //       ),
        //       action: this.tour.next,
        //     },
        //   ],
        //   highlightClass: "highlight",
        //   text: this.appGuidedTour.homeTourTextStep4,
        //   cancelIcon: {
        //     enabled: true,
        //     label: skipTourText,
        //   },
        // },
        {
          id: "profile",
          attachTo: {
            element: this.getProfileElement(),
            on: "bottom",
          },
          scrollTo: { behavior: "smooth", block: "start" },
          canClickTarget: false,
          buttons: [
            {
              text: this.$t(
                "copy.homePage.mainLayout.appGuidedTour.backButton"
              ),
              action: this.tour.back,
              secondary: true,
            },
            {
              text: this.$t("copy.homePage.mainLayout.appGuidedTour.endButton"),
              action: this.endHomeTour,
            },
          ],
          highlightClass: "highlight",
          text: this.appGuidedTour.homeTourTextStep5,
          cancelIcon: {
            enabled: true,
            label: skipTourText,
          },
        },
      ];
      this.tour.addSteps(steps);
      this.tour.start();
      this.setTour(this.tour);
    },

    triggerExpPopup(exp, slotProps) {
      slotProps.toggleExp(exp, false);
    },
    // updateExperienceCarousel() {
    //   if (this.$refs.experienceSection)
    //     this.$refs.experienceSection[0].updateCarousel();
    // },
    isIos() {
      return isIosNativeOnly();
    },
    removeDropdown() {
      this.isDropdownVisible = false;
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
  },
};
</script>

<style scoped>
.button {
  background: #fef8ec;
  height: 96px;
  width: 157.5px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  margin-bottom: 4px;
  box-shadow: 0px 5px 5px #ccc;
  border-top: 1px solid #e9e9e9;
}
.grid-cols {
  grid-template-columns: repeat(3, 75px);
}
.grid-cols-GC {
  grid-template-columns: repeat(2, 75px);
}

.unread-count {
  background: #ff6600;
  text-align: center;
  font: font-aktiv-grotesk-medium;
  size: 16px;
  line-height: 24px;
  color: #fef8ec;
  position: absolute;
  top: 5px;
  right: 0;
  width: 26.39px;
  height: 26.39px;
  border-radius: 50%;
}

h2 {
  @apply text-[32px] leading-[48px] font-aktiv-grotesk-medium text-blue;
}

@media all and (min-width: 768px) {
  .grid-cols {
    grid-template-columns: repeat(3, 115px);
  }

  .grid-cols-GC {
    grid-template-columns: repeat(2, 115px);
  }

  .unread-count {
    top: 5px;
    right: 5px;
  }

  .show {
    display: block;
  }
}
</style>
