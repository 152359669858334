<template>
  <master-layout>
    <div
      v-if="!loading"
      class="mb-6 px-[24px] md:px-8 lg:px-[56px] w-full pt-[16px] md:pt-[40px]"
      :class="{ '!pt-[60px]': isIos() }"
    >
      <img
        src="../../../public/assets/icons/go-back-chevron.svg"
        alt="Back Icon"
        class="cursor-pointer mb-[24px] lg:mb-[28px]"
        @click="backButton()"
      />
      <Selector
        :selectorTitle="title"
        :buttons="languageList"
        :selectedValue="selectedLanguage"
        @button-selected="handleButtonSelected"
      />
    </div>
  </master-layout>
</template>

<script>
import Selector from "@/components/common/Selector.vue";
import { getCurrentLocale, setLocale, languageList } from "../../utils/deviceLocale.js";
import { isIosNativeOnly } from "@/utils/platform-check";

export default {
  components: {
    Selector,
  },
  data() {
    return {
      loading: false,
      selectedLanguage: "en",
      languageList: languageList,
    };
  },
  async ionViewDidEnter() {
    this.selectedLanguage = await getCurrentLocale("Regional");
    this.loading = false;
  },
  computed: {
    title() {
      return this.$t("copy.languagePage.title");
    },
  },
  methods: {
    backButton() {
      this.$router.push("/gpcr/home");
    },
    handleButtonSelected(selectedLanguage) {
      this.selectedLanguage = selectedLanguage;
      this.loading = true;
      setLocale(this.selectedLanguage, this.$i18n, "Regional");
      this.$router.push("/gpcr/home");
    },
    isIos() {
      return isIosNativeOnly();
    },
  },
};
</script>

<style></style>
