import { createStore } from "vuex";

import cmsModule from "./modules/cms";
import photosModule from "./modules/photos";
import profileModule from "./modules/profile";
import scheduleModule from "./modules/schedule";
import signupModule from "./modules/signup";
import timerModule from "./modules/timer";
import toastModule from "./modules/toast";
import userModule from "./modules/user";
import announcementsModule from "./modules/announcements";
import tourModule from "./modules/tour";
import livevoiceModule from "./modules/livevoice";
import filterModule from "./modules/filter";
import socialModule from "./modules/social";
import playerModule from "./modules/player"

const store = createStore({
  modules: {
    cms: cmsModule,
    photos: photosModule,
    profile: profileModule,
    schedule: scheduleModule,
    signup: signupModule,
    timer: timerModule,
    toast: toastModule,
    user: userModule,
    announcements: announcementsModule,
    tour: tourModule,
    livevoice: livevoiceModule,
    filter: filterModule,
    social: socialModule,
    player: playerModule,
  },
});

export default store;
