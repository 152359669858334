<template>
  <div class="relative z-0 cursor-pointer drop-shadow-md">
    <img
      class="w-[288px] h-[162px] md:w-[288px] md:h-[162px] rounded-[16px]"
      :class="{ 'h-[300px] md:h-[300px]': type === 'sm_quotes' }"
      :src="imageUrl"
      :data-event-tracking="`${tagSlug}-${slug}`"
      alt=""
    />
    <div
      v-if="duration && type === 'video'"
      class="absolute bottom-0 right-0 z-100 mb-[8px] rounded-[4px] w-[43px] h-[20px] mr-[8px] bg-[#0B0C0F] opacity-60"
    >
      <p class="text-white text-[14px] leading-[20px] font-roboto text-center">
        {{ duration }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    duration: {
      type: String,
      required: false,
      default: "0:00",
    },
    slug: {
      type: String,
      default: "",
    },
    tagSlug: {
      type: String,
      default: "",
    },
  },
  // ../../../../public
  computed: {
    typeImageSrc() {
      if (this.type === "PDF") {
        return require("../../../../public/assets/icons/playback-resources/pdf-icon.svg");
      } else if (this.type === "Link") {
        return require("../../../../public/assets/icons/playback-resources/link-icon.svg");
      } else if (this.type === "Excel") {
        return require("../../../../public/assets/icons/playback-resources/excel-icon.svg");
      } else if (this.type === "Visual") {
        return require("../../../../public/assets/icons/playback-resources/visual-icon.svg");
      } else if (this.type === "Article") {
        return require("../../../../public/assets/icons/playback-resources/article-icon.svg");
      }
      return "";
    },
  },
};
</script>
