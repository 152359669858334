export default {
    props: ['value'],
  
    data() {
      return {
        localValue: this.value,
      }
    },
  
    watch: {
      localValue(newValue) {
        this.$emit('input', newValue)
      },
      value(newValue) {
        this.localValue = newValue
      },
    },
  }