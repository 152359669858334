<template>
  <router-link
    v-if="!isExternal"
    class="text-center py-[4px] px-[4px]"
    :class="isActive ? 'text-lightblue' : 'text-grey-40'"
    :to="to"
    :data-event-tracking="dataEventTracking"
    :data-test-id="dataTestId"
    @click.native="clickEvent()"
  >
    <component :is="icon" class="inline pb-1" :is-active="isActive" />
    <span
      class="block font-aktiv-grotesk-trial text-[12px] md:text-[12px] leading-[12px] text-center"
    >
      {{ label }}
    </span>
  </router-link>
  <a
    v-else
    class="text-center py-[4px] px-[4px] text-grey-40"
    :href="to"
    :data-test-id="dataTestId"
    target="_blank"
  >
    <component :is="icon" class="inline pb-1" color="#99A4B2" />
    <span
      class="block font-aktiv-grotesk-trial text-[12px] leading-[12px] text-center"
    >
      {{ label }}
    </span>
  </a>
</template>

<script>
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
export default {
  props: {
    isExternal: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
      required: true,
    },
    label: {
      type: String,
      default: null,
      required: true,
    },
    icon: {
      type: Object,
      default: null,
      required: true,
    },
    dataTestId: {
      type: String,
      default: null,
    },
    dataEventTracking: {
      type: String,
      default: null,
    },
  },
  computed: {
    isActive() {
      // if resource route, need to check for resource
      if (
        this.$route.path.includes("resource") &&
        this.to.includes("resource")
      ) {
        return true;
      }

      const parentActive =
        this.$route.path.includes(this.to) && this.to !== "/";
      return this.$route.path === this.to || parentActive;
    },
  },
  methods: {
    clickEvent() {
      console.log("clickevent here...");
      this.$emit("clicked-nav-button");
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: this.dataEventTracking,
          page: "bottomNav",
        },
      });
    },
  },
};
</script>
