const INITIAL_STATE = {
  currDate: '',
  speed: 1,
  close: false,
}

 const state = () => INITIAL_STATE

 const mutations = {
  setCurrDate(state, newCurrDate) {
    state.currDate = newCurrDate
  },
  setSpeed(state, speed) {
    state.speed = speed
  },
  setClose(state, close) {
    state.close = close
  },
}

 const actions = {
  setCurrDate(context, currDate) {
    context.commit('setCurrDate', currDate)
  },
  setSpeed(context, speed) {
    context.commit('setSpeed', speed)
  },
  setClose(context, close) {
    context.commit('setClose', close)
  },
}

 const getters = {
  currDate(state) {
    return state.currDate
  },
}

export default{
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
