<template>
  <div v-click-away="collapseDropdown" class="group">
    <div class="relative min-w-[120px]">
      <div
        class="bg-grey-10 h-[36px] rounded-[8px] flex items-center justify-center py-1 px-4 cursor-pointer hover:opacity-80"
        data-test-id="gensapp-resourcespage-language-dropdown-expand"
        @click="isDropdownExpanded = !isDropdownExpanded"
      >
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.34312 3.2182C3.8434 1.71793 5.87821 0.875083 7.99992 0.875083C10.1216 0.875083 12.1564 1.71793 13.6567 3.2182C15.157 4.71848 15.9998 6.75329 15.9998 8.875C15.9998 9.92556 15.7929 10.9658 15.3909 11.9364C14.9888 12.907 14.3996 13.7889 13.6567 14.5318C12.9139 15.2747 12.0319 15.8639 11.0614 16.266C10.0908 16.668 9.05048 16.8749 7.99992 16.8749C6.94935 16.8749 5.90908 16.668 4.93848 16.266C3.96789 15.8639 3.08598 15.2747 2.34312 14.5318C1.60026 13.7889 1.01099 12.907 0.608957 11.9364C0.206924 10.9658 0 9.92556 0 8.875C4.89059e-08 6.75329 0.842846 4.71848 2.34312 3.2182ZM7.99992 2.10584C6.20462 2.10584 4.48286 2.81902 3.2134 4.08848C2.70739 4.59449 2.28976 5.17236 1.97046 5.79811H14.0294C13.7101 5.17236 13.2924 4.59449 12.7864 4.08848C11.517 2.81902 9.79521 2.10584 7.99992 2.10584ZM14.5125 7.02887H1.48735C1.31855 7.62429 1.23076 8.24512 1.23076 8.875C1.23076 9.50053 1.31745 10.1217 1.48737 10.7211H14.5125C14.6824 10.1217 14.7691 9.50053 14.7691 8.875C14.7691 8.24512 14.6813 7.62429 14.5125 7.02887ZM14.0294 11.9519H1.97047C2.2922 12.5824 2.71134 13.1595 3.2134 13.6615C3.84197 14.2901 4.5882 14.7887 5.40947 15.1289C6.23074 15.4691 7.11098 15.6442 7.99992 15.6442C8.88886 15.6442 9.76909 15.4691 10.5904 15.1289C11.4116 14.7887 12.1579 14.2901 12.7864 13.6615C13.2885 13.1595 13.7076 12.5823 14.0294 11.9519Z"
            fill="#00AAE4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.99992 1.03165C7.97404 1.00857 7.94586 0.987373 7.91545 0.968396C7.62712 0.788468 7.24753 0.876344 7.0676 1.16467C5.62435 3.47744 4.85919 6.14886 4.85919 8.875C4.85919 11.6011 5.62435 14.2726 7.0676 16.5853C7.24753 16.8737 7.62712 16.9615 7.91545 16.7816C7.94586 16.7626 7.97404 16.7414 7.99992 16.7183C8.02579 16.7414 8.05397 16.7626 8.08438 16.7816C8.37271 16.9615 8.75231 16.8737 8.93223 16.5853C10.3755 14.2726 11.1406 11.6011 11.1406 8.875C11.1406 6.14886 10.3755 3.47744 8.93223 1.16467C8.75231 0.876344 8.37271 0.788468 8.08438 0.968396C8.05397 0.987373 8.02579 1.00857 7.99992 1.03165ZM7.99992 15.7513C9.24887 13.6765 9.90988 11.2994 9.90988 8.875C9.90988 6.45059 9.24888 4.07347 7.99992 1.99867C6.75096 4.07347 6.08995 6.45059 6.08995 8.875C6.08995 11.2994 6.75096 13.6765 7.99992 15.7513Z"
            fill="#00AAE4"
          />
        </svg>

        <p class="text-lightblue font-bold font-aktiv-grotesk mr-4 ml-1">
          {{ selectedLanguage.displayText }}
        </p>

        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :class="{
            'rotate-180': isDropdownExpanded,
          }"
        >
          <path
            d="M0.878418 1.08301L6.79406 7.1662L12.8772 1.25055"
            stroke="#00AAE4"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div
        v-if="isDropdownCanShow && isDropdownExpanded"
        class="w-full absolute z-10 top-[37px] text-center left-0 bg-white overflow-hidden box-shadow rounded-[8px]"
      >
        <div
          v-for="(language, index) in displayLanguages"
          :key="language.code"
          class="font-aktiv-grotesk py-3 px-[14px] cursor-pointer hover:bg-[#ededed]"
          :class="{
            'bg-lightblue text-white pointer-events-none':
              selectedLanguage.code === language.code,
            'text-grey-100': selectedLanguage.code !== language.code,
            'border-t border-grey-20': index != 0,
          }"
          :data-test-id="
            'gensapp-resourcespage-language-dropdown-selected-' + language.code
          "
          @click="onLanguageSelect(language)"
        >
          {{ language.displayText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue3-click-away";

export default {
  name: "LanguageDropdown",
  mixins: [clickaway],
  props: {
    availableLanguages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isDropdownCanShow: true,
      isDropdownExpanded: false,
      selectedLanguage: {
        displayText: "English",
        code: "en",
      },
      languages: {
        en: {
          displayText: "English",
          code: "en",
        },
        // {
        //   displayText: 'Español',
        //   code: 'es',
        // },
        tc: {
          displayText: "繁体中文",
          code: "tc",
        },
        th: {
          displayText: "ภาษาไทย",
          code: "th",
        },
        // {
        //   displayText: 'Bahasa Indonesia',
        //   code: 'id',
        // },
      },
    };
  },
  computed: {
    displayLanguages() {
      const langList = [];
      this.availableLanguages.forEach((code) => {
        langList.push(this.languages[code]);
      });
      return langList;
    },
  },
  mounted() {
    const { language } = this.$route.query;

    if (!language) return;

    this.selectedLanguage = this.languages[language];
    this.onLanguageSelect(this.languages[language]);
  },
  methods: {
    collapseDropdown() {
      this.isDropdownExpanded = false;
    },
    onLanguageSelect(language) {
      this.isDropdownCanShow = false;
      this.isDropdownExpanded = false;

      setTimeout(() => {
        this.isDropdownCanShow = true;
      }, 100);

      this.selectedLanguage = language;
      this.$emit("onChange", language);

      if (language.code === "en") {
        this.$router.push({
          path: this.$route.path,
        });

        return;
      }

      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          language: language.code,
        },
      });
    },
  },
};
</script>
<style scoped>
.box-shadow {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}
</style>
