<template>
  <master-layout>
    <div
      :class="{
        'top-0': !showHeader,
        'top-navbar-height': showHeader,
      }"
    >
      <div
        class="z-10 pt-[16px] px-[24px]"
        :class="{ '!pt-[60px]': isIos() }"
      >
        <img
          src="../../public/assets/icons/go-back-chevron.svg"
          alt="Back Icon"
          class="cursor-pointer"
          data-test-id="gensapp-experiencesignup-back"
          @click="back()"
        />
      </div>
      <div class="flex justify-center">
        <div
          class="w-[327px] lg:w-[680px] h-[640px] lg:h-[711px] mt-[160px] lg:mt-[0px] inline-flex lg:flex flex-col items-center gap-[24px]"
        >
          <div
            class="mt-[16px] md:mt-[24px] w-[279px] lg:w-[287px] h-[279px] lg:h-[287px] drop-shadow-photoqr bg-yellow rounded-[64px] flex items-center justify-center mx-auto"
          >
            <div
              class="bg-white rounded-[54px] h-[249px] lg:h-[247px] w-[249px] lg:w-[247px] flex items-center justify-center"
            >
              <div class="block lg:hidden">
                <qrcode-vue
                  :value="'https://gens.bio/' + hashId"
                  :size="188.51"
                  level="H"
                  data-test-id="gensapp-photodrop-qrcode"
                  v-bind:class="{
                    'shadow-[0_0_10px_5px_#a7a7a7]': this.isSamsung,
                  }"
                />
              </div>
              <div class="hidden lg:block">
                <qrcode-vue
                  :value="hashId"
                  :size="200"
                  level="H"
                  data-test-id="gensapp-photodrop-qrcode"
                  v-bind:class="{
                    'shadow-[0_0_10px_5px_#a7a7a7]': this.isSamsung,
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </master-layout>
</template>

<script>
import { mapState } from "vuex";
import QrcodeVue from "qrcode.vue";
import { isIosNativeOnly } from "@/utils/platform-check";

export default {
  name: "QrCode",
  components: {
    QrcodeVue,
    // Collapsible,
  },
  data() {
    return {
      isShowQR: false,
    };
  },
  computed: {
    ...mapState("profile", ["hashId"]),
    showHeader() {
      return this.$route.path !== "/home";
    },
  },
  mounted() {
    if (navigator.userAgent.match(/samsung/i)) {
      this.isSamsung = true;
      console.log("You're samsung");
    } else {
      console.log("You're not samsung");
    }
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
    back() {
      if (this.isEditEnable) {
        this.toggleEdit();
        return;
      }
      this.$router.back();
    },
  },
};
</script>
<style scoped></style>
