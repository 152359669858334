<template>
  <master-layout>
    <div id="session-material" class="lg:flex-1 lg:overflow-hidden mb-[24px]">
      <div
        class="session-bg w-full h-[200px] lg:flex-1 bg-repeat px-5 pt-[16px] bg-blue"
        :class="{
          'bg-session-card': isOrange,
          'bg-blue': isYellow,
          '!pt-[60px] !h-[240px]': isIos(),
        }"
      >
        <div class="lg:flex flex flex-col lg:items-start">
          <div class="lg:flex-shrink-0">
            <div
              class="rounded-lg lg:w-64 cursor-pointer"
              data-test-id="gensapp-session-resources-back"
              @click="back()"
            >
              <img
                src="../../../../../public/assets/icons/session-chevron.svg"
                alt="Back icon"
              />
            </div>
          </div>
          <div
            class="mt-4 lg:mt-8 text-grey-5 flex justify-between items-center w-full"
          >
            <div>
              <div
                v-if="event.label"
                class="w-auto font-aktiv-grotesk-medium text-[12px] leading-[20px] uppercase text-center rounded-xl p-1 inline-block text-black bg-yellow"
                :class="{
                  'bg-orange': isOrange,
                  'bg-black': isBlack,
                  'bg-blue': isDarkBlue,
                  'bg-yellow text-grey-100': isYellow,
                }"
              >
                {{ event.label }}
              </div>
            </div>
            <!-- TODO update live voice link control-->
            <in-app-browser
              v-if="event.enableLiveInterpretation && !isAbsentAlumni"
              :href="event.liveInterpretationLink"
              target="_blank"
              :class="{
                'pointer-events-none': !event.enableLiveInterpretation,
              }"
              @click="clickLiveInterpretation()"
            >
              <div
                class="flex cursor-pointer items-center font-aktiv-grotesk border-[1.5px] rounded-xl font-bold text-[14px] leading-[20px] px-4 py-[10px] active:bg-lightblue active:border-lightblue"
                :class="
                  !event.enableLiveInterpretation
                    ? 'bg-grey-90 border-grey-90 text-grey-70'
                    : 'border-white'
                "
              >
                <img
                  src="../../../../../public/assets/icons/live-voice-icon.svg"
                  class="pr-2"
                  :class="
                    !event.enableLiveInterpretation ? 'opacity-[0.25]' : ''
                  "
                />
                {{ $t("copy.sessionResourcesPage.accessLiveInterpretation") }}
              </div>
            </in-app-browser>
          </div>
          <div
            class="mt-4 lg:mt-8 text-grey-5 font-aktiv-grotesk-medium text-[20px] leading-[24px]"
          >
            {{ event.title }}
          </div>
          <div
            v-if="event.location"
            class="flex mb-1 mt-2 ml-2 lg:mb-2 lg:mt-4 lg:ml-4 justify-start items-start"
          >
            <img
              v-if="isOrange"
              src="../../../../../public/assets/icons/event-location-icon-orange.svg"
              alt="icon"
              class="mt-1"
            />
            <img
              v-else
              src="../../../../../public/assets/icons/event-location-icon-blue.svg"
              alt="icon"
              class="mt-1"
            />
            <p
              v-for="place in event.location"
              :key="place"
              class="tracking-wide w-[300px] max-w-[300px] font-aktiv-grotesk-trial text-[14px] leading-[20px] ml-2 lg:ml-4 text-grey-5"
            >
              {{ place }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex-1 pl-5 py-5 ml-2 lg:m-4">
        <div
          v-if="materialsCount > 0"
          class="tracking-wide font-aktiv-grotesk-medium text-[24px] leading-[32px] mt-2 lg:mt-4 text-black mb-6"
        >
          {{ $t("copy.sessionResourcesPage.sessionResourcesheader") }}
        </div>
        <section v-if="materialsCount > 0">
          <!-- <div v-if="images.length > 0">
            <div
              class="tracking-wide font-aktiv-grotesk-medium text-[20px] leading-[24px] text-black mb-4"
            >
              Quotes
            </div>
            <div
              class="w-full md:flex md:flex-col md:justify-center md:text-center overflow-hidden"
            >
              <ShareItSwiper
                id="quote"
                ref="quotes-carousel"
                :images="images"
              />
            </div>
          </div> -->
          <div v-for="(asset, type) in assets" :key="type">
            <div class="mb-12" v-if="asset.materials.length > 0">
              <div
                class="tracking-wide font-aktiv-grotesk-medium text-[20px] leading-[24px] text-black mb-4"
              >
                {{ asset.header }}
              </div>
              <div class="flex flex-col pr-5 mr-2">
                <div
                  v-for="(material, index) in asset.materials"
                  :key="index"
                  class="md:hidden w-full md:mb-0"
                  :class="
                    index === asset.materials.length - 1 ? 'mb-0' : 'mb-10'
                  "
                >
                  <in-app-browser
                    v-if="
                      !asset.header.includes('Questions') &&
                      material.externalLink
                    "
                    :href="material.externalLink"
                    target="_blank"
                    rel="noreferrer noopener"
                    :data-test-id="material.dataTestId"
                    class="block relative w-full hover:opacity-90"
                  >
                    <img
                      class="rounded-[16px] w-full aspect-video"
                      :class="
                        asset.header.includes('Tools')
                          ? 'tool-shadow'
                          : 'shadow'
                      "
                      :src="material.thumbnail.url"
                      :alt="material.description"
                    />

                    <div
                      v-if="material.isVideo"
                      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    >
                      <img
                        src="../../../../../public/assets/icons/youtube-player/play-button-dark.svg"
                        class="w-10 h-10 lg:w-[48px] lg:h-[48px]"
                      />
                    </div>
                  </in-app-browser>
                  <router-link
                    v-if="
                      !asset.header.includes('Questions') &&
                      !material.externalLink
                    "
                    :to="`/resources/video/${material.slug}`"
                    rel="noreferrer noopener"
                    :data-test-id="material.dataTestId"
                    class="block relative w-full hover:opacity-90"
                  >
                    <img
                      class="rounded-[16px] w-full aspect-video"
                      :class="
                        asset.header.includes('Tools')
                          ? 'tool-shadow'
                          : 'shadow'
                      "
                      :src="material.thumbnail.url"
                      :alt="material.description"
                    />

                    <div
                      v-if="material.isVideo"
                      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    >
                      <img
                        src="../../../../../public/assets/icons/youtube-player/play-button-dark.svg"
                        class="w-10 h-10 lg:w-[48px] lg:h-[48px]"
                      />
                    </div>
                  </router-link>
                  <div v-if="!asset.header.includes('Questions')">
                    <p
                      v-if="material.description"
                      v-html="material.description"
                      class="w-full mt-3 break-words"
                    ></p>
                  </div>
                  <div v-if="asset.header.includes('Questions')">
                    <p
                      v-if="material.description"
                      v-html="material.description"
                      class="bg-grey-10 w-full rounded-2xl p-3 text-[14px] leading-[24px] font-aktiv-grotesk"
                    ></p>
                  </div>
                  <div
                    v-if="material.externalLinkTc || material.externalLinkTh"
                    class="flex justify-center gap-3 select-none mt-3"
                  >
                    <in-app-browser
                      v-if="material.externalLinkTc"
                      :href="material.externalLinkTc"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <div
                        class="flex flex-row justify-center items-center h-[40px] w-[120px] gap-2.5 box-border border-2 border-lightblue rounded-[100px] text-lightblue active:bg-lightblue download-button text-base active:text-[#FFFFFF]"
                      >
                        <span class="">繁体中文</span>
                        <!-- <span class="text-base">{{ itemName }}</span> -->
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.5 16C1.95 16 1.479 15.804 1.087 15.412C0.695002 15.02 0.499335 14.5493 0.500002 14V11H2.5V14H14.5V11H16.5V14C16.5 14.55 16.304 15.021 15.912 15.413C15.52 15.805 15.0493 16.0007 14.5 16H2.5ZM8.5 12L3.5 7L4.9 5.55L7.5 8.15V0L9.5 0V8.15L12.1 5.55L13.5 7L8.5 12Z"
                          />
                        </svg>
                      </div>
                    </in-app-browser>
                    <in-app-browser
                      v-if="material.externalLinkTh"
                      :href="material.externalLinkTh"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <div
                        class="flex flex-row justify-center items-center h-[40px] w-[120px] gap-2.5 box-border border-2 border-lightblue rounded-[100px] text-lightblue active:bg-lightblue download-button text-base active:text-[#FFFFFF]"
                      >
                        <span class="">ไทย</span>
                        <!-- <span class="text-base">{{ itemName }}</span> -->
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.5 16C1.95 16 1.479 15.804 1.087 15.412C0.695002 15.02 0.499335 14.5493 0.500002 14V11H2.5V14H14.5V11H16.5V14C16.5 14.55 16.304 15.021 15.912 15.413C15.52 15.805 15.0493 16.0007 14.5 16H2.5ZM8.5 12L3.5 7L4.9 5.55L7.5 8.15V0L9.5 0V8.15L12.1 5.55L13.5 7L8.5 12Z"
                          />
                        </svg>
                      </div>
                    </in-app-browser>
                  </div>
                </div>
                <div class="relative hidden md:block">
                  <swiper
                    :ref="'mySwiper' + asset.header"
                    class="mx-auto relative overflow-hidden"
                    :slides-per-view="swiperOptions.slidesPerView"
                    :options="swiperOptions"
                    @swiper="setSwiperInstance($event, asset.header)"
                  >
                    <swiper-slide
                      v-for="(material, index) in asset.materials"
                      :key="index"
                      class="rounded-2xl mr-3 lg:mr-6"
                    >
                      <div class="w-[327px] md:mr-6">
                        <in-app-browser
                          v-if="
                            !asset.header.includes('Questions') &&
                            material.externalLink
                          "
                          :href="material.externalLink"
                          target="_blank"
                          rel="noreferrer noopener"
                          :data-test-id="material.dataTestId"
                          class="block relative w-full sm:w-max mb-3 hover:opacity-90"
                        >
                          <img
                            class="rounded-[16px] w-full aspect-video sm:w-[328px]"
                            :class="
                              asset.header.includes('Tools')
                                ? 'tool-shadow'
                                : 'shadow'
                            "
                            :src="material.thumbnail.url"
                            :alt="material.description"
                          />

                          <div
                            v-if="material.isVideo"
                            class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                          >
                            <img
                              src="../../../../../public/assets/icons/youtube-player/play-button-dark.svg"
                              class="w-10 h-10 lg:w-[48px] lg:h-[48px]"
                            />
                          </div>
                        </in-app-browser>
                        <router-link
                          v-if="
                            !asset.header.includes('Questions') &&
                            !material.externalLink
                          "
                          :to="`/resources/video/${material.slug}`"
                          rel="noreferrer noopener"
                          :data-test-id="material.dataTestId"
                          class="block relative w-full sm:w-max mb-3 hover:opacity-90"
                        >
                          <img
                            class="rounded-[16px] w-full aspect-video sm:w-[328px]"
                            :class="
                              asset.header.includes('Tools')
                                ? 'tool-shadow'
                                : 'shadow'
                            "
                            :src="material.thumbnail.url"
                            :alt="material.description"
                          />

                          <div
                            v-if="material.isVideo"
                            class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                          >
                            <img
                              src="../../../../../public/assets/icons/youtube-player/play-button-dark.svg"
                              class="w-10 h-10 lg:w-[48px] lg:h-[48px]"
                            />
                          </div>
                        </router-link>

                        <div v-if="!asset.header.includes('Questions')">
                          <p
                            v-if="material.description"
                            v-html="material.description"
                            class="w-full mt-3 break-words"
                          ></p>
                        </div>
                        <div v-if="asset.header.includes('Questions')">
                          <p
                            v-if="material.description"
                            v-html="material.description"
                            class="bg-grey-10 rounded-2xl p-3 text-[14px] leading-[24px] font-aktiv-grotesk"
                          ></p>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div v-else v-show="loading" class="flex items-center justify-center">
          <loading-state />
        </div>
      </div>
    </div>
  </master-layout>
</template>

<script>
import moment from "moment";

//refactor Swiper core and vue-awesome-swiper
import {
  Swiper as SwiperClass,
  Pagination,
  EffectCoverflow,
} from "swiper/core";
// import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import ShareItSwiper from "../../../../components/common/ShareCard/ShareItSwiper";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/vue";
import { StatusBar, Style } from "@capacitor/status-bar";
import { mapState, mapGetters } from "vuex";
import { isIosNativeOnly } from "@/utils/platform-check";
import LoadingState from "@/components/common/LoadingState.vue";
import user from "@/store/modules/user";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

SwiperClass.use([Pagination, EffectCoverflow]);
// const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

export default {
  components: {
    ShareItSwiper,
    Swiper,
    SwiperSlide,
    LoadingState,
  },
  data() {
    return {
      cacheLocale: "en",
      swipers: {},
      event: {},
      materialsCount: 0,
      images: [],
      swiperOptions: {
        centeredSlides: false,
        slidesPerView: "auto",
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
        },
      },
      cards: {
        "2024-11-15": {
          weekday: true,
        },
        "2024-11-16": {
          weekday: false,
        },
        "2024-11-17": {
          weekday: false,
        },
      },
    };
  },
  computed: {
    ...mapState("user", ["token"]),
    ...mapGetters("profile", ["isAbsentAlumni"]),
    ...mapState("cms", ["sessionMaterials", "loading", "audioFlag"]),
    ...mapState("livevoice", ["livevoiceSession"]),
    ...mapState("profile", ["needLiveVoice"]),

    isOrange() {
      return this.labelColor === "orange";
    },
    isDarkBlue() {
      return this.labelColor === "dark-blue";
    },
    isBlack() {
      return this.labelColor === "black";
    },
    isYellow() {
      return this.labelColor === "yellow";
    },
    labelColor() {
      // Handle scenario: where no label
      if (!this.event.label) return "";

      // Below code: all has label
      // Handle scenario: where weekday
      if (this.isWeekday && this.event.type === "experience") return "black";
      if (this.isWeekday) return "yellow";

      // Below all is weekend
      if (this.event.type === "experience") return "dark-blue";
      return "yellow";
    },
    isWeekday() {
      const dateStr = moment(new Date(this.event.startTime)).format(
        "YYYY-MM-DD"
      );
      return this.cards[dateStr].weekday;
    },
    assets() {
      return {
        pdf: {
          header: this.$t("copy.sessionResourcesPage.assets.toolsHeader"),
          materials: [],
        },
        questions: {
          header: this.$t("copy.sessionResourcesPage.assets.questionsHeader"),
          materials: [],
        },
        links: {
          header: this.$t("copy.sessionResourcesPage.assets.linksHeader"),
          materials: [],
        },
      };
    },
  },
  async ionViewWillEnter() {
    this.cacheLocale = this.$i18n.locale;
    const sessionId = this.$route.params.name;
    await this.$store.dispatch("livevoice/fetchLivevoiceSession", sessionId);

    await this.$store.dispatch("cms/fetchSessionMaterials", {
      sessionId: sessionId,
      conferenceType: "Regional",
    });

    // if (!(this.sessionMaterials.quotes.length == 0)) {
    //   this.images = this.sessionMaterials.quotes;
    //   this.materialsCount += this.sessionMaterials.quotes.length;
    // }

    if (!(this.sessionMaterials.tools.length == 0)) {
      this.assets.pdf.materials = this.sessionMaterials.tools;
      this.materialsCount += this.sessionMaterials.tools.length;
    }

    if (!(this.sessionMaterials.questions.length == 0)) {
      this.assets.questions.materials = this.sessionMaterials.questions;
      this.materialsCount += this.sessionMaterials.questions.length;
    }

    if (
      !(this.sessionMaterials.relatedResources.length == 0) ||
      !(this.sessionMaterials.gpc_videos.length == 0)
    ) {
      this.assets.links.materials = this.sessionMaterials.relatedResources;
      this.materialsCount += this.sessionMaterials.relatedResources.length;
      for (const key in this.sessionMaterials.gpc_videos) {
        this.assets.links.materials.push(this.sessionMaterials.gpc_videos[key]);
      }
      this.assets.links.materials.sort((a, b) => a.priority - b.priority);
    }

    this.fetch();
    if (isIosNativeOnly()) await StatusBar.setStyle({ style: Style.Dark });

    this.updateCarousel();
    FirebaseAnalytics.setScreenName({
      screenName: "session material - session " + sessionId,
      nameOverride: "Session Material - Session " + sessionId,
    });
  },
  async ionViewDidEnter() {
    await this.$store.dispatch("announcements/refreshAnnouncements", {
      locale: this.$i18n.locale,
      conferenceType: "Regional",
    });
    await this.$store.dispatch("announcements/fetchUnreadCount", "Regional");
  },
  ionViewDidLeave() {
    this.$i18n.locale = this.cacheLocale;
    document.querySelector("html").setAttribute("lang", this.cacheLocale);
  },
  methods: {
    updateCarousel() {
      if (this.$refs["quotes-carousel"]) this.$refs["quotes-carousel"].update();

      for (const header in this.swipers) {
        const swiper = this.swipers[header];
        swiper.update();
      }
    },
    back() {
      this.$router.go(-1);
    },
    isIos() {
      return isIosNativeOnly();
    },
    async fetch() {
      const id = this.$route.params.name;
      const result = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/schedules/byId/" + id,
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
          params: {
            conferenceType: "Regional",
          },
        }
      );

      this.event = result.data[0];
      if (this.event.locale !== this.cacheLocale) {
        this.$i18n.locale = this.video.locale;
        document.querySelector("html").setAttribute("lang", this.video.locale);
      }
    },
    setSwiperInstance(instance, header) {
      this.swipers[header] = instance;
    },
    clickLiveInterpretation() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "live interpretation",
          page: "session material", // getAnalyticsScreenName("SchedulePage", "en", "gpcr"),
        },
      });
    },
  },
};
</script>

<style scoped>
.top {
  background: url("../../../../../public/assets/images/background-session-card.png");
  height: 481px;
  width: 100%;
  position: relative;
}

.typeform {
  padding-left: 32px;
  padding-top: 72px;
}

.session-bg {
  background-size: cover;
}

.swiper-slide {
  max-width: 328px;
  align-items: flex-start;
  vertical-align: middle;
  border-radius: 10px;
  display: flex;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.swiper-container {
  list-style: none;
  z-index: 1;
  padding-bottom: 0px;
}

::v-deep(.swiper-wrapper) {
  padding-left: 0;
}

.download-button svg {
  fill: #00aae4;
}

.download-button:active svg {
  fill: #ffffff;
}

.tool-shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.shadow {
  box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.25);
}
</style>
