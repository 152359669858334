<template>
  <master-layout id="contentResourceSessions">
    <div
      v-if="!loading"
      class="lg:flex-1 lg:overflow-hidden relative font-aktiv-grotesk md:pb-[68px] lg:pb-[142px] bg-white"
    >
      <ResourcesContainer
        ref="resourceContainer"
        :show-more-resources="moreResourcesCount > 0"
      >
        <div
          class="md:ml-[24px] lg:ml-0 md:flex md:justify-between px-6 md:px-0"
        >
          <p class="font-aktiv-grotesk text-[16px] leading-[24px] pb-8">
            Welcome to your one-stop hub for all GenerationS & Strong Church
            resources. Use these sermons, videos, tools to grow your church
            Younger & Stronger.
          </p>
          <div
            class="md:ml-0 border-[2px] border-grey-40 text-[#637083] rounded-xl h-[48px] w-full md:w-[400px] flex flex-row items-center py-[10px] px-3 md:mx-6 cursor-pointer hover:opacity-80"
            @click="this.$router.push('/resources/sessions/search')"
          >
            <img
              class="mr-2"
              fill="#637083"
              src="/assets/icons/search.svg"
              alt=""
            />
            <p>Search</p>
          </div>
        </div>
        <div class="font-aktiv-grotesk">
          <ResourcesSection />
        </div>
      </ResourcesContainer>
    </div>
    <div
      v-if="loading"
      class="w-screen h-screen flex items-center justify-center"
    >
      <loading-state />
    </div>
  </master-layout>
</template>

<script>
import {
  Swiper as SwiperClass,
  Pagination,
  EffectCoverflow,
} from "swiper/core";
import ResourcesContainer from "../../components/ResourcesPage/ResourcesContainer.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import LoadingState from "@/components/common/LoadingState.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import PopupModal from "@/components/common/PopupModal.vue";
import ResourcesSection from "@/components/ResourcesPage/PlaybackResources/ResourcesSection.vue";

SwiperClass.use([Pagination, EffectCoverflow]);

export default {
  name: "SessionsPage",
  components: {
    ResourcesContainer,
    LoadingState,
    Swiper,
    SwiperSlide,
    PopupModal,
    ResourcesSection,
  },
  computed: {
    ...mapState("cms", ["sessionMaterials", "loading", "resources"]),
    ...mapState("player", ["playingPlayer"]),
    ...mapState("filter", [
      "sessions",
      "showFilterModal",
      "allResources",
      "filterType",
    ]),
  },
  async ionViewWillEnter() {
    this.setLoading(true);
    // use Promise.all to fetch all the data at once
    await Promise.all([
      this.$store.dispatch("filter/fetchSchedule", {
        locale: "en",
        conferenceType: "Global",
      }),
      this.$store.dispatch("filter/fetchTopics", {
        locale: "en",
        conferenceType: "Global",
      }),
      this.$store.dispatch("filter/fetchAllResources", {
        locale: "en",
        conferenceType: "Global",
      }),
      this.$store.dispatch("filter/fetchSearchSuggestions", {
        locale: "en",
        conferenceType: "Global",
      }),
      this.$store.dispatch("filter/updateFilteredValues", {
        filteredSessions: [],
        filteredTopics: [],
      }),
    ]);
    this.$nextTick(() => {
      this.setLoading(false);
    });
    FirebaseAnalytics.setScreenName({
      screenName: "resources",
      nameOverride: "ResourcesPage",
    });
  },
  async ionViewDidLeave() {
    const filterType = this.filterType;
    const showFilterModal = false;
    await this.$store.dispatch("filter/updateShowFilterModal", {
      showFilterModal,
      filterType,
    });
    await this.$store.dispatch("filter/clearState");
  },
  methods: {
    ...mapActions("cms", ["setLoading"]),
    openFilterModal() {
      this.$store.dispatch("filter/updateShowFilterModal", true);
    },
  },
};
</script>

<style scoped>
.tool-shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.shadow {
  box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.25);
}

.swiper-slide {
  max-width: 328px;
  align-items: flex-start;
  vertical-align: middle;
  border-radius: 10px;
  display: flex;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.swiper-container {
  list-style: none;
  z-index: 1;
  padding-bottom: 0px;
}

.download-button svg {
  fill: #00aae4;
}

.download-button:active svg {
  fill: #ffffff;
}

@media all and (min-width: 768px) {
  ::v-deep(.swiper-wrapper) {
    padding-left: 0;
  }
}
</style>
