<template>
  <div class="flex">
    <div v-if="isExclusive">
      <div class="rounded-[8px] bg-[#FFEA2C] h-[28px] px-[8px] mr-[4px] flex">
        <img src="../../../../public/assets/icons/playback-resources/exclusive-icon.svg" class="mr-[4px] pt-[9px] pb-[7px]" alt="" />
        <div class="text-[14px] leading-[16px] pt-[7px] pb-[5px] text-[#061F42] font-aktiv-grotesk font-medium">{{ $t('copy.resourcesPage.videoPage.yellowTag.exclusive') }}</div>
      </div>
    </div>
    <div v-if="isAudio">
      <div class="rounded-[8px] bg-[#FFEA2C] h-[28px] px-[8px] mr-[4px] flex">
        <div class="text-[14px] leading-[16px] pt-[7px] pb-[5px] text-[#061F42] font-aktiv-grotesk font-medium">{{ $t('copy.resourcesPage.videoPage.yellowTag.audioOnly') }}</div>
      </div>
    </div>
    <div v-if="sub !== ''">
      <div class="rounded-[8px] bg-[#FFEA2C] h-[28px] px-[8px] mr-[4px] flex">
        <img src="../../../../public/assets/icons/playback-resources/subs-icon.svg" class="mr-[6px] py-[8px]" alt="" />
        <div class="text-[14px] leading-[14px] py-[7px] text-[#061F42] font-proxima-nova">{{ sub }}</div>
      </div>
    </div>
    <div v-if="dub !== ''">
      <div class="rounded-[8px] bg-[#FFEA2C] h-[28px] px-[8px] mr-[4px] flex">
        <img src="../../../../public/assets/icons/playback-resources/dubs-icon.svg" class="mr-[4px] py-[7px]" alt="" />
        <div class="text-[14px] leading-[14px] py-[7px] text-[#061F42] font-proxima-nova">{{ dub }}</div>
      </div>
    </div>
   <div v-if="translationLabel !== ''">
     <div class="rounded-[8px] bg-[#FFEA2C] h-[28px] px-[8px] flex">
        <img src="../../../../public/assets/icons/playback-resources/eye-icon.svg" class="mr-[4px] py-[7px]" alt="" />
        <div class="text-[14px] leading-[14px] py-[7px] text-[#061F42] font-proxima-nova">{{ translationLabel }}</div>
     </div>
   </div>
  </div>
</template>

<script>
export default {
  props: {
    isVideo: {
      type: Boolean,
      required: true,
    },
    isExclusive: {
      type: Boolean,
      default: false,
    },
    isAudio: {
      type: Boolean,
      default: false,
    },
    hasChineseOverdub: {
      type: Boolean,
      default: false,
    },
    hasThaiOverdub: {
      type: Boolean,
      default: false,
    },
    hasChineseSub: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    translations: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    sub() {
      if(this.hasChineseSub) return '中文'
      return ''
    },
    dub() {
      if(this.hasThaiOverdub && this.hasChineseOverdub) return '中文 , ภาษาไทย'
      else if(this.hasThaiOverdub) return 'ภาษาไทย'
      else if(this.hasChineseOverdub) return '中文'
      return ''
    },
    translationLabel() {
      if (this.translations.length === 0) return ''

      if (this.type === 'video') return ''

      const labels = []

      this.translations.forEach((translation) => {
        if (translation.language === 'TradChi') labels.push('中文')

        if (translation.language === 'Thai') labels.push('ภาษาไทย')

      })

      return labels.join(', ')

    }
  }
}
</script>

<style>

</style>
