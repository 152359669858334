<template>
  <master-layout
    id="gpcr-content-experience"
    v-slot="slotProps"
    ref="masterLayout"
  >
    <div v-if="!isLoading" class="lg:w-[1400px]">
      <div
        v-if="signups"
        class="font-aktiv-grotesk max-w-full overflow-hidden flex flex-col pt-[16px] md:pt-[40px] pb-[92px]"
        :class="{ '!pt-[60px]': isIos() }"
      >
        <button ref="hiddenButton" @click="closeExpModal(slotProps)"></button>
        <div class="fixed bottom-[88px] z-50">
          <CheatConsole v-if="isNonProd"></CheatConsole>
        </div>
        <div class="mb-6 px-[24px] md:px-8 lg:px-[56px]">
          <img
            src="../../../../public/assets/icons/go-back-chevron.svg"
            alt="Back Icon"
            class="cursor-pointer"
            data-test-id="gensapp-experiencesignup-back"
            @click="back()"
          />
        </div>

        <section class="flex flex-col mb-3 px-6 md:px-8 lg:px-[56px]">
          <h1
            class="text-[32px] leading-[40px] font-aktiv-grotesk-medium mb-[4px] font-aktiv-grotesk-medium"
          >
            {{ $t("copy.experiencePage.title") }}
          </h1>
        </section>
        <div class="flex flex-col">
          <section v-if="isExpAllocated()" class="mt-6">
            <div class="mx-6 md:mx-8 lg:mx-[56px] max-w-[600px]">
              <div
                class="bg-white rounded-2xl p-4 mt-6 border-[1px] border-[#FF6600]"
              >
                <p
                  class="text-[20px] leading-[24px] font-aktiv-grotesk-medium mb-4 mt-1"
                >
                  {{ $t("copy.experiencePage.allocationPanel.title") }}
                </p>
                <div v-for="(signup, type) in signups" :key="type">
                  <ExperienceSuccessCard
                    v-if="signup && signup.status === 'Confirmed'"
                    :title="expData && expData[type]?.title"
                    :expTime="getTime(signup)"
                    :location="getLocation(signup)"
                    :date="getDate(signup)"
                    :type="type"
                    conference-type="Regional"
                    class="mb-3"
                  />
                </div>
                <h2
                  class="text-[16px] leading-[24px] font-aktiv-grotesk-medium font-normal"
                >
                  {{ $t("copy.experiencePage.allocationPanel.protipTitle") }}
                  <br />
                  {{ $t("copy.experiencePage.allocationPanel.protipHeader") }}
                </h2>
                <ol
                  class="list-decimal text-[16px] leading-[24px] mt-2 list-outside px-5"
                >
                  <li>
                    {{
                      $t("copy.experiencePage.allocationPanel.protipQuestion1")
                    }}
                  </li>
                  <li>
                    {{
                      $t("copy.experiencePage.allocationPanel.protipQuestion2")
                    }}
                  </li>
                </ol>
              </div>
            </div>
          </section>
          <section
            class="flex mt-10 mx-6 md:mx-8 lg:mx-[56px] flex-col md:flex-row"
          >
            <div
              class="flex flex-col flex-shrink-0 lg:flex-row lg:max-w-[600px] lg:w-full lg:items-center"
            >
              <p
                class="text-[20px] lg:text-[24px] leading-[24px] lg:leading-[32px] font-aktiv-grotesk-medium mb-3 lg:max-w-[233px] lg:mr-[40px]"
              >
                {{ this.mainExpVid?.videoDescription }}
              </p>
              <div class="relative exp-video-aspect w-full min-w-[272px]">
                <video-player
                  v-if="this.mainExpVid?.trailerVideo"
                  ref="experienceTrailerVideo"
                  class="rounded-2xl w-full min-w-[272px]"
                  data-event-tracking="experience-video-player"
                  :options="getVideoOptions"
                />
                <div
                  ref="preview"
                  class="absolute top-0 w-full rounded-2xl exp-video-aspect cursor-pointer flex flex-col items-center"
                >
                  <img
                    :src="this.mainExpVid?.trailerVideoThumbnail"
                    class="rounded-2xl w-full exp-video-aspect"
                    data-test-id="experience-video-preview"
                    @click="playVideo"
                  />
                  <div
                    class="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 rounded-2xl"
                  >
                    <img
                      src="../../../../public/assets/icons/youtube-player/play-button-dark.svg"
                      data-test-id="experience-video-play"
                      class="w-[54px] h-[54px]"
                      @click="playVideo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <GPCRExperienceListPanel :signups="signups"></GPCRExperienceListPanel>
          <section id="expCarousel" class="mt-10">
            <div ref="experiencesSection" class="header-scroll-margin">
              <h2
                class="mb-4 text-[24px] leading-[32px] font-aktiv-grotesk-medium px-6 md:px-8 lg:px-[56px]"
              >
                {{ $t("copy.experiencePage.carousel.title", { count: 4 }) }}
              </h2>
              <ExperienceCarousel
                id="exp-page-exp"
                ref="exp-page-exp"
                baseSwiperId="exp-page-exp"
                :swiperOptions="swiperOptions"
                @show-popup="triggerExpPopup($event, slotProps)"
                :expData="expData"
              ></ExperienceCarousel>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div
      v-if="isLoading"
      class="w-screen h-screen flex items-center justify-center"
    >
      <loading-state />
    </div>
  </master-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { toRaw } from "vue";
import CheatConsole from "../../../components/common/CheatConsole.vue";
// import InterestModal from "../../components/ExperiencePage/InterestModal.vue";
// import CancelModal from "../../components/ExperiencePage/CancelModal.vue";
import VideoPlayer from "@/components/common/VideoPlayer.vue";
import ExpStatusPanel from "@/components/ExperiencePage/ExpStatusPanel.vue";
import ExperienceCarousel from "@/components/ExperiencePage/ExperienceCarousel.vue";
import GPCRExperienceListPanel from "@/components/ExperiencePage/GPCRExperienceListPanel.vue";
import ExperienceSuccessCard from "@/components/ExperiencePage/ExperienceSuccessCard.vue";
import axios from "axios";
import moment from "moment";
import store from "@/store/index";
import { isIosNativeOnly } from "@/utils/platform-check";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import LoadingState from "@/components/common/LoadingState.vue";
import { StatusBar, Style } from "@capacitor/status-bar";

export default {
  name: "ExperiencePage",
  components: {
    CheatConsole,
    // InterestModal,
    // CancelModal,
    VideoPlayer,
    ExpStatusPanel,
    ExperienceCarousel,
    GPCRExperienceListPanel,
    ExperienceSuccessCard,
    LoadingState,
  },
  data() {
    return {
      experienceSlugs: ["exp-page-exp"],
      cancelModalType: "",
      signupModalType: "",
      showModal: false,
      isLoading: false,
      // videoKey: 0,
      swiperOptions: {
        centeredSlides: false,
        slidesPerView: "auto",
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true,
        },
        hasNavigation: true,
      },
    };
  },
  computed: {
    ...mapGetters("signup", ["SIGN_UP_CLOSE_TS", "GPCR_EXP_ALLOC_TS"]),
    ...mapGetters("profile", ["type"]),
    ...mapState("signup", {
      signups: "signups",
      expData: "expData",
      mainExpVid: "mainExpData",
    }),
    ...mapState("user", ["token"]),
    ...mapState("timer", ["currDate"]),
    isNonProd() {
      return process.env.VUE_APP_NODE_ENV !== "production";
    },
    hasSignupBefore() {
      let result = false;
      for (const expType in this.signups) {
        result = result || Boolean(this.signups[expType]);
      }
      return result;
    },
    watch: {
      // Watch the locale (language) property
      "$i18n.locale": function () {
        this.remountVideoPlayer(); // Call method to update key when language changes
      },
    },
    getVideoOptions() {
      return {
        autoplay: false,
        controls: true,
        playsinline: true,
        sources: [
          {
            src: this.mainExpVid?.trailerVideo,
            type: "video/mp4",
          },
        ],
      };
    },
    isExperiencesAnchorTagPresentInTheUrl() {
      if (this.$route.hash) {
        return this.experienceSlugs.includes(this.$route.hash.slice(1));
      }
      return false;
    },
  },
  async ionViewDidEnter() {
    await StatusBar.setStyle({ style: Style.Light });
    this.isLoading = true;
    await this.$store.dispatch("signup/fetchSignup", "Regional");
    await this.$store.dispatch("signup/fetchDates");

    await this.$store.dispatch("announcements/refreshAnnouncements", {
      locale: this.$i18n.locale,
      conferenceType: "Regional",
    });
    await this.$store.dispatch("announcements/fetchUnreadCount", "Regional");
    // this.remountVideoPlayer();

    setTimeout(() => {
      this.updateCarousel();
    }, 1800);

    FirebaseAnalytics.setScreenName({
      screenName: "gpcr-experience",
      nameOverride: "GPCRExperiencePage",
    });

    await this.getExperiences();
    this.populateExperienceSlugs();
    this.isLoading = false;

    this.$nextTick(() => {
      if (this.isExperiencesAnchorTagPresentInTheUrl) {
        this.scrollToFindOutMoreAboutExperiencesSection();
        const experienceFromAnchorTagPresentInTheUrl =
          this.getExperienceFromSlug(this.$route.hash.slice(1));
        // Need to use toRaw as $refs.masterLayout is a proxy
        toRaw(this.$refs.masterLayout).toggleExpModal(
          experienceFromAnchorTagPresentInTheUrl,
          false
        );
      }
    });
  },

  async ionViewDidLeave() {
    this.pauseVideo();
    this.$refs.hiddenButton.click();
  },
  methods: {
    closeExpModal(slotProps) {
      console.log("yesah");
      slotProps.closeExp();
    },
    getModalShow(modal) {
      this.showModal = modal;
    },
    closeModal() {
      this.showModal = false;
    },
    isIos() {
      return isIosNativeOnly();
    },
    goToSchedule() {
      this.showModal = false;
      this.$router.push("/schedule");
    },
    // remountVideoPlayer() {
    //   // Change the key to force the video-player to remount
    //   this.videoKey += 1;
    // },
    // Sprint 15 Refactor: can rename number to more specific name - end
    back() {
      if (this.isEditEnable) {
        this.toggleEdit();
        return;
      }
      if (this.successFromSummary) {
        this.$router.push("/gpcr/home");
        return;
      }

      this.$router.back();
    },
    backToHome() {
      this.$router.push("/gpcr/home");
      this.tour.cancel();
      this.tour.getById("behind-the-scenes");
    },
    backToTop() {
      const elem = document.getElementById("gpcr-content-experience");
      elem.scrollToTop(1000);
    },
    async getExperiences() {
      await this.$store.dispatch("signup/fetchExpData", {
        locale: this.$i18n.locale,
        conferenceType: "Regional",
      });
      await this.$store.dispatch("signup/fetchMainExpData", {
        locale: this.$i18n.locale,
        conferenceType: "Regional",
      });
      console.log(this.expData);
    },
    isExpAllocated() {
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      const expAllocDate = new Date(this.GPCR_EXP_ALLOC_TS);
      return currentDate.getTime() >= expAllocDate.getTime();
    },
    hasSignedUpForExp() {
      let result = false;
      for (const expType in this.signups) {
        result = result || Boolean(this.signups[expType]);
      }
      return result;
    },
    triggerExpPopup(exp, slotProps) {
      this.pauseVideo();
      slotProps.toggleExp(exp, false);
    },
    updateCarousel() {
      if (this.$refs["exp-page-exp"]) this.$refs["exp-page-exp"].update();
    },
    playVideo() {
      this.$refs.experienceTrailerVideo.player.play();
      this.$refs.preview.classList.add("z-[-100]");
    },
    pauseVideo() {
      this.$refs.experienceTrailerVideo.player.pause();
    },
    scrollToFindOutMoreAboutExperiencesSection() {
      this.$refs.experiencesSection.scrollIntoView({ behavior: "smooth" });
    },
    populateExperienceSlugs() {
      for (const experienceObjectKey in this.expData) {
        this.experienceSlugs.push(this.expData[experienceObjectKey].slug);
      }
    },
    getExperienceFromSlug(slug) {
      for (const experienceObjectKey in this.expData) {
        if (this.expData[experienceObjectKey].slug === slug) {
          return this.expData[experienceObjectKey];
        }
      }
    },
    getTime(signup) {
      if (signup.expTime) return signup.expTime;

      if (!this.expData || !this.expData[signup.type].dateTime) return;

      return this.expData[signup.type].dateTime[0].timing;
    },
    getLocation(signup) {
      if (signup.location) return [signup.location];

      return this.expData ? [this.expData[signup.type].location] : [];
    },
    getDate(signup) {
      if (signup.date) return signup.date;

      if (!this.expData || !this.expData[signup.type].dateTime) return;

      return this.expData[signup.type].dateTime[0].date;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

::v-deep(.swiper-slide:last-child) {
  margin-right: 48px;
}

/** Sprint 15 Refactor: convert to tailwind css - start */
.center-cancel {
  position: absolute;
  top: 160px;
  left: 0;
  right: 0;
  background-color: #f8f9f9;
  border-radius: 16px;
  width: 327px;
  height: 324px;
  margin: auto;
}

.center-thank-you {
  position: absolute;
  top: 160px;
  left: 0;
  right: 0;
  background-color: #f8f9f9;
  border-radius: 16px;
  width: 327px;
  height: 296px;
  margin: auto;
}

.exp-video-aspect {
  aspect-ratio: 327/184;
}

/* For Tablets */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .center-cancel {
    position: absolute;
    top: 244px;
    left: 0;
    right: 0;
    background-color: #f8f9f9;
    border-radius: 16px;
    width: 327px;
    height: 324px;
    margin: auto;
  }

  .center-thank-you {
    position: absolute;
    top: 244px;
    left: 0;
    right: 0;
    background-color: #f8f9f9;
    border-radius: 16px;
    width: 327px;
    height: 296px;
    margin: auto;
  }

  ::v-deep(.swiper-slide:last-child) {
    margin-right: 64px;
  }

  ::v-deep(.swiper-wrapper) {
    padding-left: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .center-cancel {
    position: absolute;
    top: 244px;
    left: 0;
    right: 0;
    background-color: #f8f9f9;
    border-radius: 16px;
    width: 327px;
    height: 324px;
    margin: auto;
  }

  .center-thank-you {
    position: absolute;
    top: 244px;
    left: 0;
    right: 0;
    background-color: #f8f9f9;
    border-radius: 16px;
    width: 327px;
    height: 296px;
    margin: auto;
  }

  ::v-deep(.swiper-slide:last-child) {
    margin-right: 112px;
  }

  ::v-deep(.swiper-wrapper) {
    padding-left: 56px;
  }
}

.header-scroll-margin {
  scroll-margin: 100px;
}
/** Sprint 15 Refactor: convert to tailwind css - end */
</style>
