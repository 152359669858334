<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <template v-if="isAuthenticated">
        <p class="hidden">{{ getToken() }}</p>
      </template>
      <div v-if="!isAuthenticated" class="fixed top-0">
        <div
          class="relative flex justify-center w-screen md:w-full md:h-screen"
        >
          <img
            src="/assets/images/pre-signin-background-mobile.png"
            srcset="/assets/images/pre-signin-background-ipad.png 768w"
            sizes="(min-width: 768px) 100vw, 100vw"
            class="w-screen h-screen object-cover"
          />
          <div
            class="absolute z-[10] top-[10vh] w-screen md:w-full md:top-[10%] md:left-[calc(50%-300px)] md:max-w-[600px] md:max-h-[900px]"
          >
            <PreSignInCarousel
              ref="preSignInPage"
              baseSwiperId="preSignInPage"
              :displayTypes="displayTypes"
              :swiperOptions="swiperOptions"
              @index-is-end="changeLoginText"
            ></PreSignInCarousel>
            <div class="hidden md:block">
              <div
                class="w-[327px] h-[48px] bg-[#00A9E6] rounded-[100px] mx-auto flex items-center text-center justify-center"
                @click="swipeOrRedirect"
              >
                <div class="text-[20px] text-[#FFF] font-aktiv-grotesk-medium">
                  {{ translatedText(buttonText) }}
                </div>
              </div>
            </div>
          </div>
          <div class="z-[100] absolute top-[90vh] md:hidden">
            <div
              class="w-[327px] h-[48px] bg-[#00A9E6] rounded-[100px] mx-auto flex items-center text-center justify-center"
              @click="swipeOrRedirect"
            >
              <div class="text-[20px] text-[#FFF] font-aktiv-grotesk-medium">
                {{ translatedText(buttonText) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import PreSignInCarousel from "../components/PreSignInPage/PreSignInCarousel.vue";
import { IonContent, IonPage, isPlatform } from "@ionic/vue";
import { Browser } from "@capacitor/browser";
import { useAuth0 } from "@auth0/auth0-vue";
import { mapActions } from "vuex";
import { createRedirectUri } from "../utils/redirect-uri";
import { useRoute } from "vue-router";
import { computed, ref, watch } from "vue";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { SplashScreen } from "@capacitor/splash-screen";
import { isIosNativeOnly, isAndroidNativeOnly } from "@/utils/platform-check";
import { getCurrentLocale } from "@/utils/deviceLocale";

export default {
  name: "PreSignInPage",
  components: {
    PreSignInCarousel,
    IonPage,
    IonContent,
  },
  setup() {
    console.log("index.vue");
    const { loginWithRedirect, isAuthenticated, user, checkSession } =
      useAuth0();
    const logoutUri = createRedirectUri(
      process.env.VUE_APP_PACKAGE_ID,
      process.env.VUE_APP_AUTH0_DOMAIN
    );
    const isBrowserOpen = ref(false);
    const isNative = isPlatform("android") || isPlatform("ios");
    const isNativeBrowser = isPlatform("mobileweb");
    const isLocal = computed(() => process.env.VUE_APP_NODE_ENV === "local");
    const usernames = ref(() => []);

    const login = async () => {
      await loginWithRedirect({
        openUrl: (url) =>
          Browser.open({
            url,
            windowName: "_self",
          }),
      });
      isBrowserOpen.value = true;
    };

    function delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    if (isNative && !isNativeBrowser) {
      watch(isBrowserOpen, async (value) => {
        if (!value) {
          await delay(1000);
          if (!isAuthenticated.value) await login();
        }
      });
      /**
       * Platform: Web/Android/iOS
       * Logs an app event.
       * @param name - name of the event to log
       *        params - key/value pairs of properties (25 maximum per event)
       * @returns void
       */
      FirebaseAnalytics.logEvent({
        name: "native_login",
        // params: {
        //   content_type: "image",
        //   content_id: "P12453",
        //   items: [{ name: "Kittens" }],
        // },
      });
    }

    return {
      isAuthenticated,
      user,
      login,
      isBrowserOpen,
      delay,
      isNative,
      isNativeBrowser,
      checkSession,
      isLocal,
      usernames,
    };
  },
  data() {
    return {
      // readMore: false,
      swiperOptions: {
        centeredSlides: false,
        slidesPerView: 1,
        grabCursor: true,
        pagination: false,
        // hasNavigation: true,
        // watchOverflow: true,
      },
      token: "",
      buttonText: "Next",
    };
  },
  created() {
    setTimeout(async () => {
      await SplashScreen.hide();
    }, 1000);
  },
  async ionViewDidEnter() {
    setTimeout(() => {
      this.$refs.preSignInPage.update();
    }, 1800);
  },
  computed: {
    isIos() {
      return isIosNativeOnly();
    },
    isAndroid() {
      return isAndroidNativeOnly();
    },
  },
  methods: {
    ...mapActions("user", ["setToken"]),
    async getToken() {
      console.log("presignin getToken");
      const route = useRoute();
      // console.log(route)
      const path = computed(() => route.path);
      const fromPath = computed(() => route.redirectedFrom);
      if (process.env.VUE_APP_NODE_ENV === "local") {
        this.token = localStorage.getItem("token");
      } else {
        const { getAccessTokenSilently } = useAuth0();
        this.token = await getAccessTokenSilently();
      }
      console.log("in getToken presignin: " + this.token);
      // console.log(fromPath.value.path)
      console.log("path value: " + path.value);
      if (path.value.includes("login")) {
        console.log("pushing to home here");
        this.$router.push("/home");
      }
      if (path.value.includes("pre-sign-in")) {
        console.log("has token and going to pre-sign-in. Pushing to home");
        this.$router.push("/home");
      } else {
        console.log("else path value: " + path.value);
      }
      this.setToken(this.token);
    },
    changeLoginText(isEnd) {
      if (isEnd) {
        this.buttonText = "Login";
      } else {
        this.buttonText = "Next";
      }
    },
    translatedText(buttonText) {
      if (buttonText == "Login") {
        return this.$t("copy.preSignInPage.loginButton");
      } else {
        return this.$t("copy.preSignInPage.nextButton");
      }
    },
    swipeOrRedirect() {
      if (this.buttonText == "Next") {
        this.$refs.preSignInPage.slideNext();
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style scoped>
::v-deep(.swiper-pagination-bullet) {
  background-color: grey-20;
}

::v-deep(.swiper-pagination-bullet-active) {
  background-color: #00a9e6;
}

::v-deep(.swiper-wrapper) {
  padding-left: 0;
}

::v-deep(.swiper-slide) {
  margin-right: 0;
}

::v-deep(.swiper-container) {
  padding-bottom: 0;
}
</style>
