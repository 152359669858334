import { Device } from "@capacitor/device";
import OneSignal from "onesignal-cordova-plugin";
import { isNativeNotWeb } from "@/utils/platform-check";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export const languageList = [
  {
    name: "English",
    value: "en",
    description: "",
  },
  {
    name: "简体中文",
    value: "zh",
    description: "",
  },
  {
    name: "ไทย",
    value: "th",
    description: "",
  },
];

// Function to get the device language or fallback to the saved/default locale
const getDeviceLocale = async () => {
  try {
    const language = await Device.getLanguageCode();
    console.log("device language:", language);
    if (language.value.includes("zh")) {
      return "zh";
    }
    return language.value;
  } catch (error) {
    console.error("Error getting device language:", error);
    return "en";
  }
};

// Function to get the locale from localStorage or fallback to the device language
export const getCurrentLocale = async (conferenceType) => {
  if (conferenceType === "Global") {
    return "en";
  }

  const savedLocale = localStorage.getItem(
    getLocaleLocalStorageKey(conferenceType)
  );
  console.log("saved localed", savedLocale);
  if (!!savedLocale) {
    return savedLocale;
  }
  if (isNativeNotWeb()) {
    const deviceLocale = await getDeviceLocale();

    if (languageList.map(language => language.value).includes(deviceLocale)) {
      return deviceLocale;
    } 
    return "en";
  }
  return "en";
}

// Function to save the locale to localStorage
export const setLocale = (locale, i18n, conferenceType) => {
  console.log("setting locale", locale);
  localStorage.setItem(getLocaleLocalStorageKey(conferenceType), locale);
  // Update the Onesignal for the new locale
  i18n.locale = locale;
  document.querySelector("html").setAttribute("lang", locale);

  if (isNativeNotWeb()) {
    OneSignal.User.setLanguage(locale);
    FirebaseAnalytics.setUserProperty({
      name: "language",
      value: i18n.locale,
    });
  }
};

const getLocaleLocalStorageKey = (conferenceType) => {
  return conferenceType === "Global" ? "gpc-app-locale" : "gpcr-app-locale";
};
