<template>
  <div class="relative">
    <swiper
      :ref="'mySwiper' + id"
      class="mx-auto relative overflow-hidden"
      :slides-per-view="options.slidesPerView"
      :options="options"
      :modules="modules"
      :pagination="true"
      :effectcoverflow="true"
      @swiper="setSwiperInstance"
    >
      <swiper-slide
        v-for="(image, index) in images"
        :key="index"
        class="rounded-2xl mr-3 lg:mr-6"
      >
        <share-it-card
          :key="index"
          :index="index"
          :image="image"
        ></share-it-card>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper as Pagination, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";
// import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

import ShareItCard from "@/components/common/ShareCard/ShareItCard";

// SwiperClass.use([Pagination, EffectCoverflow])
// const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

export default {
  components: {
    ShareItCard,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const swiper = ref(null);
    const setSwiperInstance = (swiperInstance) => {
      swiper.value = swiperInstance;
    };
    return {
      modules: [EffectCoverflow, Pagination],
      setSwiperInstance,
      swiper,
    };
  },
  props: {
    id: {
      type: String,
    },
    options: {
      default: () => {
        return {
          centeredSlides: false,
          slidesPerView: "auto",
          grabCursor: true,
          pagination: false,
          hasNavigation: false,
          watchOverflow: true,
        };
      },
      type: Object,
    },
    images: {
      type: Array,
      required: true,
    },
  },
  methods: {
    update() {
      this.swiper.update();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.swiper-pagination) {
  display: none !important;
}

.swiper-slide:last-child {
  margin-right: 48px;
}

@media screen and (min-width: 768px) {
  .swiper-slide:last-child {
    margin-right: 0px;
  }
}
</style>
