const translation = (locale) => {
  return {
    locale: locale,
    fallbackLocale: "en",
    messages: {
      en: {
        copy: {
          comma: {
            gpcr: {
              dayOne: "Fri, 15 Nov",
              dayTwo: "Sat, 16 Nov",
              dayThree: "Sun, 17 Nov",
            },
          },
          preSignInPage: {
            firstScreen: `<div class="px-[24px]">
                <div class="text-center font-aktiv-grotesk font-semibold text-[32px] md:text-[48px] leading-[32px] md:leading-[48px]">Welcome to</div>
                <div class="flex flex-row justify-center mx-auto items-end">
                  <img src="/assets/images/generations-app.svg" class="w-[190px] md:w-[258px]"/>
                  <span class="font-aktiv-grotesk font-semibold text-[32px] md:text-[48px] leading-[32px] md:leading-[48px]">&nbsp;App</span>
                </div>
                <img src="/assets/images/presignin/pre-signin-page-1.png" class="w-auto h-[40vh] md:py-[40px] mx-auto" style="padding-top: 3vh; padding-bottom: 2vh;"/>
                <div class="text-white w-[90vw] md:w-auto mx-auto text-center font-aktiv-grotesk-medium text-[20px] md:text-[28px] leading-[28px] md:leading-[32px]">
                  Discipling GenerationS to build a
                  <span class="whitespace-nowrap md:whitespace-auto">Strong Church</span>\
                </div>\
              </div>`,
            secondScreen: `<div class="pt-[8px] px-[24px]">\
              <div class="w-[90vw] md:w-[432px] text-center font-aktiv-grotesk font-semibold text-[24px] md:text-[32px] leading-[32px] md:leading-[40px] mx-auto">\
                Time to grow our churches\
                YOUNGER and STRONGER!\
              </div>\
              <img src="/assets/images/presignin/pre-signin-page-2.png" class="w-auto h-[40vh] md:py-[40px] mx-auto" style="padding-top: 3vh; padding-bottom: 2vh;" />\
              <div class="text-white w-[90vw] md:w-auto mx-auto text-center font-aktiv-grotesk-medium text-[20px] md:text-[28px] leading-[28px] md:leading-[32px] mx-auto">\
                Use this app to get the latest \
                conference updates. \
              </div>\
            </div>`,
            thirdScreen: `<div class="pt-[8px] px-[24px]">\
              <div class="w-[90vw] md:w-[432px] mx-auto text-center font-aktiv-grotesk font-semibold text-[24px] md:text-[32px] leading-[24px] md:leading-[40px] mx-auto">\
                It doesn’t end with the\
                conference…\
              </div>\
              <img src="/assets/images/presignin/pre-signin-page-3.png" class="w-auto h-[40vh] md:py-[40px] mx-auto" style="padding-top: 3vh; padding-bottom: 2vh;" />\
              <div class="text-white w-[90vw] md:w-auto mx-auto text-center font-aktiv-grotesk-medium text-[20px] md:text-[28px] leading-[28px] md:leading-[32px] mx-auto">\
                Continue accessing exclusive \
                GenerationS and Strong Church \
                resources with this app! \
              </div>\
            </div>\
            </div>`,
            nextButton: "Next",
            loginButton: "Login",
          },
          homePage: {
            profileDropdown: {
              profile: "Profile",
              language: "Language",
              conference: "Conference",
              logout: "Log Out",
            },
            conferenceType: {
              title: "Conference",
              gpcr: {
                name: "GenerationS Pastors Conference Regional",
              },
              gpc: {
                name: "GenerationS Pastors Conference",
              },
            },
            mainLayout: {
              topNav: {
                experiences: "Experiences",
                photoDrop: "PhotoDrop",
                updates: "Updates",
              },
              scheduleSection: {
                title: "Schedule",
                schedulePanel: {
                  endOfDay: "End of Day { day }",
                  tillNextTime: "Till next time!",
                  seeYouTomorrow: "See you tomorrow!",
                  seeYouNextYear: "See you in 2025!",
                  viewSessionResources: `Want to review the session resources? <br />Head to the`,
                  viewSessionResourcesPage: "Resources Page",
                  seeYouSoon: "We loved having you with us! See you soon! 🤩",
                  cantWaitToSeeYou: "Hello! We can’t wait to see you soon!",
                  // applyNow: "Apply Now",
                  happeningNow: "Happening now, ",
                  goToScheduleButton: "Go to Schedule",
                  viewNextDayScheduleButton: "View next day’s schedule",
                },
              },
              registration: {
                bannerName: "registration.png",
              },
              worshipSongs: {
                availableOnYoutube: "Available on YouTube",
                titleImgUrl: "../../../public/assets/images/home/playlist.png",
              },
              kickOffSection: {
                title: `<p class="text-[32px] text-left leading-[40px] text-blue font-aktiv-grotesk-medium pb-4">
                          Before we kick things <br class="hidden lg:block"/>off...
                        </p>`,
                description: `<p class="text-[16px] text-left lg:w-[320px] mx-auto leading-[24px] text-blue pb-5 font-aktiv-grotesk-regular">
                                Watch this short clip to get a quick overview of what GenerationS is all about!<br />
                                You'll be all set to receive the full impact of the next 3 days ahead! 😊
                              </p>`,
                downloadVideo: "Download video",
                downloadThumbnail:
                  "https://cdn.generationsmvmt.com/images/app/gpcr-pre-watch-thumbnail-en.jpg",
                downloadLink:
                  "https://cdn.generationsmvmt.com/videos/conference-app/gpcr-pre-watch-en.mp4",
              },
              experiences: {},
              usefulLinks: {
                title: "Useful links",
                links: {
                  gettingAround: "Getting around HOGC 🚶🏻‍♂️",
                  mapLink:
                    "https://cdn.generationsmvmt.com/docs/HOGC-Map-2024.pdf",
                  appGuidedTour: "App Guided Tour",
                  giving: "Giving",
                  busSchedule: "Shuttle bus schedule",
                  busSchedulePdf:
                    "https://cdn.generationsmvmt.com/docs/GPCR-2024-Shuttle-Bus-Schedule-EN.pdf",
                },
              },
              appGuidedTour: {
                startTour: "Start Tour",
                skipTour: "Skip Tour",
                skipButton: "Skip",
                backButton: "Back",
                nextButton: "Next",
                endButton: "End",
                stepPill: {
                  stepText: "Step",
                  ofText: "of",
                },
                homeTourTextStep0SCS: `
                  <div>
                      <img src="../../../assets/images/guided-tour/HOGC_SG.svg" alt="HOGC" />
                      <br>
                      <div style="text-align:center; margin-left:auto; margin-right:auto; color:#231F20; font-size: 24px; font-weight: 500; line-height: 28px;">Welcome to</div>
                      <img style="padding-bottom:18px;" src="../../../assets/images/gpcr/gpcr-2024-logo-dark.png" alt="Generations" />
                      <div style="padding-bottom:12px; text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">The GenerationS Pastors Conference App lets you view your schedule, session materials and experiences.</div>
                      <div style="padding-bottom:24px; text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Let's take a quick tour! </div>
                  </div>
                `,
                homeTourTextStep0GC: `
                  <div>
                      <img src="../../../assets/images/guided-tour/HOGC_SG.svg" alt="HOGC" />
                      <br>
                      <div style="text-align:center; margin-left:auto; margin-right:auto; color:#231F20; font-size: 24px; font-weight: 500; line-height: 28px;">Welcome to</div>
                      <img style="padding-bottom:18px;" src="../../../assets/images/gpcr/gpcr-2024-logo-dark.png" alt="Generations" />
                      <div style="padding-bottom:12px; text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">The GenerationS Pastors Conference App lets you view your schedule, session materials and experiences.</div>
                      <div style="padding-bottom:24px; text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Let's take a quick tour! </div>
                  </div>
                `,
                homeTourTextStep1: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Schedule</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Stay in the loop about the daily schedule throughout the conference!</span> <br>`,
                homeTourTextStep2: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Experiences</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Go behind the scenes to see GenerationS and Strong Church brought to life. Click here to view your allocated experiences.</span> <br>`,
                homeTourTextStep3: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">PhotoDrop</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Check out your personalised conference photos here - updated in real-time!</span> <br>`,
                homeTourTextStep4: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Before we begin...</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Watch to find out the mindset shifts to start building GenerationS and a strong church</span> <br>`,
                homeTourTextStep5: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Profile and Settings</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">You can update your contact details here and select your preferred language!</span> <br> <br>`,
                scheduleTourStep1: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Schedule</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Access the full conference schedule.</span>`,
                scheduleTourStep2: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Schedule (Daily View)</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">View the daily session timings and venues.</span>`,
                scheduleTourStep3: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">Session Resources</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">Check out “See session resources” and you’re ready for the session!</span>`,
              },
            },
          },
          languagePage: {
            title: "Language",
            english: "English",
            chinese: "Simplified Chinese",
            thai: "Thai",
          },
          profilePage: {
            showQr: "Show QR",
            editProfile: "Edit profile",
            addImage: "Add Image",
            displayName: `Display Name <span style="font-style:italic;color:#637083;">(required)</span>`,
            emptyName: "Let us know your name!",
            organization: "Church / Organization",
            title: "Title",
            email: "Email",
            showMyEmail: "Show my email",
            accountLoginEmail: "Account login email",
            socials: "Socials",
            socialMedia: {
              addSocialsTitle: "Add Socials",
              addInstagram: "Add Instagram",
              addFacebook: "Add Facebook",
              addX: "Add X",
              addLinkedIn: "Add LinkedIn",
            },
            saveChanges: "Save changes",
          },
          announcementPage: {
            title: "Latest Updates",
            announcementCard: {
              newLabel: "New",
              today: "TODAY, ",
            },
          },
          resourcesPage: {
            title: "Resources",
            filterBy: "Filter by",
            filterSection: {
              topics: "Topics",
              sessions: "Sessions",
              day: "Day { i }: ",
            },
            searchContent: {
              searchingStringText: `Search results for "{searchTerm}"`,
              noResourcesSearchText: `Your search for "{searchTerm}" didn't return any results.`,
              noOfResourcesFound: `{count} resources found`,
            },
            videoPage: {
              videoChapters: "Video Chapters",
              yellowTag: {
                exclusive: "Exclusive",
                audioOnly: "Audio only",
              },
              shareButton: "Share",
            },
            description:
              "Welcome to your one-stop hub for all GenerationS & Strong Church resources. Use these sermons, videos, tools to grow your church Younger & Stronger.",
            searchBar: "Search",
            resourceSearch: {
              bookSection: {
                header: "Book",
              },
              quotesSection: {
                header: "Quotes",
              },
              suggestionSection: {
                header: "You may be interested in...",
              },
            },
            backToTopSection: {
              otherContentText: "Interested in other resources?",
              backToTop: "Back to top",
            },
          },
          sessionResourcesPage: {
            accessLiveInterpretation: "Live Interpretation",
            sessionResourcesheader: "Session Resources",
            assets: {
              toolsHeader: "Tools",
              questionsHeader: "Discussion Time Questions",
              linksHeader: "Related Resources",
            },
          },
          experiencePage: {
            title: "Experiences",
            description:
              "Go behind the scenes and see GenerationS & Strong Church principles come to life!",
            note: "📢 Find out which Experience you'll be attending!",
            experienceList: {
              title:
                "Each delegate gets to attend 1 of 4 Experiences during GPCR:",
              exp1: "Prayer Meeting",
              exp2: "Ministry Debrief",
              exp3: "Connect Group Experience",
              exp4: "Dreams & Stories Night",
              note: "📢 Find out which Experience you'll be attending by Saturday, 9 November 2024. ",
            },
            allocationPanel: {
              title: "You’ll be attending",
              protipTitle: "💡Pro-tip",
              protipHeader:
                "Make the most of Experiences with these reflection questions:",
              protipQuestion1: "What can I implement in my church?",
              protipQuestion2:
                "How can this be applied within my church's context?",
              viewMapButton: "View map",
              viewMapButtonLink:
                "https://cdn.generationsmvmt.com/docs/HOGC-Map-2024.pdf",
              viewFloorplanButton: "View floorplan",
              viewFloorplanButtonLink:
                "https://cdn.generationsmvmt.com/docs/GPCR-Dream-and-Stories-Night-Floorplan.pdf",
            },
            carousel: {
              title: "Learn more about all {count} Experiences",
              viewDetailsButton: "View Details",
            },
          },
          schedulePage: {
            scheduleButton: "3-Day Schedule",
            schedule:
              "https://cdn.generationsmvmt.com/docs/GPCR-2024-Schedule-EN.pdf",
            dayOne: {
              day: "Fri",
              dayNum: "15",
              month: "Nov",
              announcement: `<div class="text-[16px] leading-[24px] font-aktiv-grotesk-trial text-black">Hello</div>
              📣 Have breakfast before coming! Bring a jacket too!<br>`,
            },
            dayTwo: {
              day: "Sat",
              dayNum: "16",
              month: "Nov",
              announcement: `<div class="text-[16px] leading-[24px] font-aktiv-grotesk-trial text-black">It's the weekend! </div>
              📣 Grab a bite before coming!<br>`,
            },
            dayThree: {
              day: "Sun",
              dayNum: "17",
              month: "Nov",
              announcement:
                '<div class="text-[16px] leading-[24px] font-aktiv-grotesk-trial text-black">Our last, power-packed day together!</div>📣 Have your breakfast before coming!',
            },
            eventsCard: {
              accessLiveInterpretation: "Live Interpretation",
              seeSessionResources: "See session resources",
              viewMapButtonLink:
                "https://cdn.generationsmvmt.com/docs/HOGC-Map-2024.pdf",
            },
            // experienceHeader: "Experiences",
            checkAllocation: "Check Allocation",
            backToTop: "Back to top",
          },
          photoDropPage: {
            photoDropActionBar: {
              myQR: "My QR",
              select: "Select",
              cancel: "Cancel",
              download: "Download",
              downloading: "Downloading",
              noOfSelectedPhotosText: "{ count } photo{s} selected",
            },
            myPhotosTab: {
              tabName: "For You",
            },
            forEveryoneTab: {
              tabName: "For Everyone",
              videosTab: "Videos",
              dayTab: "Day { i }",
              viewMore: "View More",
            },
            personalTab: {
              tabName: "Personal",
              subtitle:
                "Grab a friend, grab a photographer and start snapping!",
              qrCodeModal: {
                subtitle: "Have your photos dropped directly to you!",
                instructions: {
                  one: 'Show this QR Code to any HOGC photographer wearing a white "PhotoDrop" shirt! 👕📸',
                  two: "Grab your friends, get your photo taken, and come back here for the picture!",
                },
              },
            },
            customExperiencesTab: {
              tabName: "Custom Experiences",
              subtitle:
                "Photos from Experiences that you went for will be dropped here! 🎁",
            },
            selectButton: "Select",
            downloadModal: {
              header: "Get download link in email",
              description:
                "A link will be sent to your email to download the selected videos and/or photos.",
              sendToMyEmail: "Send link to my email",
              cancel: "Cancel",
            },
          },
          teamPage: {
            title: `<div class="font-aktiv-grotesk-medium mx-auto w-full xl:min-w-[360px] text-center xl:text-left text-[24px] leading-[32px]">
                      <span class="font-caveat text-[40px] leading-[32px]">Hello </span>from the Global Team!
                    </div>`,
            backRowText: "Back row, left to right:",
            backRowNames: {
              pastorMartin: "Pastor Martin Wong",
              blakeGladd: "Blake Gadd",
              nataschaBruschweiler: "Natascha Brüschweiler",
              nadineLeong: "Nadine Leong",
            },
            frontRowText: "Front row, left to right:",
            frontRowNames: {
              jamieLee: "Jamie Lee",
              valerieFifi: "Valerie Fifi",
              christianHonegger: "Christian Honegger",
              tanJianMing: "Tan Jian Ming",
            },
            meetTheFullLeadershipTeam: "Meet the full leadership team!",
          },
          sideBar: {
            home: "Home",
            resources: "Resources",
            schedule: "Schedule",
            team: "Team",
            updates: "Updates",
            termOfUse: "Terms of Use",
            privacyPolicy: "Privacy Policy",
          },
          bottomNav: {
            home: "Home",
            resources: "Resources",
            schedule: "Schedule",
            team: "Team",
            updates: "Updates",
          },
          masterLayout: {
            logoutPopup: {
              message: "Do you want to log out?",
              continue: "Continue",
              cancel: "Cancel",
            },
            newsLetterModal: {
              header: "Share with your team",
              description: `Fill in their name and email. <br>We will send each of them an invite to the GenS App!`,
              inputNamePlaceholder: "Your friend's name",
              inputEmailPlaceholder: "Your friend's email",
              errorMessage: {
                emptyNameInput: "Name is required.",
                emptyEmailInput: "Email is required.",
                notValidEmail: "Please enter a valid email address.",
              },
              subscribe: "Send email invite",
              sent: "Sent",
            },
            filterModal: {
              resetFilters: "Reset",
              applyFilters: "Apply filters",
            },
          },
        },
      },
      zh: {
        copy: {
          comma: {
            gpcr: {
              dayOne: "11月15日（五）",
              dayTwo: "11月16日（六）",
              dayThree: "11月17日（日）",
            },
          },
          preSignInPage: {
            firstScreen: `<div class="px-[24px]">
              <div class="text-center font-aktiv-grotesk font-semibold text-[28px] md:text-[36px] leading-[36px] md:leading-[44px]">欢迎使用</div>\
                <div class="flex flex-row justify-center mx-auto items-end">
                  <div class="font-aktiv-grotesk font-bold text-[40px] md:text-[60px] leading-[40px] md:leading-[60px]" style="color: #2B2E79;">累代</div>
                  <span class="font-aktiv-grotesk font-semibold text-[32px] md:text-[48px] leading-[40px] md:leading-[60px]">&nbsp;App</span>
                </div>
              <img src="/assets/images/presignin/pre-signin-page-1.png" class="w-auto h-[40vh] md:py-[40px] mx-auto" style="padding-top: 3vh; padding-bottom: 2vh;"/>
              <div class="text-white w-[90vw] md:w-auto mx-auto text-center font-aktiv-grotesk-medium text-[20px] md:text-[28px] leading-[28px] md:leading-[32px]">\
                门训“累代”\
                <span class="whitespace-nowrap md:whitespace-auto">建立“强壮教会”</span>\
              </div>\
            </div>`,
            secondScreen: `<div class="pt-[8px] px-[24px]">\
              <div class="w-[90vw] md:w-[432px] text-center font-aktiv-grotesk font-semibold text-[28px] md:text-[36px] leading-[36px] md:leading-[44px] mx-auto">\
                是时候打造<b>更年轻</b>、<br/><b>更强壮</b>的教会！\
              </div>\
              <img src="/assets/images/presignin/pre-signin-page-2.png" class="w-auto h-[40vh] md:py-[40px] mx-auto" style="padding-top: 3vh; padding-bottom: 2vh;" />\
              <div class="text-white w-[90vw] md:w-auto mx-auto text-center font-aktiv-grotesk-medium text-[20px] md:text-[28px] leading-[28px] md:leading-[32px] mx-auto">\
                使用“累代”App获取最新的特会资讯。\
              </div>\
            </div>`,
            thirdScreen: `<div class="pt-[8px] px-[24px]">\
              <div class="w-[90vw] md:w-[432px] mx-auto text-center font-aktiv-grotesk font-semibold text-[28px] md:text-[36px] leading-[36px] md:leading-[44px] mx-auto">\
                特会结束后，<br/>惊喜继续……\
              </div>\
              <img src="/assets/images/presignin/pre-signin-page-3.png" class="w-auto h-[40vh] md:py-[40px] mx-auto" style="padding-top: 3vh; padding-bottom: 2vh;" />\
              <div class="text-white w-[90vw] md:w-auto mx-auto text-center font-aktiv-grotesk-medium text-[20px] md:text-[28px] leading-[28px] md:leading-[32px] mx-auto">\
                通过App继续获取“累代”和“强壮教会”的独家资源！\
              </div>\
            </div>\
            </div>`,
            nextButton: "下一步",
            loginButton: "登录",
          },
          homePage: {
            profile: "个人资料",
            profileDropdown: {
              profile: "简介",
              language: "语言",
              conference: "特会",
              logout: "退出",
            },
            conferenceType: {
              title: "特会",
              gpcr: {
                name: "GenerationS Pastors Conference Regional",
              },
              gpc: {
                name: "GenerationS Pastors Conference",
              },
            },
            mainLayout: {
              topNav: {
                experiences: "体验",
                photoDrop: "PhotoDrop",
                updates: "更新",
              },
              scheduleSection: {
                title: "行程表",
                schedulePanel: {
                  endOfDay: "第{ day }天结束",
                  tillNextTime: "下次见！",
                  seeYouTomorrow: "明天见！",
                  seeYouNextYear: "2025年再见！",
                  viewSessionResources: `想要重温课堂资源？<br />请前往`,
                  viewSessionResourcesPage: "“资源”页面",
                  seeYouSoon: "很高兴与你相聚！再会！🤩",
                  cantWaitToSeeYou: "你好！我们迫不及待想见到你！",
                  // applyNow: "立即报名",
                  happeningNow: "现在进行 ",
                  goToScheduleButton: "前往行程表",
                  viewNextDayScheduleButton: "查看明天的行程表",
                },
              },
              registration: {
                bannerName: "registration.png",
              },
              worshipSongs: {
                availableOnYoutube: "可在YouTube观看",
                titleImgUrl:
                  "../../../public/assets/images/home/playlist-zh.svg",
              },
              kickOffSection: {
                title: `<p class="text-[32px] text-left leading-[40px] text-blue font-aktiv-grotesk-medium pb-4">
                          在我们开始前 <br class="hidden lg:block"/>...
                        </p>`,
                description: `<p class="text-[16px] text-left lg:w-[320px] mx-auto leading-[24px] text-blue pb-5 font-aktiv-grotesk-regular">
                                观看这段短片，快速了解“累代”！<br /> 你已准备好迎接具有影响力的三天！😊
                              </p>`,
                downloadVideo: "下载短片",
                downloadThumbnail:
                  "https://cdn.generationsmvmt.com/images/app/gpcr-pre-watch-thumbnail-zh.jpg",
                downloadLink:
                  "https://cdn.generationsmvmt.com/videos/conference-app/gpcr-pre-watch-zh.mp4",
              },
              experiences: {},
              usefulLinks: {
                title: "实用链接",
                links: {
                  gettingAround: "神之心教会指南 🚶🏻‍♂️",
                  mapLink:
                    "https://cdn.generationsmvmt.com/docs/zh/HOGC-Map-2024.pdf",
                  appGuidedTour: "应用程序导览",
                  giving: "奉献",
                  busSchedule: "接驳车时间表",
                  busSchedulePdf:
                    "https://cdn.generationsmvmt.com/docs/GPCR-2024-Shuttle-Bus-Schedule-ZH.pdf",
                },
              },
              appGuidedTour: {
                startTour: "开始导览",
                skipTour: "跳过导览",
                skipButton: "跳过",
                backButton: "上一步",
                nextButton: "下一步",
                endButton: "结束",
                stepPill: {
                  stepText: "步骤",
                  ofText: "/",
                },
                homeTourTextStep0SCS: `
                <div>
                    <img src="../../../assets/images/guided-tour/HOGC_SG.svg" alt="HOGC" />
                    <br>
                    <div style="text-align:center; margin-left:auto; margin-right:auto; color:#231F20; font-size: 24px; font-weight: 500; line-height: 28px;">欢迎来到</div>
                    <img style="padding-bottom:18px;" src="../../../assets/images/gpcr/gpcr-2024-logo-dark.png" alt="Generations" />
                    <div style="padding-bottom:12px; text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">“累代”牧师特会App让你查看行程表、特会资料和你被分配的体验。</div>
                    <div style="padding-bottom:24px; text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">快速导览！</div>
                </div>
              `,
                homeTourTextStep0GC: `
                <div>
                    <img src="../../../assets/images/guided-tour/HOGC_SG.svg" alt="HOGC" />
                    <br>
                    <div style="text-align:center; margin-left:auto; margin-right:auto; color:#231F20; font-size: 24px; font-weight: 500; line-height: 28px;">欢迎来到</div>
                    <img style="padding-bottom:18px;" src="../../../assets/images/gpcr/gpcr-2024-logo-dark.png" alt="Generations" />
                    <div style="padding-bottom:12px; text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">“累代”牧师特会App让你查看行程表、特会资料和你被分配的体验。</div>
                    <div style="padding-bottom:24px; text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">快速导览！</div>
                </div>
              `,
                homeTourTextStep1: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">行程表</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">随时掌握特会的每日行程！</span> <br>`,
                homeTourTextStep2: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">体验</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">窥看幕后，看看“累代”和“强壮教会”的原则如何活灵活现！点击这里查看你被分配的体验。</span> <br>`,
                homeTourTextStep3: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">PhotoDrop</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">这里可浏览专属于你的特会照片——照片将实时更新！</span> <br>`,
                homeTourTextStep4: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">开始之前……</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">观看视频，了解建立“累代”和“强壮教会”所需的思维转换！</span> <br>`,
                homeTourTextStep5: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">简介和配置</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">你可以在这里更新你的联系方式，并选择你喜欢的语言！</span> <br> <br>`,
                scheduleTourStep1: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">行程表</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">查看完整的特会行程表。</span>`,
                scheduleTourStep2: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">行程表（每日）</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">查看课堂时间和地点。</span>`,
                scheduleTourStep3: `<span style="text-align:left; color:#231F20; font-size: 20px; line-height: 24px;">课堂资源</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 16px; line-height: 24px;">浏览“查看课堂资源”，你便准备就绪！</span>`,
              },
            },
          },
          languagePage: {
            title: "语言",
            english: "英文",
            chinese: "中文（简体）",
            thailand: "泰文",
          },
          profilePage: {
            showQr: "显示二维码",
            editProfile: "编辑资料",
            addImage: "添加图片",
            displayName: `显示名称<span style="font-style:italic;color:#637083;">（必填）</span>`,
            emptyName: "告诉我们你的名字！",
            organization: "教会/机构",
            title: "职衔",
            email: "电邮",
            showMyEmail: "显示我的电邮",
            accountLoginEmail: "登录邮箱",
            socials: "社群媒体",
            socialMedia: {
              addSocialsTitle: "添加社群账号",
              addInstagram: "添加Instagram",
              addFacebook: "添加Facebook",
              addX: "添加X",
              addLinkedIn: "添加LinkedIn",
            },
            saveChanges: "保存更改",
          },
          announcementPage: {
            title: "最新更新",
            announcementCard: {
              newLabel: "全新",
              today: "今天,",
            },
          },
          resourcesPage: {
            title: "资源",
            filterBy: "筛选条件",
            filterSection: {
              topics: "专题",
              sessions: "课堂",
              day: "第{ i }天：",
            },
            searchContent: {
              searchingStringText: `搜索结果为 "{searchTerm}"`,
              noResourcesSearchText: `你搜索的 "{searchTerm}" 未返回任何结果。`,
              noOfResourcesFound: `找到 {count} 个资源`,
            },
            videoPage: {
              videoChapters: "视频章节",
              yellowTag: {
                exclusive: "独家",
                audioOnly: "仅限音频",
              },
              shareButton: "分享",
            },
            description:
              "欢迎来到“累代”和“强壮教会”的一站式平台。使用这里的讲道、视频和工具，建造更年轻、更强壮的教会。",
            searchBar: "搜寻",
            resourceSearch: {
              bookSection: {
                header: "书籍",
              },
              quotesSection: {
                header: "Quotes",
              },
              suggestionSection: {
                header: "你可能感兴趣……",
              },
            },
            backToTopSection: {
              otherContentText: "想看看其他资源？",
              backToTop: "回到顶部",
            },
          },
          sessionResourcesPage: {
            accessLiveInterpretation: "实时翻译",
            sessionResourcesheader: "课堂资源",
            assets: {
              toolsHeader: "工具",
              questionsHeader: "讨论时间问题",
              linksHeader: "相关资源",
            },
          },
          experiencePage: {
            title: "体验",
            description: "窥看幕后，看看“累代”和“强壮教会”的原则如何活灵活现！",
            note: "📢 查询你将参加的体验",
            experienceList: {
              title:
                "每位代表在累代牧师特会（区域性）期间可以参加 4 个体验中的 1 个:",
              exp1: "祷告会体验",
              exp2: "事工复盘",
              exp3: "小组聚会",
              exp4: "梦想与故事夜晚",
              note: "📢 你可在 2024年11月9日（星期六）查询你所参加的体验。",
            },
            allocationPanel: {
              title: "你将参加",
              protipTitle: "💡专业小贴士：",
              protipHeader: "充分吸取体验的要点，思考以下问题:",
              protipQuestion1: "在我教会中可实践的有哪些？",
              protipQuestion2: "我要如何在我教会的环境中运用所学到的？",
              viewMapButton: "查看地图",
              viewMapButtonLink:
                "https://cdn.generationsmvmt.com/docs/zh/HOGC-Map-2024.pdf",
              viewFloorplanButton: "查看平面图",
              viewFloorplanButtonLink:
                "https://cdn.generationsmvmt.com/docs/zh/GPCR-Dream-and-Stories-Night-Floorplan.pdf",
            },
            carousel: {
              title: "了解全部{count}项体验",
              viewDetailsButton: "查看详情",
            },
          },
          schedulePage: {
            scheduleButton: "三天的行程表",
            schedule:
              "https://cdn.generationsmvmt.com/docs/GPCR-2024-Schedule-ZH.pdf",
            dayOne: {
              day: "周五",
              dayNum: "15日",
              month: "11月",
              announcement: `<div class="text-[16px] leading-[24px] font-aktiv-grotesk-trial text-black">你好</div>
              📣 报到前请先用早餐！记得带上耳机和外套！<br>`,
            },
            dayTwo: {
              day: "周六",
              dayNum: "16日",
              month: "11月",
              announcement: `<div class="text-[16px] leading-[24px] font-aktiv-grotesk-trial text-black">周末到了！</div>
              📣 报到前请先用餐！<br>`,
            },
            dayThree: {
              day: "周日",
              dayNum: "17日",
              month: "11月",
              announcement:
                '<div class="text-[16px] leading-[24px] font-aktiv-grotesk-trial text-black">来到精彩万分的最后一天了！</div>📣 报到前请先用早餐！',
            },
            eventsCard: {
              accessLiveInterpretation: "实时翻译",
              seeSessionResources: "课堂资源",
              viewMapButtonLink:
                "https://cdn.generationsmvmt.com/docs/zh/HOGC-Map-2024.pdf",
            },
            // experienceHeader: "体验",
            checkAllocation: "查看分配",
            backToTop: "回到顶部",
          },
          photoDropPage: {
            photoDropActionBar: {
              myQR: "个人QR",
              select: "选择",
              cancel: "取消",
              download: "下载",
              downloading: "正在下载",
              noOfSelectedPhotosText: "已选择 { count } 张照片",
            },
            myPhotosTab: {
              tabName: "我的照片",
            },
            forEveryoneTab: {
              tabName: "所有人",
              videosTab: "视频",
              dayTab: "第{ i }天",
              viewMore: "查看更多",
            },
            personalTab: {
              tabName: "个人",
              subtitle: "带上朋友和摄影师，开始拍照吧！",
              qrCodeModal: {
                subtitle: "直接获取你的照片！",
                instructions: {
                  one: '寻找任何穿着“PhotoDrop"白衣的神之心摄影师，并显示你的二维码！👕📸',
                  two: "拉上你的朋友拍照，再回来浏览照片！",
                },
              },
            },
            customExperiencesTab: {
              tabName: "定制体验",
              subtitle: "你参加的体验照片将会上传到这里！🎁",
            },
            selectButton: "选择",
            downloadModal: {
              header: "通过电子邮件获取下载链接",
              description: "下载所选视频/照片的链接将发送到你的电邮。",
              sendToMyEmail: "发送链接到我的电邮",
              cancel: "取消",
            },
          },
          teamPage: {
            title: `<div class="font-aktiv-grotesk-medium mx-auto w-full xl:min-w-[360px] text-center xl:text-left text-[24px] leading-[32px]">
                      全球团队欢迎你!
                    </div>`,
            backRowText: "后排，从左到右:",
            backRowNames: {
              pastorMartin: "Martin Wong牧师",
              blakeGladd: "Blake Gadd",
              nataschaBruschweiler: "Natascha Brüschweiler",
              nadineLeong: "Nadine Leong (思慧)",
            },
            frontRowText: "前排，从左到右:",
            frontRowNames: {
              jamieLee: "Jamie Lee",
              valerieFifi: "Valerie Fifi",
              christianHonegger: "Christian Honegger",
              tanJianMing: "Tan Jian Ming",
            },
            meetTheFullLeadershipTeam: "认识整个领导团队！",
          },
          sideBar: {
            home: "首页",
            resources: "资源",
            schedule: "行程表",
            team: "团队",
            updates: "更新",
            termOfUse: "使用条款",
            privacyPolicy: "隐私政策",
          },
          bottomNav: {
            home: "首页",
            resources: "资源",
            schedule: "行程表",
            team: "团队",
            updates: "更新",
          },
          masterLayout: {
            logoutPopup: {
              message: "是否要退出？",
              continue: "继续",
              cancel: "取消",
            },
            newsLetterModal: {
              header: "与你的团队分享",
              description: `填写他们的姓名和电邮。<br>我们将会发送 GenS App 的邀请给他们！`,
              inputNamePlaceholder: "你朋友的名字",
              inputEmailPlaceholder: "你朋友的电邮",
              errorMessage: {
                emptyNameInput: "名字是必填项。",
                emptyEmailInput: "电邮是必填项。",
                notValidEmail: "请输入有效电邮。",
                errorOnSubmit: "提交表格时发生错误。请稍后再试。",
              },
              subscribe: "发送电邮邀请",
              sent: "发送",
            },
            filterModal: {
              resetFilters: "重置",
              applyFilters: "使用筛选",
            },
          },
        },
      },
      th: {
        copy: {
          comma: {
            gpcr: {
              dayOne: "ศุกร์, 15 พ.ย.",
              dayTwo: "เสาร์, 16 พ.ย.",
              dayThree: "อาทิตย์, 17 พ.ย.",
            },
          },
          preSignInPage: {
            firstScreen: `<div class="px-[24px]">\
              <div class="text-center font-aktiv-grotesk font-semibold text-[24px] md:text-[32px] leading-[32px] md:leading-[40px]">ยินดีต้อนรับสู่แอป</div>\
                <div class="flex flex-row justify-center mx-auto items-end">
                  <img src="/assets/images/generations-app.svg" class="w-[190px] md:w-[258px]"/>
                </div>
              <img src="/assets/images/presignin/pre-signin-page-1.png" class="w-auto h-[40vh] md:py-[40px] mx-auto" style="padding-top: 3vh; padding-bottom: 2vh;" />\
              <div class="text-white w-[90vw] md:w-auto mx-auto text-center font-aktiv-grotesk-medium text-[20px] md:text-[28px] leading-[28px] md:leading-[32px]">\
                การสร้างสาวก GenerationS เพื่อสร้าง \
                <span class="whitespace-nowrap md:whitespace-auto">Strong Church</span>\
              </div>\
            </div>`,
            secondScreen: `<div class="pt-[8px] px-[24px]">\
              <div class="w-[90vw] md:w-[432px] text-center font-aktiv-grotesk font-semibold text-[20px] md:text-[32px] leading-[32px] md:leading-[40px] mx-auto">\
                ถึงเวลาที่จะทำให้คริสตจักรของเรา \
                <อ่อนวัย> และ <แข็งแกร่งขึ้น>!\
              </div>\
              <img src="/assets/images/presignin/pre-signin-page-2.png" class="w-auto h-[40vh] md:py-[40px] mx-auto" style="padding-top: 3vh; padding-bottom: 2vh;" />\
              <div class="text-white w-[90vw] md:w-auto mx-auto text-center font-aktiv-grotesk-medium text-[20px] md:text-[28px] leading-[28px] md:leading-[32px] mx-auto">\
                ใช้แอปนี้เพื่อติดตามข่าวสารล่าสุดของการประชุม\
              </div>\
            </div>`,
            thirdScreen: `<div class="pt-[8px] px-[24px]">\
              <div class="w-[90vw] md:w-[432px] mx-auto text-center font-aktiv-grotesk font-semibold text-[24px] md:text-[32px] leading-[28px] md:leading-[40px] mx-auto">\
                มันไม่ได้จบลงแค่ที่การประชุม…\
              </div>\
              <img src="/assets/images/presignin/pre-signin-page-3.png" class="w-auto h-[40vh] md:py-[40px] mx-auto" style="padding-top: 3vh; padding-bottom: 2vh;" />\
              <div class="text-white w-[90vw] md:w-auto mx-auto text-center font-aktiv-grotesk-medium text-[20px] md:text-[28px] leading-[28px] md:leading-[32px] mx-auto">\
                เข้าถึงทรัพยากรพิเศษของ GenerationS และ Strong Church ได้ต่อเนื่องผ่านแอปนี้!\
              </div>\
            </div>\
            </div>`,
            nextButton: "ถัดไป",
            loginButton: "ลงชื่อเข้าใช้",
          },
          homePage: {
            profileDropdown: {
              profile: "โปรไฟล์",
              language: "ภาษา",
              conference: "การประชุม",
              logout: "ออกจากระบบ",
            },
            conferenceType: {
              title: "การประชุม",
              gpcr: {
                name: "GenerationS Pastors Conference Regional",
              },
              gpc: {
                name: "GenerationS Pastors Conference",
              },
            },
            mainLayout: {
              topNav: {
                experiences: "ประสบการณ์",
                photoDrop: "PhotoDrop",
                updates: "อัปเดต",
              },
              scheduleSection: {
                title: "ตารางเวลา",
                schedulePanel: {
                  endOfDay: "สิ้นสุดวันที่ { day }",
                  tillNextTime: "แล้วพบกันใหม่",
                  seeYouTomorrow: "เจอกันพรุ่งนี้",
                  seeYouNextYear: "เจอกันในปี 2025",
                  viewSessionResources: `ต้องการดูทรัพยากรการประชุมหรือไม่?<br />ไปที่`,
                  viewSessionResourcesPage: "หน้าทรัพยากร เลย",
                  seeYouSoonText:
                    "เราดีใจที่คุณมาร่วมกับเรา! แล้วเจอกันใหม่เร็ว ๆ นี้ 🤩",
                  cantWaitToSeeYou:
                    "สวัสดี! เราแทบรอไม่ไหวที่จะได้เจอคุณเร็ว ๆ นี้",
                  // applyNow: "สมัครตอนนี้",
                  happeningNow: "Happening now ",
                  goToScheduleButton: "ไปที่ตารางเวลา",
                  viewNextDayScheduleButton: "ดูตารางเวลาของวันถัดไป",
                },
              },
              registration: {
                bannerName: "registration.png",
              },
              worshipSongs: {
                availableOnYoutube: "รับชมได้บน YouTube",
                titleImgUrl:
                  "../../../public/assets/images/home/playlist-th.svg",
              },
              kickOffSection: {
                title: `<p class="text-[32px] text-left leading-[40px] text-blue font-aktiv-grotesk-medium pb-4">
                          ก่อนที่เราจะเริ่ม<br class="hidden lg:block"/>...
                        </p>`,
                description: `<p class="text-[16px] text-left lg:w-[320px] mx-auto leading-[24px] text-blue pb-5 font-aktiv-grotesk-regular">
                                ดูวิดีโอสั้นนี้เพื่อรับภาพรวมเกี่ยวกับ GenerationS<br /> คุณจะพร้อมเต็มที่สำหรับ 3 วันที่จะมาถึง 😊
                              </p>`,
                downloadVideo: "ดาวน์โหลดวิดีโอ",
                downloadThumbnail:
                  "https://cdn.generationsmvmt.com/images/app/gpcr-pre-watch-thumbnail-th.jpg",
                downloadLink:
                  "https://cdn.generationsmvmt.com/videos/conference-app/gpcr-pre-watch-th.mp4",
              },
              experiences: {},
              usefulLinks: {
                title: "ลิงก์ที่เป็นประโยชน์",
                links: {
                  gettingAround: "การเดินทางใน HOGC 🚶🏻‍♂️",
                  mapLink:
                    "https://cdn.generationsmvmt.com/docs/th/HOGC-Map-2024.pdf",
                  appGuidedTour: "ทัวร์แนะนำแอป",
                  giving: "การบริจาค",
                  busSchedule: "ตารางรถรับ-ส่ง",
                  busSchedulePdf:
                    "https://cdn.generationsmvmt.com/docs/GPCR-2024-Shuttle-Bus-Schedule-TH.pdf",
                },
              },
              appGuidedTour: {
                startTour: "เริ่มการทัวร์",
                skipTour: "ข้ามการทัวร์",
                skipButton: "ข้าม",
                backButton: "กลับ",
                nextButton: "ถัดไป",
                endButton: "สิ้นสุด",
                stepPill: {
                  stepText: "ขั้นตอนที่",
                  ofText: "จาก",
                },
                homeTourTextStep0SCS: `
                <div>
                    <img src="../../../assets/images/guided-tour/HOGC_SG.svg" alt="HOGC" />
                    <br>
                    <div style="text-align:center; margin-left:auto; margin-right:auto; color:#231F20; font-size: 28px; font-weight: 500; line-height: 28px;">ยินดีต้อนรับสู่</div>
                    <img style="padding-bottom:18px;" src="../../../assets/images/gpcr/gpcr-2024-logo-dark.png" alt="Generations" />
                    <div style="padding-bottom:12px; text-align:left; color:#231F20; font-weight: 400; font-size: 20px; line-height: 24px;">แอปการประชุมสำหรับศิษยาภิบาล GenerationS ช่วยให้คุณดูตารางเวลาของคุณ เข้าถึงสื่อการประชุม และปรับแต่งประสบการณ์ต่างๆ</div>
                    <div style="padding-bottom:24px; text-align:left; color:#231F20; font-weight: 400; font-size: 20px; line-height: 24px;">มาเริ่มการทัวร์สั้นๆ กันเถอะ</div>
                </div>
              `,
                homeTourTextStep0GC: `
                <div>
                    <img src="../../../assets/images/guided-tour/HOGC_SG.svg" alt="HOGC" />
                    <br>
                    <div style="text-align:center; margin-left:auto; margin-right:auto; color:#231F20; font-size: 28px; font-weight: 500; line-height: 28px;">ยินดีต้อนรับสู่</div>
                    <img style="padding-bottom:18px;" src="../../../assets/images/gpcr/gpcr-2024-logo-dark.png" alt="Generations" />
                    <div style="padding-bottom:12px; text-align:left; color:#231F20; font-weight: 400; font-size: 20px; line-height: 24px;">แอปการประชุมสำหรับศิษยาภิบาล GenerationS ช่วยให้คุณดูตารางเวลาของคุณ เข้าถึงสื่อการประชุม และปรับแต่งประสบการณ์ต่างๆ</div>
                    <div style="padding-bottom:24px; text-align:left; color:#231F20; font-weight: 400; font-size: 20px; line-height: 24px;">มาเริ่มการทัวร์สั้นๆ กันเถอะ</div>
                </div>
              `,
                homeTourTextStep1: `<span style="text-align:left; color:#231F20; font-size: 24px; line-height: 24px;">ตารางเวลา</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 20px; line-height: 24px;">ติดตามตารางประจำวันตลอดการประชุม</span> <br>`,
                homeTourTextStep2: `<span style="text-align:left; color:#231F20; font-size: 24px; line-height: 24px;">ประสบการณ์</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 20px; line-height: 24px;">ไปดูเบื้องหลังเพื่อเห็น GenerationS และ Strong Church ที่มีชีวิตชีวา คลิกที่นี่เพื่อดูประสบการณ์ที่คุณได้รับมอบหมาย</span> <br>`,
                homeTourTextStep3: `<span style="text-align:left; color:#231F20; font-size: 24px; line-height: 24px;">PhotoDrop</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 20px; line-height: 24px;">ตรวจสอบภาพถ่ายการประชุมที่ปรับแต่งเฉพาะของคุณที่นี่ - อัปเดตแบบเรียลไทม์</span> <br>`,
                homeTourTextStep4: `<span style="text-align:left; color:#231F20; font-size: 24px; line-height: 24px;">ก่อนที่เราจะเริ่ม...</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 20px; line-height: 24px;">ชมเพื่อค้นหาการเปลี่ยนแปลงทางความคิดที่จะเริ่มสร้าง GenerationS และโบสถ์ที่แข็งแกร่ง</span> <br>`,
                homeTourTextStep5: `<span style="text-align:left; color:#231F20; font-size: 24px; line-height: 24px;">โปรไฟล์และการตั้งค่า</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 20px; line-height: 24px;">คุณสามารถอัปเดตรายละเอียดการติดต่อของคุณที่นี่และเลือกภาษาที่คุณต้องการ</span> <br> <br>`,
                scheduleTourStep1: `<span style="text-align:left; color:#231F20; font-size: 24px; line-height: 24px;">ตารางเวลา</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 20px; line-height: 24px;">เข้าถึงตารางเวลาการประชุมทั้งหมด</span>`,
                scheduleTourStep2: `<span style="text-align:left; color:#231F20; font-size: 24px; line-height: 24px;">ตารางเวลา (มุมมองรายวัน)</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 20px; line-height: 24px;">ดูเวลาการประชุมและสถานที่ประจำวัน</span>`,
                scheduleTourStep3: `<span style="text-align:left; color:#231F20; font-size: 24px; line-height: 24px;">สื่อการประชุม</span> <br> <br>
                    <span style="text-align:left; color:#231F20; font-weight: 400; font-size: 20px; line-height: 24px;">ตรวจสอบ 'ดูสื่อการประชุม' และคุณพร้อมสำหรับการประชุมแล้ว</span>`,
              },
            },
          },
          languagePage: {
            title: "ภาษา",
            english: "ภาษาอังกฤษ",
            chinese: "ภาษาจีนตัวย่อ",
            thai: "ภาษาไทย",
          },
          profilePage: {
            showQr: "แสดง QR",
            editProfile: "แก้ไขโปรไฟล์",
            addImage: "เพิ่มรูปภาพ",
            displayName: `ชื่อที่แสดง <span style="font-style:italic;color:#637083;">(จำเป็น)</span>`,
            emptyName: "บอกชื่อของคุณให้เราทราบ",
            organization: "โบสถ์ / องค์กร",
            title: "ตำแหน่ง",
            email: "อีเมล",
            showMyEmail: "แสดงอีเมลของฉัน",
            accountLoginEmail: "อีเมลสำหรับเข้าสู่ระบบบัญชี",
            socials: "โซเชียล",
            socialMedia: {
              addSocialsTitle: "เพิ่มโซเชียล",
              addInstagram: "เพิ่ม Instagram",
              addFacebook: "เพิ่ม Facebook",
              addX: "เพิ่ม X",
              addLinkedIn: "เพิ่ม LinkedIn",
            },
            saveChanges: "บันทึกการเปลี่ยนแปลง",
          },
          announcementPage: {
            title: "อัปเดตล่าสุด",
            announcementCard: {
              newLabel: "ใหม่",
              today: "วันนี้, ",
            },
          },
          resourcesPage: {
            title: "ทรัพยากร",
            filterBy: "กรองตาม",
            filterSection: {
              topics: "หัวข้อ",
              sessions: "การประชุม",
              day: "วันที่{ i }: ",
            },
            searchContent: {
              searchingStringText: `ผลลัพธ์การค้นหาสำหรับ "{searchTerm}"`,
              noResourcesSearchText: `การค้นหาของคุณสำหรับ "{searchTerm}" ไม่มีผลลัพธ์`,
              noOfResourcesFound: `พบทรัพยากร {count} รายการ`,
            },
            videoPage: {
              videoChapters: "บทในวิดีโอ",
              yellowTag: {
                exclusive: "พิเศษ",
                audioOnly: "เฉพาะเสียง",
              },
              shareButton: "แชร์",
            },
            description:
              "ยินดีต้อนรับสู่ศูนย์รวมทรัพยากรของ GenerationS และ Strong Church สำหรับคุณ ใช้คำเทศนา วิดีโอ และเครื่องมือต่าง ๆ เหล่านี้เพื่อทำให้โบสถ์ของคุณเติบโตขึ้นอย่างเยาว์วัยและแข็งแกร่งขึ้น",
            searchBar: "ค้นหา",
            resourceSearch: {
              bookSection: {
                header: "หนังสือ",
              },
              quotesSection: {
                header: "คำคม",
              },
              suggestionSection: {
                header: "คุณอาจสนใจใน...",
              },
            },
            backToTopSection: {
              otherContentText: "สนใจทรัพยากรอื่น ๆ หรือไม่",
              backToTop: "กลับไปด้านบน",
            },
          },
          sessionResourcesPage: {
            accessLiveInterpretation: "การแปลสด",
            sessionResourcesheader: "สื่อการประชุม",
            assets: {
              toolsHeader: "เครื่องมือ",
              questionsHeader: "คำถามเกี่ยวกับเวลาสนทนา",
              linksHeader: "ทรัพยากรที่เกี่ยวข้อง",
            },
          },
          experiencePage: {
            title: "ประสบการณ์",
            description:
              "ไปเบื้องหลังและดูหลักการของ GenerationS และ Strong Church ที่มีชีวิตขึ้นมา",
            note: "📢ค้นหาว่าคุณจะเข้าร่วมประสบการณ์ไหน",
            experienceList: {
              title:
                "ผู้เข้าร่วมแต่ละคนจะเข้าร่วม 1 ใน 4 ประสบการณ์ระหว่าง GPCR:",
              exp1: "ประสบการณ์การประชุมอธิษฐาน",
              exp2: "บรีฟภายหลังจากการรับใช้",
              exp3: "ประสบการณ์กลุ่มย่อย",
              exp4: "คืนแห่งความฝันและเรื่องราว",
              note: "📢 ค้นหาว่าคุณจะเข้าร่วมประสบการณ์ใดภายในวันเสาร์ที่ 9 พฤศจิกายน 2024",
            },
            allocationPanel: {
              title: "คุณจะเข้าร่วม",
              protipTitle: "💡เคล็ดลับ:",
              protipHeader:
                "ใช้ประโยชน์สูงสุดจากประสบการณ์ด้วยคำถามในการสะท้อนความคิด:",
              protipQuestion1: "ฉันสามารถนำอะไรไปใช้ในโบสถ์ของฉันได้บ้าง",
              protipQuestion2:
                "สิ่งนี้สามารถนำไปใช้ในบริบทของโบสถ์ของฉันได้อย่างไร",
              viewMapButton: "ดูแผนที่",
              viewMapButtonLink:
                "https://cdn.generationsmvmt.com/docs/th/HOGC-Map-2024.pdf",
              viewFloorplanButton: "ดูแผนผังชั้น",
              viewFloorplanButtonLink:
                "https://cdn.generationsmvmt.com/docs/th/GPCR-Dream-and-Stories-Night-Floorplan.pdf",
            },
            carousel: {
              title: "เรียนรู้เพิ่มเติมเกี่ยวกับประสบการณ์ทั้ง {count}",
              viewDetailsButton: "ดูรายละเอียด",
            },
          },
          schedulePage: {
            scheduleButton: "ตารางเวลา 3 วัน",
            schedule:
              "https://cdn.generationsmvmt.com/docs/GPCR-2024-Schedule-TH.pdf",
            dayOne: {
              day: "ศุกร์",
              dayNum: "15",
              month: "พ.ย.",
              announcement: `<div class="text-[16px] leading-[24px] font-aktiv-grotesk-trial text-black">สวัสดี</div>
              📣 ทานอาหารเช้าก่อนมา!<br class="md:hidden"> อย่าลืมนำหูฟังและเสื้อแจ็คเก็ตมาด้วยนะ<br>`,
            },
            dayTwo: {
              day: "เสาร์",
              dayNum: "16",
              month: "พ.ย.",
              announcement: `<div class="text-[16px] leading-[24px] font-aktiv-grotesk-trial text-black">สุดสัปดาห์แล้ว</div>
              📣 ทานอะไรก่อนมาด้วยนะ<br>`,
            },
            dayThree: {
              day: "อาทิตย์",
              dayNum: "17",
              month: "พ.ย.",
              announcement:
                '<div class="text-[16px] leading-[24px] font-aktiv-grotesk-trial text-black">วันสุดท้ายของเราจะเต็มไปด้วยเซสชันที่ทรงพลัง</div>📣 ทานอาหารเช้าก่อนมานะ',
            },
            eventsCard: {
              accessLiveInterpretation: "การแปลสด",
              seeSessionResources: "การประชุม",
              viewMapButtonLink:
                "https://cdn.generationsmvmt.com/docs/th/HOGC-Map-2024.pdf",
            },
            // experienceHeader: "ประสบการณ์",
            checkAllocation: "ตรวจสอบการจัดสรร",
            backToTop: "กลับไปด้านบน",
          },
          photoDropPage: {
            photoDropActionBar: {
              myQR: "ส่วนตัวQR",
              select: "เลือก",
              cancel: "ยกเลิก",
              download: "ดาวน์โหลด",
              downloading: "กำลังดาวน์โหลด",
              noOfSelectedPhotosText: "เลือกรูปภาพ { count } รูป",
            },
            myPhotosTab: {
              tabName: "รูปภาพของฉัน",
            },
            forEveryoneTab: {
              tabName: "สำหรับทุกคน",
              videosTab: "วิดีโอ",
              dayTab: "วันที่{ i }",
              viewMore: "ดูเพิ่มเติม",
            },
            personalTab: {
              tabName: "ส่วนตัว",
              subtitle: "ชวนเพื่อน ชวนช่างภาพ แล้วเริ่มถ่ายรูปกันเลย",
              qrCodeModal: {
                subtitle: "ให้รูปภาพของคุณถูกส่งตรงถึงคุณ",
                instructions: {
                  one: "แสดง QR Code นี้ให้ช่างภาพ HOGC คนใดที่สวมเสื้อ “PhotoDrop” สีขาว 👕📸",
                  two: "ชวนเพื่อนของคุณ มาถ่ายรูป และกลับมาที่นี่เพื่อดูรูป",
                },
              },
            },
            customExperiencesTab: {
              tabName: "ประสบการณ์ที่กำหนดเอง",
              subtitle: "รูปภาพจากประสบการณ์ที่คุณเข้าร่วมจะถูกส่งมาที่นี่ 🎁",
            },
            selectButton: "เลือก",
            downloadModal: {
              header: "รับลิงก์ดาวน์โหลดทางอีเมล",
              description:
                "ลิงก์จะถูกส่งไปยังอีเมลของคุณเพื่อดาวน์โหลดวิดีโอและ/หรือรูปภาพที่เลือก",
              sendToMyEmail: "ส่งลิงก์ไปยังอีเมลของฉัน",
              cancel: "ยกเลิก",
            },
          },
          teamPage: {
            title: `<div class="font-aktiv-grotesk-medium mx-auto w-full xl:min-w-[360px] text-center xl:text-left text-[24px] leading-[32px]">
                      สวัสดีจากทีม Global
                    </div>`,
            backRowText: "แถวหลัง จากซ้ายไปขวา:",
            backRowNames: {
              pastorMartin: "Pastor Martin Wong",
              blakeGladd: "Blake Gadd",
              nataschaBruschweiler: "Natascha Brüschweiler",
              nadineLeong: "Nadine Leong",
            },
            frontRowText: "แถวหน้า จากซ้ายไปขวา:",
            frontRowNames: {
              jamieLee: "Jamie Lee",
              valerieFifi: "Valerie Fifi",
              christianHonegger: "Christian Honegger",
              tanJianMing: "Tan Jian Ming",
            },
            meetTheFullLeadershipTeam: "พบกับทีมผู้นำทั้งหมด",
          },
          sideBar: {
            home: "หน้าแรก",
            resources: "ทรัพยากร",
            schedule: "ตารางเวลา",
            team: "ทีม",
            updates: "อัปเดต",
            termOfUse: "ข้อกำหนดการใช้งาน",
            privacyPolicy: "นโยบายความเป็นส่วนตัว",
          },
          bottomNav: {
            home: "หน้าแรก",
            resources: "ทรัพยากร",
            schedule: "ตารางเวลา",
            team: "ทีม",
            updates: "อัปเดต",
          },
          masterLayout: {
            logoutPopup: {
              message: "คุณต้องการออกจากระบบหรือไม่",
              continue: "ดำเนินการต่อ",
              cancel: "ยกเลิก",
            },
            newsLetterModal: {
              header: "แชร์กับทีมของคุณ",
              description: `กรอกชื่อและอีเมลของพวกเขา<br>เราจะส่งคำเชิญให้แต่ละคนเข้าร่วมแอป GenS`,
              inputNamePlaceholder: "ชื่อเพื่อนของคุณ",
              inputEmailPlaceholder: "อีเมลของเพื่อนของคุณ",
              errorMessage: {
                emptyNameInput: "ต้องระบุชื่อ",
                emptyEmailInput: "ต้องการอีเมล",
                notValidEmail: "โปรดป้อนที่อยู่อีเมลที่ถูกต้อง",
                errorOnSubmit:
                  "เกิดข้อผิดพลาดขณะส่งแบบฟอร์ม โปรดลองอีกครั้งในภายหลัง",
              },
              subscribe: "ส่งคำเชิญทางอีเมล",
              sent: "Sent",
            },
            filterModal: {
              resetFilters: "รีเซ็ต",
              applyFilters: "ใช้ตัวกรอง",
            },
          },
        },
      },
    },
  };
};

export default translation;
