<template>
  <master-layout>
    <div
      :class="{
        'top-0': !showHeader,
        'top-navbar-height': showHeader,
      }"
    >
      <div class="lg:w-[calc(100vw-292px)]">
        <div
          id="profileBanner"
          class="h-[200px] w-full"
          :class="{ '!h-[240px]': isIos() }"
        >
          <div
            class="z-10 relative top-[16px] left-[24px]"
            :class="{ '!top-[60px]': isIos() }"
          >
            <img
              src="../../../public/assets/icons/go-back-chevron.svg"
              alt="Back Icon"
              class="cursor-pointer"
              data-test-id="gensapp-experiencesignup-back"
              @click="back()"
            />
          </div>
          <div class="z-1 mt-[35px]" :class="{ '!mt-[79px]': isIos() }">
            <img
              :src="desktopProfileBanner.url"
              :class="desktopProfileBanner.width"
              class="hidden lg:block mx-auto"
            />
            <img
              :src="mobileProfileBanner.url"
              :class="mobileProfileBanner.width"
              class="lg:hidden mx-auto"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <div
          class="z-10 w-[327px] lg:w-full mt-[-48px] inline-flex lg:flex flex-col items-center gap-[24px]"
        >
          <div>
            <div
              v-if="!showProfilePic"
              class="flex items-center w-[96px] h-[96px] text-[#FFF] text-[40px] font-family-['aktiv-grotesk-medium'] justify-center leading-loose rounded-full bg-lightblue border-white border-[3px]"
            >
              {{ name.charAt(0) }}
            </div>
            <img
              v-if="showProfilePic"
              :src="profilePicture"
              class="w-[96px] h-[96px] rounded-full border-white border-[3px]"
            />
          </div>
          <div
            class="flex flex-col w-[327px] lg:w-full gap-3 text-center justify-center"
          >
            <div
              class="w-full font-semibold text-[20px] leading-[24px] font-['Proxima Nova']"
            >
              {{ name }}
            </div>
            <div class="w-full">
              <div class="font-[16px] font-semibold text-[16px] leading-[24px]">
                {{ organization }}
              </div>
              <div class="font-[16px] font-normal text-[14px] leading-[20px]">
                {{ title }}
              </div>
            </div>
            <div
              class="flex flex-row w-[120px] h-[40px] mx-auto font-aktiv-grotesk-trial block justify-center items-center rounded-[8px] bg-white view-qr-code-shadow"
            >
              <img
                src="../../../public/assets/icons/qrcode-viewfinder.svg"
                class="w-[32px] h-[32px]"
              />
              <button
                class="text-lightblue text-[16px] leading-[24px] text-center"
                @click="showQR()"
              >
                {{ $t("copy.profilePage.showQr") }}
              </button>
            </div>
          </div>
          <div
            v-if="showEmail() || instagram || facebook || x || linkedin"
            class="flex flex-col w-[327px] lg:w-[504px] py-[12px] px-[12px] rounded-[8px] bg-white border-solid border-[1px] border-grey-20"
          >
            <div v-if="showEmail()" class="mb-3">
              <div class="w-full font-semibold text-[16px] leading-[24px]">
                {{ $t("copy.profilePage.email") }}
              </div>
              <div
                class="w-full font-normal text-lightblue text-[16px] leading-[28px]"
              >
                {{ email }}
              </div>
            </div>
            <div
              v-if="instagram || facebook || x || linkedin"
              class="w-full font-semibold text-[16px] leading-[24px] mb-2"
            >
              {{ $t("copy.profilePage.socials") }}
            </div>
            <div class="flex flex-row w-full">
              <a
                v-if="instagram"
                class="w-[32px] h-[32px] mr-6"
                :href="instagramURL()"
                target="_blank"
              >
                <img
                  src="../../../public/assets/icons/logo-instagram.svg"
                  alt="Instagram Social"
                />
              </a>
              <a
                v-if="facebook"
                class="w-[32px] h-[32px] mr-6"
                :href="facebookURL()"
                target="_blank"
              >
                <img
                  src="../../../public/assets/icons/logo-facebook.svg"
                  alt="Facebook Social"
                />
              </a>
              <a
                v-if="x"
                class="w-[32px] h-[32px] mr-6"
                :href="xURL()"
                target="_blank"
              >
                <img
                  src="../../../public/assets/icons/logo-twitterx.svg"
                  alt="X Social"
                />
              </a>
              <a
                v-if="linkedin"
                class="w-[32px] h-[32px]"
                :href="linkedInURL()"
                target="_blank"
              >
                <img
                  src="../../../public/assets/icons/logo-linkedin.svg"
                  alt="LinkedIn Social"
                />
              </a>
            </div>
          </div>
          <div class="w-[327px] lg:w-full h-[48px]">
            <button
              class="font-aktiv-grotesk-trial w-full mx-auto inline-block rounded-[100px] bg-lightblue text-white text-[20px] leading-[24px] text-center py-[12px] mb-4"
              @click="editProfile()"
            >
              {{ $t("copy.profilePage.editProfile") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </master-layout>
</template>

<script>
import { mapState } from "vuex";
import { isIosNativeOnly } from "@/utils/platform-check";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { StatusBar, Style } from "@capacitor/status-bar";

export default {
  name: "Social",
  data() {
    return {
      showProfilePic: false,
    };
  },
  computed: {
    ...mapState("social", [
      "name",
      "organization",
      "title",
      "email",
      "emailFlag",
      "instagram",
      "facebook",
      "x",
      "linkedin",
      "profilePicture",
    ]),
    showHeader() {
      return this.$route.path !== "/home";
    },
    desktopProfileBanner() {
      const banners = {
        en: {
          url: require("../../../public/assets/images/profile-logo-desktop.svg"),
          width: "w-[930px]",
        },
        zh: {
          url: require("../../../public/assets/images/profile-logo-desktop-zh.svg"),
          width: "w-[626px]",
        },
        th: {
          url: require("../../../public/assets/images/profile-logo-desktop-th.svg"),
          width: "w-[841px]",
        },
      };
      return banners[this.$i18n.locale] || banners.en;
    },
    mobileProfileBanner() {
      const banners = {
        en: {
          url: require("../../../public/assets/images/profile-logo-mobile.svg"),
          width: "w-[351px]",
        },
        zh: {
          url: require("../../../public/assets/images/profile-logo-mobile-zh.svg"),
          width: "w-[160px]",
        },
        th: {
          url: require("../../../public/assets/images/profile-logo-mobile-th.svg"),
          width: "w-[334px]",
        },
      };
      return banners[this.$i18n.locale] || banners.en;
    },
  },
  async mounted() {
    if (navigator.userAgent.match(/samsung/i)) {
      this.isSamsung = true;
      console.log("You're samsung");
    } else {
      console.log("You're not samsung");
    }
    if (isIosNativeOnly()) {
      await StatusBar.setStyle({ style: Style.Dark });
    }
  },
  async ionViewWillEnter() {
    await this.$store.dispatch("social/fetchSocialProfile");
    if (isIosNativeOnly()) {
      await StatusBar.setStyle({ style: Style.Dark });
    }
    if (this.profilePicture) {
      this.showProfilePic = true;
    }
    FirebaseAnalytics.setScreenName({
      screenName: "profile - view",
      nameOverride: "ProfilePage - View",
    });
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
    back() {
      this.$router.push("/");
    },
    editProfile() {
      this.$router.push("/profile/edit");
    },
    showQR() {
      this.$router.push("/qr-code");
    },
    instagramURL() {
      return "https://www.instagram.com/" + this.instagram; // Remove as string when store has been re-adjusted
    },
    facebookURL() {
      return "https://www.facebook.com/" + this.facebook;
    },
    xURL() {
      return "https://www.x.com/" + this.x;
    },
    linkedInURL() {
      return "https://www.linkedin.com/in/" + this.linkedin;
    },
    showEmail() {
      if (this.email) {
        if (this.emailFlag) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.view-qr-code-shadow {
  box-shadow: 0px 1px 10px 0px #0000001a;
}
@media (min-width: 769px) {
  #profileBanner {
    background-image: url("../../../public/assets/images/bg-profileDesktop.png");
    background-size: 100% 100%; /* Stretch image to cover entire area */
    background-repeat: no-repeat; /* Prevent image from repeating */
    background-position: center; /* Center the background image */
  }
}

@media (max-width: 768px) {
  #profileBanner {
    background-image: url("../../../public/assets/images/bg-profileMobile.png");
    background-size: 100% 100%; /* Stretch image to cover entire area */
    background-repeat: no-repeat; /* Prevent image from repeating */
    background-position: center; /* Center the background image */
  }
}
</style>
