<template>
  <in-app-browser
    :id="videoId"
    :href="videoUrl"
    target="_blank"
    :data-event-tracking="dataEventTracking"
    @log-event="logYouTubeStart"
  >
    <div class="relative rounded-xl drop-shadow-md">
      <div class="relative cursor-pointer">
        <img
          :class="customClass"
          :src="posterUrl"
          :alt="alt"
          :height="posterHeight"
          :width="posterWidth"
          :data-event-tracking="dataEventTracking"
        />

        <div
          v-if="title && !isVideoShown"
          class="absolute bottom-0 left-0 thumbnail-overlay w-full h-full flex p-4 cursor-pointer"
        >
          <p
            class="mt-auto font-aktiv-grotesk font-bold text-white text-18-lh-24 z-10"
          >
            {{ title }}
          </p>
        </div>
        <img
          class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
          :src="playImage"
          alt="Play"
        />
        <div
          v-if="durationTxt"
          class="w-[43px] h-[20px] absolute bottom-3 right-3 md:bottom-4 md:right-4 rounded bg-grey-100 opacity-60 text-grey-5 text-[14px] leading-[20px] font-roboto text-center"
        >
          {{ durationTxt }}
        </div>
      </div>
    </div>
  </in-app-browser>
</template>

<script>
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
export default {
  props: {
    videoId: {
      type: String,
      required: true,
    },
    videoUrl: {
      type: String,
      required: true,
    },
    posterUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    isDarkButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    posterWidth: {
      type: Number,
      default: null,
    },
    posterHeight: {
      type: Number,
      default: null,
    },
    dataEventTracking: {
      type: String,
      default: "",
    },
    durationTxt: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    preferredCaption: {
      type: String,
      default: null,
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    playImage() {
      return this.isDarkButton
        ? require("../../../../public/assets/icons/youtube-player/play-button-dark.svg")
        : require("../../../../public/assets/icons/youtube-player/play-button.svg");
    },
  },
  methods: {
    logYouTubeStart() {
      const videoTitle = this.posterUrl.split("/").pop().split(".").at(0);
      FirebaseAnalytics.logEvent({
        name: "video_play",
        params: {
          video_title: videoTitle,
          video_status: "start",
          video_provider: "html",
        },
      });
    },
  },
};
</script>
