<template>
  <empty-layout>
    <div
      class="w-full h-full flex justify-center text-black font-proxima-nova"
      :class="isIos() ? 'pt-[60px]': ''"
    >
      <div v-html="accessDenied.htmlPage"></div>
    </div>
  </empty-layout>
</template>

<script>
import EmptyLayout from "@/components/EmptyLayout.vue";
import { isIosNativeOnly } from "@/utils/platform-check";
import axios from "axios";
export default {
  components: { EmptyLayout },
  name: "access-denied",
  data() {
    return {
      accessDenied: {
        htmlPage: ''
      },
    }
  },
  async ionViewDidEnter() {
    await this.fetchCVP()
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
    async fetchCVP() {
      const { data } = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/access-denied-page",
      );
      // console.log("DATA", data);
      this.accessDenied = data;
      // console.log("CVPOBJ: ", this.cvpObj);
    },
  },
};
</script>

<style scope></style>
