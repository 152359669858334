<template>
  <master-layout pageTitle="Team">
    <div
      class="w-full flex justify-start items-center pt-[48px] md:pt-[48px] xl:w-[1000px] xl:pt-[80px] pb-[80px] md:pb-[48px] fit-content"
      :class="{ 'pt-[92px]': isIos() }"
    >
      <div
        class="text-center overflow-hidden xl:flex xl:flex-col relative mx-6 md:mx-[108px] xl:ml-[70px] xl:mr-auto xl:min-w-[360px] xl:max-w-[360px] xl:text-left"
      >
        <div v-html="$t('copy.teamPage.title')"></div>
        <div>
          <img
            src="../../../public/assets/images/team/team.png"
            class="w-auto mt-10 xl:hidden"
          />
        </div>
        <div
          class="text-[18px] leading-[24px] text-left mt-6 font-aktiv-grotesk"
        >
          <p class="text-grey-60">{{ $t("copy.teamPage.backRowText") }}</p>
          <p class="text-grey-80">
            <span class="whitespace-nowrap"
              >{{ $t("copy.teamPage.backRowNames.pastorMartin") }} |
            </span>
            <span class="whitespace-nowrap"
              >{{ $t("copy.teamPage.backRowNames.blakeGladd") }} |
            </span>
            <br />
            <span class="whitespace-nowrap"
              >{{ $t("copy.teamPage.backRowNames.nataschaBruschweiler") }} |
            </span>
            <br />
            <span class="whitespace-nowrap">{{
              $t("copy.teamPage.backRowNames.nadineLeong")
            }}</span>
          </p>
        </div>
        <div
          class="text-[18px] leading-[24px] text-left mt-6 font-aktiv-grotesk"
        >
          <p class="text-grey-60">{{ $t("copy.teamPage.frontRowText") }}</p>
          <p class="text-grey-80">
            <span class="whitespace-nowrap"
              >{{ $t("copy.teamPage.frontRowNames.jamieLee") }} |
            </span>
            <span class="whitespace-nowrap"
              >{{ $t("copy.teamPage.frontRowNames.valerieFifi") }} |
            </span>
            <br />
            <span class="whitespace-nowrap"
              >{{ $t("copy.teamPage.frontRowNames.christianHonegger") }} |
            </span>
            <br class="xs:hidden" />
            <span class="whitespace-nowrap">{{
              $t("copy.teamPage.frontRowNames.tanJianMing")
            }}</span>
          </p>
        </div>
        <div class="flex justify-center xl:justify-start">
          <in-app-browser
            href="https://page.heartofgodchurch.org/leadership/"
            target="_blank"
            data-event-tracking="gensapp-team-meetthefullleadershipteam-button"
            @click="logEvent()"
          >
            <div
              class="cursor-pointer px-5 py-3 bg-lightblue text-grey-5 font-aktiv-grotesk-medium text-[20px] leading-[24px] rounded-full mt-10"
            >
              {{ $t("copy.teamPage.meetTheFullLeadershipTeam") }}
            </div>
          </in-app-browser>
        </div>
      </div>
      <img
        src="../../../public/assets/images/team/team.png"
        class="xl:w-[504px] xl:h-[380px] hidden xl:block"
      />
    </div>
  </master-layout>
</template>

<script>
import { isIosNativeOnly } from "@/utils/platform-check";
import { StatusBar, Style } from "@capacitor/status-bar";
import { mapState } from "vuex";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export default {
  name: "TeamPage",
  computed: {
    ...mapState("user", ["token"]),
  },
  async ionViewDidEnter() {
    if (isIosNativeOnly()) {
      await StatusBar.setStyle({ style: Style.Light });
    }
    await this.$store.dispatch("announcements/refreshAnnouncements", {
      locale: this.$i18n.locale,
      conferenceType: "Regional",
    });
    await this.$store.dispatch("announcements/fetchUnreadCount", "Regional");
    FirebaseAnalytics.setScreenName({
      screenName: "team",
      nameOverride: "TeamPage",
    });
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
    logEvent() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "gensapp-team-meetthefullleadershipteam-button",
          page: "schedule",
        },
      });
    },
  },
};
</script>
<style scoped>
.fit-content {
  height: fit-content;
}
</style>
