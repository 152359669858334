<template>
  <master-layout>
    <div
      class="w-full h-full flex flex-col mt-4"
      :class="{ 'pt-12': isIos || isAndroid }"
    >
      <div>
        <div class="mb-6 px-[24px] md:px-[32px] lg:px-[56px]">
          <img
            src="../../../../public/assets/icons/go-back-chevron.svg"
            alt="Back Icon"
            class="cursor-pointer"
            @click="back"
          />
        </div>

        <div
          class="flex items-center flex-row mx-[24px] md:ml-[96px] lg:mx-[96px] h-[48px] relative justify-between"
        >
          <!-- Filter Button -->
          <div
            class="border-2 border-lightblue px-3 py-[.375rem] text-center font-semibold font-aktiv-grotex-trial whitespace-nowrap text-white bg-lightblue rounded-full transition duration-75 ease-linear cursor-pointer"
            @click="openFilterModal(filterType)"
          >
            {{ translatedFilterType }}
          </div>
          <p
            class="absolute flex flex-row text-[14px] relative text-grey-80 font-['AktivGrotesk-Regular']"
          >
            {{
              $t("copy.resourcesPage.searchContent.noOfResourcesFound", {
                count: (filteredResources?.length || 0) + (filteredQuotes?.length || 0) + (filteredBooks?.length || 0),
              })
            }}
          </p>
        </div>

        <div
          v-if="!showFilterModal"
          class="font-aktiv-grotesk flex flex-row ml-[24px] md:mx-[96px] h-[48px] relative"
        >
          <FilterResultSection
            :filteredResources="filteredResources"
            :filteredQuotes="filteredQuotes"
            :filteredBooks="filteredBooks"
          />
        </div>
      </div>
    </div>
  </master-layout>
</template>

<script>
import { mapState } from "vuex";
import FilterResultSection from "@/components/ResourcesPage/PlaybackResources/FilterResultSection.vue";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { isIosNativeOnly, isAndroidNativeOnly } from "@/utils/platform-check";

export default {
  name: "ResourceFilter",
  components: {
    FilterResultSection,
  },
  layout: "library",
  computed: {
    ...mapState("filter", [
      "filteredSessions",
      "filteredTopics",
      "filteredTypes",
      "showFilterResults",
      "showFilterModal",
      "filteredMaterials",
      "filteredResources",
      "filteredQuotes",
      "filteredBooks",
      "assets",
      "allResources",
      "filterType",
    ]),
    isIos() {
      return isIosNativeOnly();
    },
    isAndroid() {
      return isAndroidNativeOnly();
    },
    translatedFilterType() {
      if (this.filterType == "Sessions") {
        return this.$t("copy.resourcesPage.filterSection.sessions");
      }
      if (this.filterType == "Topics") {
        return this.$t("copy.resourcesPage.filterSection.topics");
      }
    },
  },
  async ionViewDidEnter() {
    if (!this.filterType) {
      this.$router.push("/resources");
    }
    FirebaseAnalytics.setScreenName({
      screenName: "resources - filter",
      nameOverride: "ResourcesPage - Filter",
    });
  },
  async ionViewDidLeave() {
    const filterType = this.filterType;
    const showFilterModal = false;
    await this.$store.dispatch("filter/updateShowFilterModal", {
      showFilterModal,
      filterType,
    });
  },
  methods: {
    back() {
      this.$router.back();
    },
    async openFilterModal(type) {
      const filterType = type;
      const showFilterModal = true;
      await this.$store.dispatch("filter/updateShowFilterModal", {
        showFilterModal,
        filterType,
      });
    },
  },
};
</script>
