<template>
  <div
    ref="resource-container"
    class="max-w-full overflow-x-hidden overflow-y-scroll flex flex-col lg:flex-1 min-h-screen"
  >
    <div
      class="px-6 pt-[40px] pb-6 bg-grey-10 overflow-visible md:px-[56px]"
      :class="{ 'pt-[84px]': isIos() }"
    >
      <h1
        class="text-grey-100 text-[24px] leading-[32px] font-aktiv-grotesk-medium"
      >
        {{ $t("copy.resourcesPage.title") }}
      </h1>

      <div v-if="isSCS()" class="flex flex-row items-center gap-2 mt-4">
        <div
          class="text-grey-100 text-[16px] leading-[24px] font-aktiv-grotesk-medium"
        >
          {{ $t("copy.resourcesPage.filterBy") }}
        </div>
        <div
          v-for="page in pagesSCS"
          :key="page.displayText"
          class="border-2 border-lightblue px-3 py-[.375rem] text-center font-semibold font-aktiv-grotex-trial whitespace-nowrap text-lightblue rounded-full transition duration-75 ease-linear cursor-pointer hover:bg-lightblue hover:text-white"
          :data-event-tracking="scsTabDET(page)"
          :data-test-id="'gensapp-resources-scs-tabs-' + page.displayText"
          @click="openFilterModal(page)"
        >
          {{ page.translatedText }}
        </div>
      </div>
      <div v-else class="flex flex-wrap gap-2">
        <router-link
          v-for="page in pagesGC"
          :key="page.displayText"
          :to="page.path"
          class="border-2 border-lightblue px-3 py-[.375rem] text-center font-semibold font-aktiv-grotex-trial whitespace-nowrap rounded-full transition duration-75 ease-linear hover:bg-white"
          :class="{
            'bg-lightblue text-white pointer-events-none':
              $route.path === page.path,
            'text-lightblue': $route.path !== page.path,
          }"
          :data-event-tracking="gcTabDET(page)"
          :data-test-id="'gensapp-resources-gc-tabs-' + page.displayText"
          @click="logGCEvent(page)"
        >
          {{ page.displayText }}
        </router-link>
      </div>
      <!-- <div class="flex flex-wrap gap-2 mt-6">
        <router-link
          key="More Resources"
          to="/resources/more-resources"
          class="border-2 border-lightblue px-3 xs:px-4 py-[.375rem] text-center font-semibold font-aktiv-grotex-trial whitespace-nowrap rounded-full transition duration-75 ease-linear hover:bg-white"
          :class="{
            'bg-lightblue text-white pointer-events-none':
              $route.path === '/resources/more-resources',
            'text-lightblue': $route.path !== '/resources/more-resources',
          }"
          data-test-id="gensapp-resourcespage-scs-tabs-more-resources"
        >
          More Resources
        </router-link>
      </div> -->
    </div>

    <div class="bg-grey-5 pt-10 pb-8 relative w-full flex-1 md:px-[56px]">
      <slot></slot>
    </div>
    <!-- <div
      v-else-if="isQuotes()"
      class="bg-white px-[24px] py-8 relative w-full flex-1 md:px-[56px]"
    >
      <slot></slot>
    </div>
    -->

    <div
      v-if="showBackToTop"
      class="px-[24px] md:pl-[56px] flex flex-col items-center pb-20 sm:flex-row bg-[#F8F9F9]"
    >
      <p
        class="font-aktiv-grotesk-trial text-center xs:text-left font-semibold text-[20px] text-grey-100 sm:mr-2"
      >
        {{ $t("copy.resourcesPage.backToTopSection.otherContentText") }}
      </p>
      <div
        class="flex items-center cursor-pointer hover:opacity-90"
        data-test-id="gensapp-resourcespage-backtotop"
        @click="scrollToTop"
      >
        <p
          class="font-aktiv-grotesk-trial font-semibold text-[20px] text-lightblue mr-2 hover:underline"
        >
          {{ $t("copy.resourcesPage.backToTopSection.backToTop") }}
        </p>
        <img
          class="w-[12px]"
          src="../../../public/assets/images/chevron-up-blue.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { isIosNativeOnly } from "@/utils/platform-check";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export default {
  name: "ResourcesContainer",
  props: {
    showMoreResources: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allPagesGC: [
        {
          path: "/resources/sessions",
          displayText: "Sessions",
        },
        // {
        //   path: "/resources/learning-communities",
        //   displayText: "Learning Community",
        // },
        {
          path: "/resources/book",
          displayText: "Book",
        },
        {
          path: "/resources/more-resources",
          displayText: "More Resources",
        },
      ],
      showBackToTop: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.onResize();
    }, 4000);

    // this.$nextTick(function () {
    //   this.onResize();
    // });
    window.addEventListener("resize", this.onResize);
  },
  computed: {
    ...mapState("cms", ["loading"]),
    ...mapGetters("profile", ["type"]),
    pagesGC() {
      if (!this.showMoreResources) return this.allPagesGC.slice(0, 2);

      return this.allPagesGC;
    },
    pagesSCS() {
      if (!this.showMoreResources) return this.allPagesSCS.slice(0, 2);

      return this.allPagesSCS;
    },
    allPagesSCS() {
      return [
        {
          path: "/resources/book",
          displayText: "Topics",
          translatedText: this.$t("copy.resourcesPage.filterSection.topics"),
        },
        {
          path: "/resources/sessions",
          displayText: "Sessions",
          translatedText: this.$t("copy.resourcesPage.filterSection.sessions"),
        },
        {
          path: "/resources/more-resources",
          displayText: "More Resources",
          translatedText: "More Resources",
        },
      ];
    },
  },
  watch: {
    loading(newState, oldState) {
      setTimeout(() => {
        this.onResize();
      }, 4000);
    },
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
    scsTabDET(page) {
      const pathName = page.path;
      const pos = pathName.lastIndexOf("/");
      const result =
        "gensapp-resources-scs-tabs-" + pathName.substring(pos + 1);
      return result;
    },
    gcTabDET(page) {
      const pathName = page.path;
      const pos = pathName.lastIndexOf("/");
      const result = "gensapp-resources-gc-tabs-" + pathName.substring(pos + 1);
      return result;
    },
    async openFilterModal(page) {
      const filterType = page.displayText;
      const showFilterModal = true;
      await this.$store.dispatch("filter/updateShowFilterModal", {
        showFilterModal,
        filterType,
      });

      const pathName = page.path;
      const pos = pathName.lastIndexOf("/");
      const result =
        "gensapp-resources-scs-tabs-" + pathName.substring(pos + 1);
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: result,
          page: "resources",
        },
      });
    },
    logGCEvent(page) {
      const pathName = page.path;
      const pos = pathName.lastIndexOf("/");
      const result = "gensapp-resources-gc-tabs-" + pathName.substring(pos + 1);
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: result,
          page: "resources",
        },
      });
    },
    scrollToTop() {
      const MAP = {
        "/resources/book": "contentResourceBook",
        "/resources/sessions": "contentResourceSessions",
        "/resources/learning-communities": "contentResourceLearningCommunity",
        "/resources/more-resources": "contentResourceMoreResources",
        "/gpcr/resources/sessions": "gpcrContentResourceSessions",
      };

      const elem = document.getElementById(MAP[this.$route.path]);
      console.log(elem);
      elem.scrollToTop(1000);
    },
    isQuotes() {
      return this.$route.path === "/resources/quotes";
    },
    isTools() {
      return this.$route.path === "/resources/tools";
    },
    isSCS() {
      if (this.type === "SCS") {
        return true;
      } else return false;
    },
    onResize() {
      const element = this.$refs["resource-container"];
      if (!element) return (this.showBackToTop = false);

      const screenHeight =
        window.screen.width < 768
          ? window.screen.height
          : window.screen.height - 88;
      this.showBackToTop = element.scrollHeight > screenHeight + 160;
    },
  },
};
</script>
