<template>
  <div>
    <div
      v-for="(resource, i) in allResources"
      :ref="resource.slug"
      :key="i"
      class="mt-[40px] w-[calc(100vw-24px)] ml-[24px] lg:ml-0 lg:w-full bg-[#F8F9F9]"
    >
      <h1
        class="mb-[16px] font-aktiv-grotesk text-[24px] leading-[32px] font-bold"
      >
        {{ resource.name }}
      </h1>
      <ResourcesSlider
        :tag-slug="resource.slug"
        :category-index="i"
        :items="resource.resources"
      />
    </div>
    <div
      v-if="allBooks.length > 0"
      class="mt-[40px] w-[calc(100vw-24px)] ml-[24px] lg:ml-0 lg:w-full bg-[#F8F9F9]"
    >
      <h1
        class="mb-[16px] font-aktiv-grotesk text-[24px] leading-[32px] font-bold"
      >
        {{ allBooks[0].name }}
      </h1>
      <div class="rounded-[16px] w-[228px] md:w-[262px] md:pr-6 lg:w-[287px]">
        <in-app-browser :href="bookUrl" target="_blank">
          <img
            class="w-full rounded-[16px] cursor-pointer"
            :src="allBooks[0].resources[0].thumbnail"
          />
        </in-app-browser>
      </div>
    </div>
    <!-- <div
      v-if="allQuotes.length > 0"
      class="mt-[40px] w-[calc(100vw-24px)] ml-[24px] pb-[80px] lg:ml-0 lg:w-full bg-[#F8F9F9]"
    >
      <h1
        class="mb-[16px] font-aktiv-grotesk text-[24px] leading-[32px] font-bold"
      >
        {{ allQuotes[0].name }}
      </h1>
      <ResourcesSlider
        :tag-slug="allQuotes[0].slug"
        :category-index="i"
        :items="allQuotes[0].resources"
      />
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import ResourcesSlider from "@/components/ResourcesPage/PlaybackResources/ResourcesSlider.vue";

export default {
  components: {
    ResourcesSlider,
  },
  computed: {
    ...mapState("filter", [
      "allResources",
      "allQuotes",
      "allBooks",
      "isLoading",
    ]),
    bookUrl() {
      if (this.allBooks) {
        return this.allBooks[0].resources[0].resourceTranslations[0].resourceUrl;
      }
      return "";
    },
  },
};
</script>
