<template>
  <div class="w-full">
    <video
      :ref="`video-player-${id}`"
      :class="cssClasses"
      class="video-js w-full vjs-big-play-centered"
    ></video>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-contrib-quality-levels";
import "@mycujoo/videojs-hls-quality-selector";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
videojs.Vhs.GOAL_BUFFER_LENGTH = 30;
videojs.Vhs.MAX_GOAL_BUFFER_LENGTH = 60 * 60;

// Get the Component base class from Video.js
const Component = videojs.getComponent('Component')

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class TitleBar extends Component` would work
// identically.
const MyButton = videojs.extend(Component, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor(player, options) {
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    Component.apply(this, arguments)

    // If a `text` option was passed in, update the text content of
    // the component.
    if (options.text) {
      this.updateTextContent(options.text)
    }
  },

  // The `createEl` function of a component creates its DOM element.
  createEl() {
    return videojs.createEl('div', {
      // Prefixing classes of elements within a player with "vjs-"
      // is a convention used in Video.js.
      className: 'vjs-button',
    })
  },

  // This function could be called at any time to update the text
  // contents of the component.
  updateTextContent(text) {
    // If no text was provided, default to "Title Unknown"
    if (typeof text !== 'string') {
      text = 'Title Unknown'
    }

    // Use Video.js utility DOM methods to manipulate the content
    // of the component's element.
    videojs.emptyEl(this.el())
    videojs.appendContent(this.el(), text)
  },
})

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('MyButton', MyButton)

export default {
  name: "VideoPlayer",
  props: {
    metadata: {
      type: Object,
      required: true,
      validator(metadata) {
        return (
          metadata.url && metadata.mime && metadata.thumbnail && metadata.title
        );
      },
    },
    id: {
      type: String,
      required: true,
    },
    cssClasses: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      player: null,
      lastTimeUpdate: 0,
      accumulatedTimeWatched: 0,
      sendInterval: 60, // in seconds
      progressTracked: [], // Keeps track of the progress points already tracked
      playerOptions: {
        autoplay: false,
        controls: true,
        playsinline: true,
        playbackRates: [0.5, 1, 1.5, 2],
        poster: this.metadata.thumbnail,
        sources: [
          {
            src: this.metadata.url,
            type: this.metadata.mime,
          },
        ],
        fluid: true,
        html5: {
          nativeTextTracks: false, // TODO: Need to make this dynamic when we have subtitles. Hard code it temporary to fix an iOS issue where it shows "Unknown CC"
          vhs: {
            experimentalBufferBasedABR: true,
          },
          hls: {
            limitRenditionByPlayerDimensions: true,
            useDevicePixelRatio: true,
            bandwidth: 6194304,
          },
        },
      },
      tenFlag: false,
      thrityFlag: false,
      fiftyFlag: false,
      seventyFlag: false,
      ninetyFlag: false,
    };
  },
  mounted() {
    this.player = videojs(
      this.$refs[`video-player-${this.id}`],
      this.playerOptions,
      () => {
        this.player.log("onPlayerReady", this);
      }
    );
    // console.log(this.isSafari());
    // if (!this.isSafari()) {
    //   this.player.hlsQualitySelector({
    //     displayCurrentQuality: true,
    //   });
    // }
    // const qualityLevels = this.player.qualityLevels();

    // qualityLevels.on("addqualitylevel", function (event) {
    //   const qualityLevel = event.qualityLevel;

    //   qualityLevel.enabled = qualityLevel.height >= 720;
    //   if (qualityLevel.height < 720) {
    //     qualityLevels.removeQualityLevel(qualityLevel);
    //   }
    // });
    this.addVideoListeners();
    
    this.player.on("play", function () {
      console.log("video started...");
      
      FirebaseAnalytics.logEvent({
        name: "video_play",
        params: {
          video_title: this.player().currentSrc().substring(this.player().currentSrc().indexOf(".com/") + 1),
          video_status: "start",
          video_provider: "html",
        },
      });
    });
    this.player.on("ended", function () {
      console.log("video ended...");
      FirebaseAnalytics.logEvent({
        name: "video_play",
        params: {
          video_title: this.player().currentSrc().substring(this.player().currentSrc().indexOf(".com/") + 1),
          video_status: "completed",
          video_provider: "html",
        },
      });
    });
    this.player.on("progress", function () {
      const percentage = Math.round(
        (this.player().currentTime() / this.player().duration()) * 100
      );

      console.log("precentatge", percentage);
      if (percentage >= 90 && !this.ninetyFlag) {
        FirebaseAnalytics.logEvent({
          name: "video_play",
          params: {
            video_title: this.player().currentSrc().substring(this.player().currentSrc().indexOf("/") + 1),
            video_percent: "90",
            video_status: "progress",
            video_provider: "html",
          },
        });
        this.ninetyFlag = true;
      } else if (percentage >= 70 && !this.seventyFlag) {
        FirebaseAnalytics.logEvent({
          name: "video_play",
          params: {
            video_title: this.player().currentSrc().substring(this.player().currentSrc().indexOf("/") + 1),
            video_percent: "70",
            video_status: "progress",
            video_provider: "html",
          },
        });
        this.seventyFlag = true;
      } else if (percentage >= 50 && !this.fiftyFlag) {
        FirebaseAnalytics.logEvent({
          name: "video_play",
          params: {
            video_title: this.player().currentSrc().substring(this.player().currentSrc().indexOf("/") + 1),
            video_percent: "50",
            video_status: "progress",
            video_provider: "html",
          },
        });
        this.fiftyFlag = true;
      } else if (percentage >= 30 && !this.thirtyFlag) {
        FirebaseAnalytics.logEvent({
          name: "video_play",
          params: {
            video_title: this.player().currentSrc().substring(this.player().currentSrc().indexOf("/") + 1),
            video_percent: "30",
            video_status: "progress",
            video_provider: "html",
          },
        });
        this.thirtyFlag = true;
      } else if (percentage >= 10 && !this.tenFlag) {
        FirebaseAnalytics.logEvent({
          name: "video_play",
          params: {
            video_title: this.player().currentSrc().substring(this.player().currentSrc().indexOf("/") + 1),
            video_percent: "10",
            video_status: "progress",
            video_provider: "html",
          },
        });
        this.tenFlag = true;
      }
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.sendAccumulatedTimeWatched(); // Ensure to send any accumulated time when component is destroyed

      if (this.player.audioTracks) {
        const tracks = this.player.audioTracks();
        tracks.removeEventListener("change", this.trackAudioChange);
      }

      this.player.dispose();
    }
  },
  methods: {
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
    addVideoListeners() {
      this.player.on("timeupdate", this.trackWatchDuration);
      this.player.on("ended", this.trackVideoEnd);
      this.player.on("play", this.trackVideoStart);
      this.player.on("loadeddata", this.updateAudioTrackLabels);

      if (this.player.audioTracks) {
        const tracks = this.player.audioTracks();
        tracks.addEventListener("change", this.trackAudioChange);
      }
    },
    trackWatchDuration() {
      const currentTime = this.player.currentTime();
      const totalDuration = this.player.duration();

      // Calculate time watched
      let timeWatched = currentTime - this.lastTimeUpdate;
      if (timeWatched < 0) {
        timeWatched = 0;
      }
      this.accumulatedTimeWatched += timeWatched;

      // Track progress
      const progressPoints = [0.1, 0.25, 0.5, 0.75, 0.9, 1];
      for (const point of progressPoints) {
        if (
          currentTime >= point * totalDuration &&
          !this.progressTracked.includes(point)
        ) {
          this.trackVideoProgress(point);
          this.progressTracked.push(point);
        }
      }

      // Send event to Google Analytics 4 every 60 seconds
      if (this.accumulatedTimeWatched >= this.sendInterval) {
        this.sendAccumulatedTimeWatched();
      }

      this.lastTimeUpdate = currentTime;
    },
    trackVideoProgress(progress) {
      const progressPercentage = progress * 100;

      if (window.gtag) {
        window.gtag("event", "video_progress", {
          video_title: this.metadata.title,
          progress_percentage: progressPercentage,
        });
      }
    },
    trackVideoStart() {
      if (this.player.currentTime() <= 0) {
        // Reset progress tracking when video starts over
        this.progressTracked = [];

        if (window.gtag) {
          window.gtag("event", "video_start", {
            video_title: this.metadata.title,
          });
        }
      }
    },
    trackVideoEnd() {
      this.sendAccumulatedTimeWatched();

      if (window.gtag) {
        window.gtag("event", "video_end", {
          video_title: this.metadata.title,
        });
      }
    },
    seek(seconds) {
      this.player.currentTime(seconds);
    },
    play() {
      this.player.play();
    },
    sendAccumulatedTimeWatched() {
      if (window.gtag) {
        window.gtag("event", "video_watch_duration", {
          video_title: this.metadata.title,
          time_watched: this.accumulatedTimeWatched,
        });
      }
      this.accumulatedTimeWatched = 0;
    },
    trackAudioChange() {
      const tracks = this.player.audioTracks();

      const activeTracks = Array.from(tracks).filter((track) => track.enabled);

      // Guard clause: If no active tracks, just exit
      if (activeTracks.length === 0) return;

      const activeTrack = activeTracks[0].label; // Take the first active track

      // Guard clause: If gtag is not available, exit
      if (!window.gtag) return;

      window.gtag("event", "audio_track_change", {
        video_title: this.metadata.title,
        audio_track: activeTrack,
      });
    },
    updateAudioTrackLabels() {
      const audioTrackList = this.player.audioTracks();
      for (let i = 0; i < audioTrackList.length; i++) {
        const element = audioTrackList[i];
        if (element.label === "original") element.label = "English";
      }
    },
  },
};
</script>

<style>
.video-js .vjs-time-control {
  display: block;
}
.video-js .vjs-remaining-time {
  display: none;
}

.video-js .vjs-menu li {
  text-transform: initial;
}
</style>
