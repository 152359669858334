<template>
  <div class="relative pb-3 rounded-2xl">
    <h1 class="text-[20px] font-aktiv-grotesk-medium leading-[24px] mb-2">
      Custom Experiences
    </h1>
    <p
      v-if="['Pending', 'Submitted Interest'].includes(status)"
      class="text-[16px] leading-[24px] mt-3 mb-2"
      :data-test-id="'gensapp-experiencesignup-experiencetext-' + status"
    >
      Thank you for signing up 😊
    </p>
    <p
      v-else-if="status === 'Confirmed'"
      class="text-[16px] leading-[24px] mb-2 mt-3"
      :data-test-id="'gensapp-experiencesignup-experiencetext-' + status"
    >
      You’re good to go!
    </p>
    <p
      v-else-if="status === 'Unavailable'"
      class="text-[16px] leading-[24px] mb-2 mt-3"
      :data-test-id="'gensapp-experiencesignup-experiencetext-' + status"
    >
      Unfortunately, sign ups for Experiences have closed.
    </p>

    <div v-if="status === 'Submitted Interest'">
      Your custom experiences will be confirmed
      <span class="font-bold">by {{ getExpAlloc }}.</span>
    </div>
    <div v-if="status === 'Pending'">
      We are working on your registration and we will confirm your allocation
      <span class="font-bold">by {{ getExpAlloc }}.</span>
    </div>
    <div v-else-if="status === 'Confirmed'">
      Time & venue is confirmed for your other Experiences. See you there!
    </div>
    <div v-else-if="status === 'Unavailable'">
      <!-- <h1 class="text-[16px] leading-[24px] mb-2">
        We have allocated slots in a way that ensures every church/organisation
        has a representative attending the experience.
      </h1>
      <h1 class="text-[16px] leading-[24px]">
        We apologise to those who did not manage to get a slot. Your interest
        and questions are important to us! Do speak to your hosts, they might be
        able to answer you! If not, they will let the global team know and we
        will do our best to connect you with the right people. 👍🏻😃
      </h1> -->
      You may still reach out to your Global Host and we’ll see how we can help!
    </div>

    <div
      class="w-full rounded-xl p-1 text-center mt-4"
      :data-test-id="'gensapp-experiencesignup-experiencestatus-panelcolor'"
      :class="getPanelColor(status)"
    >
      <p
        class="font-aktiv-grotesk-trial text-[16px] leading-[24px]"
        :data-test-id="'gensapp-experiencesignup-experiencestatus'"
      >
        Status: {{ statusTxt }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpStatusPanel",
  props: {
    status: {
      typs: String,
    },
    getExpAlloc: {
      type: String,
      default: "",
    },
  },
  computed: {
    statusTxt() {
      const STATUS_MAP = {
        "Submitted Interest": "Processing",
        Unavailable: "Closed",
        Pending: "Pending",
        Confirmed: "Confirmed",
      };
      return STATUS_MAP[this.status];
    },
  },
  methods: {
    getPanelColor(status) {
      const STATUS_MAP = {
        "Submitted Interest": "bg-orange bg-opacity-25",
        Unavailable: "bg-grey-20",
        Pending: "bg-pending-yellow",
        Confirmed: "bg-green",
      };
      return STATUS_MAP[status];
    },
    goToSchedule() {
      this.$router.push({
        path: "/schedule",
      });
    },
  },
};
</script>

<style scoped></style>
