<template>
  <header class="inline flex items-start items-center justify-end mt-[12px]">
    <BaseButton
      v-if="!hideQr"
      solid-classes="qr-button mr-3 text-lightblue bg-grey-5 !text-[14px] !leading-[20px] rounded-[100px] items-center h-9"
      data-test-id="gensapp-photodrop-toggleqr"
      @click="toggleQr"
    >
      <img
        src="/assets/icons/qr-icon.svg"
        class="cursor-pointer w-6 h-6 mr-2"
        alt="QR icon"
      />
      {{ $t("copy.photoDropPage.photoDropActionBar.myQR") }}
    </BaseButton>
    <BaseButton
      type="outline"
      class="select-multiple-button !bg-lightblue text-white bg-grey-5 !text-[14px] !leading-[20px] rounded-[100px] items-center h-9"
      :class="{ '!bg-[#0082C1]': isSelectingPhotos }"
      @click="toggleSelect"
    >
      {{ isSelectingPhotos ? $t("copy.photoDropPage.photoDropActionBar.cancel") : $t("copy.photoDropPage.photoDropActionBar.select") }}
    </BaseButton>
  </header>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BaseButton from "../../components/common/BaseButton.vue";
import isIos from "../../mixins/isIos";
import ChevronLeftBlack from "@/components/icons/ChevronLeftBlack";
import Cancel from "@/components/icons/Cancel";

export default {
  components: {
    BaseButton,
  },
  mixins: [isIos],
  props: {
    hideQr: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("photos", ["isSelectingPhotos", "selectedPhotos", "isShowQr"]),
    ...mapGetters("photos", ["isPhotosEmpty"]),
    icon() {
      return this.isSelectingPhotos ? Cancel : ChevronLeftBlack;
    },
  },
  methods: {
    onNavClick() {
      if (this.isShowQr) this.toggleQr();
      else if (this.isSelectingPhotos) this.removeSelect();
      else this.navigateToHome();
    },
    toggleSelect() {
      this.$store.dispatch(
        "photos/updateIsSelectingPhotos",
        !this.isSelectingPhotos
      );
      this.$store.dispatch("photos/resetSelectedPhotos");
    },
    removeSelect() {
      this.$store.dispatch("photos/updateIsSelectingPhotos", false);
      this.$store.dispatch("photos/resetSelectedPhotos");
    },
    toggleQr() {
      this.$store.dispatch("photos/updateIsShowQr", true);
      this.$emit("open-modal");
    },
    navigateToHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped></style>
