<template>
  <svg v-if="!isActive" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.66667 16H4L16 4L28 16H25.3333" stroke="#99A4B2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.6665 16V25.3333C6.6665 26.0406 6.94746 26.7189 7.44755 27.219C7.94765 27.719 8.62593 28 9.33317 28H22.6665C23.3737 28 24.052 27.719 24.5521 27.219C25.0522 26.7189 25.3332 26.0406 25.3332 25.3333V16" stroke="#99A4B2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 28.0007V20.0007C12 19.2934 12.281 18.6151 12.781 18.115C13.2811 17.6149 13.9594 17.334 14.6667 17.334H17.3333C18.0406 17.334 18.7189 17.6149 19.219 18.115C19.719 18.6151 20 19.2934 20 20.0007V28.0007" stroke="#99A4B2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg v-else width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.66667 16H4L16 4L28 16H25.3333" fill="#00AAE4"/>
<path d="M6.66667 16H4L16 4L28 16H25.3333" stroke="#00AAE4" stroke-width="2.20267" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.66602 16V25.3333C6.66602 26.0406 6.94697 26.7189 7.44706 27.219C7.94716 27.719 8.62544 28 9.33268 28H22.666C23.3733 28 24.0515 27.719 24.5516 27.219C25.0517 26.7189 25.3327 26.0406 25.3327 25.3333V16" fill="#00AAE4"/>
<path d="M6.66602 16V25.3333C6.66602 26.0406 6.94697 26.7189 7.44706 27.219C7.94716 27.719 8.62544 28 9.33268 28H22.666C23.3733 28 24.0515 27.719 24.5516 27.219C25.0517 26.7189 25.3327 26.0406 25.3327 25.3333V16" stroke="#00AAE4" stroke-width="2.20267" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 28.0007V20.0007C12 19.2934 12.281 18.6151 12.781 18.115C13.2811 17.6149 13.9594 17.334 14.6667 17.334H17.3333C18.0406 17.334 18.7189 17.6149 19.219 18.115C19.719 18.6151 20 19.2934 20 20.0007V28.0007" fill="white"/>
<path d="M12 28.0007V20.0007C12 19.2934 12.281 18.6151 12.781 18.115C13.2811 17.6149 13.9594 17.334 14.6667 17.334H17.3333C18.0406 17.334 18.7189 17.6149 19.219 18.115C19.719 18.6151 20 19.2934 20 20.0007V28.0007" stroke="#00AAE4" stroke-width="2.20267" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


</template>

<script>
export default {
  name: 'HomeIcon',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
