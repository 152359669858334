<template>
  <div v-if="!canHide">
    <BreakCard
      v-if="eventType === 'break'"
      :isWeekday="isWeekday"
      class="mb-6"
    />
    <div
      v-else
      :id="id"
      class="flex flex-col mx-[24px] text-[16px] leading-[24px] font-aktiv-grotesk header-scroll-margin"
      :class="{ 'mx-0': subExp }"
    >
      <TimeSeparator
        v-if="!subExp && eventType !== 'end'"
        :now="now"
        :show-time="showTime"
        :start-time="startTime"
        :timer-txt="timerTxt"
        :timer-txt-replace="timerTxtReplace"
        class="mb-6"
      />
      <div
        v-if="eventType !== 'end'"
        class="relative rounded-2xl overflow-hidden border-[1px] border-grey-10 border-solid p-[16px]"
        :class="cardClass"
      >
        <div
          :class="{ 'justify-between': label, 'justify-end': !label }"
          class="flex flex-row items-center mb-[8px]"
        >
          <div
            v-if="label"
            class="inline-block uppercase text-[12px] leading-[20px] font-aktiv-grotesk-medium text-center rounded-xl py-[2px] px-[8px]"
            :class="{
              'bg-orange text-white': isOrange,
              'bg-black text-white': isBlack,
              'bg-blue text-white': isDarkBlue,
              'bg-yellow text-grey-100': isYellow,
            }"
          >
            {{ label }}
          </div>
          <a
            v-if="livevoice && needLiveVoice && !isAbsentAlumni"
            :href="livevoicelink"
            target="_blank"
            rel="noopener noreferrer"
            class="flex flex-row font-bold ml-auto text-[14px] leading-[20px] hover:underline text-lightblue"
            @click="clickLiveVoice()"
          >
            <img
              class="mr-[4px]"
              src="../../../public/assets/icons/interpretation-icon.svg"
            />
            {{ $t("copy.schedulePage.eventsCard.accessLiveInterpretation") }}
          </a>
        </div>
        <div
          v-if="title"
          class="font-aktiv-grotesk-trial mb-3 text-[20px] leading-[24px]"
        >
          <h1 v-html="title"></h1>
        </div>
        <div
          v-if="subtitle"
          class="font-aktiv-grotesk-trial mb-3 text-[16px] leading-[24px]"
        >
          <h1 v-html="subtitle"></h1>
        </div>
        <div v-if="timing" class="flex mb-2 justify-start items-start">
          <img
            v-if="subExp"
            src="../../../public/assets/icons/event-duration-icon-orange.svg"
            class="w-[20px] h-[20px] mt-1"
          />
          <img
            v-else
            src="../../../public/assets/icons/event-duration-icon-blue.svg"
            class="w-[16px] h-[16px] mt-1 ml-1"
          />
          <p class="ml-[6px] text-grey-80 text-[16px] leading-[24px]">
            {{ timing }}
          </p>
        </div>
        <in-app-browser
          :href="$t('copy.schedulePage.eventsCard.viewMapButtonLink')"
          target="_blank"
        >
          <div
            class="flex mb-1 justify-start items-start rounded-[8px] location cursor-pointer"
            :class="
              isWeekday || subExp
                ? 'bg-none bg-opacity-10 text-gray-800'
                : 'bg-none text-blue'
            "
          >
            <img
              v-if="
                (subExp || eventType === 'experience') &&
                location &&
                location.length > 0
              "
              src="../../../public/assets/icons/event-location-icon-orange.svg"
              alt="icon"
              class="mt-1 ml-1"
            />
            <img
              v-else-if="location && location.length > 0"
              src="../../../public/assets/icons/event-location-icon-blue.svg"
              alt="icon"
              class="mt-1 ml-1"
            />
            <div v-if="location && location.length > 0">
              <p
                v-for="place in location"
                :key="place"
                class="ml-2"
                :class="
                  eventType === 'experience' ? 'text-orange' : 'text-lightblue'
                "
              >
                {{ place }}
              </p>
            </div>
            <!-- <div v-else>
                <p class="ml-2">To be assigned</p>
              </div> -->
          </div>
        </in-app-browser>
        <!-- <div
            v-if="cgExp"
            class="mb-[8px] mb-[8px] mt-2 border-[0.5px] border-grey-10"
          ></div> -->
        <div
          v-if="cardDescription"
          class="font-aktiv-grotesk text-[14px] leading-[20px] text-grey-100 mb-[10px]"
        >
          <h1 v-html="cardDescription"></h1>
        </div>

        <div class="flex flex-col gap-4">
          <!-- TODO: hard code expgroup to be refactor -->
          <ScheduleCardGroup
            v-if="cgExps.length"
            v-for="(expGroup, index) in expGroups"
            :key="index"
            :title="expGroup.name"
            :card-label="cgExpLabelText(expGroup.canSignUp)"
            :class="{
              hidden:
                !cgExps[expGroup.indexes[0]] ||
                !cgExps[expGroup.indexes[0]].title,
              '!p-0 !border-none': expGroups.length === 1,
            }"
          >
            <!-- <div v-for="exp in cgExps.slice(0, 1)" :key="exp"> -->
            <div
              v-if="expGroups"
              v-for="grpIndex in expGroup.indexes"
              :key="grpIndex"
            >
              <EventsCard
                v-if="cgExps[grpIndex]"
                :subtitle="cgExps[grpIndex].title"
                :cgExp="cgExps[grpIndex].cgExp"
                :subExp="true"
                eventType="experience"
                :timing="cgExps[grpIndex].timing"
                :location="cgExps[grpIndex].location"
                :card-label="cgExps[grpIndex].cardLabel"
                :cardDescription="cgExps[grpIndex].cardDescription"
                :interest-desc="cgExps[grpIndex].interestDesc"
                :expFindOut="cgExps[grpIndex].expFindOut"
                :class="{ 'mt-5': expGroups.length > 1 }"
                @show-popup="emitPopupEvent"
              />
              <div
                v-if="!isAbsentAlumni && expGroup.hasAllocation"
                class="flex justify-end mt-4"
              >
                <!-- TODO GPC 2025 remove isExpAllocated -->
                <button
                  v-if="isExpAllocated()"
                  class="rounded-3xl text-white text-[16px] leading-[24px] font-aktiv-grotesk-trial text-center py-2 px-4 mb-2 bg-orange"
                  :data-event-tracking="title in detMap ? detMap[title] : ''"
                  data-test-id="gensapp-schedule-experience-signupnow"
                  @click="goExperiencePage()"
                >
                  {{ getBtnText() }}
                </button>
              </div>
            </div>
            <!-- <div v-if="show">
            <EventsCard
              v-for="exp in cgExps.slice(1)"
              :key="exp"
              :subtitle="exp.title"
              :location="exp.location"
              :cgExp="exp.cgExp"
              :subExp="true"
              :timing="exp.timing"
              :card-label="exp.cardLabel"
              :cardDescription="exp.cardDescription"
              :interest-desc="exp.interestDesc"
              :expFindOut="exp.expFindOut"
              class="mt-5"
            />
          </div> -->
          </ScheduleCardGroup>
        </div>
        <div
          v-if="notes && !showSeating"
          class="text-[14px] leading-[20px] mt-[8px] text-grey-60"
          v-html="notes"
        ></div>
        <CardLabel
          v-if="cardLabel != null && cardLabel !== ''"
          :color="getLabelColor()"
          >{{ cardLabel }}</CardLabel
        >
        <div
          v-if="
            (eventType === 'experience' && canSignup && !isSignupClosed()) ||
            (cgExp && subExp)
          "
          class="flex justify-end mt-4"
        >
          <button
            v-if="!isAbsentAlumni"
            class="rounded-3xl text-white text-[16px] leading-[24px] font-aktiv-grotesk-trial text-center py-2 px-4 mb-2"
            :class="
              isWeekday || (cgExp && subExp) ? 'bg-orange' : 'bg-lightblue'
            "
            data-event-tracking="gensapp-schedule-custom-experience-findoutmore"
            data-test-id="gensapp-schedule-experience-signupnow"
            @click="clickExpButton()"
          >
            {{ getBtnText() }}
          </button>
        </div>
        <p class="flex justify-end italic text-[14px]">
          {{ this.interestDesc }}
        </p>
        <div class="flex">
          <p
            v-if="eventType === 'experience' && canSignup"
            class="italic text-grey-80 ml-auto"
          >
            {{ getSignUpText() }}
          </p>
          <router-link
            v-if="sessionId > 0 && hasMaterial"
            :to="'/schedule/session-card/' + sessionId"
            class="font-bold ml-auto text-[14px] leading-[20px] mt-2 hover:underline materials-card"
            :class="isWeekday ? 'text-lightblue' : 'text-lightblue'"
            :data-event-tracking="
              'gensapp-schedule-' +
              label.toLowerCase().replace(/\s/g, '') +
              '-seesessionmaterials'
            "
            :data-test-id="
              'gensapp-schedule-' +
              label.toLowerCase().replace(/\s/g, '') +
              '-seesessionmaterials'
            "
            @click="clickSessionResources()"
            >{{ $t("copy.schedulePage.eventsCard.seeSessionResources") }}
          </router-link>
          <div
            v-if="subExp && expFindOut"
            class="font-bold ml-auto text-[14px] leading-[20px] mt-2 hover:underline text-orange"
            :data-event-tracking="
              'gensapp-schedule-experience-' +
              this.expFindOut.type +
              '-findoutmore'
            "
            @click="showExpPopup()"
          >
            Find out more
          </div>
        </div>
        <div class="flex">
          <router-link
            v-if="showSeating && !isAbsentAlumni"
            :to="'/seating/' + serviceId"
            class="font-bold ml-auto text-[14px] leading-[20px] mt-2 hover:underline"
            :class="isWeekday ? 'text-lightblue' : 'text-lightblue'"
            :data-test-id="
              'gensapp-schedule-' + serviceId + '-see-seating-plan'
            "
            >See seating plan
          </router-link>
        </div>
        <div v-if="tableTimeQuestions" class="mt-3">
          <div
            v-html="tableTimeQuestions"
            class="text-[14px] text-grey-60 relative bg-grey-5 border-[1px] border-grey-10 px-4 py-3 rounded-2xl"
          ></div>
        </div>

        <div v-if="showAlumni">
          <div class="mb-[8px] mt-[16px] border-[0.5px] border-grey-20"></div>
          <div class="flex justify-start items-center mt-3">
            <p
              class="font-aktiv-grotesk-medium text-[14px] leading-[24px] mr-[6px] pb-1"
            >
              Also happening:
            </p>
          </div>
          <div
            class="relative bg-grey-5 border-[1px] border-grey-10 p-4 pt-8 rounded-2xl"
          >
            <CardLabel color="orange">Alumni Track</CardLabel>
            <div class="font-aktiv-grotesk-trial mb-2">
              {{ alumni.title }}
            </div>
            <a
              v-if="alumni.location && alumni.location.length > 0"
              :href="
                $t('copy.experiencePage.allocationPanel.viewMapButtonLink')
              "
              target="_blank"
            >
              <div
                class="flex mb-1 justify-start items-start rounded-[8px] location px-2 cursor-pointer"
                :class="
                  isWeekday
                    ? 'bg-orange bg-opacity-10 text-orange'
                    : 'bg-none text-blue'
                "
              >
                <img
                  v-if="eventType !== 'experience'"
                  src="../../../public/assets/icons/event-location-icon-blue.svg"
                  alt="icon"
                  class="mt-1"
                />
                <img
                  v-else
                  src="../../../public/assets/icons/event-location-icon-orange.svg"
                  alt="icon"
                  class="mt-1"
                />
                <div>
                  <p
                    v-if="alumni.location && alumni.location > 0"
                    v-for="place in alumni.location"
                    :key="place"
                    class="ml-2"
                  >
                    {{ place }}
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <!-- <div
          v-if="cgExps.length > 0"
          class="cursor-pointer w-[32px] h-[32px] rounded-full bg-[#21252C] flex items-center justify-center absolute -bottom-4 left-1/2 transform -translate-x-1/2"
          :class="rotationClass"
          data-test-id="gensapp-schedule-cg-experience-expandCard"
          @click="expandCard()"
        >
          <ChevronDownIcon class="text-white" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import BreakCard from "./BreakCard.vue";
import TableAlumniSharing from "./TableAlumniSharing.vue";
import TimeSeparator from "./TimeSeparator.vue";
import CardLabel from "../common/CardLabel.vue";
import Tooltip from "../icons/Tooltip.vue";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import ChevronDownIcon from "@/components/icons/ChevronDownIcon.vue";
import ScheduleCardGroup from "./ScheduleCardGroup.vue";
import { isGPCRPage } from "@/utils/conference-type";
import { getAnalyticsScreenName } from "@/utils/analytics";

export default {
  name: "EventsCard",
  components: {
    TimeSeparator,
    TableAlumniSharing,
    BreakCard,
    CardLabel,
    Tooltip,
    ChevronDownIcon,
    ScheduleCardGroup,
  },
  data() {
    return {
      // show: false,
      isShowInfo: false,
      showingTooltip: false,
      tooltipHover: false,
      detMap: {
        // "Go Behind the Scenes Experience":
        //   "gensapp-schedule-cgexperience-day4-findoutmore",
        // "Media Room Experience": "gensapp-schedule-mediaexperience-findoutmore",
        // "Ministry Debriefs":
        //   "gensapp-schedule-ministrydebriefexperience-findoutmore",
        // "Dreams & Stories Night":
        //   "gensapp-schedule-dreamsstoriesexperience-findoutmore",
      },
      btnText: "",
      gpcExpGroups: [
        {
          canSignUp: true,
          hasAllocation: true,
          indexes: [0],
          name: 'Custom <br class="lg:hidden">Experiences',
        },
        { canSignUp: false, indexes: [1], name: "Conference-wide Experiences" },
        { canSignUp: false, indexes: [2], name: "Walk-in Experiences" },
      ],
    };
  },
  props: {
    eventType: {
      type: String,
      default: "",
    },
    showTime: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    cardLabel: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    location: {
      type: Array,
      default: () => [],
    },
    notes: {
      type: String,
      default: "",
    },
    sessionId: {
      type: Number,
      default: 0,
    },
    livevoice: {
      type: Boolean,
      default: false,
    },
    livevoicelink: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      required: true,
    },
    isWeekday: {
      type: Boolean,
      default: false,
    },
    now: {
      type: Boolean,
      default: false,
    },
    startTime: {
      type: String,
      default: "",
    },
    hasSeating: {
      type: Boolean,
      default: false,
    },
    tableTimeQuestions: {
      type: String,
      default: "",
    },
    alumni: {
      type: Object,
      default: () => {},
    },
    // TODO: to be removed
    toggleAlumni: {
      type: Boolean,
      default: true,
    },
    timerTxt: {
      type: String,
      default: "",
    },
    timerTxtReplace: {
      type: Boolean,
      default: false,
    },
    timing: {
      type: String,
      default: "",
    },
    hideDelegateType: {
      type: String,
      default: null,
    },
    cgExp: {
      type: Boolean,
      default: false,
    },
    subExp: {
      type: Boolean,
      default: false,
    },
    cardDescription: {
      type: String,
      default: "",
    },
    cgExps: {
      type: Array,
      default: () => [],
    },
    interestDesc: {
      type: String,
      default: "",
    },
    hasMaterial: {
      type: Boolean,
      default: false,
    },
    expFindOut: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("profile", ["name", "type", "isAbsentAlumni"]),
    ...mapGetters("signup", [
      "SIGN_UP_CLOSE_TS",
      "EXP_ALLOC_TS",
      "GPCR_EXP_ALLOC_TS",
    ]),
    ...mapState("timer", ["currDate"]),
    ...mapState("signup", ["signups", "expData", "mainExpData"]),
    ...mapState("profile", ["needLiveVoice"]),
    expGroups() {
      return isGPCRPage() ? this.gpcrExpGroups : this.gpcExpGroups;
    },
    gpcrExpGroups() {
      return [
        {
          canSignUp: false,
          hasAllocation: true,
          indexes: [0],
          // name: this.$t("copy.schedulePage.experienceHeader"),
          name: "",
        },
      ];
    },
    allocationText() {
      return this.$t("copy.schedulePage.checkAllocation");
    },
    rotationClass() {
      return this.show ? "rotate-180" : "";
    },
    labelColor() {
      // Handle scenario: where no label
      if (!this.label) return "";

      // Below code: all has label
      // Handle scenario: where weekday
      if (this.eventType === "experience") return "black";
      if (this.isWeekday) return "yellow";

      return "yellow";
    },
    isOrange() {
      return this.labelColor === "orange";
    },
    isDarkBlue() {
      return this.labelColor === "dark-blue";
    },
    isBlack() {
      return this.labelColor === "black";
    },
    isYellow() {
      return this.labelColor === "yellow";
    },
    serviceId() {
      // no the best way to handle, should use type instead of string manipulation since copy can change
      if (this.title.includes("Leaders' Meeting")) return 0;
      if (this.title.includes("Dreams & Stories")) return 999;
      if (this.label.includes("SERVICE")) {
        const labelList = this.label.split(" ");
        return labelList[1];
      }
      return null;
    },
    showSeating() {
      return this.hasSeating && this.isSeatingEvent;
    },
    isSeatingEvent() {
      return (
        this.label.includes("SERVICE") ||
        this.title.includes("Leaders' Meeting") ||
        this.title.includes("Dreams & Stories")
      );
    },
    showAlumni() {
      return this.alumni && this.toggleAlumni;
    },
    cardClass() {
      let newClass = "";
      if (this.cgExps.length > 0) {
        newClass += "overflow-visible pb-9 ";
      }

      if (this.subExp) {
        newClass += "bg-grey-5";
        return newClass;
      }

      if (this.eventType === "experience")
        return "border-orange border-opacity-25";
      // if (
      //   this.eventType === "experience" &&
      //   this.canSignup &&
      //   !this.isSignupClosed()
      // ) {
      //   return this.isWeekday
      //     ? "bg-light-orange border-orange"
      //     : "bg-paleblue border border-lightblue";
      // }
      return newClass + "bg-white";
    },
    canSignup() {
      if (this.isAbsentAlumni) return false;
      if (this.expFindOut === null) return false;
      if (this.mainExpData === null) return false;
      return this.mainExpData.registerableExps.some(
        (item) => item.type === this.expFindOut.type
      );
    },
    canHide() {
      return this.hideDelegateType && this.hideDelegateType === this.type;
    },
  },
  watch: {
    showingTooltip(value) {
      if (value) {
        window.addEventListener("mousedown", this.outsideClick);
      } else {
        window.removeEventListener("mousedown", this.outsideClick);
      }
    },
  },
  methods: {
    // expandCard() {
    //   this.show = !this.show;
    // },
    showInfo() {
      this.isShowInfo = !this.isShowInfo;
    },
    isSignupClosed() {
      const signUpDate = new Date(this.SIGN_UP_CLOSE_TS);
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      return currentDate.getTime() >= signUpDate.getTime();
    },
    isExpAllocated() {
      const allocationDate = new Date(
        isGPCRPage() ? this.GPCR_EXP_ALLOC_TS : this.EXP_ALLOC_TS
      );
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      return currentDate.getTime() >= allocationDate.getTime();
    },
    getSignUpText() {
      if (this.isSignupClosed()) return "Sign up is closed.";

      const dayStr = moment(this.SIGN_UP_CLOSE_TS).format("ddd, DD MMM, h A");
      return "Indicate your interest by " + dayStr + ".";
    },
    getLabelColor() {
      if (this.cgExp && this.subExp) return "orange";
      if (this.isSignupClosed() || this.subExp) return "grey";
      return this.isWeekday ? "orange" : "paledarkblue";
    },
    outsideClick(event) {
      const { tooltip, tooltipTrigger } = this.$refs;

      if (!tooltip.contains(event.target) && event.target !== tooltipTrigger) {
        this.showingTooltip = false;
      }
    },
    toggleTooltip() {
      this.showingTooltip = !this.showingTooltip;
    },
    goExperiencePage() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "check experience allocation",
          page: "schedule", // getAnalyticsScreenName("SchedulePage", "en", "gpcr"),
        },
      });
      this.$router.push({
        path: "/experience/",
        hash: "#exp-page-exp",
      });
    },
    clickExpButton() {
      if (this.isExpAllocated()) {
        this.goExperiencePage();
      } else {
        this.goExperiencePageScrollToCarousel();
      }
    },
    showExpPopup() {
      if (this.expFindOut === null) {
        return;
      }

      const exp = this.expData[this.expFindOut.type];
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name:
            "gensapp-schedule-experience-" + exp.type + "-findoutmore",
          page: "schedule",
        },
      });
      this.$emit("show-popup", exp);
    },
    emitPopupEvent(exp) {
      this.$emit("show-popup", exp);
    },
    getBtnText() {
      if (isGPCRPage()) return this.allocationText;

      return this.isExpAllocated()
        ? this.allocationText
        : (this.btnText = "Find out more");
    },
    cgExpLabelText(canSignUp) {
      if (!canSignUp) return "";
      return this.isSignupClosed() ? "Sign-up closed" : "Sign-up required";
    },
    clickLiveVoice() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "live interpretation",
          page: "schedule", // getAnalyticsScreenName("SchedulePage", "en", "gpcr"),
        },
      });
    },
    clickSessionResources() {
      FirebaseAnalytics.logEvent({
        name: "click_button",
        params: {
          button_name: "see session resources",
          page: "schedule", // getAnalyticsScreenName("SchedulePage", "en", "gpcr"),
        },
      });
    },
  },
};
</script>
<style scoped>
.tooltip-box {
  top: calc(100% + 14px);

  left: -10px;

  box-shadow: 0px 0px 5px rgba(11, 12, 15, 0.35);
}

.tooltip-box:before {
  content: "";
  position: absolute;
  top: -7px;
  right: 93px;
  border: 6px solid #373e49;
  border-color: transparent #373e49 #373e49 transparent;
  background-color: #373e49;
  transform-origin: 0 0;
  transform: rotate(45deg);
  z-index: -1;
  box-shadow: -3px -3px 3px rgba(11, 12, 15, 0.15);
}
.tooltip-box:after {
  display: none;
}

.location {
  width: fit-content;
}

.header-scroll-margin {
  scroll-margin: 100px;
}
</style>
