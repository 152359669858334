<template>
  <PopupModal
    :show="show"
    :enable-click-away="false"
    @close-popup="closeModal()"
  >
    <div
      v-if="show"
      class="fixed bg-white rounded-2xl top-[calc(50%-44px)] left-1/2 transform -translate-y-1/2 -translate-x-1/2 px-[10px] xs:px-6 pt-[32px] pb-[32px] flex flex-col z-50 font-aktiv-grotesk-medium w-[327px]"
      @wheel.prevent
      @touchmove.prevent
      @scroll.prevent
    >
      <div class="mb-8 flex justify-center">
        <img
          class="h-[64px] w-[64px]"
          alt="Green Tick"
          src="../../../public/assets/images/green-tick.png"
        />
      </div>
      <h1
        v-if="firstSignUp"
        class="text-[24px] leading-[32px] font-aktiv-grotesk-medium text-center mb-4"
      >
        <span>Thank you for signing up!</span>
      </h1>
      <h1
        v-if="firstSignUp == false"
        class="text-[24px] leading-[32px] font-aktiv-grotesk-medium text-center mb-4"
      >
        <span>Your preferences have been updated!</span>
      </h1>
      <p
        v-if="firstSignUp"
        class="text-[16px] leading-[24px] font-aktiv-grotesk text-center mb-8"
      >
        Custom Experiences will be confirmed by
        <span class="font-bold whitespace-nowrap">{{ getExpAlloc }}</span
        >.
        <br />
        Come back to see which experiences you'll be attending!
      </p>
      <p
        v-if="firstSignUp == false"
        class="text-[16px] leading-[24px] font-aktiv-grotesk text-center mb-8"
      >
        Your allocated Experiences will be updated in the Experiences page and
        Schedule on
        <span class="font-bold whitespace-nowrap">{{ getExpAlloc }}</span
        >.
      </p>
      <button
        class="font-aktiv-grotesk-trial w-full mx-auto max-w-[295px] rounded-[100px] text-white text-[20px] leading-[24px] text-center py-[12px] mb-6 bg-orange hover:bg-[#F27521] cursor-pointer"
        @click="goBackExperience()"
      >
        View my experiences
      </button>
      <a
        href="/"
        class="text-[20px] leading-[24px] text-center font-aktiv-grotesk-trial cursor-pointer text-orange"
      >
        Back to home
      </a>
    </div>
  </PopupModal>
</template>

<script>
import PopupModal from "../common/PopupModal.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "InterestModal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    getExpAlloc: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState("signup", ["firstSignUp"]),
  },
  methods: {
    ...mapActions("signup", ["setSuccessFromSummary"]),
    closeModal() {
      this.$emit("close-modal");
    },
    goBackExperience() {
      this.setSuccessFromSummary(true);
      this.$router.push("/experience");
    },
  },
  components: { PopupModal },
};
</script>

<style scoped></style>
