<template>
    <div
      class="flex flex-col text-center mx-auto w-[303px] md:w-[80%] font-aktiv-grotesk-regular text-[20px] leading-[24px] text-grey-80"
    >
      <h1 v-if="day >= 1 && day <= lastDay" class="font-aktiv-grotesk-trial pb-2">
        {{ $t("copy.homePage.mainLayout.scheduleSection.schedulePanel.endOfDay", { day: translatedDay() }) }}
      </h1>
      <h1 v-else-if="day > lastDay" class="font-aktiv-grotesk-trial pb-2">
        {{
          $t(
            "copy.homePage.mainLayout.scheduleSection.schedulePanel.tillNextTime"
          )
        }}
      </h1>
  
      <p v-if="day < 1" class="font-aktiv-grotesk-trial">
        {{
          $t(
            "copy.homePage.mainLayout.scheduleSection.schedulePanel.cantWaitToSeeYou"
          )
        }}
      </p>
      <p v-else-if="day === lastDay">
        {{
          $t(
            "copy.homePage.mainLayout.scheduleSection.schedulePanel.seeYouNextYear"
          )
        }}<br />
        <!-- <in-app-browser
          v-if="isPastLastDay"
          href="https://generationsmvmt.com/conference/generations-pastors-conference-regional/"
          target="_blank"
          class="underline font-aktiv-grotesk-trial cursor-pointer"
          :class="getTextColor()"
          data-event-tracking="gensapp-schedule-day-5-2025apply"
          data-test-id="gensapp-schedule-2024conf-register-signupnow"
          @click="logEvent()"
          >{{ $t("copy.homePage.mainLayout.scheduleSection.schedulePanel.applyNow") }}
        </in-app-browser> -->
      </p>
      <div v-else-if="day > lastDay">
        <p class="pb-6">
          <p v-html="$t('copy.homePage.mainLayout.scheduleSection.schedulePanel.viewSessionResources')"></p>
          <router-link
            to="/resources/sessions"
            class="underline font-aktiv-grotesk-trial"
            :class="getTextColor()"
            >{{ $t("copy.homePage.mainLayout.scheduleSection.schedulePanel.viewSessionResourcesPage") }}</router-link
          >.
        </p>
  
        <p class="w-[290px] md:w-auto">
          {{
            $t(
              "copy.homePage.mainLayout.scheduleSection.schedulePanel.seeYouSoon"
            )
          }}
        </p>
      </div>
      <p v-if="day === 1" class="font-aktiv-grotesk-regular">
        {{ $t("copy.homePage.mainLayout.scheduleSection.schedulePanel.seeYouTomorrow") }}
      </p>
      <p v-if="day === 2" class="font-aktiv-grotesk-regular">
        {{ $t("copy.homePage.mainLayout.scheduleSection.schedulePanel.seeYouTomorrow") }}
      </p>
    </div>
  </template>
  
  <script>
  import { mapState, mapGetters } from "vuex";
  import { toChineseNumeral } from "@/utils/translation.js"
  export default {
    name: "GPCREodPanel",
    props: {
      day: {
        type: Number,
        default: 0,
      },
      isPastLastDay: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters("profile", ["type"]),
      ...mapState("timer", ["currDate"]),
      lastDay() {
        return 3;
      },
    },
    methods: {
      getTextColor() {
        return "text-lightblue";
      },
      logEvent() {
        FirebaseAnalytics.logEvent({
          name: "click_button",
          params: {
            button_name: "gensapp-schedule-day-5-2025apply",
            page: "schedule",
          },
        });
      },
      translatedDay() {
        const savedLocale = this.$i18n.locale
        return savedLocale == "zh" ? toChineseNumeral(this.day) : this.day
      },
    },
  };
  </script>
  
  <style scoped></style>
  