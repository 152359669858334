<template>
  <section id="sortCard" class="font-aktiv-grotesk text-[16px] leading-[24px]">
    <div
      v-if="isEditEnable && overallStatus !== 'Unavailable' && !hasSignupBefore"
      class="w-auto flex mb-3 px-6 md:px-8 lg:px-[56px]"
    >
      <div
        class="text-[12px] leading-[20px] font-aktiv-grotesk-medium text-white bg-orange rounded-full py-[2px] px-2"
      >
        Step 2
      </div>
    </div>
    <div id="experience-choices" class="px-6 md:px-8 lg:px-[56px]">
      <h2 v-if="title" class="font-aktiv-grotesk-medium text-[24px]">
        {{ title }}
      </h2>
      <p
        v-if="isEditEnable && overallStatus !== 'Unavailable'"
        class="mt-[12px]"
      >
        To have time to interact with the HOGC members and have an impactful
        experience, each delegate will get to attend
        {{ this.allocatedExpsNo }} out of {{ this.exps.length }}
        Custom Experiences.
      </p>
      <div
        v-if="isEditEnable && overallStatus !== 'Unavailable'"
        class="flex bg-grey-10 items-center py-[10px] px-2 rounded-lg mt-4 font-aktiv-grotesk-medium"
      >
        <img src="../../../public/assets/icons/drag-dots.svg" />
        <p class="pl-3 text-[12px] leading-[20px]">
          Tap & hold to drag and arrange your preferences!
        </p>
      </div>
      <div
        v-if="isEditEnable && overallStatus !== 'Unavailable'"
        class="flex mt-6"
      >
        <div
          class="w-12 flex flex-col justify-around font-aktiv-grotesk-medium text-[16px] leading-[24px] text-orange"
        >
          <p v-for="i in exps.length" :key="i">{{ getRankingNumber(i) }}</p>
        </div>
        <draggable
          :list="pending_signups"
          @start="drag = true"
          @end="drag = false"
          item-key="id"
          class="w-full"
        >
          <template #item="{ element, index }">
            <div
              class="border border-white flex text-grey-90 items-center justify-between py-[21px] px-4 rounded-lg box-shadow bg-white mb-2"
            >
              <p class="font-aktiv-grotesk-medium">{{ element.name }}</p>
              <img src="../../../public/assets/icons/drag-dots.svg" />
            </div>
          </template>
        </draggable>
      </div>
      <div v-else>
        <section
          v-if="overallStatus !== 'Unavailable'"
          class="rounded-xl border border-orange border-opacity-25 bg-white p-4"
        >
          <ExpStatusPanel
            :status="overallStatus"
            :getExpAlloc="getExpAlloc()"
            class="mb-3"
          />
          <div
            v-if="overallStatus === 'Submitted Interest'"
            v-for="(signup, index) in signupsByRank"
            :key="index"
            class="font-aktiv-grotesk-regular flex p-4 items-center bg-white mb-2 rounded-xl bg-grey-5"
          >
            <div
              class="w-[44px] font-aktiv-grotesk-medium bg-[#FF660026] text-orange p-2 text-[14px] leading-[10px] text-center rounded-xl mr-2"
            >
              {{ getRankingNumber(index + 1) }}
            </div>

            <p class="text-[16px] leading-[24px] font-aktiv-grotesk-medium">
              {{ expNameMap[signup.type] }}
            </p>
          </div>
          <div
            v-if="overallStatus === 'Confirmed'"
            v-for="(signup, index) in signupsByRank"
            :key="index"
          >
            <ExperienceSuccessCard
              v-if="signup.status === 'Confirmed'"
              :title="expNameMap[signup.type]"
              :expTime="getTime(signup)"
              :location="getLocation(signup)"
              :date="getDate(signup)"
              :type="signup.type"
              class="mb-3"
            />
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import momentts from "moment-timezone";
import draggable from "vuedraggable";
import { mapActions, mapState, mapGetters } from "vuex";
import ExpStatusPanel from "./ExpStatusPanel.vue";
import ExperienceSuccessCard from "./ExperienceSuccessCard.vue";
export default {
  name: "MyExperienceSection",
  components: {
    draggable,
    ExpStatusPanel,
    ExperienceSuccessCard,
  },
  props: {
    isEditEnable: {
      type: Boolean,
      default: true,
    },
    signups: {
      type: Object,
      default: () => {},
    },
    expData: {
      type: Object,
    },
    allocatedExpsNo: {
      type: Number,
    },
  },
  data() {
    return {
      drag: false,
      exps: [
        {
          type: "CG",
          name: "Connect Group Experience",
        },
        {
          type: "DSN",
          name: "Dreams & Stories Night",
        },
        {
          type: "PM",
          name: "Prayer Meeting Experience",
        },
        {
          type: "MD",
          name: "Debrief Experience",
        },
      ],
      experienceData: this.expData,
    };
  },
  // watch: {
  //   exps(newExps, oldExps) {
  //     // this.setPendingSignupState(newExps);
  //   },
  //   pending_signups(newPending, oldPending) {
  //     // this.exps = newPending;
  //   },
  // },
  computed: {
    ...mapGetters("signup", ["SIGN_UP_CLOSE_TS"]),
    ...mapState("timer", ["currDate"]),
    ...mapState("signup", ["pending_signups", "EXP_ALLOC_TS"]),
    signupsByRank() {
      let signupArray = Object.values(this.signups);
      // remove null element
      // remove those is unavaliable status

      signupArray.sort((a, b) => {
        if (a.ranking < b.ranking) return -1;
        if (a.ranking > b.ranking) return 1;
        return 0;
      });

      return signupArray;
    },
    hasSignupBefore() {
      let result = false;
      for (const expType in this.signups) {
        result = result || Boolean(this.signups[expType]);
      }
      return result;
    },
    overallStatus() {
      if (this.hasSignupBefore && !this.isExpAllocated())
        return "Submitted Interest";

      const statusSet = new Set();
      for (const expType in this.signups) {
        const exp = this.signups[expType];
        if (exp && exp.status) statusSet.add(exp.status);
      }

      if (this.isSignupClosed() && statusSet.size == 0) return "Unavailable";

      if (statusSet.size === 1 && statusSet.has("Unavailable"))
        return "Unavailable";

      if (statusSet.has("Confirmed")) return "Confirmed";

      // if (statusSet.has("Pending")) return "Pending";

      return "";
    },
    expNameMap() {
      return this.exps.reduce((map, obj) => {
        map[obj.type] = obj.name;
        return map;
      }, {});
    },
    title() {
      // TODO simplify logic
      return (!this.hasSignupBefore && this.overallStatus !== "Unavailable") ||
        (this.isEditEnable && this.overallStatus !== "Unavailable")
        ? "Tell us your preferred Custom Experiences"
        : "";
    },
  },
  methods: {
    ...mapActions("signup", ["setPendingSignupState"]),
    isSignupClosed() {
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      const signUpExpiry = new Date(this.SIGN_UP_CLOSE_TS);
      return currentDate.getTime() >= signUpExpiry.getTime();
    },
    isExpAllocated() {
      const currentDate =
        this.currDate === "" ? new Date() : new Date(this.currDate);
      const expAllocDate = new Date(this.EXP_ALLOC_TS);
      return currentDate.getTime() >= expAllocDate.getTime();
    },
    getTime(signup) {
      if (signup.expTime) return signup.expTime;

      if (!this.experienceData[signup.type].dateTime) return;

      return this.experienceData[signup.type].dateTime[0].timing;
    },
    getLocation(signup) {
      if (signup.location) return [signup.location];

      return this.experienceData[signup.type].location;
    },
    getDate(signup) {
      if (signup.date) return signup.date;

      if (!this.experienceData[signup.type].dateTime) return;

      return this.experienceData[signup.type].dateTime[0].date;
    },
    getRankingNumber(i) {
      let j = i % 10,
        k = i % 100;
      if (j === 1 && k !== 11) {
        return i + "st";
      }
      if (j === 2 && k !== 12) {
        return i + "nd";
      }
      if (j === 3 && k !== 13) {
        return i + "rd";
      }
      return i + "th";
    },
    getExpAlloc() {
      momentts.tz.setDefault("Asia/Singapore");
      const dayStr = momentts(this.EXP_ALLOC_TS).format("dddd, D MMMM");
      const timeStr = momentts(this.EXP_ALLOC_TS).format("ha");
      return dayStr + " at " + timeStr;
    },
    nonRegisterableExp() {
      const filterList = [];
      for (var key in this.expData) {
        if (!this.exps.some((e) => e.type === key)) {
          // if (key != 'BM' && key != 'YH'){
          filterList.push(this.expData[key]);
          // }
        }
      }
      return filterList;
    },
  },
};
</script>

<style scoped>
.box-shadow {
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
}

.sortable-chosen {
  @apply !border-orange;
}

.sortable-drag {
  opacity: 0 !important;
}
</style>
