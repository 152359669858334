<template>
  <svg v-if="!isActive" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.0007 6.66699H8.00065C6.52789 6.66699 5.33398 7.8609 5.33398 9.33366V25.3337C5.33398 26.8064 6.52789 28.0003 8.00065 28.0003H24.0007C25.4734 28.0003 26.6673 26.8064 26.6673 25.3337V9.33366C26.6673 7.8609 25.4734 6.66699 24.0007 6.66699Z" stroke="#00AAE4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.334 4V9.33333" stroke="#00AAE4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.668 4V9.33333" stroke="#00AAE4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.33398 14.667H26.6673" stroke="#00AAE4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.66797 20C8.66797 21.1046 9.5634 22 10.668 22C11.7725 22 12.668 21.1046 12.668 20C12.668 18.8954 11.7725 18 10.668 18C9.5634 18 8.66797 18.8954 8.66797 20Z" fill="#00AAE4"/>
<path d="M24.0007 6.66699H8.00065C6.52789 6.66699 5.33398 7.8609 5.33398 9.33366V14.5H26.6673V9.33366C26.6673 7.8609 25.4734 6.66699 24.0007 6.66699Z" stroke="#00AAE4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  <svg v-else width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.9997 6.66699H7.99967C6.52692 6.66699 5.33301 7.8609 5.33301 9.33366V25.3337C5.33301 26.8064 6.52692 28.0003 7.99967 28.0003H23.9997C25.4724 28.0003 26.6663 26.8064 26.6663 25.3337V9.33366C26.6663 7.8609 25.4724 6.66699 23.9997 6.66699Z" stroke="#00AAE4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.333 4V9.33333" stroke="#00AAE4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.666 4V9.33333" stroke="#00AAE4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.33301 14.667H26.6663" stroke="#00AAE4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.66602 20C8.66602 21.1046 9.56145 22 10.666 22C11.7706 22 12.666 21.1046 12.666 20C12.666 18.8954 11.7706 18 10.666 18C9.56145 18 8.66602 18.8954 8.66602 20Z" fill="#00AAE4"/>
<path d="M23.9997 6.66699H7.99967C6.52692 6.66699 5.33301 7.8609 5.33301 9.33366V14.5H26.6663V9.33366C26.6663 7.8609 25.4724 6.66699 23.9997 6.66699Z" fill="#00AAE4" stroke="#00AAE4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
