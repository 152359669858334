<template>
  <div
    class="rounded-2xl border-[1px] border-grey-10 border-solid p-[16px] relative overflow-visible text-[14px] leading-[20px] font-aktiv-grotesk"
  >
    <div
      class="inline-block font-aktiv-grotesk-medium bg-blue text-white uppercase text-[12px] leading-[20px] text-center rounded-xl py-[2px] px-[8px] mb-2"
    >
      Experience
    </div>
    <h1 class="text-[16px] font-aktiv-grotesk-trial leading-[24px] mb-3">
      Service Prep Experience
    </h1>
    <div class="flex mb-1">
      <img src="../../../public/assets/icons/event-location-icon-blue.svg" />
      <p class="text-[16px] leading-[24px] text-grey-80 ml-2">
        Atrium, Imaginarium Level 4
      </p>
    </div>

    <CardLabel :color="getLabelColor(spe)">{{ getLabelText(spe) }}</CardLabel>

    <p class="mb-4">
      Come be a fly on the wall and observe different ministries as they prepare
      for services.
    </p>
    <div class="flex justify-end">
      <router-link v-if="!isSignupClosed() && !spe" to="/experience/#SPE">
        <button
          class="rounded-3xl bg-lightblue text-white text-[16px] leading-[24px] font-aktiv-grotesk-trial text-center py-2 px-4 mb-2"
          data-event-tracking="gensapp-schedule-svcprep-experience-findoutmore"
          data-test-id="gensapp-schedule-svcprep-experience-findoutmore"
        >
          Find out more
        </button>
      </router-link>
    </div>
    <div class="flex justify-between">
      <p
        class="italic text-grey-80"
        :class="!isSignupClosed() && !spe ? 'ml-auto' : 'mr-auto'"
      >
        {{ getSignUpText() }}
      </p>
      <div class="flex items-end">
        <router-link
          v-if="isSignupClosed() || spe"
          to="/experience/#SPE"
          class="font-bold text-lightblue ml-auto whitespace-nowrap hover:underline"
          data-event-tracking="gensapp-schedule-svcprep-experience-seemore"
          data-test-id="gensapp-schedule-svcprep-experience-seemore"
          >See more
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import CardLabel from '../common/CardLabel.vue'

export default {
  name: 'ServicePrepExperienceCard',
  components: { CardLabel },
  computed: {
    ...mapGetters('signup', ['payload', 'SIGN_UP_CLOSE_TS', 'spe']),
    ...mapState('timer', ['currDate']),
  },
  created() {
    this.$store.dispatch('signup/fetchSignup')
    this.$store.dispatch('signup/fetchDates')
  },
  methods: {
    isSignupClosed() {
      const signUpDate = new Date(this.SIGN_UP_CLOSE_TS)
      const currentDate =
        this.currDate === '' ? new Date() : new Date(this.currDate)
      return currentDate.getTime() >= signUpDate.getTime()
    },
    getSignUpText() {
      if (this.isSignupClosed()) return 'Sign up is closed.'

      const dayStr = moment(this.SIGN_UP_CLOSE_TS).format(
        'ddd, DD MMM, hh:mm A'
      )
      return 'Indicate your interest by ' + dayStr + '.'
    },
    getLabelColor(spe) {
      if (!spe) return 'grey'

      const STATUS_MAP = {
        Unavailable: 'grey-20',
        Pending: 'lightyellow',
        Assigned: 'green',
        Confirmed: 'green',
      }
      return STATUS_MAP[spe.status]
    },
    getLabelText(spe) {
      if (!spe) return 'Limited Slots'
      return 'Slot ' + spe.status
    },
  },
}
</script>
