<template>
  <svg v-if="!isActive" width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.3333 6.66699H8.33329C6.86053 6.66699 5.66663 7.8609 5.66663 9.33366V25.3337C5.66663 26.8064 6.86053 28.0003 8.33329 28.0003H24.3333C25.8061 28.0003 27 26.8064 27 25.3337V9.33366C27 7.8609 25.8061 6.66699 24.3333 6.66699Z" stroke="#99A4B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.6666 4V9.33333" stroke="#99A4B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.9999 4V9.33333" stroke="#99A4B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.66663 14.667H27" stroke="#99A4B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.99988 20C8.99988 21.1046 9.89531 22 10.9999 22C12.1045 22 12.9999 21.1046 12.9999 20C12.9999 18.8954 12.1045 18 10.9999 18C9.89531 18 8.99988 18.8954 8.99988 20Z" fill="#99A4B2"/>
<path d="M24.3333 6.66699H8.33329C6.86053 6.66699 5.66663 7.8609 5.66663 9.33366V14.5H27V9.33366C27 7.8609 25.8061 6.66699 24.3333 6.66699Z" stroke="#99A4B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  <svg v-else width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.3332 6.66699H8.33317C6.86041 6.66699 5.6665 7.8609 5.6665 9.33366V25.3337C5.6665 26.8064 6.86041 28.0003 8.33317 28.0003H24.3332C25.8059 28.0003 26.9998 26.8064 26.9998 25.3337V9.33366C26.9998 7.8609 25.8059 6.66699 24.3332 6.66699Z" stroke="#00AAE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.6665 4V9.33333" stroke="#00AAE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.9998 4V9.33333" stroke="#00AAE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.99976 20C8.99976 21.1046 9.89519 22 10.9998 22C12.1043 22 12.9998 21.1046 12.9998 20C12.9998 18.8954 12.1043 18 10.9998 18C9.89519 18 8.99976 18.8954 8.99976 20Z" fill="#00AAE4"/>
<path d="M24.3332 6.66699H8.33317C6.86041 6.66699 5.6665 7.8609 5.6665 9.33366V14.5H26.9998V9.33366C26.9998 7.8609 25.8059 6.66699 24.3332 6.66699Z" fill="#00AAE4" stroke="#00AAE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: 'ScheduleIcon',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
