<template>
  <empty-layout>
    <div class="relative bg-grey-5 text-grey-100 font-aktiv-grotesk">
      <div
        class="flex flex-col container mx-auto px-6 md:px-16 lg:px-32 xl:px-48 2xl:px-[310px] py-12"
      >
        <h1 class="text-36-lh-32 font-bold">Terms of Use</h1>

        <h2 class="text-28-lh-32 font-bold mt-8 mb-4">
          Welcome to our GenerationS Pastors Conference App!
        </h2>

        <h2 class="text-28-lh-32 font-bold mt-8 mb-4">1. Service(s)</h2>
        <p class="text-18-lh-38">
          <b>1.1</b> The products and services on this website are provided by
          Heart of God Church Group (HOGC). These Terms of Use ("Terms") govern
          your use of HOGC's websites, apps, and other services, including but
          not limited to:
          <span class="indent">
            <b>(a)</b> HOGC’s main website, accessible at
            <a
              class="text-lightblue"
              target="_blank"
              href="https://heartofgodchurch.org"
            >
              https://heartofgodchurch.org</a
            >;
          </span>
          <span class="indent">
            <b>(b)</b> The video streaming platform (“HOGC On Air”), accessible
            at
            <a
              class="text-lightblue"
              target="_blank"
              href="https://on-air.heartofgodchurch.org"
              >https://on-air.heartofgodchurch.org</a
            >
            and
            <a
              class="text-lightblue"
              target="_blank"
              href="https://youth.heartofgodchurch.org"
              >https://youth.heartofgodchurch.org;</a
            >
          </span>
          <span class="indent">
            <b>(c)</b> HOGC’s stories / blog site (“HOGC Stories”), accessible
            at
            <a
              class="text-lightblue"
              target="_blank"
              href="https://hogcstories.com"
              >https://hogcstories.com</a
            >;
          </span>
          <span class="indent">
            <b>(d)</b> HOGC’s Academic Excellence website, accessible at
            <a
              class="text-lightblue"
              target="_blank"
              href="https://www.hogcae.com/"
              >https://www.hogcae.com/</a
            >;
          </span>
          <span class="indent">
            <b>(e)</b> HOGC’s John 3:16 application, accessible at
            <a
              class="text-lightblue"
              target="_blank"
              href="https://john316app.com/"
              >https://john316app.com/</a
            >;
          </span>
          <span class="indent">
            <b>(f)</b> An online one-stop guide to running interfaith events, a
            collaboration between HOGC and Masjid Khalid mosque, accessible at
            <a
              class="text-lightblue"
              target="_blank"
              href="https://interfaith.sg/"
              >https://interfaith.sg/</a
            >;
          </span>
          <span class="indent">
            <b>(g)</b> The Senior Pastors’ websites, accessible at
            <a
              class="text-lightblue"
              target="_blank"
              href="https://pastorhow.com"
              >https://pastorhow.com</a
            >
            and
            <a
              class="text-lightblue"
              target="_blank"
              href="https://pastorlia.com"
              >https://pastorlia.com</a
            >
            respectively; and
          </span>
          <span class="indent">
            <b>(h)</b> The Senior Pastors’ publication “GenerationS” website,
            accessible at
            <a
              class="text-lightblue"
              target="_blank"
              href="https://generationsmvmt.com"
              >https://generationsmvmt.com</a
            >; and
          </span>
          <span class="indent">
            <b>(i)</b> The application(s) developed for GenerationS Pastors Conference(s), accessible at
            <a
              class="text-lightblue"
              target="_blank"
              href="https://generations.app"
              >https://generations.app</a
            >, 
            <a
              class="text-lightblue"
              target="_blank"
              href="https://apps.apple.com/us/app/generations-pastors-conference/id6450853590?platform=iphone"
              >Apple App Store</a
            >
             and 
            <a
              class="text-lightblue"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=app.mobile.conf.gens&hl=en_US"
              >Google Play Store</a
            >.
          </span>
          <br />

          (collectively referred to as the <b>"Services"</b>)

          <br />
          <br />
          <b>1.2</b> As some of our Services may be software that is downloaded
          to your computer, phone, tablet, or other device, you agree that we
          may automatically update such software, and that these Terms will
          apply to such updates. Please read these Terms carefully, and contact
          us if you have any questions or concerns. By using our Services, you
          agree to be bound by these Terms, including the policies referenced in
          these Terms. If you disagree with any part of these Terms, you must
          immediately discontinue your access or use of the Services.
          <br /><br />
          <b>1.3</b> Specific terms and conditions of use appearing on specific
          web pages and/or sections of the Services may be applicable to such
          sections of the Services. HOGC may also from time to time issue
          guidelines and/or notices (“Guidelines”) pertaining to the access to
          and/or use of the Services. Such specific terms and conditions of use,
          and Guidelines, shall constitute a part of these Terms. <br /><br />
          <b>1.4</b> In the event of any conflict between such specific terms
          and conditions of use, the Guidelines, and these Terms, the order in
          which the documents shall prevail (to the extent of such conflict)
          shall be as follow:
          <span class="indent">
            <b>1)</b> the specific terms and conditions of use;
          </span>
          <span class="indent"> <b>2)</b> the Guidelines; then, </span>
          <span class="indent"> <b>3)</b> these Terms. </span><br />
          <b>1.5</b> HOGC reserves the right at any time, from time to time, to
          make changes to the whole or any part of these Terms and/or the
          Services as it deems appropriate. Your continued access to the
          Services shall constitute your acknowledgement and acceptance of the
          changes made to these Terms, and the Guidelines issued. <br /><br />
        </p>
        <h2 class="text-28-lh-32 font-bold mt-8 mb-4">2. Copyright</h2>
        <p class="text-18-lh-38">
          <b>2.1</b> The Services and its contents are subject to copyright
          protection under the laws of Singapore and, through international
          treaties, other countries. The copyright in the contents and materials
          made available through the Services, whether individually or as a
          whole, is owned by HOGC. However, the copyright in some contents and
          materials incorporated within the Services may be owned by third
          parties where so indicated. <br /><br />
          <b>2.2</b> No part of the contents or materials made available through
          the Services may be reproduced, licensed, sold, published,
          transmitted, modified, adapted, publicly displayed, broadcast
          (including storage in any medium by electronic means whether or not
          transiently for any purpose save as permitted herein) without the
          prior written permission of HOGC. Unless stated otherwise, you may
          view the Services and its constituent contents and materials and save
          an electronic copy, or print out a copy, of parts of such contents and
          materials solely for your own information, research or study, provided
          you (a) do not modify the copy from how it originally appears on the
          respective Service(s); and (b) include the copyright notice "© Heart
          of God Church" on such copy. <br /><br />
          <b>2.3</b> Except with the prior written permission of HOGC, you may
          not create a derivative work from the contents and materials provided
          through the Services, nor decompile, reverse-engineer or disassemble
          such contents and materials. <br /><br />
          <b>2.4</b> HOGC’s logos and trademarks should not be removed from
          pages on which they originally appear. HOGC's web pages should always
          appear exactly as posted without variation unless the prior written
          approval of HOGC has been obtained. <br /><br />
          <b>2.5</b> You must not otherwise exercise the copyright in the whole
          or any part of the contents and materials in the Services for any
          other purpose except as expressly permitted by any applicable law or
          with HOGC’s prior written consent. <br /><br />
          <b>2.6</b> Any rights relating to the contents and materials provided
          through the Services that is not expressly granted herein are
          reserved, and no licence or right is granted to you by implication,
          estoppel or otherwise. <br /><br />
        </p>
        <h2 class="text-28-lh-32 font-bold mt-8 mb-4">3. Trademarks</h2>
        <p class="text-18-lh-38">
          <b>3.1</b> The logo and name of HOGC or any of its affiliates are
          trademarks of HOGC or its affiliates. HOGC has policies governing the
          use of its name, including the names of its programmes, and its
          trademarks. The use, reproduction, copying or redistribution of
          trademarks without the prior written permission of HOGC or its
          affiliates is prohibited. All other trademarks appearing on the
          Services are the marks of their respective owners.<br /><br />
        </p>
        <h2 class="text-28-lh-32 font-bold mt-8 mb-4">
          4. Links from other websites
        </h2>
        <p class="text-18-lh-38">
          <b>4.1</b> HOGC supports and encourages good online etiquette. HOGC
          should be informed of links to its Services from external webpages,
          websites and/or apps. However, HOGC reserves the right to require the
          removal of any links from external webpages, websites and/or apps to
          its Services. <br /><br />
          <b>4.2</b> Unless otherwise stated, you may not link, mirror, or frame
          the Services or any part thereof to any other webpage, website, server
          or otherwise. Except with the prior written permission of HOGC, no
          trademark, trade name, service mark, product name or logo of HOGC may
          be used as a link or to mark any link to the Services or any part
          thereof, or any other webpage or website. HOGC requires that the
          contents of its Services not appear within the frames of third-party
          webpages, websites and/or apps, nor be accompanied in any way by
          third-party material that may create a false or mistaken impression in
          the mind of the viewer about HOGC’s affiliation or association with or
          endorsement of the third-party site, page, app, frame, or material.
          <br /><br />
          <b>4.3</b> Subject to the restrictions below, you are allowed to
          hyperlink to the Services or to deep-link to certain specific webpages
          or sections of the Services:
          <span class="indent">
            <b>(a)</b> You may not deep-link to the Services or any part thereof
            which has been dynamically created by our system and customised for
            particular usage sessions; and
          </span>
          <span class="indent">
            <b>(b)</b> You may not hyperlink or deep-link to the Services or any
            part thereof from any webpage, website and/or app which contains
            materials or information that is inappropriate, profane, defamatory,
            infringing, obscene, indecent, vulgar, harassing, privacy invading,
            abusive, threatening, harmful, tortious, objectionable, unlawful, or
            violates any applicable intellectual property or proprietary rights.
          </span>
          <br />
          <b>4.4</b> Notwithstanding anything to the contrary in these Terms,
          HOGC reserves all rights to terminate any link from any webpage,
          website and/or app to the Services or any part thereof for any reason.
          Upon HOGC’s instructions, you must disable, remove, and terminate any
          link from any webpage, website and/or app, or any mirroring or framing
          of the Services or any part thereof immediately. <br /><br />
          <b>4.5</b> HOGC bears no responsibility for the content available on
          any third-party webpage, website and/or app which links, mirrors or
          frames our Services or any part thereof. In no circumstances will HOGC
          be considered to be associated or affiliated in whatever manner with
          any trademarks, trade names, service marks, logos, insignia or other
          devices used or appearing on third-party webpages, websites and/or
          apps that link, mirror or frame our Services or any part thereof.
          <br /><br />
        </p>
        <h2 class="text-28-lh-32 font-bold mt-8 mb-4">5. Disclaimer</h2>
        <p class="text-18-lh-38">
          <b>5.1</b> You acknowledge and agree that all the material and content
          made available on or through the Services is provided "as is". HOGC
          has used reasonable endeavours to ensure that the information and
          materials provided through its Services are correct at the time of
          publication or posting. However, HOGC gives no warranty and accepts no
          responsibility or liability for the accuracy or the completeness of
          the information and materials provided through the Services for any
          purpose whatsoever. No reliance should be made by any user on the
          information or material so posted; instead, you should independently
          verify the accuracy and completeness of the information and/or
          materials with the relevant body. <br /><br />
          <b>5.2</b> You acknowledge and agree that HOGC does not guarantee,
          represent or warrant that our Services are free of malicious software,
          including but not limited to computer viruses, Trojan horses, worms,
          logic bombs, time bombs, cancelbots, corrupted files or any other
          similar software, program, or material which is malicious or
          technologically harmful. <br /><br />
          <b>5.3</b> You acknowledge and agree that HOGC shall not be held
          responsible or liable in any way for any and/or all consequences
          (including, without limitation, damages for loss of profits, business
          interruption, or loss of information) that may be incurred by you as a
          direct or indirect result of using, or the inability to use, any
          materials or contents on the Services, even if HOGC has been advised
          of the possibility of such damages in advance; and no right of action
          will arise as a result of personal injury or property damage,
          howsoever arising, sustained as a result of reference to, or reliance
          upon, any information contained in, or omitted from, the Services,
          whether through neglect or otherwise. <br /><br />
          <b>5.4</b> The Services may contain links to other internet sites or
          resources operated by parties other than HOGC. Such links are provided
          for the convenience of users of the Services. As HOGC has no control
          over such sites and resources, you acknowledge and agree that HOGC is
          not responsible nor liable for any content or material on or available
          from such sites or resources. In providing such links, HOGC does not
          in any way, expressly or implicitly, endorse the linked sites or
          resources or the respective contents thereof. You further acknowledge
          and agree that HOGC shall not be responsible or liable, whether
          directly or indirectly, for any damage or loss caused or sustained by
          or alleged to be caused or sustained by you, in connection with the
          use or reliance on any information or material available on such
          linked sites or resources. <br /><br />
        </p>
        <h2 class="text-28-lh-32 font-bold mt-8 mb-4">
          6. User-Contributed Content
        </h2>
        <p class="text-18-lh-38">
          <b>6.1</b> Where you make use of any feature that allows you to post
          or upload content to the Services, or to make contact with other users
          of the Services, you must comply with the content standards set out in
          this Clause 6. The content that you post or upload must comply with
          the applicable law in Singapore (including but not limited to the
          Maintenance of Religious Harmony Act (Chapter 167A) and the Sedition
          Act (Chapter 290) and in any country from which they are posted or
          uploaded. <br /><br />
          <b>6.2</b> In particular, such content must not:
          <span class="indent">
            <b>(a)</b> Contain any material which is defamatory of any
            person;</span
          >
          <span class="indent">
            <b>(b)</b> Contain any material which is obscene, offensive,
            illegal, unlawful, fraudulent, defamatory, libellous, harassing,
            hateful, inflammatory, racially or ethnically offensive, or
            encourages conduct that would be considered a criminal offense, give
            rise to civil liability, violate any law, or is otherwise
            inappropriate;</span
          >
          <span class="indent">
            <b>(c)</b> Contain any unsolicited or unauthorized advertising,
            promotional materials, spam, junk mail, chain letters, pyramid
            schemes, or any other form of unauthorized solicitation;</span
          >
          <span class="indent">
            <b>(d)</b> Contain material regarding sweepstakes, contests, or
            lotteries, or otherwise related to gambling;</span
          >
          <span class="indent">
            <b>(e)</b> Promote sexually explicit material;</span
          >
          <span class="indent"> <b>(f)</b> Promote violence;</span>
          <span class="indent">
            <b>(g)</b> Promote discrimination based on race, sex, religion,
            nationality, disability, sexual orientation or age;</span
          >
          <span class="indent">
            <b>(h)</b> Infringe any intellectual property right of any other
            person;</span
          >
          <span class="indent">
            <b>(i)</b> Be likely to deceive any person;</span
          >
          <span class="indent">
            <b>(j)</b> Be made in breach of any legal duty owed to a
            third-party, such as a contractual duty or a duty of
            confidence;</span
          >
          <span class="indent"> <b>(k)</b> Promote any illegal activity;</span>
          <span class="indent">
            <b>(l)</b> Contain any content with seditious tendency or political
            references;</span
          >
          <span class="indent">
            <b>(m)</b> Promote ill-will and hostility amongst the users or
            general public of Singapore;</span
          >
          <span class="indent">
            <b>(n)</b> Be threatening, abuse or invade another’s privacy, or
            cause annoyance, inconvenience or needless anxiety;</span
          >
          <span class="indent">
            <b>(o)</b> Be likely to harass, upset, embarrass, alarm or annoy any
            other person;</span
          >
          <span class="indent">
            <b>(p)</b> Be used to impersonate any person, or to misrepresent
            your identity or affiliation with any person;</span
          >
          <span class="indent">
            <b>(q)</b> Give the impression that the content emanates from HOGC
            if that is not the case; and/or</span
          >
          <span class="indent">
            <b>(r)</b> Advocate, promote or assist any unlawful act such as, for
            example, copyright infringement or computer misuse.</span
          >
          <span class="indent">
            You irrevocably and unconditionally represent and warrant that any
            content you post or upload to or through the Services complies with
            the content standards set out above.</span
          >
          <br />
          <b>6.3</b> You irrevocably and unconditionally represent and warrant
          that any content you post or upload to or through our services does
          not violate, misappropriate or infringe the rights of any third-party,
          including but not limited to privacy rights, copyright, trademark
          and/or any other intellectual property rights. <br /><br />
          <b>6.4</b> HOGC has the right to disclose your identity to any
          third-party claiming that any content posted or uploaded by you to the
          Services constitutes a violation of their intellectual property
          rights. <br /><br />
          <b>6.5</b> HOGC will not be responsible or liable to any third-party
          for the content or accuracy of any content posted or uploaded by you
          or any other user of the Services. HOGC does not undertake to review
          user contributions or any other material before it is posted or
          uploaded on or through the Services, and cannot ensure prompt removal
          of objectionable material after it has been posted or uploaded. HOGC
          hence assumes no liability for any action or inaction regarding
          transmissions, communications, or content provided by any user or
          third-party. <br /><br />
          <b>6.6</b> All content posted or uploaded on or through the Services
          must comply with these Terms. By posting or uploading any content or
          material on or through the Services, you grant HOGC and its service
          providers, and each of their and HOGC’s respective licensees,
          successors, and assigns, the right to use, reproduce, modify, perform,
          display, license, distribute, and otherwise disclose to third parties
          any such material in order to offer the Services. You represent and
          warrant that you own or control all rights in and to the content or
          material that you post or upload on or through the Services, and that
          you have the right to grant this license and that all of your
          contributions do and will comply with these Terms. HOGC will not be
          responsible or liable to any third-party for the content or accuracy
          of any content or material posted or uploaded to the Services by you
          or any other user of the Services. <br /><br />
          <b>6.7</b> HOGC reserves the right to remove your content from the
          Services where your content does not comply with the content standards
          set out in this Clause 6. While we make all reasonable efforts to vet
          content posted by users of the Services, we hereby disclaim all
          liability for any content posted by other users that are of an
          offensive nature and/or infringe on third-party intellectual property
          rights (the “Infringing Material”). Kindly contact us at
          <a
            class="text-lightblue"
            target="_blank"
            href="mailto:info@heartofgodchurch.org"
            >info@heartofgodchurch.org</a
          >
          if you wish to report any Infringing Material, and we shall promptly
          take down such Infringing Material from our Services. <br /><br />
          <b>6.8</b> Notwithstanding the foregoing, and unless agreed otherwise,
          you shall remain the owner of any content you post or upload on or
          through the Services, provided, however, that HOGC shall have the
          non-exclusive, worldwide, perpetual right to use, distribute, license
          and sublicense, translate, exploit and copy such content, in our sole
          discretion; and provided, further, that you shall not be entitled to
          any compensation whatsoever in connection with the use and enjoyment
          of our rights described above. <br /><br />
        </p>
        <h2 class="text-28-lh-32 font-bold mt-8 mb-4">7. Prohibited Uses</h2>
        <p class="text-18-lh-38">
          <b>7.1</b> You agree that you will not use or attempt to use any
          method, device, software, or routine to harm others or interfere with
          the functioning of any of the Services, or use and/or monitor any
          information in or related to the Services for any unauthorized
          purpose. <br /><br />
          <b>7.2</b> In addition to the general restrictions above, the
          following restrictions and conditions apply specifically to your use
          of content and material on the Services. Any determination regarding
          breach of any of the following is final. Please review the following
          list of prohibited uses carefully before using the Services.
          Specifically, you agree not to use any of the Services to:
          <span class="indent">
            <b>(a)</b> Deploy programs, software, or applications designed to
            interrupt, destroy or limit the functionality of any computer
            software or hardware or telecommunications equipment, including by
            engaging in any denial of service attack or similar conduct;</span
          >
          <span class="indent">
            <b>(b)</b> Deploy or use programs, software or applications designed
            to harm, interfere with the operation of, or access in an
            unauthorized manner, services, networks, servers, or other
            infrastructure;</span
          >
          <span class="indent">
            <b>(c)</b> Exceed your authorized access to any portion of the
            Services;</span
          >
          <span class="indent">
            <b>(d)</b> Remove, delete, alter, circumvent, avoid, or bypass any
            digital rights management technology, encryption or security tools
            used anywhere on the Services or in connection with the
            Services;</span
          >
          <span class="indent">
            <b>(e)</b> Collect or store personal data about any person without
            the appropriate authorisation or consent;</span
          >
          <span class="indent">
            <b>(f)</b> Alter or modify without permission any part of the
            Services or their content, including ads;</span
          >
          <span class="indent">
            <b>(g)</b> Obtain or attempt to access or otherwise obtain any
            content or information through any means not intentionally made
            available or provided for through the Services;</span
          >
          <span class="indent">
            <b>(h)</b> Exploit errors in design, features which are not
            documented, and/or bugs to gain access to any part of the Services
            that would otherwise not be available to you.</span
          >
          <br />

          <b>7.3</b> Additionally, you agree not to:
          <span class="indent">
            <b>(a)</b> Use the Services in any manner that could disable,
            overburden, damage, or impair the site or interfere with any other
            person’s use of the Services, including their ability to engage in
            real time activities through the Services;</span
          >
          <span class="indent">
            <b>(b)</b> Use any robot, spider, or other automatic device,
            process, or means to access the Services for any purpose, including
            monitoring or copying any of the material on the Services without
            our prior written consent;</span
          >
          <span class="indent">
            <b>(c)</b> Use any manual process to monitor or copy any of the
            material on the Services or for any other unauthorized purpose
            without our prior written consent;</span
          >
          <span class="indent">
            <b>(d)</b> Use any information obtained from or through the Services
            to block or interfere with the display of any advertising on the
            Services, or for the purpose of implementing, modifying or updating
            any software or filter lists that block or interfere with the
            display of any advertising on the Services;</span
          >
          <span class="indent">
            <b>(e)</b> Use any device, bots, scripts, software, or routine that
            interferes with the proper working of the Services or that shortcut
            or alter the Services’ functions to run or appear in ways that are
            not intended by the design of the Services;</span
          >
          <span class="indent">
            <b>(f)</b> Introduce or upload any viruses, Trojan horses, worms,
            logic bombs, time bombs, cancelbots, corrupted files or any other
            similar software, program, or material which is malicious or
            technologically harmful or that that may damage the operation of
            another’s property or of the Services;</span
          >
          <span class="indent">
            <b>(g)</b> Attempt to gain unauthorized access to, interfere with,
            damage, or disrupt any parts of the Services, the server on which
            the Services are stored, or any server, computer, or database
            connected to the Services;</span
          >
          <span class="indent">
            <b>(h)</b> Remove any copyright or other proprietary notices from
            the Services or any of the materials contained therein;</span
          >
          <span class="indent">
            <b>(i)</b> Attack the Services via a denial-of-service attack or a
            distributed denial-of-service attack;</span
          >
          <span class="indent">
            <b>(j)</b> Otherwise attempt to interfere with the proper working of
            the Services.</span
          >
          <br />
        </p>
        <h2 class="text-28-lh-32 font-bold mt-8 mb-4">
          8. Monitoring and Enforcement
        </h2>
        <p class="text-18-lh-38">
          <b>8.1</b> HOGC has the right, but not the obligation, to:
          <br />
          <span class="indent">
            <b>(a)</b> remove or refuse to post any material or content you
            submit or contribute to the Services for any or no reason in our
            sole discretion;
          </span>
          <span class="indent">
            <b>(b)</b> monitor any communication occurring on or through the
            Services to confirm compliance with these Terms, the security of the
            Services, or any legal obligation;
          </span>
          <span class="indent">
            <b>(c)</b> take any action with respect to any material or content
            posted by you that we deem necessary or appropriate in our sole
            discretion, including if HOGC believes that such material or content
            violates these Terms, infringes any intellectual property right or
            other right of any person or entity, threatens the personal safety
            of users of the Services or the public, or could create liability
            for HOGC;
          </span>
          <span class="indent">
            <b>(d)</b> disclose personally identifying information or other
            information about you to any third-party who claims that material or
            content posted by you violates their rights, including their
            intellectual property rights, or their right to privacy;
          </span>
          <span class="indent">
            <b>(e)</b> take appropriate legal action, including without
            limitation, referral to law enforcement, for any illegal or
            unauthorized use of the Services;
          </span>
          <span class="indent">
            <b>(f)</b> terminate or suspend your access to all or part of the
            Services for any or no reason, including, without limitation, any
            violation of these Terms.
          </span>
          <br />

          <b>8.2</b> Without limiting the foregoing, HOGC has the right to fully
          cooperate with any law enforcement authorities or court order
          requesting or directing HOGC to disclose the identity or other
          information of anyone posting any material or content on or through
          the Services. You waive and hold harmless HOGC and its site operators,
          their parent entities, their respective affiliates, licensors, service
          providers, officers, directors, employees, agents, successors, and
          assigns from any claims resulting from any action taken by HOGC or any
          of the foregoing parties during or as a result of its investigations
          and from any actions taken as a consequence of investigations by
          either HOGC, such parties, or law enforcement authorities.
          <br /><br />
        </p>
        <h2 class="text-28-lh-32 font-bold mt-8 mb-4">9. Personal Data</h2>
        <p class="text-18-lh-38">
          <b>9.1</b> HOGC respects the privacy of individuals and recognizes the
          importance of the personal data you have entrusted to us, and believe
          that it is our responsibility to properly manage, protect and process
          your personal data. <br /><br />
          <b>9.2</b> If you choose to share your personal data via an e-mail or
          any other form, whether through the Services or otherwise, we
          recommend that you read our Privacy Policy at
          <a
            target="_blank"
            class="text-lightblue"
            href="https://heartofgodchurch.org/privacy-policy"
            >https://heartofgodchurch.org/privacy-policy</a
          >
          and
          <a
            target="_blank"
            class="text-lightblue"
            href="https://privacy.heartofgodchurch.org"
            >https://privacy.heartofgodchurch.org</a
          >, explaining how we use your personal data when you interact with us
          via the Services or in any other way. Please also note that where the
          Services contain links to other websites not maintained by HOGC, such
          third-party websites are subject to their own data protection and
          privacy practices and you are encouraged to examine the privacy
          policies of those websites. <br /><br />
          <b>9.3</b> If you have any queries on this policy or have any other
          queries in relation to how we process your personal data, please
          examine our Privacy Policy, which contains information on how to
          contact our Data Protection Officer. <br /><br />
        </p>
        <h2 class="text-28-lh-32 font-bold mt-8 mb-4">10. Cookies</h2>
        <p class="text-18-lh-38">
          <b>10.1</b> Some functionalities of the Services may require the use
          of cookies. Cookies are small data files which are sent to your
          browser or device to store and track certain information about you
          when you use the Services. Cookies are also used to improve and
          maintain the functionality of the Services, and to track information
          such as the number of users of the Services, their frequency of use,
          the profile of users and other similar information relating to the use
          of the Services. <br /><br />
          <b>10.2</b> We use cookies for the following purposes:
          <br />
          <span class="indent">
            <b>(a)</b> Technical Necessity – these are essential cookies which
            allows access and operation of our website, including to:
          </span>
          <span class="indent-lg">
            <b>(i)</b> enable you to navigate our website and use its features;
            and,
          </span>
          <span class="indent-lg">
            <b>(ii)</b> authenticate users and prevent fraudulent use;
          </span>
          <span class="indent">
            <b>(b)</b> Functionality – these cookies assist in making the
            navigating of our website easier for you, including to;
          </span>
          <span class="indent-lg">
            <b>(i)</b> remember your preferences and information; and,
          </span>
          <span class="indent-lg">
            <b>(ii)</b> provide enhanced personalisation and customisation to
            features during your use of our website (such as your user name and
            language preferences);
          </span>
          <span class="indent">
            <b>(c)</b> Performance and Analytics – these cookies gather
            statistics on your navigation and use of our website to improve how
            our website functions and to understand how our users interact with
            our website, including to;
          </span>
          <span class="indent-lg">
            <b>(i)</b> collect information on the frequency in which our
            webpages are visited; and,
          </span>
          <span class="indent-lg">
            <b>(ii)</b> record error messages that are received on our webpages;
          </span>
          <span class="indent">
            <b>(d)</b> Advertising – these cookies allow us to present
            appropriate advertising to our users, including to:
          </span>
          <span class="indent-lg">
            <b>(i)</b> collect information on the levels of interaction with
            ads, websites, and applications of our advertisers; and,
          </span>
          <span class="indent-lg">
            <b>(ii)</b> allow third parties to display appropriate advertising
            on their pages.
          </span>
          (collectively, the “Purposes”)
          <br /><br />

          <b>10.3</b> While these cookies can provide some degree of information
          and insight on when and how you use the Services, they cannot read
          data off the hard disk or storage media of your device. <br /><br />
          <b>10.4</b> Not all cookies collect personal data, and you can control
          cookies through your web browser settings. This allows you to
          determine which cookies to allow and which to refuse. If you disallow
          the use of cookies on our website, please note that you may not be
          able to access the full range of functions that our website offers.
          <br /><br />
          <b>10.5</b> Personal data (if any) that we collect from you through
          cookies may be passed to our third party service providers, whether
          located in Singapore or elsewhere, for one or more of the Purposes,
          for managing and/or administering our website, or for the purpose of
          data hosting/storage/backup. <br /><br />
          <b>10.6</b> Your use of our website constitutes consent by you to our
          use of cookies and to the matters set out herein. <br /><br />
        </p>
        <h2 class="text-28-lh-32 font-bold mt-8 mb-4">
          11. Governing Law and Jurisdiction
        </h2>
        <p class="text-18-lh-38">
          <b>11.1</b> These Terms shall be governed by, and construed in
          accordance with, the laws of the Republic of Singapore. <br /><br />
          <b>11.2</b> These Terms shall be interpreted solely with reference to
          this English language version of these Terms, notwithstanding any
          translation of these Terms. <br /><br />
          <b>11.3</b> Subject to Clause 11.3, any dispute arising out of or in
          connection with these Terms, including any question regarding the
          existence, validity or termination of these Terms, will be referred to
          and finally resolved in the Courts of the Republic of Singapore and
          you submit to the exclusive jurisdiction of the Courts of the Republic
          of Singapore. <br /><br />
          <b>11.4</b> We may, at our sole discretion, refer any dispute referred
          to in Clause 11.2 to arbitration administered by the Singapore
          International Arbitration Centre ("SIAC") in Singapore in accordance
          with the Arbitration Rules of the SIAC ("SIAC Rules") for the time
          being in force, which rules are deemed to be incorporated by reference
          in this Clause. Further:
          <span class="indent">
            <b>(a)</b> the seat of the arbitration will be Singapore;</span
          >
          <span class="indent">
            <b>(b)</b> the tribunal will consist of one (1) arbitrator to be
            agreed upon in accordance with the SIAC Rules, except that if no
            agreement is reached within thirty (30) calendar days after receipt
            by one party of such a proposal from the other, the arbitrator will
            be appointed by the Chairman of the SIAC;</span
          >
          <span class="indent">
            <b>(c)</b> the language of the arbitration will be English;
            and</span
          >
          <span class="indent">
            <b>(d)</b> all information, pleadings, documents, evidence, and all
            matters relating to the arbitration will be confidential.</span
          >
          <br />
          <b>11.5</b> Where HOGC is a defendant or respondent, HOGC shall have
          at least thirty (30) calendar days before the commencement of any
          legal action against HOGC to elect to exercise the right under this
          Clause to have the dispute submitted to arbitration. This right to
          elect will not limit HOGC’s right to a limitation defence and the
          period to exercise the right will not be abridged by reason of any
          accrual of a limitation defence in HOGC’s favour during the said
          period.
          <br />
          <br />
        </p>
        <h2 class="text-28-lh-32 font-bold mt-8 mb-4">12. General</h2>
        <p class="text-18-lh-38">
          <b>12.1</b> These Terms are the entire agreement between you and HOGC
          in relation to your access and use of the Services, and shall
          supersede all previous communications (whether written, oral or
          otherwise), discussions or letters relating to the same. <br /><br />
          <b>12.2</b> If any provision of these Terms, in whole or in part, is
          held to be illegal, invalid or unenforceable under any enactment or
          rule of law or by any court in any jurisdiction: (i) such provision or
          part will, to that extent, be deemed not to form part of these Terms,
          but the legality, validity and enforceability of the remainder of
          these Terms is not affected; and (ii) the legality, validity and
          enforceability of that or any other provision or part under any other
          jurisdiction will not be affected. <br /><br />
          <b>12.3</b> You agree to indemnify, defend and hold HOGC harmless from
          and against all claims, demands, actions, losses, damages, costs and
          expenses (including legal fees), arising out of and/or in connection
          with your breach of these Terms, your access or use of the Services,
          and/or your failure to comply with any applicable laws or regulations.
          <br /><br />
          <b>12.4</b> These Terms are entered into between you and HOGC. For the
          avoidance of doubt, except as expressly stated in these Terms, a
          person who is not a party to these Terms shall have no right under the
          Contracts (Rights of Third Parties) Act (Cap. 53B) to enforce any of
          the terms of these Terms. <br /><br />
          <b>12.5</b> HOGC has the right at its absolute discretion to enter
          into any sub-contract for the provision of the Services or any part
          thereof without the prior consent of you. <br /><br />
        </p>
        <h2 class="text-28-lh-32 font-bold mt-8 mb-4">13. Contact Us</h2>
        <p class="text-18-lh-38">
          <b>13.1</b> You may contact HOGC at: <br /><br />
          Heart of God Church<br />
          115 Eunos Ave 3 #04-01<br />
          Singapore 409839<br />
          <a class="text-lightblue" target="_blank" href="tel:+6563326086"
            >+65 6332 6086</a
          ><br />
          <a
            class="text-lightblue"
            target="_blank"
            href="mailto:info@heartofgodchurch.org"
            >info@heartofgodchurch.org</a
          >

          <br /><br />
          <b>13.2</b> Any notice that HOGC intends to give to you may be carried
          out by posting the relevant notice on the Services. You are deemed to
          have received notice of the same upon HOGC posting the relevant notice
          on the Services.
        </p>
      </div>
      <Footer />
    </div>
  </empty-layout>
</template>

<script>
import EmptyLayout from '@/components/EmptyLayout.vue';
export default {
  layout: "empty",
  auth: false,
  components: {
    EmptyLayout
  }
};
</script>

<style scoped lang="scss">
.indent {
  display: block;
  padding-left: 2rem;
}
.indent-lg {
  display: block;
  padding-left: 4rem;
}
</style>