<template>
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.613281"
      y="0.41748"
      width="44.0367"
      height="44.0367"
      rx="22.0184"
      fill="#7C8A9C"
      class="hover:fill-[#637083]"
    />
    <ellipse
      cx="14.3745"
      cy="22.4356"
      rx="4.12844"
      ry="4.12844"
      stroke="white"
      stroke-width="2.19572"
    />
    <ellipse
      cx="28.1363"
      cy="30.6929"
      rx="4.12844"
      ry="4.12844"
      stroke="white"
      stroke-width="2.19572"
    />
    <ellipse
      cx="28.1363"
      cy="14.1787"
      rx="4.12844"
      ry="4.12844"
      stroke="white"
      stroke-width="2.19572"
    />
    <line
      x1="17.955"
      y1="19.4203"
      x2="24.9042"
      y2="15.4082"
      stroke="white"
      stroke-width="2.19572"
    />
    <line
      x1="17.6778"
      y1="24.9252"
      x2="24.627"
      y2="28.9373"
      stroke="white"
      stroke-width="2.19572"
    />
  </svg>
</template>
