<template>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <!-- <in-app-browser :href="mailtoLink" target="_blank"> -->
    <ion-fab-button @click="handleClick">
      <ion-icon :icon="mailIcon"></ion-icon>
    </ion-fab-button>
    <!-- </in-app-browser> -->
  </ion-fab>
</template>

<script>
import { IonFab, IonFabButton, IonIcon } from "@ionic/vue";
import { mail } from "ionicons/icons";
import InAppBrowser from "../InAppBrowser.vue";

export default {
  components: {
    IonFab,
    IonFabButton,
    IonIcon,
    InAppBrowser,
  },
  data() {
    return {
      mailIcon: mail,
      mailtoLink:
        "mailto:hello@generationsmvmt.com?subject=GenS%20App%20Enquiry",
    };
  },
  methods: {
    handleClick() {
      window.location.href = this.mailtoLink;
    },
  },
};
</script>

<style scoped></style>
